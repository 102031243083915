import React, { useMemo } from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import Icons from "@/components/Icons";
import enrichment from "@/services/enrichment.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import { useTableStore } from "@/stores/table.store";

import { EnrichmentApiCallPayload } from "@/types/enrichment.types";
import { generateFilterPayload } from "@/lib/utils";
import { useProviderList } from "@/hooks/useProviderData";
import { useModalStore } from "@/stores/modal.store";
import { convertSlateToText } from "@/utils";

interface Props {
  isDisabled?: boolean;
  apiPayload: Partial<EnrichmentApiCallPayload>;
}
const SaveAndRunEnrichment: React.FC<Props> = ({ isDisabled, apiPayload }) => {
  const toast = useToast();
  const updateState = useModalStore((state) => state.updateState);
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: EnrichmentApiCallPayload) =>
      enrichment.callEnrichmentApi(payload),
  });
  const { data: providerList } = useProviderList();

  const apiKeySelect = useEnrichStore((state) => state.keySelect);
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const runConditionFormula = useEnrichStore(
    (state) => state.runConditionFormula,
  );
  const isInvalidConditionFormula = useEnrichStore(
    (state) => state.isInvalidConditionFormula,
  );
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const isSelectedAll = useTableStore((state) => state.isSelectedAll);
  const tableRows = useTableStore((state) => state.rowsData);
  const tableData = useTableStore((state) => state.tableData);
  const tableId = useTableStore((state) => state.tableData._id);
  const queryParams = useTableStore((state) => state.queryParams);
  const tableFilters = useTableStore((state) => state.tableFilters);
  const totalRows = useTableStore((state) => state.totalRows);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);

  const updatedTable = useTableStore((state) => state.updateState);

  const isUserApiKeyExists = useMemo(() => {
    if (!providerList?.data) return false;

    return !!providerList.data?.filter(
      (provier) => provier.name === apiPayload.apiKeyName,
    ).length;
  }, [apiPayload.apiKeyName, providerList]);

  const clearAllState = () => {
    usePresetsStore.setState({
      selectedPresetValues: "",
      presetsModalData: {},
    });
    useEnrichStore.setState({
      examples: [],
      selectedEnrichments: null,
    });

    useTableStore.setState({
      isSelectedAll: false,
      selectedRowsId: [],
    });
  };

  const onSave = async ({
    isForAll = false,
    isForSelected = false,
    rowIds = [],
    runnigFor = 0,
  }: {
    isForAll?: boolean;
    isForSelected?: boolean;
    rowIds?: string[];
    runnigFor?: number;
  }) => {
    const { filterPayload } = generateFilterPayload(tableFilters.filters || []);

    const isUseOwnKey = apiKeySelect === "ownApiKey";

    if (isUseOwnKey && !isUserApiKeyExists && apiPayload.apiKeyName) {
      toast({
        title: "Please add your own API key",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const payLoad: EnrichmentApiCallPayload = {
      enrichmentName: selectedEnrichments?.enrichmentName || "",
      runCondition: isInvalidConditionFormula
        ? ""
        : convertSlateToText(runConditionFormula || []).text
          ? runConditionFormula
          : null,
      isUseOwnKey,
      isSelectedAll: isForAll || isSelectedAll,
      selectedRowsId: rowIds.length ? rowIds : selectedRowsId,
      tableId,
      queryParams,
      tableFilters: {
        filterBody: filterPayload,
      },
      totalDataToProcess: rowIds.length || selectedRowsId.length || totalRows,
      ...apiPayload,
    };

    await mutateAsync(payLoad, {
      onSuccess: (response) => {
        if (response?.error?.message?.includes("Not enough credit")) {
          updateState({
            isOpenModal: true,
            modalData: { modalType: "pricing" },
          });
        }
        if (!response.success || response.data.success === false) {
          toast({
            title:
              response?.error?.message ||
              response?.data?.message ||
              "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          updateTableMetaDta({
            isProcessing: false,
            totalDataToProcess: 0,
            processedData: 0,
          });
          return;
        }
        toast({
          title: response.data?.message || "Enrichment started",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        updateTableMetaDta({
          isProcessing: true,
          totalDataToProcess: payLoad.totalDataToProcess,
          processedData: 0,
        });

        clearAllState();

        const newAddedColumn = response?.data?.newColumns || [];
        const newColumns = tableData?.columns?.concat(newAddedColumn);

        const updatedRows = tableRows.map((row, index) => {
          const newRow = { ...row };
          if (isForSelected) {
            if (selectedRowsId.includes(row._id)) {
              if (newAddedColumn?.[0]?._id) {
                newRow.cells = {
                  ...newRow.cells,
                  [newAddedColumn?.[0]._id]: {
                    value: "queued...",
                  },
                };
              }
            }
          } else if (runnigFor) {
            if (index < runnigFor) {
              if (newAddedColumn?.[0]?._id) {
                newRow.cells = {
                  ...newRow.cells,
                  [newAddedColumn?.[0]._id]: {
                    value: "queued...",
                  },
                };
              }
            }
          } else {
            if (newAddedColumn?.[0]?._id) {
              newRow.cells = {
                ...newRow.cells,
                [newAddedColumn?.[0]._id]: {
                  value: "queued...",
                },
              };
            }
          }

          return newRow;
        });

        updatedTable({
          tableData: {
            ...tableData,
            metaData: {
              isProcessing: true,
              processedData: 0,
              totalDataToProcess: runnigFor || totalRows,
            },
            columns: newColumns,
          },
          rowsData: updatedRows,
          isCreatedNewColumn: true,
        });

        setTimeout(() => {
          updatedTable({
            isCreatedNewColumn: false,
          });
        }, 2000);
      },
      onError: (err) => {
        toast({
          title: err.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        updateTableMetaDta({
          isProcessing: false,
          totalDataToProcess: 0,
          processedData: 0,
        });
      },
    });
  };

  const handleRunForXRows = (rows: number) => {
    const rowsId = tableRows.slice(0, rows).map((row) => row._id);
    onSave({
      rowIds: rowsId,
      runnigFor: rows,
    });
  };

  const xNumbers = [5, 10, 20];

  return (
    <div className="flex items-center gap-y-2.5 border-t border-[#e7e8ec] p-4 flex-wrap">
      <div className="flex gap-x-4 justify-between w-full">
        {!!selectedRowsId.length && (
          <Button
            isDisabled={isDisabled || isPending || isInvalidConditionFormula}
            isLoading={isPending}
            size={"sm"}
            onClick={() =>
              onSave({ isForSelected: true, runnigFor: selectedRowsId?.length })
            }
            className="flex-1 w-full"
          >
            Save and run for selected ( {selectedRowsId?.length} )
          </Button>
        )}
        <div className="flex w-full flex-1">
          <Button
            isDisabled={isDisabled || isPending || isInvalidConditionFormula}
            isLoading={isPending}
            size={"sm"}
            onClick={() => handleRunForXRows(10)}
            sx={{
              borderRightRadius: 0,
            }}
            className="flex-1"
          >
            Save and run for first 10
          </Button>
          <Menu placement="top-end">
            <MenuButton
              isDisabled={isDisabled}
              as={Button}
              size="sm"
              sx={{
                borderLeftRadius: 0,
                backgroundColor: "#CBD5E0 !important",
              }}
            >
              <Icons.ArrowDownIcon size={15} />
            </MenuButton>
            <MenuList>
              {xNumbers.map((number) => (
                <MenuItem
                  key={number}
                  onClick={() => handleRunForXRows(number)}
                  className="!text-[14px] !font-medium"
                >
                  {`Save and run for first ${number}`}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      </div>
      <Button
        isDisabled={isDisabled || isPending || isInvalidConditionFormula}
        isLoading={isPending}
        size={"sm"}
        onClick={() => onSave({ isForAll: true })}
        className="flex-1"
      >
        Save and run for all
      </Button>
    </div>
  );
};

export default React.memo(SaveAndRunEnrichment);
