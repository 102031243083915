import { Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";
import { useProviderStore } from "@/stores/provider.store";

const KeySelect = () => {
  const keySelect = useEnrichStore((state) => state.keySelect);
  const updateState = useEnrichStore((state) => state.updateState);
  const updateProviderState = useProviderStore((state) => state.updateState);

  return (
    <>
      <div className="mt-4 px-4">
        <RadioGroup
          onChange={(newValue: "persanaApiKey" | "ownApiKey") => {
            updateState({
              keySelect: newValue,
            });
          }}
          value={keySelect}
        >
          <Stack direction="row">
            <Radio
              size="md"
              name="persanaApiKey"
              value="persanaApiKey"
              colorScheme="primary"
            >
              Use Persana's API Key
            </Radio>
            <Radio
              size="md"
              name="ownApiKey"
              value="ownApiKey"
              colorScheme="primary"
            >
              Use your own API key
            </Radio>
          </Stack>
        </RadioGroup>

        {keySelect === "ownApiKey" && (
          <Button
            className="mt-3 cursor-pointer text-sm text-[#676d7e]"
            onClick={() => {
              updateProviderState({
                isProviderApiKeyModalOpen: true,
                providerApiKeyModalData: {
                  title: "OpenAI",
                  providerName : "openAiApiKey"
                },
              });
            }}
            size="xs"
          >
            Add/Change API key
          </Button>
        )}
      </div>
    </>
  );
};

export default KeySelect;
