import { Button } from "@chakra-ui/react";

import { useTableStore } from "@/stores/table.store";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";

const ClearFilter = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (tableId: string) =>
      tableService.fetchTableData({
        tableId,
        clearFilters: true,
      }),
  });

  const tableFilters = useTableStore((state) => state.tableFilters);
  const updateState = useTableStore((state) => state.updateState);
  const tableId = useTableStore((state) => state.tableData._id);

  const handleClearFilter = async () => {
    const _tableFilters = {
      ...tableFilters,
      filters: [],
    };
    updateState({
      tableFilters: _tableFilters,
    });

    // TODO: Improve it later, to do not call if filter is Empty
    await mutateAsync(tableId, {
      onSuccess: (response) => {
        if (response) {
          updateState({
            ...response,
          });
        }
      },
    });

    updateState({
      tableFilters: {
        ..._tableFilters,
        open: false,
      },
    });
  };

  return (
    <Button
      className="flex w-full items-center justify-center gap-x-2 truncate rounded-md !bg-warning px-3 py-1.5 !text-sm duration-300"
      size="sm"
      onClick={handleClearFilter}
      isLoading={isPending}
    >
      <span>Clear Filter</span>
    </Button>
  );
};

export default ClearFilter;
