import { useEffect, useState } from "react";
import { Descendant } from "slate";

import CustomAccordion from "@/components/Common/CustomAccordion";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import WatchTutorial from "@/components/Common/WatchTutorial";
import { initialSlateValue, methods } from "./consts";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import CustomTextEditor from "../../Common/CustomTextEditor";
import Description from "../../Common/Description";
import AddMoreBtn from "../../Common/AddMoreBtn";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { convertSlateToText } from "@/utils";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

interface EnrichmentDataType extends EnrichmentMetaData {
  endpoint?: any;
  headers?: CustomField[];
  body?: any;
  method?: string;
}

function HttpApi() {
  const [selectedMethod, setSelectedMethod] = useState<Option | null>(null);
  const [requestBody, setRequestBody] = useState<any>(initialSlateValue);
  const [endPoint, setEndpoint] = useState<any>(initialSlateValue);
  const [headers, setHeaders] = useState<CustomField[]>([]);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const handleFieldChange = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ) => {
    setHeaders((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      console.log(enrichmentData);
      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        enrichmentData.method &&
          setSelectedMethod({
            value: enrichmentData.method,
            label: enrichmentData.method,
          });
        setEndpoint(enrichmentData.endpoint);
        setRequestBody(enrichmentData.body);
        if (enrichmentData.headers) {
          setHeaders(enrichmentData.headers.map((header) => header));
        }
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto space-y-4">
        <WatchTutorial
          subText={
            "Send or retrieve from any tool or database using a general API endpoint"
          }
          link=""
        />

        <CustomAccordion
          title="Method (Opitonal)"
          titleClassName="font-title text-sms"
        >
          <MultiSelectDropdown
            options={methods}
            isCreateAble={false}
            isMulti={false}
            closeMenuOnSelect={true}
            onChange={(selectedValue) => {
              setSelectedMethod(selectedValue as Option);
            }}
            placeholder="Select an item from the dropdown..."
            value={selectedMethod}
          />
          <Description>
            Type in the HTTP method of your call, e.g. GET (default), POST,
            PATCH, PUT, or DELETE
          </Description>
        </CustomAccordion>
        <div>
          <p className="font-semibold font-title text-[0.9rem] pb-1">
            Endpoint
          </p>
          <CustomTextEditor
            slateValue={endPoint}
            setSlateValue={setEndpoint}
            isInline
            editorHeight="fit-content"
            editorClassName="!px-2 !py-[4.5px]"
            placeholder="Enter value..."
          />
          <Description>
            Enter the endpoint to send the requests to, e.g.
            https://dummy.com/products
          </Description>
        </div>
        <CustomAccordion
          title="Body (Opitonal)"
          titleClassName="font-title text-sm"
        >
          <CustomTextEditor
            slateValue={requestBody}
            setSlateValue={setRequestBody}
            editorHeight="6rem"
            placeholder="Enter value..."
          />
          <Description>
            {
              'Enter the data body to include, e.g. {"email": "john@persana.com", "name": "John"} When using column tokens in a value, keep the key in quotes, but do not wrap the value in quotes e.g. { "email": {{email field}}, "name": {{name field}} }'
            }
          </Description>
        </CustomAccordion>
        <CustomAccordion
          title="Header (Opitonal)"
          titleClassName="font-title text-sm"
        >
          <AddMoreBtn
            keyName="Key"
            handleFieldChange={handleFieldChange}
            title="Add new key value pair"
            description="Enter any header fields to set, e.g. {'API-key': 'abcd1234'}"
            moreFields={headers}
            setMoreFileds={setHeaders}
          />
        </CustomAccordion>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!convertSlateToText(endPoint).text}
        apiPayload={{
          method: selectedMethod?.value,
          endpoint: endPoint,
          body: requestBody,
          headers: headers,
        }}
      />
    </>
  );
}

export default HttpApi;
