import Icons from "../Icons";
import { categories } from "./constants";
import { useEnrichStore, CategoryType } from "@/stores/enrich.store";

const Sidebar = () => {
  const activeCategory = useEnrichStore((state) => state.activeCategory);
  const updateState = useEnrichStore((state) => state.updateState);

  const handleCategory = (category: CategoryType) => {
    const newActiveCategory = category === activeCategory ? "" : category;

    updateState({
      activeCategory: newActiveCategory,
      searchInput: "",
    });
  };

  return (
    <div className="enrichment-modal w-[25%] bg-bgSecondary">
      <p className="w-full px-[40px] py-[30px] text-[20px] font-semibold text-[#000]">
        Enrich <span className="text-textPurple">Data</span>
      </p>
      <div className="mb-[20px] flex flex-col gap-[20px]">
        {categories.map(({ name, icon }) => (
          <div
            key={name}
            className={`flex w-full cursor-pointer items-center gap-[15px] py-[16px] pl-[30px] ${
              name === activeCategory ? "bg-[#EDE0FA]" : "bg-transparent"
            }`}
            onClick={() => handleCategory(name)}
          >
            {
              name == "All Enrichments" ? (
                <Icons.SelectAllIcon className={`text-xl ${
                  name === activeCategory
                    ? "text-textPurple"
                    : "text-black"
                }`} />
              ) : <img src={icon} alt="Enrichment Icon" />
            }
            <p
              className={`text-[16px] ${
                name === activeCategory
                  ? "font-[500] text-textPurple"
                  : "font-[400] text-black"
              } `}
            >
              {name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
