import { ModalType } from "@/types/modal.types";
import Icons from "../Icons";

export const salesGPTDetails: {
  title: string;
  id: "find-people-companies" | "starter-tables";
  pointers: { name: string; _id?: string }[];
}[] = [
  {
    title: "Find people, companies or ask a question",
    id: "find-people-companies",
    pointers: [
      { name: "Does Salesforce have an API?" },
      { name: "Where is the headquarters of Meta?" },
      { name: "Find healthcare companies in United States" },
      { name: "Find people who work at Google and live in San Francisco" },
    ],
  },
  {
    title: "Starter Tables",
    id: "starter-tables",
    pointers: [
      { name: "Find YC companies that recently launched" },
      { name: "Find AI companies that are recently funded" },
      { name: "Find actively hiring YC companies" },
      { name: "Find all Saas companies" },
    ],
  },
];

export type PredefinedSuggestionType = {
  title: string;
  description?: string;
  key: "findPeople" | "findCompanies" | "importCSV" | "starterTables";
  icon: () => JSX.Element;
  id?: string;
  modalType?: ModalType;
};

export const predefinedSuggestions: PredefinedSuggestionType[] = [
  {
    title: "Find people",
    description: "Find people who work at Google and live in San Francisco",
    key: "findPeople",
    modalType: "apollo-people-search",
    icon: () => <Icons.PeopleIcon className="text-primaryPurple text-[18px]" />,
  },
  {
    title: "Find companies",
    description: "Find AI companies that are recently funded",
    key: "findCompanies",
    modalType: "linkedin-company-search",
    icon: () => (
      <Icons.CompanyDetailsIcon className="text-primaryPurple text-[18px]" />
    ),
  },
  {
    title: "Import CSV",
    key: "importCSV",
    modalType: "import-csv",
    icon: () => <Icons.CSVIcon className="text-primaryPurple text-[14px]" />,
  },
];
