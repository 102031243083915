/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import DataTable from "./data-table";
import CellExpendViewModal from "../Enrichment/CellExpendViewModal";
import Pagination from "./Pagination";
import BottomPageActions from "./PageActions/BottomPageActions";

import { useTableStore } from "@/stores/table.store";
import useSocket from "@/lib/socket";
import { convertObject } from "@/utils";
import enrichment from "@/services/enrichment.service";
import type { ISocketData, ISocketProcessingData } from "@/types/socket.types";
import { downloadFile } from "@/lib/utils";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";

const toastId = "processing-completed";

interface Props {
  isRefetching: boolean;
}

const PersanaTable: React.FC<Props> = () => {
  const toast = useToast();
  const gridRef = useRef<AgGridReact>(null);
  const queryClient = useQueryClient();
  const updateUserStore = userStore((state) => state.updateState);
  const { mutateAsync: updateCredits } = useMutation({
    mutationFn: () => userService.getUserCreditDetails(),
    onSuccess: (response: any) => {
      updateUserStore({
        creditDetails: {
          creditLeft: response?.data?.creditLeft,
          creditUsed: response?.data?.creditUsed,
          planType: response?.data?.planType,
        },
      });
    },
  });

  const { subscribe, connect, disconnect } = useSocket();

  const updateRows = useTableStore((state) => state.updateRows);
  const expendedCellValue = useTableStore((state) => state.expendedCellValue);
  const isProcessing = useTableStore(
    (state) => state.tableData.metaData?.isProcessing,
  );
  const isWebhook = useTableStore(
    (state) => !!state.tableData.metaData?.isWebhook,
  );
  const updateRowsData = useTableStore((state) => state.updateRowsData);
  const columns = useTableStore((state) => state.tableData.columns);
  const totalRows = useTableStore((state) => state.totalRows);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);

  const tableId = useTableStore((state) => state.tableData._id);
  const tableName = useTableStore((state) => state.tableData.name);
  useEffect(() => {
    connect();
    subscribe(tableId, async (data: ISocketData) => {
      const {
        metaData,
        rowsData = [],
        isForNew = false,
        isCreateRows,
        isHideEndPopup = false,
      } = data;

      console.log("SOCKET DATA 1", data);

      if (rowsData.length) {
        if (isForNew) {
          updateRows(rowsData);
        } else if (isWebhook) {
          updateRowsData(rowsData);
        }
      }

      if (rowsData.length && !isWebhook) {
        updateRowsData(rowsData);
        const rowsPayload = rowsData.map(({ cells, ...rowData }) => {
          return {
            ...convertObject(cells),
            rowData,
          };
        });

        // Update table
        gridRef.current?.api.flushAsyncTransactions();
        const query = isCreateRows
          ? { add: rowsPayload }
          : { update: rowsPayload };
        gridRef.current?.api?.applyTransactionAsync(query);
      }

      if (metaData) {
        updateTableMetaDta({
          ...metaData,
        });

        if (
          !metaData?.isProcessing ||
          metaData?.processedData === metaData?.totalDataToProcess
        ) {
          if (!toast.isActive(toastId) && !isHideEndPopup) {
            toast({
              title: "Processing completed",
              description: "All the data has been processed",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
              id: toastId,
            });
          }
          await enrichment.callCronJobAPI();
          await updateCredits();
          await queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });
          gridRef.current?.api?.refreshCells();
        }
      }
    });

    subscribe(`${tableId}-processing`, async (data: ISocketProcessingData) => {
      console.log("SOCKET DATA 2", data);

      if (data.tableMetaData) {
        updateTableMetaDta({
          ...data.tableMetaData,
        });
      }
      if (data.downloadLink) {
        downloadFile({
          url: data.downloadLink,
          fileName: `${tableName}.csv`,
        });
      }
      if (data.isRefetch) {
        await queryClient.refetchQueries({
          queryKey: ["table", tableId],
        });
      }
    });

    return () => {
      disconnect();
    };
  }, [tableId, isWebhook]);

  return (
    <div>
      <DataTable gridRef={gridRef} />
      <div className="flex justify-between items-center">
        <BottomPageActions />
        <div className="mr-2 flex gap-2 items-center">
          <div className={`${isProcessing ? "hidden" : ""}`}>
            <p className="text-sm text-darkTextGray">
              (Rows: {totalRows}, Columns: {columns.length})
            </p>
          </div>
          <Pagination gridRef={gridRef} />
        </div>
      </div>
      {expendedCellValue.isOpen && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <CellExpendViewModal gridRef={gridRef} />
        </React.Suspense>
      )}
    </div>
  );
};

export default PersanaTable;
