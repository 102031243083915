import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { countries } from "countries-list";
import { useToast } from "@chakra-ui/react";

import ActionButtons from "../Common/ActionButtons";
import AccordionButton from "../../Common/AccordionButton";
import SearchInputAccordion from "@/components/Common/SearchInputAccordion";
import PersanaInput from "@/components/Common/PersanaInput";
import Icons from "@/components/Icons";
import AnimatedLoader from "@/components/Icons/SvgIcons/AnimatedLoaders";

import leadService from "@/services/leads.service";
import { SearchCompanyType, SearchPeopleType } from "@/types/leads.types";
import { apolloCompanySize, jobTitles } from "@/utils/constants";
import {
  extractNameAndContinent,
  filterValidObjKeys,
  handlePushToArray,
} from "@/utils";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

type FindPeopleInputsApolloProps = {
  isSearching: boolean;
  setIsSearching: (val: boolean) => void;
  setCurrentSearchData: any;
  onCloseModal?: () => void;
};

interface StaticType {
  name: string;
  value?: string;
}

interface InputtedData {
  person_titles: StaticType[];
  person_not_titles: StaticType[];
  person_past_titles: StaticType[];
  person_seniorities: StaticType[];
  q_person_name: string;
  organization_ids: any; // assuming ids are numbers
  not_organization_ids: any; // assuming ids are numbers
  person_past_organization_ids: any; // assuming ids are numbers
  organization_industry_tag_ids: StaticType[]; // assuming ids are numbers
  organization_num_employees_ranges: StaticType[]; // ["20001,5000"]
  q_organization_keyword_tags: StaticType[]; // array of company tags
  q_not_organization_keyword_tags: StaticType[]; // array of company tags
  person_locations: StaticType[]; // array of locations
  person_not_locations: StaticType[]; // array of locations
  person_location_name: string; // string of zip (if location radius selected)
  person_location_radius: string; // string of radius (if location radius selected)
  // contact_email_status: string[]; // array of email statuses
  // extrapolated_email_confidence: string[]; // ["75to85"] array of email confidences
}

const FindPeopleInputsApollo = ({
  isSearching,
  onCloseModal,
  setCurrentSearchData,
  setIsSearching,
}: FindPeopleInputsApolloProps) => {
  const [fetchedCompanies, setFetchedCompanies] = useState<any>([]);
  const [isGettingSavedTemplates] = useState(false);
  const [selectedTab, setSelectedTab] = useState("search");

  const toast = useToast();
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  let timer: NodeJS.Timeout;

  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchCompanyType }) =>
      leadService.searchCompany(payload),
  });

  const { mutateAsync: searchPeopleMutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchPeopleType }) =>
      leadService.searchPeople(payload),
  });

  const [inputtedData, setInputtedData] = useState<InputtedData>({
    person_titles: [],
    person_not_titles: [],
    person_past_titles: [],
    person_seniorities: [],
    q_person_name: "",
    organization_ids: [], // array of company ids
    not_organization_ids: [], // array of company ids
    person_past_organization_ids: [], // array of company ids
    organization_industry_tag_ids: [], // array of industry ids
    organization_num_employees_ranges: [], // array of employee range ["20001,5000"],
    q_organization_keyword_tags: [], // array of company tags
    q_not_organization_keyword_tags: [], // array of company tags
    person_locations: [], // array of locations
    person_not_locations: [], // array of locations
    person_location_name: "", // string of zip (if location radius selected)
    person_location_radius: "", // string of radius (if location radius selected)
  });

  const handleCompanySearch = async (companyName: string) => {
    // Clear previous timer if it exists
    clearTimeout(timer);

    // Set a new timer to call the API after 0.5s of user inactivity
    timer = setTimeout(async () => {
      await mutateAsync(
        {
          payload: {
            q_organization_name: companyName,
            per_page: 50,
          },
        },
        {
          onSuccess: (response) => {
            // Update the state
            setFetchedCompanies(response?.data.companies || []);
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    }, 500);
  };

  const handleSearch = async (searchType: string) => {
    console.log(searchType);
    const convertedInputtedData = {
      page: 1,
      per_page: 100,
      person_titles: inputtedData.person_titles.map((title) => title?.name),
      person_not_titles: inputtedData.person_not_titles.map(
        (title) => title?.name,
      ),
      person_past_titles: inputtedData.person_past_titles.map(
        (title) => title?.name,
      ),
      organization_ids: inputtedData.organization_ids.map(
        (company: any) => company?.organization_id || company?.id,
      ),
      not_organization_ids: inputtedData.not_organization_ids.map(
        (company: any) => company?.organization_id,
      ),
      person_past_organization_ids:
        inputtedData.person_past_organization_ids.map(
          (company: any) => company?.organization_id,
        ),
      organization_num_employees_ranges:
        inputtedData.organization_num_employees_ranges.map((size) =>
          size?.name?.replace("-", ","),
        ),
      q_organization_keyword_tags: inputtedData.q_organization_keyword_tags.map(
        (tag) => tag?.name,
      ),
      q_not_organization_keyword_tags:
        inputtedData.q_not_organization_keyword_tags.map((tag) => tag?.name),
      person_locations: inputtedData.person_locations.map(
        (location) => location?.name,
      ),
      person_not_locations: inputtedData.person_not_locations.map(
        (location) => location?.name,
      ),
      q_person_name: inputtedData.q_person_name,
    } as any;

    const validInputtedData = filterValidObjKeys(convertedInputtedData);

    console.log({ validInputtedData });
    console.log({ convertedInputtedData });

    if (Object.keys(validInputtedData || {}).length === 0) {
      toast({
        title: "No data to search",
        description: "Please input some data to search",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
      return;
    }

    setIsSearching(true);
    await searchPeopleMutateAsync(
      {
        payload: validInputtedData,
      },
      {
        onSuccess: (response) => {
          setCurrentSearchData(validInputtedData || {});
          updateLeadState({
            searchLeadsResult: {
              profiles: response?.data.profiles || [],
              pagination: response?.data.pagination || {
                total_entries: 0,
                total_pages: 0,
              },
              apiPath: "leads/search-people?per_page=100&want_to_import=true",
            },
          });
          setIsSearching(false);
          console.log(response, "response");
        },
        onError: (error) => {
          setIsSearching(false);
          console.log(error, "error");
        },
      },
    );
  };

  return (
    <div className="flex h-full w-full flex-col justify-between bg-[#F8F0FF]">
      <div className="h-[5rem] flex-grow  space-y-4 overflow-y-auto p-4">
        <p className="px-4 py-4 text-[20px] font-semibold text-[#000] flex items-center space-x-2.5">
          <button onClick={onCloseModal}>
            <Icons.LeftCircleArrow className="text-[#693DC7] text-3xl" />
          </button>{" "}
          <span>
            Find <span className="text-[#693DC7]">People</span>
          </span>
        </p>
        {selectedTab === "search" ? (
          <>
            <SearchInputAccordion
              button={
                <AccordionButton
                  icon={
                    <Icons.OfficeBuildingIcon
                      className="text-[1.3rem]"
                      color="#693DC7"
                    />
                  }
                >
                  Company
                </AccordionButton>
              }
            >
              <div className="space-y-3">
                <PersanaInput
                  value={inputtedData?.organization_ids}
                  placeholder={"e.g. Google, Facebook, Apple"}
                  name={"organization_ids"}
                  label={"Companies to include"}
                  showDropdown
                  isDynamic
                  arrayOfValues={fetchedCompanies}
                  onChange={(target) => {
                    handleCompanySearch(target.value);
                  }}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.not_organization_ids}
                  placeholder={"e.g. Google, Facebook, Apple"}
                  name={"not_organization_ids"}
                  label={"Companies to exclude"}
                  showDropdown
                  isDynamic
                  arrayOfValues={fetchedCompanies}
                  onChange={(target) => {
                    handleCompanySearch(target.value);
                  }}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.person_past_organization_ids}
                  placeholder={"e.g. Google, Facebook, Apple"}
                  name={"person_past_organization_ids"}
                  label={"Past Companies to include"}
                  showDropdown
                  arrayOfValues={fetchedCompanies}
                  onChange={(target) => {
                    handleCompanySearch(target.value);
                  }}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.organization_num_employees_ranges}
                  placeholder={"e.g. 11-20 , 101-200"}
                  name={"organization_num_employees_ranges"}
                  label={"Number of Employees"}
                  showDropdown
                  disabledCustomSelect
                  arrayOfValues={apolloCompanySize}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.q_organization_keyword_tags}
                  placeholder={"e.g. Cloud, AWS"}
                  name={"q_organization_keyword_tags"}
                  label={"Keywords to Include"}
                  showDropdown
                  arrayOfValues={[]}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.q_not_organization_keyword_tags}
                  placeholder={"e.g. Cloud, AWS"}
                  name={"q_not_organization_keyword_tags"}
                  label={"Keywords to exclude"}
                  showDropdown
                  arrayOfValues={[]}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
              </div>
            </SearchInputAccordion>
            <SearchInputAccordion
              defaultIndex={0}
              button={
                <AccordionButton
                  icon={
                    <Icons.SubtitleIcon
                      className="text-[1.3rem]"
                      color="#693DC7"
                    />
                  }
                >
                  Job Titles
                </AccordionButton>
              }
            >
              <div className="space-y-3">
                <PersanaInput
                  value={inputtedData?.person_titles}
                  placeholder={"e.g. CEO, CTO"}
                  name={"person_titles"}
                  label={"Job Titles to include"}
                  showDropdown
                  arrayOfValues={jobTitles}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.person_not_titles}
                  placeholder={"e.g. Intern, Manager"}
                  name={"person_not_titles"}
                  label={"Job Titles to exclude"}
                  showDropdown
                  arrayOfValues={jobTitles}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.person_past_titles}
                  placeholder={"e.g. Intern, Manager"}
                  name={"person_past_titles"}
                  label={"Past Job Titles to include"}
                  showDropdown
                  arrayOfValues={jobTitles}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
              </div>
            </SearchInputAccordion>
            <SearchInputAccordion
              button={
                <AccordionButton
                  icon={
                    <Icons.LocationIcon className="text-xl" color="#693DC7" />
                  }
                >
                  Location
                </AccordionButton>
              }
            >
              <div className="space-y-3">
                <PersanaInput
                  value={inputtedData?.person_locations}
                  placeholder={"e.g. , San Francisco, United States"}
                  name={"person_locations"}
                  label={"City / State / Country or Any Location"}
                  arrayOfValues={extractNameAndContinent(countries)}
                  showDropdown
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
                <PersanaInput
                  value={inputtedData?.person_not_locations}
                  placeholder={"e.g. , San Francisco, United States"}
                  name={"person_not_locations"}
                  label={"Location to exclude"}
                  arrayOfValues={extractNameAndContinent(countries)}
                  showDropdown
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
              </div>
            </SearchInputAccordion>
            <SearchInputAccordion
              button={
                <AccordionButton
                  icon={
                    <Icons.PersonAddIcon className="text-xl" color="#693DC7" />
                  }
                >
                  Name
                </AccordionButton>
              }
            >
              <div className="space-y-3">
                <PersanaInput
                  value={inputtedData?.q_person_name}
                  placeholder={"e.g. John Doe"}
                  name={"q_person_name"}
                  label={"Person Name"}
                  onChange={(e) => {
                    setInputtedData((prevState) => ({
                      ...prevState,
                      q_person_name: e.target.value,
                    }));
                  }}
                />
              </div>
            </SearchInputAccordion>
          </>
        ) : (
          <div className="space-y-3">
            {isGettingSavedTemplates ? (
              <div className="flex items-center justify-center pt-[15%]">
                <AnimatedLoader className="h-12 w-12" />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <ActionButtons
        isLoading={isSearching}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default FindPeopleInputsApollo;
