import { Box } from "@chakra-ui/react";
import Icons from "../Icons";

const BookACallCard = () => {
  return (
    <Box className="flex flex-col items-center justify-center px-[20px] py-[50px] bg-gray rounded-[20px] w-[240px] h-[200px]">
      <div className="mb-[20px] flex flex-col items-center justify-center gap-[8px]">
        <div className="h-[48px] w-[48px] rounded-[16px]  bg-white p-3 flex items-center justify-center">
          <Icons.QuestionIcon className="w-[20px] h-[20px] text-textPurple" />
        </div>
        <p className="text-[18px] font-semibold text-black">
          Need any{" "}
          <span className="font-semibold text-primaryPink">Help? </span>
        </p>
      </div>

      <div>
        <a
          href="https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding"
          rel="noreferrer"
          target="_blank"
          className="flex h-[40px] w-[140px] cursor-pointer items-center justify-center rounded-md bg-white hover:shadow"
        >
          <span className="text-[16px] font-semibold text-textPurple">
            Book
          </span>
        </a>
      </div>
    </Box>
  );
};

export default BookACallCard;
