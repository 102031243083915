import { Button } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import { useEnrichStore } from "@/stores/enrich.store";

const ImportData = () => {
  const updateState = useEnrichStore((state) => state.updateState);
  const handleImportData = () => {
    updateState({
      selectedEnrichments: {
        enrichmentName: "importData",
        credits: "1",
        icon: <Icons.ImportIcon />,
        name: "Import Data",
      },
      isOpenEnrichModal: false,
    });
  };
  return (
    <Button
      className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !bg-gray !text-sm hover:!shadow-md"
      leftIcon={<Icons.ImportIcon />}
      onClick={handleImportData}
      isDisabled
    >
      Import
    </Button>
  );
};

export default ImportData;
