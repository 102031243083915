/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import providerService from "@/services/providers.service";

export const useProviderList = () => {
  return useQuery({
    queryKey: ["providers"],
    queryFn: () => providerService.getProviderList(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
  });
};

export const useSmartLeadsCampaigns = () => {
  return useQuery({
    queryKey: ["smartLeadsCampaigns"],
    queryFn: () => providerService.getSmarLeadsCampaigns(),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 24 * 30, // 30 days
    gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
  });
};