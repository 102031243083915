import { QuestProvider, Referral as ReferEarn } from "@questlabs/react-sdk";

import questConfig from "@/constants/questConfig";
import { useUserData } from "@/hooks/useUserData";

export default function Referral({
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) {
  const userData = useUserData();

  const closeModal = (e: any) => {
    if (document.getElementById("modal_box")?.contains(e.target)) {
      /* empty */
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div
      className="fixed w-screen h-screen flex items-center justify-center top-0 left-0 bg-[rgba(0,0,0,0.45)] cursor-pointer"
      style={{ zIndex: "100" }}
      onClick={closeModal}
    >
      <div id={"modal_box"} className={"cursor-default"}>
        <QuestProvider
          apiKey={questConfig.API_KEY}
          apiSecret={questConfig.API_SECRET}
          entityId={questConfig.ENTITY_ID}
        >
          <ReferEarn
            questId={questConfig.REFERRAL_SCREN_ID}
            token={questConfig.ADMIN_TOKEN}
            userId={questConfig.ADMIN_ID}
            // @ts-ignore
            uniqueEmailId={userData?.data?.Email}
            uniqueUserId={userData?.data?.Email}
            heading={"Free Persana Credits 🚀"}
            description={
              "Share your unique referral link with friends and receive up to 6,000 credits each time a friend signs up!"
            }
            referralLink={`${window.location.origin}/login?ref=`}
            showReferralCode={false}
            showFooter={false}
            gradientBackground
            gradientBackgroundColor="linear-gradient(127deg, rgb(105, 61, 199) 0%, rgb(191, 114, 225) 100%)"
            styleConfig={{
              PrimaryButton: {
                background:
                  "linear-gradient(127deg, rgb(105, 61, 199) 0%, rgb(191, 114, 225) 100%)",
              },
            }}
          />
        </QuestProvider>
      </div>
    </div>
  );
}
