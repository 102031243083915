/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import tableService from "@/services/table.service";

export const useTableData = (tableId: string) => {
  return useQuery({
    queryKey: ["table", tableId],
    queryFn: () => {
      return tableService.fetchTableData({
        tableId,
      });
    },
    refetchOnMount: true,
    staleTime: 1,
    gcTime: 1,
    retry: true,
  });
};
