import { useState } from "react";
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";
import { usePresetsStore } from "@/stores/presets.store";
import { usePresetsData, useSavePreset } from "@/hooks/usePresetsData";

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  title: string;
}

interface ExampleState {
  input: string;
  output: string;
}

const SaveCustomPreset = ({ isModalOpen, handleClose, title }: Props) => {
  const toast = useToast();

  const { isPending, mutateAsync } = useSavePreset();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [examples, setExamples] = useState([{ input: "", output: "" }]);

  const presetsModalData = usePresetsStore((state) => state.presetsModalData);
  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );
  const { refetch } = usePresetsData(presetsModalData.modalType!);

  const handleExampleChange = (
    index: number,
    field: keyof ExampleState,
    value: string,
  ) => {
    setExamples((prevState) => {
      const newState = [...prevState];
      newState[index][field] = value;
      return newState;
    });
  };

  const handleAddExample = () => {
    setExamples([...examples, { input: "", output: "" }]);
  };

  const savePresetValues = () => {
    const presetValues = {
      name,
      prompt: selectedPresetValues,
      examples,
      enrichmentType: presetsModalData.modalType,
    };
    mutateAsync(presetValues, {
      onSuccess: () => {
        handleClose();
        refetch();
        toast({
          title: "Success",
          description: "Preset saved successfully",
          status: "success",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
      },
      onError: () => {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
      },
    });
  };

  return (
    <Modal
      size={"2xl"}
      isOpen={isModalOpen}
      onClose={handleClose}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent height={"fit-content"}>
        <ModalHeader className="border-b border-gray-300">
          <p className="flex items-center gap-x-2">
            <Icons.AgentIcon color="#ffef00" size={26} />
            <span>{title}</span>
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY={"auto"}
          paddingY={"1.5rem"}
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
          width={"full"}
        >
          <div className="p-[0 10px 0 0] h-[35rem] overflow-auto">
            <div className="space-y-6 overflow-y-auto">
              <div className="text-sm">
                <h2 className="pb-0.5 font-semibold text-[#1d2026]">
                  Save as custom template
                </h2>
                <p className="text-[#676d7e]">
                  Save your current integration configuration as a custom
                  template available to your entire workspace
                </p>
              </div>

              {/* Input for Title */}
              <div>
                <label>Title</label>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="E.g. Split the names"
                  className="w-full rounded-md border border-gray-300 p-2 focus:border-blue-500 focus:outline-none text-sm"
                />
              </div>

              {/* Input for Description */}
              <div>
                <label>Description</label>
                <textarea
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="E.g. Split the names into first and last names"
                  className="w-full resize-none rounded-md !border-[#e2e8f0] p-2 focus:border-blue-500 focus:outline-none text-sm"
                  style={{
                    border: "1px solid #e2e8f0",
                  }}
                />
              </div>

              {/* Input for Prompt */}
              <div className="pb-3">
                <label>Prompt</label>
                <Textarea
                  rows={8}
                  value={selectedPresetValues || ""}
                  style={{
                    border: "1px solid #cbd5e0",
                  }}
                  className="w-full resize-none rounded-md border border-gray-300 p-2 bg-lightGray focus:border-blue-500 focus:outline-none"
                  onChange={() => ""}
                />
              </div>

              <h2 className="!m-0">Examples</h2>

              <div className="!mt-1">
                {examples.map((example, index) => (
                  <div key={index} className="mt-4">
                    <Input
                      value={example.input}
                      onChange={(e) =>
                        handleExampleChange(index, "input", e.target.value)
                      }
                      placeholder="Example Input"
                      className="border-gray-300 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none"
                    />
                    <Input
                      value={example.output}
                      onChange={(e) =>
                        handleExampleChange(index, "output", e.target.value)
                      }
                      placeholder="Example Output"
                      className="border-gray-300 mt-2 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                ))}
                <Divider className="my-2" />
                <Button size="xs" onClick={handleAddExample}>
                  Add More Example
                </Button>
              </div>
            </div>

            <div className="mt-5">
              <Button
                colorScheme="green"
                onClick={savePresetValues}
                isDisabled={isPending}
                isLoading={isPending}
              >
                <Icons.CheckCircleIcon className="mr-2 text-lg" />
                Save Preset
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SaveCustomPreset;
