/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import tableService from "@/services/table.service";

export const useTableList = () => {
  return useQuery({
    queryKey: ["tables"],
    queryFn: () => tableService.getTableList(),
    staleTime: 1000 * 60 * 30, // 30 minutes
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};
