import Papa from "papaparse";
import { Descendant } from "slate";
import Cookies from "universal-cookie";

import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { Column } from "@/types/table.types";
import tableService from "@/services/table.service";
import { createANewColumnName } from "@/components/Table/utils";

export const isUrl = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol (http or https)
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?" + // port
      "(\\/[-a-z\\d%_.~+]*)*" + // path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
};

export const convertObject = (input: {
  [key: string]: { value: string };
}): {
  [key: string]: string;
} => {
  const output: { [key: string]: string } = {};
  for (const key in input) {
    output[key] = input[key].value || "";
  }
  return output;
};

export const generateId = (text: string) => {
  return `${text || ""}-${Math.random().toString(36).substr(2, 9)}`;
};

export const replaceToBr = (str: string) => {
  return str.replace(/\n/g, "<br />");
};

export const extractNameAndContinent = (obj: any) => {
  const result = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const { name, continent } = obj[key];
      result.push({ name, continent });
    }
  }

  return result;
};

export const handlePushToArray = (
  value: any,
  stateToUpdate: any,
  setStateToUpdate: (state: any) => void,
) => {
  setStateToUpdate({
    ...stateToUpdate,
    [value?.name]: value?.value,
  });
};

export function filterValidObjKeys(obj: any) {
  if (typeof obj !== "object" || obj === null) {
    throw new Error("Input must be an object");
  }

  const result = {} as any;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "number") {
        if (value > 0) {
          result[key] = value;
        }
      }
      if (typeof value === "string") {
        if (
          typeof value === "boolean" ||
          (typeof value === "string" && value.trim() !== "")
        ) {
          result[key] = value;
        }
      } else if (typeof key === "object") {
        if (Object.keys(value).length > 0) {
          result[key] = value;
        }
      } else if (Array.isArray(value)) {
        if (value.length > 0) {
          result[key] = value;
        }
      }
    }
  }

  return result;
}

export const getInitialColumn = (regex: RegExp) => {
  const columns = useTableStore.getState().tableData?.columns;
  const rows = useTableStore.getState().rowsData;

  if (!columns || !rows) {
    return;
  }

  const matchedColumns = [] as {
    column: Column;
    matchCount: number;
  }[];

  columns.forEach((column) => {
    let matchCount = 0;
    rows.slice(0, 10).forEach((row) => {
      const cellValue = row.cells?.[column._id]?.value;
      if (typeof cellValue !== "string") return;
      if (row.cells?.[column._id]?.value.match(regex)) {
        matchCount++;
      }
    });

    if (matchCount >= 1) {
      matchedColumns.push({
        column,
        matchCount,
      });
    }
  });

  if (matchedColumns.length === 0) {
    return;
  }

  const mostMatchedColumn = matchedColumns?.reduce(
    (max, cell) => (cell.matchCount > max.matchCount ? cell : max),
    matchedColumns[0],
  );

  return mostMatchedColumn?.column;
};

export const preselectEnrichmentState = (
  selectedEnrichment: any,
  updateState: (state: TSelectedColumnOption) => void,
  key?: string,
) => {
  const relyOnColumn = useTableStore
    .getState()
    .tableData?.columns.find(
      (column) => column._id === selectedEnrichment[key || "columnId"],
    );

  if (relyOnColumn) {
    updateState({
      key: relyOnColumn.name,
      keyId: relyOnColumn._id,
      iconType: relyOnColumn.metaData?.iconType || "url",
    });
  }
};

export const getInitialColumnByColName = (name: string) => {
  const columns = useTableStore.getState().tableData?.columns;

  if (!columns) {
    return;
  }

  const matchedColumn = columns.find((column) => column.name?.includes(name));

  return matchedColumn;
};

export const getParsedCsvData = (file: any) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      worker: true,
      preview: 1,
      step: function (results: any) {
        const data = {
          columns: results.meta.fields,
        };
        // rows: results.data,
        resolve(data);
      },
      error: function (error: any, file: any) {
        console.log("mylog error", error, file);
        reject(error);
      },
    });
  });
};

export const convertSlateToText = (
  slateValue: Descendant[],
  useId: boolean = false,
) => {
  let result = "";
  let count = [] as { [key: string]: string }[] | any[];

  if (!slateValue) {
    return { text: "", count };
  }

  slateValue?.forEach((element: any) => {
    if (element.type === "paragraph") {
      element.children.forEach((child: any) => {
        if (child.type === "inlineVoid") {
          console.log("child.children", child);
          if (useId) {
            result += `{{${child.id}}}`;
            count.push({
              [child.id]: child.children[0].text,
            });
          } else {
            result += `{{${child.children[0].text}}}`;
            count.push(child.children[0].text);
          }
        } else {
          result += child.text;
        }
      });
    }
  });

  return { text: result, count };
};

export const extractVariables = (str: string) => {
  const regex = /{{(.*?)}}/g;
  let match;
  const values = [];
  while ((match = regex.exec(str)) !== null) {
    values.push(match[1]);
  }
  return values;
};

export function validateEmail(email: string) {
  if (typeof email !== "string" || email.trim() === "") return false;
  // Regular expression pattern for email validation
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the pattern
  return pattern.test(email);
}

export const setCookie = (
  key: string,
  value: string,
  expirationDate?: Date,
) => {
  const cookies = new Cookies();

  const isDevelopment = process.env.NODE_ENV === "development";

  // if no expiration date is provided, default to 1 year
  const expiredIn =
    expirationDate ||
    new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

  cookies.set(key, value, {
    path: "/", // Specify the path where the cookie is accessible
    secure: !isDevelopment,
    sameSite: isDevelopment ? "lax" : "none",
    // Note: 'httpOnly' cannot be set from client-side JavaScript
    expires: expiredIn,
    domain: isDevelopment ? "localhost" : ".persana.ai",
  });
};

export const isNameChanged = ({
  firstName,
  lastName,
  previousFirstName,
  previousLastName,
}: {
  firstName: string;
  lastName: string;
  previousFirstName: string;
  previousLastName: string;
}) => {
  if (previousFirstName === "NA" || previousLastName === "NA") {
    return "Y";
  }
  const fName = firstName.toLowerCase();
  const lName = lastName.toLocaleLowerCase();
  const prevFName = previousFirstName.toLocaleLowerCase();
  const prevLName = previousLastName.toLocaleLowerCase();
  const currentName = `${fName} ${lName}`;
  const previousName = `${prevFName} ${prevLName}`;

  if (
    currentName.includes(previousName) ||
    previousName.includes(currentName)
  ) {
    return "Y";
  }

  const isFirstNameChanged =
    fName.length <= prevFName.length
      ? !prevFName.includes(fName)
      : !fName.includes(prevFName);

  const isLastNameChanged =
    lName.length <= prevLName.length
      ? !prevLName.includes(lName)
      : !lName.includes(prevLName);

  return isFirstNameChanged || isLastNameChanged ? "N" : "Y";
};

export function convertPromptToContext(
  questionsArray: { prompt: string; answer: string }[],
) {
  if (questionsArray.length === 0) return "";

  let contextString = "";
  for (let i = 0; i < questionsArray.length; i++) {
    if (i !== 0) {
      contextString += "\n";
      contextString +=
        "Previous question: " + questionsArray[i - 1].prompt + "\n";
    }
    contextString += "Previous Question: " + questionsArray[i].prompt + "\n";
    contextString += "Answer: " + questionsArray[i].answer + "\n";
  }
  return contextString;
}

export const addNewColumn = async ({ columnName }: { columnName: string }) => {
  const newColumnName =
    createANewColumnName(
      columnName,
      useTableStore.getState().tableData?.columns,
    ) || columnName;

  const response = await tableService.insertColumn(
    useTableStore.getState().tableData?._id,
    {
      name: newColumnName,
    },
  );

  if (response.data?.success) {
    if (response?.data?.data) {
      useTableStore.getState().updateState({
        tableData: response.data.data,
      });
    }
  }
};
