import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

import type { TSelectedColumnOption } from "@/types/common.types";

const LinkedInUrlTab = ({
  selectedLinkedinUrlForWorkEmail,
  setSelectedLinkedinUrlForWorkEmail,
}: {
  selectedLinkedinUrlForWorkEmail: TSelectedColumnOption | null;
  setSelectedLinkedinUrlForWorkEmail: (
    value: TSelectedColumnOption | null,
  ) => void;
}) => {
  return (
    <div>
      <p className="pb-1 text-base font-semibold">Linkedin Url</p>
      <SelectColumnDropdown
        selectedOption={selectedLinkedinUrlForWorkEmail}
        setSelectedOption={(value) => {
          const selectedLinkedinUrl = value as TSelectedColumnOption;
          setSelectedLinkedinUrlForWorkEmail(selectedLinkedinUrl);
        }}
      />
    </div>
  );
};

export default LinkedInUrlTab;
