import { Button, MenuItem, useToast } from "@chakra-ui/react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import tableService from "@/services/table.service";

interface MenuItemProps {
  onClose?: () => void;
}

const RemoveRows = ({ onClose }: MenuItemProps) => {
  const queryClient = useQueryClient();

  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const tableId = useTableStore((state) => state.tableData._id);
  const toast = useToast({
    position: "top-right",
    duration: 3000,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => tableService.removeRows(selectedRowsId, tableId),
  });

  const handleDelete = async () => {
    await mutateAsync(undefined, {
      onSuccess: (response: any) => {
        if (response.success) {
          toast({
            title: "Rows deleted",
            status: "success",
          });
          onClose && onClose();
          queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });
        } else {
          toast({
            title: "Failed to delete rows",
            description: response.message,
            status: "error",
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Failed to delete rows",
          description: error.message,
          status: "error",
        });
      },
    });
  };

  return (
    <MenuItem
      className="!px-0 !pb-0"
      closeOnSelect={false}
      disabled={isPending}
    >
      <Button
        size="sm"
        variant="outline"
        leftIcon={<Icons.DeleteIcon size={20} className="text-red-700" />}
        className="m-auto w-[94%]"
        onClick={handleDelete}
        isLoading={isPending}
      >
        <span className="flex-1 text-red-700">
          Delete {selectedRowsId.length} Rows
        </span>
      </Button>
    </MenuItem>
  );
};

export default RemoveRows;
