import SalesoftIntegration from "./SalesoftIntegration";
import OutreachIntegration from "./OutreachIntegration";
import GithubIntegration from "./GithubIntegration";
import SmartleadIntegration from "./SmartleadIntegration";
import OpenAIIntegration from "./OpenAIIntegration";
import SalesforceIntegration from "./SalesforceIntegration";
import HubspotIntegration from "./HubspotIntegration";
import HubspotKeyIntegration from "./HubspotKeyIntegration";
import GmailIntegration from "./GmailIntegration";

const Integrations = () => {
  return (
    <section className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
      <HubspotIntegration />
      <SalesforceIntegration />
      <OutreachIntegration />
      <SalesoftIntegration />
      <GithubIntegration />
      <GmailIntegration />
      <SmartleadIntegration />
      <OpenAIIntegration />
      <HubspotKeyIntegration />
    </section>
  );
};

export default Integrations;
