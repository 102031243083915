import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";

import MultiSelectDropdown from "../Common/MultiSelectDropdown";

import { Option } from "../Common/MultiSelectDropdown/types";
import { categoriesList } from "@/utils/constants";

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  selectedFilters: {
    categories: Option[];
    jobTitles: Option[];
    jobDescriptions: Option[];
  };
  setIsOpen: (val: boolean) => void;
  handleTrack: () => void;
  updateSelectedFilters: (key: string, value: Option[]) => void;
};
const TrackJobOpeningsModal = ({
  isOpen,
  isLoading,
  selectedFilters,
  setIsOpen,
  updateSelectedFilters,
  handleTrack,
}: Props) => {
  return (
    <Modal
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>
          <p className="w-full text-center text-[22px]">Track Job Titles</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="mb-6 flex flex-col gap-5">
          <p className="mb-3 w-full text-center text-[18px]">
            Choose the job titles that you want to track
          </p>
          <div>
            <MultiSelectDropdown
              placeholder="Filter for job titles"
              value={selectedFilters.jobTitles}
              options={[]}
              onChange={(selectedValues) => {
                updateSelectedFilters("jobTitles", selectedValues as Option[]);
              }}
            />
            <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
              Filter for specific words or phrases in job titles (e.g.
              "Engineer, Sales, Accountant"). This does not accept AND or ORs.
            </p>
          </div>
          <div>
            <MultiSelectDropdown
              placeholder="Filter Jobs by selected categories (optional)"
              options={categoriesList}
              onChange={(selectedValues) => {
                updateSelectedFilters("categories", selectedValues as Option[]);
              }}
              value={selectedFilters.categories}
            />
            <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
              Filter Jobs by selected categories
            </p>
          </div>
          <div>
            <MultiSelectDropdown
              placeholder="Filter for job descripton (optional)"
              options={[]}
              onChange={(selectedValues) => {
                updateSelectedFilters(
                  "jobDescriptions",
                  selectedValues as Option[],
                );
              }}
              value={selectedFilters.jobDescriptions}
            />
            <p className="text-xs font-title font-medium tracking-wide pt-0.5 pl-0.5 text-darkTextGray">
              Filter for specific words or phrases in job descripton (e.g.
              "Hubspot, Smartleads, Salesforce"). This does not accept AND or
              ORs.
            </p>
          </div>
          <Button
            isDisabled={selectedFilters?.jobTitles?.length === 0}
            isLoading={isLoading}
            onClick={() => handleTrack()}
            className="mt-3 w-full hover:opacity-80"
            style={{
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
              color: "#fff",
            }}
          >
            <div>Track</div>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrackJobOpeningsModal;
