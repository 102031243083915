import FilterModals from "@/components/Enrichment/FilterModals";
import EnrichModal from "@/components/Enrichment/Modal";
import PresetsModal from "@/components/Presets";
import PricingModal from "@/components/Leads/PricingModal";
import GetStartedModal from "@/components/Leads/GetStartedModal";
import TutorialModal from "@/components/Leads/TutorialModal";
import GenerateLeadsModal from "@/components/Leads/GenerateLeadsModal";
import GoogleSRPModal from "@/components/Leads/GoogleSRPModal";
import ImportCSVModal from "@/components/Leads/ImportCSVModal";
import GithubStargazersModal from "@/components/Leads/GithubStargazersModal";
import LeadsModal from "@/components/Leads/Modal";
import HubspotImportModal from "@/components/Leads/HubspotImportModal";
import ShareTableLinkModal from "@/components/Leads/ShareTableLinkModal";
import GenerateFormulaModal from "@/components/Enrichment/GenerateFormula";
import WelcomeModal from "@/components/Leads/WelcomeModal";
import CustomErrorBoundary from "./Common/ErrorComponent/CustomErrorBoundary";

import { usePresetsStore } from "@/stores/presets.store";
import { useModalStore } from "@/stores/modal.store";
import { useEnrichStore } from "@/stores/enrich.store";
import AddTeammatesModal from "./TeamOverview";

const RootComponents = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const isOpenEnrichModal = useEnrichStore((state) => state.isOpenEnrichModal);
  const generateFormulaModal = useEnrichStore(
    (state) => state.generateFormulaModal,
  );
  const isOpenPresetsModal = usePresetsStore(
    (state) => state.isOpenPresetsModal,
  );
  const { isOpenModal, modalData , addTeamMembersModal } = useModalStore((state) => state);

  return (
    <>
      {selectedEnrichments?.enrichmentName && <FilterModals />}
      {generateFormulaModal?.isOpen && <GenerateFormulaModal />}
      {isOpenEnrichModal && <EnrichModal />}
      {isOpenPresetsModal && <PresetsModal />}
      {isOpenModal && modalData.modalType === "pricing" && <PricingModal />}
      {isOpenModal && modalData.modalType === "get-started" && (
        <GetStartedModal />
      )}
      {isOpenModal && modalData.modalType === "tutorial" && <TutorialModal />}
      {isOpenModal &&
        (modalData.modalType === "apollo-people-search" ||
          modalData.modalType === "linkedin-company-search") && (
          <GenerateLeadsModal />
        )}
      {isOpenModal && modalData.modalType === "google-srp" && (
        <GoogleSRPModal />
      )}
      {isOpenModal && modalData.modalType === "import-csv" && (
        <ImportCSVModal />
      )}
      {isOpenModal && modalData.modalType === "github-stargazers" && (
        <GithubStargazersModal />
      )}
      {isOpenModal && modalData.modalType === "tutorial" && <TutorialModal />}
      {isOpenModal && modalData.metaData?.isLeads && <LeadsModal />}
      {isOpenModal &&
        (modalData.modalType === "job-tracking" ||
          modalData.modalType === "job-opening") && <HubspotImportModal />}
      {isOpenModal && modalData.modalType === "share-table-link" && (
        <ShareTableLinkModal />
      )}
      {isOpenModal && modalData.modalType === "welcome-modal" && (
        <WelcomeModal />
      )}
      {
        addTeamMembersModal.isOpen && (
          <AddTeammatesModal />
        )
      }
    </>
  );
};

const WrappedRootComponents = () => (
  <CustomErrorBoundary>
    <RootComponents />
  </CustomErrorBoundary>
);

export default WrappedRootComponents;
