import {
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Table,
  useToast,
  ChakraProvider,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { Link } from "@tanstack/react-router";
import Swal from "sweetalert2";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Icons from "@/components/Icons";
import TableIcon from "@/assets/svgs/tableIcon.svg";

import type { TableData } from "@/types/table.types";
import tableService from "@/services/table.service";

interface Props {
  data?: Partial<TableData>[];
}

const TableList = ({ data }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: (tableId: string) => tableService.deleteTable(tableId),
  });

  const handleDelete = async (tableId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(tableId, {
          onSuccess: (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top-right",
              });
              return;
            }

            queryClient.refetchQueries({
              queryKey: ["tables"],
            });
            toast({
              title: "Table deleted successfully",
              status: "success",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
        });
      },
    });
  };

  // TODO:
  if (!data) return <div>No Data Found</div>;

  return (
    <ChakraProvider>
      <Box overflowX="auto">
        <TableContainer>
          <Box overflowY="auto" maxHeight="300px" className="custom-scrollbar">
            <Table variant="simple" size="sm">
              <Thead position="sticky" top={0} bgColor="white">
                <Tr>
                  <Th>Table Name</Th>
                  <Th>Created Date</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item) => (
                  <Tr key={item._id}>
                    <Td>
                      <Link
                        className="flex items-center gap-2 cursor-pointer"
                        to="/table/$tableId"
                        params={{
                          tableId: item._id!,
                        }}
                      >
                        <div className=" p-2">
                          <img src={TableIcon} alt="tableIcon" />
                        </div>
                        <Tooltip label={item.name} placement="right">
                          <p className="hover:text-primaryPinkHover text-with-ellipsis">
                            {item.name}
                          </p>
                        </Tooltip>
                      </Link>
                    </Td>
                    <Td>{format(item.createdAt!, "MMM dd, yyyy")}</Td>
                    <Td>
                      <div
                        className="cursor-pointer rounded-md bg-[#FEF7F7] p-2 w-[30px] flex items-center justify-center"
                        onClick={() => {
                          handleDelete(item._id!);
                        }}
                      >
                        <Icons.DeleteBinIcon color="red" />
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </TableContainer>
      </Box>
    </ChakraProvider>
  );
};

export default TableList;
