import { getInitialColumn, getInitialColumnByColName } from "@/utils";
import { REGEX } from "../../constants";

export const options = [
  {
    name: "By Website",
    label: "Company Website",
    value: "website",
  },
  {
    name: "By Name",
    label: "Company Name",
    value: "name",
  },
  {
    name: "By LinkedIn",
    label: "Company LinkedIn URL",
    value: "linkedIn",
  },
];

export const functions = [
  {
    value: "website",
    getColumn: () => {
      return getInitialColumn(REGEX.WEBSITE);
    },
  },
  {
    value: "name",
    getColumn: () => {
      return getInitialColumnByColName("Company Name");
    },
  },
  {
    value: "linkedIn",
    getColumn: () => {
      return getInitialColumn(REGEX.LINKEDINCOMPANY);
    },
  },
];
