import { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useMutation } from "@tanstack/react-query";

import Icons from "@/components/Icons";
import StarterList from "@/components/Home/StarterList";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";

import { allActions } from "./constants";
import { ActionType } from "./types";

const GetStartedModal = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: createBlankTable, isPending } = useMutation({
    mutationFn: () => tableService.createBlankTable(),
  });

  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleCardClick = (actionType?: ActionType) => {
    switch (actionType) {
      case "people": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "apollo-people-search",
            metaData: { openPreviousModal: true },
          },
        });

        updateLeadState({
          searchLeadsResult: null,
        });
        break;
      }
      case "companies": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "linkedin-company-search",
            metaData: { openPreviousModal: true },
          },
        });

        updateLeadState({
          searchLeadsResult: null,
        });
        break;
      }
      case "google-srp": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "google-srp",
            metaData: { openPreviousModal: true },
          },
        });
        break;
      }
      case "csv": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "import-csv",
            metaData: { openPreviousModal: true },
          },
        });
        break;
      }
      case "github": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "github-stargazers",
            metaData: { openPreviousModal: false },
          },
        });
        break;
      }
      case "webhook": {
        updateState({
          isOpenModal: true,
          modalData: {
            modalType: "webhook",
            metaData: {
              openPreviousModal: true,
              modalTitle: "Creating Webhook Table",
              isLeads: true,
            },
          },
        });
        break;
      }
    }
  };

  const handleCreateBlankTable = async () => {
    await createBlankTable(undefined, {
      onSuccess: (response) => {
        if (!response.success || !response.data?.success) {
          toast({
            title: "Error",
            description: response.data?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        handleModalClose();
        const tableId = response.data?.data?.tableId;
        if (tableId) {
          navigate({
            to: "/table/$tableId",
            params: {
              tableId,
            },
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpenModal && modalData?.metaData?.scrollToBottom) {
        const listDiv = document.getElementById("starterTables");
        listDiv?.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }, 400);
  }, [isOpenModal, modalData]);

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "get-started"}
      motionPreset="scale"
      size="5xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

      <ModalContent className="rounded-[20px]">
        <div
          className="h-[18px]"
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
            boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
            borderRadius: "6px 6px 0 0",
          }}
        />
        <ModalBody className="">
          <div className="flex items-center justify-between p-10">
            <h3 className="text-[24px] font-bold">
              Enrich Your <span className="text-textPurple">Leads</span>
            </h3>
            <button
              className="btn-primary-outline flex min-w-fit cursor-pointer items-center justify-center gap-3 rounded p-2 px-5 text-[16px] font-[500] text-white"
              disabled={isPending}
              onClick={handleCreateBlankTable}
            >
              <Icons.OutlineAddIcon className="text-xl" />
              <span>{isPending ? "Creating..." : "New Blank Table"}</span>
            </button>
          </div>
          <div className="space-y-9 px-10 pb-10 max-h-[73vh] overflow-y-auto">
            {allActions.map((action, index) => (
              <div
                key={index}
                className={`startwith-action-container-${index + 1}`}
              >
                {action?.name && (
                  <h2 className="pb-2.5 font-title text-lg font-bold">
                    {action?.name}
                  </h2>
                )}
                <div
                  className="grid gap-5"
                  style={{
                    gridTemplateColumns: `repeat(${action?.maxCards}, minmax(0, 1fr))`,
                  }}
                >
                  {action?.actions?.map((card, index) => (
                    <div
                      key={index}
                      id={`startWithCard_${index + 1}`}
                      onClick={() => handleCardClick(card.actionType)}
                    >
                      <div
                        className={`flex items-start cursor-pointer gap-[25px] rounded-md border-[2px] p-4 w-full min-h-[150px] ${
                          index === 0 || card?.highlight
                            ? "border-textPurple bg-gray"
                            : "border-gray bg-gray"
                        }`}
                      >
                        <p className="flex items-center space-x-3  text-left text-base font-semibold">
                          <span>{card.icon}</span>
                        </p>
                        <div>
                          <p
                            className={`mb-[12px] text-left text-[16px] font-medium text-black`}
                          >
                            {card?.title}
                          </p>
                          <p
                            className={`text-left text-[14px] font-[300] text-black ${
                              action?.maxCards === 2 ? "pb-3" : ""
                            }`}
                          >
                            <span>{card?.description}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <StarterList />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GetStartedModal;
