import { ChangeEvent, useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import HubspotLogo from "@/assets/images/hubsport.png";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";

const HubspotKeyIntegration = () => {
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const [hubspotKey, setHubspotKey] = useState("");

  const handleHubspotKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHubspotKey(e.target.value);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => userService.saveHubspotKey(hubspotKey, currentUser?._id),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
        setHubspotKey("");
      } else {
        toast({
          title: response?.data?.message || "Key saved successfully.",
          status: "success",
          isClosable: true,
        });
        setHubspotKey("");
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={HubspotLogo} alt={"HubspotLogo"} className="w-[2rem]" />
        <h2 className="font-semibold">Hubspot</h2>
      </div>
      <div>
        <p className="mb-4">{`Add your Hubspot key`}</p>

        <input
          className="mr-5 border-2 border-black"
          onChange={(e) => handleHubspotKeyChange(e)}
          value={hubspotKey}
        />

        <Button
          colorScheme={"green"}
          variant="outline"
          size="sm"
          isLoading={isPending}
          onClick={() => mutateAsync()}
          isDisabled={!hubspotKey}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default HubspotKeyIntegration;
