import Icons from "../Icons";

import GPT from "@/assets/svgs/enrichment-gpt.svg";
import Verify from "@/assets/svgs/enrichment-verify.svg";
import News from "@/assets/svgs/enrichment-news.svg";
import Company from "@/assets/svgs/sidebar-company-enrichment.svg";
import Email from "@/assets/svgs/sidebar-email-info.svg";
import Web from "@/assets/svgs/sidebar-web-research.svg";
import Prospect from "@/assets/svgs/sidebar-prospect-enrichment.svg";
import AI from "@/assets/svgs/sidebar-ai-enrichment.svg";
import Campaign from "@/assets/svgs/campaign.svg";
import ApiFy from "@/assets/images/apify.png";
import { Column } from "@/types/table.types";

export type TopEnrichmentTypes = {
  name: string;
  enrichmentName: string;
  icon: any | (() => Element);
  iconBg?: string;
  label?: string;
  subtitle?: string;
  categories?: string[];
  hasPrecaution?: boolean;
  showResponse?: boolean;
  columnData?: Column;
  rowData?: any;
  onClick?: () => void;
  credits: string;
  isHidden?: boolean;
};

export const topEnrichments: TopEnrichmentTypes[] = [
  {
    name: "Persana Company AI Agent",
    icon: () => <Icons.AgentIcon color="#1972f5" size={26} />,
    enrichmentName: "askAQuestion",
    iconBg: "#fff",
    label: "Ask and answer any question about a company with AI",
    subtitle:
      "Instantly access key data about any company. Get accurate insights on revenue, employee count, headquarters location, and business model (SAAS, B2B/B2C), and answer any question all through our propriatary AI agent.",
    categories: ["Web Research", "AI Enrichment"],
    credits: "1",
  },
  {
    name: "Find Work Email",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using either LinkedIn URLs or a combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    name: "Use AI (ChatGPT + Claude)",
    enrichmentName: "useAi",
    icon: GPT,
    iconBg: "#fff",
    label: "Enrich data using AI",
    subtitle:
      "Use the combined intelligence of GPT and Claude. Generate hyper-personalized messaging, ask and answer complex queries, and elevate your business interactions.",
    categories: ["Web Research", "AI Enrichment"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    name: "Google Search",
    enrichmentName: "googleSearch",
    subtitle:
      "Perform large-scale Google searches for prospect information, and pair it with our AI enrichment to enhance your prospecting strategies.",
    categories: ["Web Research"],
    icon: () => <Icons.GoogleIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    credits: "1",
  },
] as const;

export const allEnrichments: TopEnrichmentTypes[] = [
  {
    name: "Person Profile Details",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.LinkedInIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    name: "Enrich Company Details",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    name: "Job Openings",
    enrichmentName: "jobOpenings",
    icon: () => <Icons.UserListIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Unearth the latest career opportunities within top companies.",
    subtitle:
      "Just input a company URL to get a comprehensive list of current job openings, a key indicator of potential business needs and opportunities.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    name: "Find Linkedin Url",
    enrichmentName: "findLinkedInUrl",
    icon: () => <Icons.LinkedInIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label: "Seamlessly match emails to their LinkedIn profile links.",
    subtitle:
      "Locate LinkedIn profiles at scale using just a work email or the prospect's name and company. This tool is essential for expanding your professional network and identifying key contacts.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    name: "Company News",
    enrichmentName: "companyNews",
    icon: News,
    iconBg: "#fff",
    label: "Stay a step ahead with real-time company updates.",
    subtitle: "Stay a step ahead with real-time company updates.",
    categories: ["Company Enrichment"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    name: "Find People At Company",
    enrichmentName: "findPeopleAtCompany",
    icon: () => <Icons.PeopleIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Find people at a company",
    subtitle:
      "Identify and connect with key individuals at your target companies. Input the company name and define your ideal customer profile to get a curated list of contacts, use this with additonal enrichments such as Find Work Email to supercharge your outreach.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    name: "Company Technologies",
    enrichmentName: "companyTechnologies",
    icon: () => <Icons.QRCodeIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Uncover the technological backbone of a company given its URL.",
    subtitle:
      "Identify the technologies a prospect company uses. This vital insight helps in assessing whether they are a suitable prospect for your products or services.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    name: "Scrape Website",
    enrichmentName: "scrapeWebsite",
    subtitle:
      "Automate the process of extracting valuable data from prospect websites. Use this feature in tandem with AI enrichment to gather comprehensive information effortlessly.",
    categories: ["Web Research"],
    icon: () => <Icons.WebsiteIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    credits: "1",
  },
  {
    name: "Persana AI Agent 2.0 (Beta)",
    icon: () => <Icons.RobotIcon color="#1972f5" size={26} />,
    enrichmentName: "persanaAgent2",
    iconBg: "#fff",
    label: "Ask and answer any question about a company with AI",
    subtitle:
      "Instantly access key data about any company. Get accurate insights on revenue, employee count, headquarters location, and business model (SAAS, B2B/B2C), and answer any question all through our propriatary AI agent.",
    categories: ["Web Research", "AI Enrichment"],
    credits: "1",
  },
  {
    name: "Generate Sales Emails",
    enrichmentName: "generateSalesEmails",
    icon: () => <Icons.CampaignIcon size={26} color="#1972f5" />,
    hasPrecaution: true,
    label: "Hyperpersonalized AI messages at scale.",
    subtitle:
      "Transform a LinkedIn URL into a powerful highly personalized sales emails, increasing your engagement rates and potential for successful conversions.",
    iconBg: "#fff",
    credits: "1",
    categories: [],
  },
  {
    name: "Email Verification and Bounce Rates",
    enrichmentName: "verifyEmail",
    icon: Verify,
    iconBg: "#fff",
    label: "Validate email to prevent bounce rates",
    subtitle:
      "Ensure your emails reach their intended recipients with our email verification and bounce testing tool. Avoid spam filters and maintain a high deliverability score for effective email marketing campaigns.",
    categories: ["Email Information"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    name: "Add Lead to Smartleads",
    enrichmentName: "addLeadToSmartleadsCampaign",
    icon: Campaign,
    iconBg: "#fff",
    label: "Add a lead to a campaign in Smartlead.ai.",
    subtitle:
      "Integrate new leads and personalizations into Smartleads, ensuring each lead is personalized and tailored to your business needs.",
    categories: [],
    credits: "FREE",
  },
  {
    name: "Apify Actor",
    enrichmentName: "apifyActor",
    icon: ApiFy,
    iconBg: "#fff",
    label: "Run Apify actors to scrape data from websites.",
    subtitle: "",
    categories: [],
    credits: "FREE",
  },
  {
    name: "Add Leads to Apollo Sequences",
    enrichmentName: "addLeadToApollo",
    icon: () => <Icons.GoogleAdsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Add leads to Apollo Sequences",
    subtitle: "Add leads to Apollo Sequences",
    categories: [],
    credits: "1/FREE",
  },
  {
    name: "Webhook",
    enrichmentName: "webhook",
    icon: () => <Icons.WebhookIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Webhook",
    subtitle: "Webhook",
    categories: [],
    credits: "1/FREE",
    isHidden: true,
  },
  {
    name: "HTTP API",
    enrichmentName: "httpApi",
    icon: () => <Icons.HttpIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "HTTP API",
    subtitle: "HTTP API",
    categories: [],
    credits: "FREE",
  },
  ...topEnrichments,
];

export const categories = [
  { name: "All Enrichments", icon: "" },
  { name: "Prospect Enrichment", icon: Prospect },
  // { name: "Linkedin Enrichment", icon: LinkedIn },
  { name: "Company Enrichment", icon: Company },
  { name: "Email Information", icon: Email },
  { name: "Web Research", icon: Web },
  { name: "AI Enrichment", icon: AI },
] as const;

export const REGEX = {
  LINKEDINPROFILE:
    /https?:\/\/(?:www\.)?linkedin\.com\/(?:in|profile)\/[\w-]+\/?/g,
  LINKEDINCOMPANY: /https?:\/\/(www\.)?linkedin\.com\/company\/[\w-]+\/?/gi,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi,
  WEBSITE:
    /^(?!.*linkedin\.com)(https?:\/\/(?:[a-zA-Z0-9]{2,}\.)+[^\s/$.?#].[^\s]*)$/g,
} as const;

export const jobOpeningsTutorialLink =
  "https://www.youtube.com/embed/MPCA-5Fu3Ig?si=voR5C47hj-W_e4Fw";
export const companyTechnologiesTutorialLink =
  "https://www.youtube.com/embed/UkHqXos4iBY?si=xD9hL5x0WXwK3YOJ";
export const companyNewsTutorialLink =
  "https://www.youtube.com/embed/ViKrWs6xrRE?si=gvOhvIIiLjd9BbAG";
export const workEmailsTutorialLink =
  "https://www.youtube.com/embed/L39i0MyO2Io?si=tnoZo9mAmyNtzv_Q";
export const companyQuestionTutorialLink =
  "https://www.youtube.com/embed/G4NABAcIm_o?si=FWXMEHG-IJyofjnt";
export const googleSearchTutorialLink =
  "https://www.youtube.com/embed/KEBestxLkJQ?si=WvoqvoALBQPYv6er";
export const scrapeWebsitesTutorialLink =
  "https://www.youtube.com/embed/ucEOiJ4osIo?si=WQPkAAX6jRQ2TcBi";
export const useChatGPTTutorialLink =
  "https://www.youtube.com/embed/ucEOiJ4osIo?si=WQPkAAX6jRQ2TcBi";
