import { useEffect } from "react";

import ResultScreenLoader from "../../Common/ResultScreenLoader";
import ResultScreenInfo from "../../Common/ResultScreenInfo";
import SearchLeadsResultTableActions from "./SearchLeadsResultTableActions";
import NoResult from "../../Common/NoResult";

import { tableActionIcons } from "../../Icons/tableActionIcons";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

type SearchLeadsResultTableProps = {
  isSearching: boolean;
  getFormattedColumnRows: any;
  generateInitialResultTable: () => void;
  currentSearchData: any;
  onCloseModal: (closeAll?: boolean) => void;
};

const SearchLeadsResultTable = ({
  isSearching,
  onCloseModal,
  generateInitialResultTable,
  currentSearchData,
}: SearchLeadsResultTableProps) => {
  const {
    searchLeadsResult,
    searchLeadsColumnNames: columnNames,
    searchLeadsResultRows: columnRows,
  } = useSearchLeadsStore();
  const infos = [
    "Filter by job titles, companies , skills, locations, or more.",
    `Click "Search" to see profiles that match your search.`,
    `Click "Import" to Import your list into a Persana table to \n enrich them more.`,
  ];

  useEffect(() => {
    if (searchLeadsResult && searchLeadsResult?.profiles?.length > 0) {
      generateInitialResultTable();
    }
  }, [searchLeadsResult]);

  if (isSearching) {
    return <ResultScreenLoader />;
  }

  if (!searchLeadsResult) {
    return <ResultScreenInfo infos={infos} />;
  }

  if (searchLeadsResult?.profiles?.length === 0) {
    return <NoResult />;
  }

  return (
    <div className="flex size-full flex-col justify-between">
      <div className="w-full overflow-x-auto pt-3">
        <div className="flex h-[36px] w-fit items-center border-y border-[#0000001a]">
          {columnNames?.map((column, index) => {
            const OptionIcon = tableActionIcons["text"];
            return (
              <div
                key={`${index}-${Math.random()}`}
                className="main-header-container"
              >
                <div
                  className={`relative flex h-full w-[200px]  cursor-pointer items-center space-x-1.5 border-r border-[#0000001a] bg-[#FBFBFC] p-[0.5rem] text-[#676d7e] duration-300 hover:bg-[#f6f6f8] ${index === 0 ? "border-l" : ""
                    }`}
                >
                  <span>{<OptionIcon className="h-auto w-4" />}</span>
                  <span className="truncate text-[15px] font-medium capitalize leading-[1]">
                    {column?.value}
                  </span>
                  <span className="absolute right-0 h-full w-[3px] bg-primary opacity-0 hover:opacity-100"></span>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          {columnRows?.length > 0 &&
            columnRows?.[0]?.rows?.map((_: any, rowIndex: number) => {
              return (
                <div
                  key={`${rowIndex}-${Math.random()}`}
                  className="flex h-full w-fit items-center border-b border-[#0000001a]"
                >
                  {columnNames?.map((item: any, colIndex: number) => {
                    const currentRow = columnRows.find(
                      (row) => row?.colId === item?.id,
                    );
                    return (
                      <div
                        key={`${colIndex}-${Math.random()}`}
                        className={`flex h-full w-[200px] cursor-pointer items-center space-x-1.5 border-r border-[#0000001a] bg-white p-[0.5rem] text-[#676d7e] ${colIndex === 0 ? "border-l" : ""
                          }`}
                      >
                        {
                          <div className="autoamtions-table-cell-conatiner relative flex size-full items-center space-x-2 duration-500">
                            <p className="truncate text-[15px] font-medium leading-[1]">
                              {currentRow?.rows[rowIndex]?.value}
                            </p>
                          </div>
                        }
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      <SearchLeadsResultTableActions
        onCloseModal={onCloseModal}
        currentSearchPayload={currentSearchData}
      />
    </div>
  );
};

export default SearchLeadsResultTable;
