import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import AddTeammates from "./AddTeammates";
import ManageTeam from "./ManageTeam";
import { useTeamData } from "@/hooks/useTeamData";
import { Member } from "@/types/team.types";
import { useModalStore } from "@/stores/modal.store";

const AddTeammatesModal = () => {
  const [teamMembers, setTeamMembers] = useState<Member[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const { addTeamMembersModal , updateState } = useModalStore((state) => state);
  const { data: teamData } = useTeamData();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const onClose = () => {
    updateState({
      addTeamMembersModal: {
        isOpen: false,
        isForShare: false,
      },
    })
  };


  useEffect(() => {
    if (teamData?.data) {
      setTeamMembers(teamData.data.members.filter(member => member.role !== "admin"));
    }
  }, [teamData?.data]);

  useEffect(() => {
    if (addTeamMembersModal.isForShare) {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [addTeamMembersModal.isForShare]);

  return (
    <Modal
      isOpen={addTeamMembersModal.isOpen}
      onClose={onClose}
      size={"xl"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton className="z-10" />
        <ModalBody>
          <Tabs align="center" position="relative" variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              <Tab>Add Teammates</Tab>
              <Tab>Manage Team {
                teamMembers?.length ? `(${teamMembers.length})` : ""
              }</Tab>
            </TabList>

            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />

            <TabPanels>
              <TabPanel>
                <AddTeammates changeTab={handleTabsChange} />
              </TabPanel>
              <TabPanel>
                <ManageTeam changeTab={handleTabsChange} teamMembers={teamMembers} isForShare={addTeamMembersModal.isForShare} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddTeammatesModal;
