export const defaultPromptText = `I want you to write a personalized email for the lead and make it persuasive and engaging but authentic.
It should not sound robotic. The tone is friendly and persuasive, the first paragraph of the email should include personalizations based on the lead.
You have to follow the rules:
  - The email should be concise
  - The email contains 3 paragraphs and the email length is under 70 words.
  - The first paragraph should be only 2 sentences and have a personalized compliment about the lead's experience or their company.
  - The second paragraph should be about how my company can help them. It should be only 2 sentences.
  - The third paragraph should be about the call to action, and we can ask the lead a question to get them to the call to action.
  Make the call to action friendly and try to make a strong relationship with the lead. It should just be one line.
  Do not include a subject line.
  - The message should be under 100 words!
  - Keep two empty lines after the salutation
  Make sure the message is under 70 words and 3 paragraphs in total`;
