import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";

import { getParsedCsvData } from "@/utils";
import accountMonitoringService from "@/services/accountMonitoring.service";

const predefinedColumns = [
  {
    label: "Company Name",
    value: "companyName",
  },
  {
    label: "Company Website",
    value: "companyWebsite",
  },
];

const CompanyCSVUpload = ({
  fetchPaginationData,
}: {
  fetchPaginationData: () => void;
}) => {
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [uploadedColumns, setUploadedColumns] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>({
    companyName: "",
    companyWebsite: "",
  });
  const [selectedFile, setSelectedFile] = useState<Blob>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    const columnNames = (await getParsedCsvData(file)) as any;
    setSelectedFile(file);
    if (columnNames?.columns) {
      setIsOpen(true);
      setUploadedColumns(columnNames?.columns?.filter(Boolean));
    }
  };

  const onClose = () => setIsOpen(false);

  const handleSubmit = async () => {
    if (!selectedOptions.companyName) {
      toast({
        position: "top-right",
        title: "Select Company Name field",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }
    if (!selectedOptions.companyWebsite) {
      toast({
        position: "top-right",
        title: "Select Company Website field",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    // @ts-ignore
    formData.append("file", selectedFile);
    formData.append("companyName", selectedOptions.companyName);
    formData.append("companyWebsite", selectedOptions.companyWebsite);
    setIsSubmitting(true);
    const res = await accountMonitoringService.uploadCompanies(formData);
    if (res.success) {
      toast({
        title: "Success",
        description: "File uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchPaginationData();
      setIsOpen(false);
    } else {
      toast({
        title: "Error",
        description: "There was an issue uploading the file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <label className="m-0 flex h-[40px] cursor-pointer items-center justify-center gap-3 rounded-[6px] bg-primary-gradient p-3 font-[600] text-white hover:opacity-80">
        Upload CSV
        <input
          type="file"
          accept=".csv"
          className="hidden"
          onChange={handleFileChange}
        />
      </label>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalBody>
            <div>
              {predefinedColumns.map((column) => (
                <Card key={column.label} className="my-2">
                  <CardBody>
                    <div className="flex items-center justify-between">
                      <Text className="flex-1">{column.label}</Text>
                      <div className="flex-1">
                        <Select
                          placeholder="Select Column"
                          onChange={(selectedItem) => {
                            setSelectedOptions((prevState: any) => ({
                              ...prevState,
                              [column.value]: selectedItem.target.value,
                            }));
                          }}
                          value={selectedOptions[column.value]}
                        >
                          {uploadedColumns.map((column) => (
                            <option value={column} key={column}>
                              {column}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
              <div className="mb-5 mt-3 flex w-full justify-center">
                <Button
                  colorScheme="purple"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CompanyCSVUpload;
