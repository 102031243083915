/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from "react";
import { Button } from "@chakra-ui/react";

import Icons from "../Icons";
import SelectColumnDropdown from "../Enrichment/Common/SelectColumnDropdown";

import { findPlaceholderValues } from "@/lib/utils";
import { usePresetsStore } from "@/stores/presets.store";

import type { PresetsResponseData } from "@/types/presets.types";
import type { TSelectedColumnOption } from "@/types/common.types";

interface Props {
  presetsData: PresetsResponseData[];
}

const PresetsList = ({ presetsData }: Props) => {
  const updateState = usePresetsStore((state) => state.updateState);

  const [selectedPreset, setSelectedPreset] =
    useState<PresetsResponseData | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<
    {
      placeholderValue: string;
      value: TSelectedColumnOption;
    }[]
  >([]);

  const placeholderValue = useMemo(() => {
    if (!selectedPreset) return [];
    return findPlaceholderValues(selectedPreset.prompt);
  }, [selectedPreset]);

  const onUsePresetClick = () => {
    let modifiedPrompts = selectedPreset?.prompt || "";

    selectedColumns.forEach((item) => {
      const placeholder = `{{${item.placeholderValue}}}`;
      if (modifiedPrompts.includes(placeholder)) {
        modifiedPrompts = modifiedPrompts.replace(
          placeholder,
          `{{${item.value.key}}}`,
        );
      }
    });

    setSelectedColumns([]);
    updateState({
      isOpenPresetsModal: false,
      selectedPresetValues: modifiedPrompts || "",
    });
  };

  return (
    <div className="">
      {selectedPreset ? (
        <div className="h-full space-y-6 overflow-y-auto">
          <div className="flex justify-start">
            <Icons.ArrowLeftIcon
              onClick={() => {
                setSelectedPreset(null);
                setSelectedColumns([]);
              }}
              className="cursor-pointer text-[#000000]/70"
              size={20}
            />
          </div>

          <div className="text-sm !mt-2">
            <h2 className="pb-0.5 font-semibold text-[#1d2026]">
              {selectedPreset.name}
            </h2>
            <p className="text-[#676d7e]">{selectedPreset.description}</p>
          </div>

          {placeholderValue.map((value, index) => {
            return (
              <div className="text-sm" key={`${value}_${index}`}>
                <h4 className="pb-0.5 font-semibold text-[#1d2026]">{value}</h4>
                <SelectColumnDropdown
                  selectedOption={
                    selectedColumns.find(
                      (col) => col.placeholderValue === value,
                    )?.value || null
                  }
                  setSelectedOption={(selectedColumn) => {
                    // @ts-ignore
                    setSelectedColumns((prevState) => {
                      const matchedColumn = prevState.find(
                        (col) => col.placeholderValue === value,
                      );
                      if (matchedColumn) {
                        matchedColumn.value =
                          selectedColumn as TSelectedColumnOption;
                        return [...prevState];
                      } else {
                        return [
                          ...prevState,
                          {
                            placeholderValue: value,
                            value: selectedColumn,
                          },
                        ];
                      }
                    });
                  }}
                  notInportal
                />
              </div>
            );
          })}

          <div className="text-sm">
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">
              Prompt Preview
            </h4>
            <p className="cursor-not-allowed rounded-md border border-[#c3c7d0] bg-[#f6f6f8] p-3 text-[#676d7e]">
              {selectedPreset.prompt}
            </p>
          </div>

          {selectedPreset.condition && (
            <div className="text-sm">
              <h4 className="pb-0.5 font-semibold text-[#1d2026]">
                Conditional Run Preview
              </h4>
              <p className="cursor-not-allowed rounded-md border border-[#c3c7d0] bg-[#f6f6f8] p-3 text-[#676d7e]">
                {selectedPreset.condition}
              </p>
            </div>
          )}

          <Button
            colorScheme="green"
            onClick={onUsePresetClick}
            isDisabled={placeholderValue?.length > 0 && !selectedColumns.length}
          >
            <Icons.CheckCircleIcon className="mr-2 text-lg" />
            Use this preset
          </Button>
        </div>
      ) : (
        <div className="flex-grow space-y-4 overflow-y-auto custom-scrollbar">
          {presetsData?.map((preset, index) => (
            <div
              key={index}
              className="w-[98%] cursor-pointer rounded-md bg-[#f6f6f8] p-3 duration-300 hover:bg-[#E7E8EC]"
              onClick={() => setSelectedPreset(preset)}
            >
              <p className="font-semibold">{preset.name}</p>
              <p className="text-sm">{preset.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PresetsList;
