import { FETCH_TABLE_LIMIT } from "@/constants/table";
import MakeAPICall from "@/lib/apiCalls";
import { generateFilterPayload } from "@/lib/utils";
import { useTableStore } from "@/stores/table.store";
import { TableCacheSocketData } from "@/types/enrichment.types";

import type {
  DeleteRowResponse,
  ResponseData,
  RowData,
  RowUpdatePayload,
  TableData,
  DeleteColumnResponse,
  RowCreatePayload,
  ColumnCreatePayload,
  Column,
} from "@/types/table.types";

class TableService {
  async getTableList() {
    const apiCall = new MakeAPICall({
      apiPath: `table/list`,
    });
    const response = await apiCall.get<Partial<ResponseData["tableData"]>[]>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error);
    }
  }

  async getCellData(tableId: string, rowId: string, columnId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/cell/${tableId}`,
      params: {
        rowId,
        columnId,
      },
    });
    const response = await apiCall.get();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error);
    }
  }

  async fetchTableData({
    tableId,
    clearFilters,
    nextPage,
  }: {
    tableId: string;
    clearFilters?: boolean;
    nextPage?: number;
  }) {
    const queryParams = useTableStore.getState().queryParams;
    const tableFilters = useTableStore.getState().tableFilters;

    const { filterPayload } = generateFilterPayload(tableFilters.filters || []);

    const bodyPayload = {
      filterBody: filterPayload,
    };

    const { pageNumber } = queryParams || {};
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}`,
      params: {
        ...queryParams,
        pageNumber: nextPage || pageNumber || 1,
        limit: FETCH_TABLE_LIMIT,
        clearFilters: !!clearFilters,
      },
      payload: bodyPayload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: ResponseData;
      message?: string;
    }>();
    if (response.success && response.data?.success) {
      const responseData = response.data?.data;
      useTableStore.setState({
        ...responseData,
      });
      return responseData;
    } else {
      throw new Error(response.error);
    }
  }

  async updateTableData(
    tableId: string,
    payload: {
      name: string; // TODO: add more later
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}`,
      payload,
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }

  async updateColumnData(
    tableId: string,
    columnId: string,
    payload: Partial<Column>,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/column/${columnId}`,
      payload,
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }

  async updateColumnsData({
    tableId,
    payload,
  }: {
    tableId: string;
    payload: {
      columnId: string;
      column: Partial<Column>;
    }[];
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/columns`,
      payload,
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }

  async updateRowData({
    tableId,
    rowId,
    payload,
  }: {
    payload: RowUpdatePayload;
    tableId: string;
    rowId: string;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/row/${rowId}`,
      payload,
    });
    const response = await apiCall.patch<RowData>();
    return response;
  }

  async deleteTable(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}`,
    });
    const response = await apiCall.delete<ResponseData>();
    return response;
  }

  async copyTable(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/copy/${tableId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async deleteRow(tableId: string, rowId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/row/${rowId}`,
    });
    const response = await apiCall.delete<DeleteRowResponse>();
    return response;
  }

  async insertRow(tableId: string, payload?: RowCreatePayload) {
    const apiCall = new MakeAPICall({
      apiPath: `table/insert-row/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: RowData;
      message: string;
    }>();
    return response;
  }

  async insertColumn(
    tableId: string,
    payload: ColumnCreatePayload,
    query?: { [key: string]: string },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/insert-column/${tableId}`,
      payload,
      params: query,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: TableData;
      message: string;
    }>();
    return response;
  }

  async deleteColumn(tableId: string, columnId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/column/${columnId}`,
    });
    const response = await apiCall.delete<DeleteColumnResponse>();
    return response;
  }

  async deleteRunningProcess(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/cancel-job/${tableId}`,
    });
    const response = await apiCall.delete<ResponseData["tableData"]>();
    return response;
  }

  async createAndGetStarterTable(id: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/starter-list/${id}`,
    });
    const response = await apiCall.get<ResponseData>();
    if (response.success) {
      return response.data;
    }
    throw new Error(response.error);
  }

  async downloadCSV(tableId: string, queryParams: any, payload?: any) {
    const apiCall = new MakeAPICall({
      apiPath: `table/download-csv/${tableId}`,
      params: queryParams,
      payload,
    });
    const response = await apiCall.getBlob();

    if (response?.type === "application/json") {
      return {
        success: true,
        message: "We're creating your file...",
      };
    }

    const url = window.URL.createObjectURL(
      new Blob([response], { type: "text/csv" }),
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${queryParams.tableName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async getCSVSignUrl(fileKey: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/get-csv`,
      payload: {
        fileKey,
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      signedUrl: string;
      message?: string;
    }>();
    return response;
  }

  async getTableCacheData(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/cache-data/${tableId}`,
    });
    const response = await apiCall.get<TableCacheSocketData[]>();
    return response;
  }

  async createBlankTable() {
    const apiCall = new MakeAPICall({
      apiPath: `table/blank-table`,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: { tableId: string };
      message?: string;
    }>();
    return response;
  }
  async removeRows(rowIds: string[], tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/rows`,
      params: {
        rowIds: rowIds.join(","),
      },
    });
    const response = await apiCall.delete<{
      success: boolean;
      message?: string;
    }>();
    return response;
  }

  async generateShareLink(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/generate-shareable-link/${tableId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getTablePreviewData(token: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/preview?token=${token}`,
    });
    const response = await apiCall.get();
    return response;
  }
  async generateFormula(tableId: string, payload: any) {
    const apiCall = new MakeAPICall({
      apiPath: `table/generate-formula/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: {
        formula: string;
        usedTableColumns: Column[];
        formulaAppliedRows: string[];
      };
      message?: string;
    }>();

    return response;
  }

  async addFormulaColumn(
    tableId: string,
    payload: {
      formula: string;
      name?: string;
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/add-formula-to-table/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: TableData;
      message?: string;
    }>();
    return response;
  }

  async shareWithTeam(tableId: string, payload: {
    usersId: string[];
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/share-table-with-team/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      message?: string;
      data : TableData
    }>();
    return response;
  }
}

const tableService = new TableService();
export default tableService;
