import { FilterType } from "@/types/table.types";

export const findPlaceholderValues = (text: string) => {
  const regex = /{{(.*?)}}/g;
  const matches = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1]);
  }
  return matches;
};

export const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
};

export const generateFilterPayload = (filter: FilterType[]) => {
  const filterPayload = filter.map((item) => {
    const { column } = item;
    return {
      ...item,
      column: {
        _id: column._id,
        name: column.name,
      },
    };
  });
  const isAnyEmpty = filterPayload.some(
    (item) => item.condition.hasInput && !item.value,
  );

  return {
    filterPayload,
    isAnyEmpty,
  };
};

export function arrayToObject(array: any[]) {
  const obj: { [key: string]: any } = {}; // Specify the type of obj as an indexable object
  array.forEach((item) => {
    const key = item.name.replace(/\s+/g, "_"); // Replace spaces with underscores
    obj[key] = item.value;
  });
  return obj;
}

export const downloadFile = ({
  url,
  fileName = "table.csv",
}: {
  url: string;
  fileName?: string;
}) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
