import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import React from "react";

import PresetsList from "./PresetsList";

import Icons from "../Icons";

import { usePresetsStore } from "@/stores/presets.store";
import { usePresetsData } from "@/hooks/usePresetsData";

import GptLogo from "@/assets/svgs/chatGpt.svg";
import { modalTypeTitleMapping } from "./constants";

const PresetsModals = () => {
  const presetsModalData = usePresetsStore((state) => state.presetsModalData);
  const updateState = usePresetsStore((state) => state.updateState);

  const { isLoading, data } = usePresetsData(presetsModalData.modalType!);

  const presetsData = data?.data;

  console.log("presetsData", presetsData);

  return (
    <Modal
      isOpen={presetsModalData !== null}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="2xl"
      onClose={() => {
        updateState({
          isOpenPresetsModal: false,
        });
      }}
      useInert={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="rounded-[20px]">
        <ModalHeader className="border-b border-gray-300">
          <p className="flex items-center gap-x-2">
            {presetsModalData.modalType === "use-ai" && (
              <img
                src={GptLogo}
                className="h-[30px] w-[30px] rounded-full"
                alt="logo"
              />
            )}
            {presetsModalData.modalType === "ask-question" && (
              <Icons.AgentIcon color="#ffef00" size={26} />
            )}
            {presetsModalData.modalType === "sales-emails" && (
              <Icons.CampaignIcon size={26} color="#1972f5" />
            )}

            <span>
              {modalTypeTitleMapping[presetsModalData.modalType!] || ""}
            </span>
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="h-[35rem] overflow-auto custom-scrollbar">
            {isLoading && (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            )}
            {!isLoading && !presetsData && (
              <div className="flex justify-center items-center">
                <p className="text-lg text-gray-500">No data found</p>
              </div>
            )}

            <React.Suspense
              fallback={
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              }
            >
              {!isLoading && presetsData && (
                <PresetsList presetsData={presetsData} />
              )}
            </React.Suspense>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PresetsModals;
