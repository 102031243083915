import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import { CellPosition, Column, IRowNode } from "ag-grid-community";

import Icons from "../Icons";
import { useTableStore } from "@/stores/table.store";
import { RowUpdatePayload } from "@/types/table.types";
import tableService from "@/services/table.service";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

function CellExpendViewModal({ gridRef }: Props) {
  const [currentlySelectedCell, setCurrentlySelectedCell] =
    useState<CellPosition | null>(null);
  const [cellValueUpdated, setCellValueUpdated] = useState<boolean>(false);

  const { expendedCellValue, tableData, updateState } = useTableStore();
  const toast = useToast();

  const cellValueType = typeof expendedCellValue.cellValue;

  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      payload,
      rowId,
    }: {
      tableId: string;
      payload: RowUpdatePayload;
      rowId: string;
    }) =>
      tableService.updateRowData({
        tableId,
        payload,
        rowId,
      }),
  });

  const gridApi = gridRef?.current?.api;

  const updateTableAndDB = async () => {
    if (cellValueUpdated) {
      const updatedValue = expendedCellValue;

      if (!updatedValue?.cellValue?.trim()) return;

      const rowNode = gridRef?.current!.api?.getRowNode(updatedValue.rowId);
      if (rowNode) {
        const rowData = rowNode?.data || {};
        rowData[updatedValue.columnId] = updatedValue.cellValue;
        gridRef.current?.api?.applyTransaction({ update: [rowData] });
      }

      await mutateAsync(
        {
          tableId: tableData._id,
          payload: {
            columnId: updatedValue.columnId,
            cellValue: {
              value: updatedValue.cellValue,
            },
          },
          rowId: updatedValue.rowId,
        },
        {
          onSuccess: (response) => {
            if (!response.success) {
              toast({
                title: "Error",
                description: response.error?.message || "Something went wrong",
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
              return;
            }
            toast({
              title: "Success",
              description: `Cell data updated successfully`,
              status: "success",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
          onError: (error) => {
            toast({
              title: "Error",
              description: error?.message || "Something went wrong",
              status: "error",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
        },
      );
    }
  };

  const onClose = async () => {
    updateState({
      expendedCellValue: {
        isOpen: false,
        cellValue: "",
        columnName: "",
        columnId: "",
        rowId: "",
      },
    });

    console.log("myLog close check");

    await updateTableAndDB();

    setCellValueUpdated(false);
    if (currentlySelectedCell && gridApi) {
      gridApi.setFocusedCell(
        currentlySelectedCell.rowIndex,
        currentlySelectedCell.column,
      );
    }
  };

  const updateCellValue = (rowIndex: number, column: Column) => {
    gridApi?.setFocusedCell(rowIndex, column);
    const newSelectedCell = gridApi?.getFocusedCell();
    if (gridApi && newSelectedCell) {
      const rowNode = gridApi.getDisplayedRowAtIndex(
        newSelectedCell.rowIndex,
      ) as IRowNode;
      const newValue = gridApi?.getValue(newSelectedCell.column, rowNode);

      updateState({
        expendedCellValue: {
          ...expendedCellValue,
          cellValue: newValue,
          columnName: newSelectedCell.column.getColDef().headerName || "",
          columnId: column.getColId(),
          rowId: rowNode.data.rowData._id,
          rowIndex,
        },
      });
    }
  };

  const handleArrowUp = async () => {
    const selectedCell = gridApi?.getFocusedCell();

    if (selectedCell && gridApi) {
      setCurrentlySelectedCell(selectedCell);
      const { rowIndex, column } = selectedCell;

      if (rowIndex > 0) {
        updateCellValue(rowIndex - 1, column);
      }
    }

    await updateTableAndDB();
    setCellValueUpdated(false);
  };

  const handleArrowDown = async () => {
    const selectedCell = gridApi?.getFocusedCell();

    if (selectedCell && gridApi) {
      setCurrentlySelectedCell(selectedCell);
      const { rowIndex, column } = selectedCell;
      if (rowIndex < gridApi.getLastDisplayedRow()) {
        updateCellValue(rowIndex + 1, column);
      }
    }

    await updateTableAndDB();
    setCellValueUpdated(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      handleArrowUp();
    } else if (e.key === "ArrowDown") {
      console.log("ArrowDown");
      handleArrowDown();
    }
  };

  expendedCellValue.cellValue =
    cellValueType === "object"
      ? JSON.stringify(expendedCellValue.cellValue, null, 2)
      : expendedCellValue.cellValue || "";

  return (
    <Modal
      isOpen={expendedCellValue?.isOpen}
      onClose={onClose}
      isCentered
      size={"2xl"}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent onKeyDown={handleKeyDown}>
        <ModalHeader className="text-center">
          {expendedCellValue.columnName}
        </ModalHeader>
        <ModalBody>
          <Textarea
            value={expendedCellValue.cellValue}
            onChange={(e) => {
              setCellValueUpdated(true);
              updateState({
                expendedCellValue: {
                  ...expendedCellValue,
                  cellValue: e.target.value,
                },
              });
            }}
            resize="none"
            rows={15}
          />
        </ModalBody>
        <ModalFooter className="flex items-center !justify-start gap-3">
          <div
            className="flex cursor-pointer items-center gap-1"
            onClick={handleArrowUp}
          >
            <span className="flex h-4 w-4 items-center justify-center rounded-full bg-primary">
              <Icons.ThickArrowDown className=" rotate-180 transform text-xs text-white" />
            </span>
            <span className="text-xs font-medium text-[#676d7e]">
              Previous Cell
            </span>
          </div>
          <div className="flex cursor-pointer items-center gap-1">
            <span className="flex h-4 w-4 items-center justify-center rounded-full bg-primary">
              <Icons.ThickArrowDown className=" text-xs text-white" />
            </span>
            <span className="text-xs font-medium text-[#676d7e]">
              Next Cell
            </span>
          </div>
          <div className="flex cursor-pointer items-center gap-1">
            <span className="flex w-fit items-center justify-center rounded bg-primary px-1 py-0.5 text-xs font-medium text-white">
              Esc
            </span>
            <span className="text-xs font-medium text-[#676d7e]">
              Close Modal
            </span>
          </div>
          <div className="flex-1 flex justify-end">
            <p className="text-sm font-medium text-[#676d7e]">
              Row {(expendedCellValue?.rowIndex || 0) + 1} of{" "}
              {gridApi?.getDisplayedRowCount()}
            </p>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CellExpendViewModal;
