import { useEffect } from "react";
import { Button } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const ErrorComponent = ({ error }: any) => {
  useEffect(() => {
    Sentry.captureException(error.message, {
      tags: {
        priority: "high",
      },
      extra: {
        error,
      },
    });

    // After 3 seconds, reload the page
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, [error]);

  const handleReload = () => {
    window.location.reload();
  };

  console.error(error);

  return (
    <div className="mt-5 flex size-full flex-col items-center justify-center">
      <p>Oops! Something Went Wrong.</p>
      <p className="mb-5">We are working on it.</p>
      <Button variant="solid" colorScheme="red" onClick={handleReload}>
        Reload
      </Button>
    </div>
  );
};

export default ErrorComponent;
