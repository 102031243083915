import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";

import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";
import SidebarLinks from "./Links";
import Icons from "@/components/Icons";

import PersanaLogo from "@/assets/images/logo.png";
import { useModalStore } from "@/stores/modal.store";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";

const Sidebar = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);

  const navigate = useNavigate();
  const updateState = useModalStore((state) => state.updateState);
  const creditDetails = userStore((state) => state.creditDetails);
  const currentUser = userStore((state) => state.currentUser);

  const isAdmin = currentUser?.role === "admin";

  const handleLogout = async () => {
    localStorage.removeItem("persana-alradyShowedIntro");
    navigate({ to: "/login" });
    await userService.logout();
    window.location.reload();
  };

  const buttons = [
    {
      name: "Upgrade",
      icon: <Icons.CrownIcon className="h-6 w-6" />,
      onClick: () =>
        updateState({ isOpenModal: true, modalData: { modalType: "pricing" } }),
    },
    {
      name: "Book Onboarding Call",
      icon: <Icons.PhoneIcon className="h-6 w-6" />,
      onClick: () => {
        window.open(
          "https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding",
          "_blank",
        );
      },
    },
    {
      name: "Help Center",
      icon: <Icons.HelpIcon className="h-6 w-6" />,
      onClick: () => {
        window.open(
          "https://viralcopy.notion.site/Persana-Help-Center-656c99a5b9b048e9955fc9a1676e0a00",
          "_blank",
        );
      },
    },
    {
      name: "Logout",
      icon: <Icons.LogoutIcon className="h-6 w-6" />,
      onClick: handleLogout,
    },
  ];

  let openingTimeout: any;
  const handleMouseEnter = () => {
    setExpandSidebar(true);
    setIsMouseLeave(false);
    openingTimeout = setTimeout(() => {
      setIsHovering(true);
    }, 250);
  };

  const handleMouseLeave = () => {
    setIsMouseLeave(true);
    clearTimeout(openingTimeout);
    if (openingTimeout) {
      setIsHovering(false);
      setExpandSidebar(false);
    }
    setTimeout(() => {
      setIsHovering(false);
      setExpandSidebar(false);
    }, 250);
  };

  return (
    <div className="flex h-full">
      <div className="!duration-1000">
        <div
          className={`sidebar sm:none fixed !z-[100] flex min-h-full flex-col bg-white pb-3 shadow-2xl shadow-white/5 transition-all duration-[400ms] dark:!bg-navy-800 dark:text-white ${
            expandSidebar ? "w-[17rem]" : "w-[4rem]"
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="absolute right-4 top-4 block cursor-pointer text-white dark:text-white xl:hidden">
            <Icons.HiXIcon />
          </span>
          <div>
            <div className="flex items-center justify-center pb-4">
              <img
                src={PersanaLogo}
                className="h-auto w-[30px] my-[10px] pointer-events-none"
              />
            </div>
          </div>
          <ul
            className="mb-auto w-full overflow-y-scroll scrollbar-thin "
            style={{
              scrollbarColor: "transparent transparent",
            }}
          >
            <SidebarLinks isHovering={isHovering} />
          </ul>
          <div className="w-full sidebar">
            {!isMouseLeave && isHovering && !isAdmin ? (
              <div className="w-full px-5 pb-5">
                <div className="flex items-center justify-between space-x-10 pb-2 font-poppins">
                  <div className="flex items-center gap-x-2">
                    <span className="font-medium text-[#475467]">
                      {creditDetails?.planType || "Free Plan"}
                    </span>
                  </div>
                  <p className="flex items-center gap-x-1">
                    <span className="font-medium text-[#AAB2BA]">
                      {creditDetails?.creditUsed || 0}
                    </span>{" "}
                    <span className="font-semibold text-[#344054]">/</span>
                    <span className="font-semibold text-[#344054]">
                      {creditDetails?.creditLeft || 0}
                    </span>
                  </p>
                </div>

                {creditDetails?.creditUsed != null &&
                creditDetails?.creditLeft != null ? (
                  <div className="h-3 w-full rounded-full bg-[#E4E9ED]">
                    <div
                      className="h-3 rounded-full bg-[#0284FE]"
                      style={{
                        width: `${
                          (creditDetails?.creditUsed /
                            creditDetails?.creditLeft) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {buttons.map((button, index) => (
              <div
                key={index}
                onClick={button.onClick}
                className="w-full cursor-pointer bg-white py-[5px] transition-all"
              >
                <button className="relative flex w-full py-1 duration-300 hover:cursor-pointer hover:!bg-[#dce6f15d]">
                  <div className="flex cursor-pointer px-1">
                    <span
                      className={`flex w-12 justify-center text-xs font-medium text-[#000000]/70`}
                    >
                      {button.icon}
                    </span>
                    {isHovering && (
                      <p
                        className={`leading-1 flex font-medium text-[#000]/80`}
                      >
                        {button.name}
                      </p>
                    )}
                  </div>
                </button>
              </div>
            ))}
            <div className="pl-[8px]">
              <button className="mt-3 flex cursor-pointer items-center justify-center gap-x-2 px-1">
                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#dce6f15d] text-[#1C1C1C] text-base uppercase">{`${
                  currentUser?.profileInfo?.firstName?.[0] || "P"
                }`}</span>{" "}
                {isHovering && (
                  <div className="flex flex-col items-start text-[#1C1C1C] ">
                    <span className="truncate ">
                      {currentUser?.profileInfo?.firstName}{" "}
                      {currentUser?.profileInfo?.lastName}
                    </span>
                    <span className="truncate text-[10px] ">
                      {currentUser?.Email}
                    </span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedSidebar = () => (
  <CustomErrorBoundary>
    <Sidebar />
  </CustomErrorBoundary>
);

export default WrappedSidebar;
