import ColumnList from "./ColumnList";

const BottomPageActions = () => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");

  return (
    <div className="flex items-center h-[50px] mt-1 pt-[0.50rem] pb-[0.25rem]">
      {!isTablePreviewPage && (
        <div className="flex items-center gap-3">
          <ColumnList />
        </div>
      )}
    </div>
  );
};

export default BottomPageActions;
