import { useMemo } from "react";
import { Link } from "@tanstack/react-router";

import routes from "@/constants/routes";
import { RoutesType } from "@/types/routes.types";
import { useUserData } from "@/hooks/useUserData";

type SidebarLinkProps = {
  isHovering: boolean;
};
const Links = ({ isHovering }: SidebarLinkProps) => {
  const { data: userData } = useUserData();

  const activeRoute = (routeName: string) => {
    return location.pathname === routeName;
  };
  const filteredRoutes = useMemo(() => {
    return routes?.filter((route) => !route.isHidden) || [];
  }, [routes]) as RoutesType[];

  const isAdmin = userData?.role === "admin";

  return (
    <>
      {filteredRoutes
        .filter((route) => {
          if (route.isForAdmin) {
            return isAdmin;
          }
          return true;
        })
        .map((route, index) => {
          return (
            <div
              key={index}
              style={{
                display: `${route?.isHidden ? "none" : "block"}`,
                transition: "background-color 0.3s ease-in-out",
                padding: "5px 0",
                cursor: "pointer",
              }}
            >
              <Link to={`${route.path}`} params={{}}>
                <div
                  className="relative mb-1.5 flex py-[10px] duration-300 hover:cursor-pointer hover:!bg-faintGray"
                  style={{
                    background: activeRoute(route.path)
                      ? "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)"
                      : "white",
                  }}
                >
                  <div className="flex cursor-pointer px-1" key={index}>
                    <span
                      className={`w-12 ${
                        activeRoute(route.path) === true
                          ? "font-bold text-white dark:text-white"
                          : "font-medium text-dark/70"
                      }`}
                    >
                      <route.icon
                        className={`mx-auto ${
                          route.path === "quickgenerate" ? "h-6 w-6" : "h-6 w-6"
                        }`}
                      />
                    </span>
                    <p
                      className={`leading-1 flex ${
                        activeRoute(route.path) === true
                          ? "font-bold text-white dark:text-white"
                          : "font-medium text-dark/80"
                      } ${isHovering ? "block" : "hidden"}`}
                    >
                      {route.name}
                    </p>
                  </div>
                  {activeRoute(route.path) ? (
                    <div className="absolute right-0 top-[50%] h-[100%] w-1 translate-y-[-50%] transform rounded-lg bg-primary dark:bg-primary" />
                  ) : null}
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
};

export default Links;
