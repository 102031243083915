import { Link, Text, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import AccountMonitoringHeader from "../Common/AccountMonitoring/AccountMonitoringHeader";
import AccountMonitoringSidebar from "../Common/AccountMonitoring/AccountMonitoringSidebar";
import AccountMonitoringActions from "../Common/AccountMonitoring/AccountMonitoringActions";
import AccountMonitoringImportModal from "../Common/AccountMonitoring/AccountMonitoringImportModal";
import TrackJobOpeningsModal from "./TrackJobOpeningModal";
import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";

import { Option } from "../Common/MultiSelectDropdown/types";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { userStore } from "@/stores/user.store";
import { useModalStore } from "@/stores/modal.store";
import { HiringIntentDetailsType } from "@/types/accountMonitoring.types";

const HiringIntent = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const gridRef = useRef<AgGridReact>(null);
  const [loading, setLoading] = useState(false);
  const [trackingModal, setTrackingModal] = useState(false);
  const [quickAddModal, setQuickAddModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  const [tableSearchQuery, setTableSearchQuery] = useState("");
  const [quickAddInput, setQuickAddInput] = useState("");
  const [sortCompanyBy, setSortCompanyBy] = useState<"asc" | "desc">("asc");
  const [selectedSidebarCompany, setSelectedSidebarCompany] =
    useState<HiringIntentDetailsType | null>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<
    HiringIntentDetailsType[]
  >([]);
  const [companiesList, setCompaniesList] = useState<HiringIntentDetailsType[]>(
    [],
  );
  const [companiesData, setCompaniesData] = useState<HiringIntentDetailsType[]>(
    [],
  );
  const [filteredData, setFilteredData] = useState<HiringIntentDetailsType[]>(
    [],
  );
  const [sidebarCompanyList, setSidebarCompanyList] = useState<
    HiringIntentDetailsType[]
  >([]);
  const [selectedFilters, setSelectedFilters] = useState<{
    categories: Option[];
    jobTitles: Option[];
    jobDescriptions: Option[];
  }>({
    categories: [],
    jobTitles: [],
    jobDescriptions: [],
  });

  const companyNameRenderer = (props: any) => {
    return (
      <>
        {props.data.companyWebsite ? (
          <Link
            style={{ color: "#693DC7" }}
            target="_blank"
            href={props.data.companyWebsite}
          >
            {props.data.companyName}
          </Link>
        ) : (
          <p>{props.data.companyName}</p>
        )}
      </>
    );
  };

  const jobRenderer = (props: any) => {
    return (
      <>
        {props.data.jobOpeningUrl ? (
          <Link
            style={{ color: "#693DC7" }}
            target="_blank"
            href={props.data.jobOpeningUrl}
          >
            {props?.data?.role || ""}
          </Link>
        ) : (
          <p>{props?.data?.role || ""}</p>
        )}
      </>
    );
  };

  const jobPostedRenderer = (props: any) => {
    return <Text>{props?.data?.jobPosted?.split("T")?.[0] || ""}</Text>;
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      cellDataType: false,
    };
  }, []);

  const colDefs: ColDef[] = [
    {
      headerName: "",
      checkboxSelection: true,
      width: 80,
      pinned: "left",
      headerCheckboxSelection: true,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "companyName",
      headerName: "Company",
      cellRenderer: companyNameRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "role",
      headerName: "Role",
      cellRenderer: jobRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "jobPosted",
      headerName: "Job Posted",
      cellRenderer: jobPostedRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "location",
      headerName: "Location",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
  ];

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedCompanies(selectedRows);
  }, []);

  const { updateState } = useModalStore((state) => state);

  const handleClearState = () => {
    setSelectedSidebarCompany(null);
    setSearchCompanyText("");
    setSortCompanyBy("asc");
    setSelectedCompanies([]);
    setFilteredData(companiesData);
    setSelectedFilters({ categories: [], jobTitles: [], jobDescriptions: [] });
  };

  const handleCompanyClick = (item: HiringIntentDetailsType) => {
    if (selectedSidebarCompany?.companyName === item.companyName) {
      setSelectedSidebarCompany(null);
      setFilteredData(companiesData);
      return;
    }
    setSelectedSidebarCompany(item);
    const filtered = companiesData?.filter(
      (companyDetails: HiringIntentDetailsType) => {
        return item.companyName === companyDetails.companyName;
      },
    );
    setFilteredData(filtered);
  };

  const toggleSortCompanyList = () => {
    setSortCompanyBy((prevState) => (prevState === "asc" ? "desc" : "asc"));
  };

  const syncJobForSelectedCompanies = async () => {
    const jobCategories = selectedFilters?.categories
      ?.map((category: Option) => category?.value)
      ?.join(",");
    const uniqueCompanies = selectedCompanies?.reduce(
      (
        accumulator: HiringIntentDetailsType[],
        currentValue: HiringIntentDetailsType,
      ) => {
        const existingObject = accumulator.find(
          (obj: HiringIntentDetailsType) =>
            obj.companyName === currentValue.companyName,
        );
        if (!existingObject) {
          accumulator.push(currentValue);
        }

        return accumulator;
      },
      [],
    );
    const selectedCompanyIds = uniqueCompanies?.map(
      (item: HiringIntentDetailsType) => item?._id,
    );
    const res = await accountMonitoringService.getJobOpenings({
      userId: userStore.getState().currentUser._id,
      jobTitles: selectedFilters.jobTitles.map((item: Option) => item.value),
      jobDescriptions: selectedFilters.jobDescriptions.map(
        (item) => item.value,
      ),
      jobCategories,
      companyIds: selectedCompanyIds,
    });

    if (res.success) {
      toast({
        position: "top-right",
        title: "Success",
        description: res.data?.status || "Success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return res.data;
    } else {
      Swal.fire({
        title: "Error!",
        // @ts-ignore
        text: res.message || "Something went wrong",
        icon: "error",
        confirmButtonText: "cancel",
      });
    }
    setLoader(false);
  };

  function handleSorting(payload: any) {
    return payload.sort((a: any, b: any) => {
      if (a?.jobPosted && b?.jobPosted) {
        return moment(b?.jobPosted).diff(moment(a?.jobPosted));
      } else {
        return a;
      }
    });
  }

  const fetchPaginationData = useCallback(async () => {
    setQuickAddModal(false);
    setLoading(true);
    const res = await accountMonitoringService.getAllCompanies();
    if (res?.success) {
      const result = handleSorting(res.data);
      setCompaniesData(result);
      setFilteredData(result);
      setSidebarCompanyList(result);
    }
    setLoading(false);
  }, []);

  const handleHubspotImport = () => {
    updateState({
      isOpenModal: true,
      modalData: {
        modalType: "job-opening",
        metaData: { callback: fetchPaginationData },
      },
    });
  };

  const deleteCompanies = async (payload: { companies: string[] }) => {
    const res = await accountMonitoringService.deleteCompanies(payload);
    if (res?.success) {
      toast({
        title: "Success",
        description: "Companies deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchPaginationData();
    } else {
      toast({
        title: "Error",
        //@ts-ignore
        description: data.message || "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "red",
      cancelButtonColor: "green",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          companies: selectedCompanies?.map((item) => item._id),
        };
        await deleteCompanies(payload);
        handleClearState();
      }
    });
  };

  const handleAdd = async () => {
    setLoader(true);
    const res = await accountMonitoringService.quickAdd(
      quickAddInput,
      "job-opening",
    );
    if (res.success) {
      toast({
        title: "Success",
        description: "Company added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      fetchPaginationData();
    } else {
      toast({
        title: "Error",
        //@ts-ignore
        description: res.message || "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoader(false);
  };

  const handleTrack = async () => {
    setLoader(true);
    await syncJobForSelectedCompanies();
    handleClearState();
    await fetchPaginationData();
    setLoader(false);
    setTrackingModal(false);
  };

  const handleJobOpenings = () => {
    if (selectedCompanies?.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one company",
        icon: "error",
        confirmButtonText: "cancel",
      });
      return;
    }
    setTrackingModal(true);
  };

  const updateSelectedFilters = useCallback((key: string, value: Option[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const convertToCSV = (data: any) => {
    const { _id, user, createdAt, updatedAt, __v, ...rest } = data[0];
    const headers = Object.keys(rest).join(",");
    const rows = data
      .map((obj: any) => {
        const { _id, user, createdAt, updatedAt, __v, ...rest } = obj;
        const modifiedValues = Object.values(rest).map((value) =>
          typeof value === "string" ? value.replace(/,/g, "") : value,
        );
        return modifiedValues.join(",");
      })
      .join("\n");
    return headers + "\n" + rows;
  };

  const handleExport = () => {
    const payload =
      selectedCompanies?.length > 0 ? selectedCompanies : companiesData;
    const csv = convertToCSV(payload);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job-openings.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTableSearch = (query: string) => {
    setTableSearchQuery(query);
    if (query) {
      const queryText = query.toLocaleLowerCase();
      const filtered = companiesData?.filter((item) => {
        return (
          item?.companyName?.toLocaleLowerCase()?.includes(queryText) ||
          item?.role?.toLocaleLowerCase()?.includes(queryText) ||
          item?.location?.toLocaleLowerCase()?.includes(queryText)
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(companiesData);
    }
  };

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () =>
      accountMonitoringService.createTable({
        totalRows: filteredData?.length,
        monitoringType: "trackJobs",
        selectedRowsIds: selectedCompanies?.map((item) => item._id) || [],
      }),
    onSuccess: (response) => {
      if (response.success) {
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.data?.tableData._id,
          },
        });
      } else {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    const filteredCompanyList = companiesList
      ?.filter((company: HiringIntentDetailsType) => {
        if (!searchCompanyText) return true;
        return company?.companyName
          ?.toLowerCase()
          ?.includes(searchCompanyText.toLowerCase());
      })
      ?.sort((a: HiringIntentDetailsType, b: HiringIntentDetailsType) => {
        if (sortCompanyBy === "asc") {
          return a?.companyName.localeCompare(b.companyName);
        } else {
          return b?.companyName.localeCompare(a.companyName);
        }
      })
      ?.filter(Boolean);
    setSidebarCompanyList(filteredCompanyList);
  }, [companiesList, searchCompanyText, sortCompanyBy]);

  useEffect(() => {
    if (companiesData.length) {
      const uniqueCompanies = companiesData.reduce(
        (acc: HiringIntentDetailsType[], current: HiringIntentDetailsType) => {
          const x = acc.find(
            (item: HiringIntentDetailsType) =>
              item?.companyName === current?.companyName,
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        },
        [],
      );
      setCompaniesList(uniqueCompanies);
    }
  }, [companiesData]);

  useEffect(() => {
    fetchPaginationData();
  }, [fetchPaginationData]);

  return (
    <div className="flex w-[100%] gap-3">
      <AccountMonitoringSidebar
        loading={loading}
        searchCompanyText={searchCompanyText}
        selectedCompany={selectedSidebarCompany}
        filteredCompanyList={sidebarCompanyList}
        setSearchCompanyText={setSearchCompanyText}
        toggleSortCompanyList={toggleSortCompanyList}
        handleClearState={handleClearState}
        handleCompanyClick={handleCompanyClick}
      />
      <div className="my-3 flex-1 rounded-2xl bg-white">
        <AccountMonitoringHeader
          title="Job Openings"
          monitoringType="companies"
          deleteText="companies"
          trackBttonText="Track Job Openings"
          loader={loader}
          loading={loading}
          totalItemsCount={filteredData?.length}
          selectedItemsCount={selectedCompanies?.length}
          handleDelete={handleDelete}
          handleTrack={handleJobOpenings}
          setQuickAddModal={setQuickAddModal}
        />
        <AccountMonitoringActions
          tableSearchQuery={tableSearchQuery}
          filteredData={filteredData}
          showFilters={false}
          isPending={isPending}
          handleExport={handleExport}
          handleTableSearch={handleTableSearch}
          handleCreate={mutateAsync}
        />
        <div
          className="ag-theme-quartz mt-5"
          style={{ width: "100%", height: "70vh" }}
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={filteredData}
            defaultColDef={defaultColDef}
            headerHeight={40}
            rowHeight={38}
            rowClass="border-1 border-gray-200"
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressColumnMoveAnimation
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
      <AccountMonitoringImportModal
        loader={loader}
        loading={loading}
        isOpen={quickAddModal}
        quickAddInput={quickAddInput}
        csvType="Company"
        placeholder="Enter Company URL"
        handleHubspotImport={handleHubspotImport}
        handleAdd={handleAdd}
        fetchPaginationData={fetchPaginationData}
        setIsOpen={setQuickAddModal}
        setQuickAddInput={setQuickAddInput}
      />
      <TrackJobOpeningsModal
        isOpen={trackingModal}
        isLoading={loader}
        setIsOpen={setTrackingModal}
        handleTrack={handleTrack}
        selectedFilters={selectedFilters}
        updateSelectedFilters={updateSelectedFilters}
      />
    </div>
  );
};

const WrappedHiringIntent = () => (
  <CustomErrorBoundary>
    <HiringIntent />
  </CustomErrorBoundary>
);

export default WrappedHiringIntent;
