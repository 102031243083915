import { useQuery } from "@tanstack/react-query";
import { FileRoute } from "@tanstack/react-router";
import { Container, Skeleton } from "@chakra-ui/react";

import tableService from "@/services/table.service";
import TableList from "@/pages/TableList";

export const Route = new FileRoute("/table/").createRoute({
  component: TablePage,
});

function TablePage() {
  const { isLoading, data } = useQuery({
    queryKey: ["tables"],
    queryFn: async () => tableService.getTableList(),
    gcTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });

  return (
    <Container maxW="70%" className="mt-10">
      <h1 className="font-bold text-lg mb-4">Your Workflows</h1>
      {isLoading ? (
        <div>
          <Skeleton height="20px" className="mb-2" />
          <Skeleton height="20px" className="mb-2" />
          <Skeleton height="20px" className="mb-2" />
        </div>
      ) : (
        <TableList data={data} />
      )}
    </Container>
  );
}

export default TablePage;
