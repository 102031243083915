import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import LinkedInUrlTab from "./LinkedInUrlTab";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import UserDetailsTab from "./UserDetailsTab";

import { REGEX, workEmailsTutorialLink } from "../../constants";
import { Column } from "@/types/enrichment.types";
import { TSelectedColumnOption } from "@/types/common.types";
import { getInitialColumn, getInitialColumnByColName } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

type TabName = "Find with LinkedIn URL" | "Use Name & Company";
type FilterType = "fullName" | "first&lastName";

const FindWorkEmail = () => {
  const [selectedTab, setSelectedTab] = useState<TabName>(
    "Find with LinkedIn URL",
  );

  const updateState = useEnrichStore((state) => state.updateState);
  const [selectedNameType, setSelectedNameType] =
    useState<FilterType>("fullName");
  const [selectedFullName, setSelectedFullName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedCompanyName, setSelectedCompanyName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedFirstName, setSelectedFirstName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedLastName, setSelectedLastName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedLinkedinUrlForWorkEmail, setSelectedLinkedinUrlForWorkEmail] =
    useState<TSelectedColumnOption | null>(null);

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const allStates = [
    {
      name: "fullName",
      updateState: setSelectedFullName,
    },
    {
      name: "companyName",
      updateState: setSelectedCompanyName,
    },
    {
      name: "firstName",
      updateState: setSelectedFirstName,
    },
    {
      name: "lastName",
      updateState: setSelectedLastName,
    },
    {
      name: "linkedinUrl",
      updateState: setSelectedLinkedinUrlForWorkEmail,
    },
  ];

  const getCompanyDetails = () => {
    let companyDetails = [];
    if (selectedNameType === "fullName") {
      if (selectedFullName?.keyId) {
        companyDetails.push({
          id: selectedFullName?.keyId,
          name: "fullName",
        });
      }
    } else {
      if (selectedFirstName?.keyId) {
        companyDetails.push({
          id: selectedFirstName?.keyId,
          name: "firstName",
        });
      }
      if (selectedLastName?.keyId) {
        companyDetails.push({
          id: selectedLastName?.keyId,
          name: "lastName",
        });
      }
    }
    if (selectedCompanyName?.keyId) {
      companyDetails.push({
        id: selectedCompanyName?.keyId,
        name: "companyName",
      });
    }

    return companyDetails;
  };

  const getColumnIds = () => {
    if (selectedTab === "Find with LinkedIn URL") {
      let columnIds = [
        {
          id: selectedLinkedinUrlForWorkEmail?.keyId,
          name: "linkedinUrl",
        },
      ];
      const companyDetails = getCompanyDetails();
      columnIds = columnIds.concat(companyDetails);

      return columnIds;
    } else {
      const companyDetails = getCompanyDetails();
      return companyDetails;
    }
  };

  const tabs: {
    name: TabName;
    component: () => JSX.Element;
  }[] = [
    {
      name: "Find with LinkedIn URL",
      component: () => (
        <LinkedInUrlTab
          {...{
            selectedLinkedinUrlForWorkEmail,
            setSelectedLinkedinUrlForWorkEmail,
          }}
        />
      ),
    },
    {
      name: "Use Name & Company",
      component: () => (
        <UserDetailsTab
          {...{
            selectedNameType,
            setSelectedNameType,
            selectedFullName,
            setSelectedFullName,
            selectedCompanyName,
            setSelectedCompanyName,
            selectedFirstName,
            setSelectedFirstName,
            selectedLastName,
            setSelectedLastName,
          }}
        />
      ),
    },
  ];

  const getButtonStatus = () => {
    if (selectedTab === "Find with LinkedIn URL") {
      return !selectedLinkedinUrlForWorkEmail?.keyId;
    } else {
      return selectedNameType === "fullName"
        ? !selectedFullName?.keyId || !selectedCompanyName?.keyId
        : !selectedFirstName?.keyId ||
            !selectedLastName?.keyId ||
            !selectedCompanyName?.keyId;
    }
  };

  const updateInitialColumn = (
    value: string | RegExp,
    updateState: React.Dispatch<
      React.SetStateAction<TSelectedColumnOption | null>
    >,
  ) => {
    const initialColumn =
      typeof value === "string"
        ? getInitialColumnByColName(value)
        : getInitialColumn(value);

    if (initialColumn) {
      updateState({
        key: initialColumn.name,
        keyId: initialColumn._id,
        iconType: initialColumn.metaData?.iconType || "url",
      });
    }
  };

  useEffect(() => {
    if (viewMode !== "edit") {
      if (selectedTab === "Find with LinkedIn URL") {
        updateInitialColumn(
          REGEX.LINKEDINPROFILE,
          setSelectedLinkedinUrlForWorkEmail,
        );
      } else {
        if (selectedNameType === "fullName") {
          updateInitialColumn("full name", setSelectedFullName);
        } else {
          updateInitialColumn("fist name", setSelectedFirstName);
          updateInitialColumn("last name", setSelectedLastName);
        }
        updateInitialColumn(REGEX.WEBSITE, setSelectedCompanyName);
      }
    }
  }, [columns, selectedTab, selectedNameType]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;

      if (enrichmentData) {
        setSelectedTab(enrichmentData?.selectedSearchType || "");
        setSelectedNameType(enrichmentData?.selectedNameType || "");
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });

        enrichmentData?.columnId?.map((columnId: any) => {
          const relyOnColumn = columns.find(
            (column) => column._id === columnId.id,
          );
          if (relyOnColumn) {
            const state = allStates.find(
              (state) => state.name === columnId.name,
            );
            if (state) {
              state.updateState({
                key: relyOnColumn.name,
                keyId: relyOnColumn._id,
                iconType: relyOnColumn.metaData?.iconType || "url",
              });
            }
          }
        });
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <div className="overflow-y-auto">
      <div className="p-4">
        <WatchTutorial
          subText="Find work email from profile name and company name"
          link={workEmailsTutorialLink}
        />
        <Tabs>
          <TabList className="border-b-2 border-gray">
            {tabs.map((tab, index) => (
              <Tab
                fontSize={"base"}
                fontWeight={"bold"}
                key={index}
                onClick={() => {
                  setSelectedTab(tab.name);
                }}
                className="flex-1"
              >
                <span
                  className={
                    tab.name === selectedTab ? "text-blue" : "text-dark"
                  }
                >
                  {tab.name}
                </span>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab, index) => {
              const Component = tab.component;
              return <TabPanel key={index}>{<Component />}</TabPanel>;
            })}
          </TabPanels>
        </Tabs>

        {selectedTab === "Find with LinkedIn URL" && (
          <PersanaAccordion
            items={[
              {
                button: (
                  <button className="w-full truncate text-left font-semibold text-[#000000]">
                    Increase match rate
                  </button>
                ),

                panel: (
                  <div className="space-y-3">
                    <p className="font-title text-sm font-semibold tracking-wide">
                      For more accurate results, you can also use name and
                      company name to find work email.
                    </p>
                    <UserDetailsTab
                      {...{
                        selectedNameType,
                        setSelectedNameType,
                        selectedFullName,
                        setSelectedFullName,
                        selectedCompanyName,
                        setSelectedCompanyName,
                        selectedFirstName,
                        setSelectedFirstName,
                        selectedLastName,
                        setSelectedLastName,
                      }}
                    />
                  </div>
                ),
              },
            ]}
          />
        )}
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={getButtonStatus()}
        apiPayload={{
          columnId: getColumnIds() as Column[],
          selectedSearchType: selectedTab,
          selectedNameType: selectedNameType,
        }}
      />
    </div>
  );
};

export default FindWorkEmail;
