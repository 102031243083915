import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import Webhook from "../Webhook";

const LeadsModal = () => {
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);
  console.log("modalData", modalData);

  const handleClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "" },
    });
  };

  return (
    <Modal
      isCentered
      isOpen={true}
      motionPreset="scale"
      size="xl"
      onClose={handleClose}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="rounded-[20px] font-poppins">
        <ModalHeader>
          <p className="text-2xl">
            {modalData.metaData?.modalTitle || "Modal Title"}
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalData.modalType === "webhook" && (
            <Webhook onClose={handleClose} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LeadsModal;
