import MakeAPICall from "@/lib/apiCalls";

class UserService {
  constructor(private apiCaller = MakeAPICall) {}

  async getOtp(userInput: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/enter`,
      payload: { email: userInput },
    });
    const response = await apiCall.post();
    return response;
  }

  async verifyOtp(email: string, value: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/verify/email-otp`,
      payload: { email, otp: value },
    });
    const response = await apiCall.post();
    return response;
  }

  async getUser() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/users/details?fields=creditLeft,creditUsed,profileInfo,useSelfOpenAIKey,planType,Email,token,role`,
    });
    const response = await apiCall.get();
    if (response) {
      //@ts-ignore
      return response?.data;
    } else {
      return {};
    }
  }

  async getUserCreditDetails() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/users/details?fields=creditLeft,creditUsed,planType`,
    });
    const response = await apiCall.get<{
      creditLeft: number;
      creditUsed: number;
      planType: string;
      _id: string;
    }>();
    if (response) {
      //@ts-ignore
      return response;
    } else {
      return {};
    }
  }

  async refreshCredits(email: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/credit/details/${email}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async logout() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/web/logout`,
    });

    const response = await apiCall.get();
    return response;
  }

  async saveSmartleadAPIKey(apiKey: string, userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/saveSmartLeadKey`,
      payload: {
        data: {
          apiKey,
          userId: userId,
        },
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async saveHubspotKey(apiKey: string, userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/saveHubspotKey`,
      payload: {
        data: {
          apiKey,
          userId: userId,
        },
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async saveOpenAIKey(apiKey: string, userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/saveOpenAIKey`,
      payload: {
        data: {
          apiKey,
          userId: userId,
        },
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async connectHubspot(userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/hubspot/code/${userId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async disconnectHubspot(userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/clear-hubspot/${userId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async connectSalesforce(userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/salesforce/code/${userId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async updateQuestUserData(userId: string, token: string, name: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_QUEST_BACKEND_URL}/api/users/${userId}`,
      headers: {
        apikey: import.meta.env.VITE_QUEST_API_KEY,
        apisecret: import.meta.env.VITE_QUEST_API_SECRET,
        token,
        userId,
      },
      payload: { name },
    });
    const response = await apiCall.post();
    return response;
  }

  async updateUserProfileData(url: string, email: string, info: any) {
    const apiCall = new this.apiCaller({
      url,
      payload: { email, info },
    });
    const response = await apiCall.post();
    return response;
  }

  async generateUserCompanyDescription(url: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/scrape`,
      payload: { url },
    });
    const response = await apiCall.post();
    return response;
  }

  async updateProfileInfo({ email, info }: { email: string; info: any }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/updateProfileInfo`,
      payload: { email, info },
    });
    const response = await apiCall.post();
    return response;
  }

  async getuserList({
    limit,
    page,
    search,
  }: {
    limit: number;
    page: number;
    search: string;
  }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin`,
      payload: { limit, page, search },
    });
    const response = await apiCall.post();
    return response;
  }

  async deleteUser(id: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin/${id}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getSubscriptionLink(priceId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/getSubscriptionLink/${priceId}?redirectUrl=${window.location.href}`,
    });
    const response = await apiCall.get<{
      link: string;
    }>();
    return response;
  }

  async userLogin(email: string, token: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/verify-admin-login?userEmail=${email}&token=${token}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getWhitelistedUsers() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/whitelisted`,
    });
    const response = await apiCall.get<{
      success: boolean;
      message: string;
    }>();
    return response;
  }

  async checkIsWhitelisted() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/is-whitelisted`,
    });
    const response = await apiCall.get();
    return response;
  };  

  async updateWhitelistedUsers(email: string, action: "create" | "delete") {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/whitelisted/create-or-delete`,
      payload: { email, action },
    });
    const response = await apiCall.post();
    return response;
  }

  async getUserDetailsForAdmins(email: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin/get-user-details`,
      payload: { email },
    });
    const response = await apiCall.post();
    return response;
  }
  async updateUserCredits(email: string, credits: number) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin/update-credits`,
      payload: { email, credits },
    });
    const response = await apiCall.put();
    return response;
  }
}

const userService = new UserService();
export default userService;
