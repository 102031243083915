import AllEnrichments from "./AllEnrichments";
import Search from "./Search";
import TopEnrichments from "./TopEnrichments";

const MainContent = () => {
  return (
    <div className="flex w-[75%] px-[40px] py-[30px] pb-4 min-h-[636px]">
      <div className="w-full">
        <Search />
        <TopEnrichments />
        <AllEnrichments />
      </div>
    </div>
  );
};

export default MainContent;
