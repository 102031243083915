import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import Swal from "sweetalert2";
import moment from "moment";
import dayjs from "dayjs";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import AccountMonitoringSidebar from "../Common/AccountMonitoring/AccountMonitoringSidebar";
import AccountMonitoringActions from "../Common/AccountMonitoring/AccountMonitoringActions";
import AccountMonitoringHeader from "../Common/AccountMonitoring/AccountMonitoringHeader";
import AccountMonitoringImportModal from "../Common/AccountMonitoring/AccountMonitoringImportModal";
import {
  UserDetailsRenderer,
  companyDetailsRenderer,
  dateRenderer,
  roleDetailsRenderer,
} from "./Renderers";
import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";

import accountMonitoringService from "@/services/accountMonitoring.service";
import { userStore } from "@/stores/user.store";
import { useModalStore } from "@/stores/modal.store";
import { JobChangesType } from "@/types/accountMonitoring.types";
import { isNameChanged } from "@/utils";

const JobChanges = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const gridRef = useRef<AgGridReact>(null);
  const { currentUser } = userStore();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderCount, setLoaderCount] = useState(0);
  const [shouldClearRows, setShouldClearRows] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedLeadCountNum, setSelectedLeadCountNum] = useState(0);
  const [searchCompanyText, setSearchCompanyText] = useState("");
  const [tableSearchQuery, setTableSearchQuery] = useState("");
  const [sortCompanyBy, setSortCompanyBy] = useState("asc");
  const [sortRoleStartBy] = useState("desc");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [quickAddModal, setQuickAddModal] = useState(false);
  const [quickAddInput, setQuickAddInput] = useState("");
  const cancelledRef = useRef(false);
  const [leadsData, setLeadsData] = useState<JobChangesType[]>([]);
  const [filteredData, setFilteredData] = useState<JobChangesType[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<JobChangesType[]>(
    [],
  );
  const [companiesList, setCompanies] = useState<JobChangesType[]>([]);
  const [filteredCompanyList, setFilteredCompanyList] = useState<
    JobChangesType[]
  >([]);
  const [selectedCompany, setSelectedCompany] =
    useState<JobChangesType | null>();
  const { updateState } = useModalStore((state) => state);

  const actionLabelMap = {
    changed_company: "New Company",
    change_title: "New Title",
    additional_job: "Additional Job",
    no_change: "No Change",
  } as any;

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      cellDataType: false,
    };
  }, []);

  const colDefs: ColDef[] = [
    {
      headerName: "",
      checkboxSelection: true,
      width: 80,
      pinned: "left",
      headerCheckboxSelection: true,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "firstName",
      headerName: "Name / Job Title",
      cellRenderer: UserDetailsRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
      width: 300,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "hubspotCompanyName",
      headerName: "Hubspot Company Name",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "formattedDate",
      headerName: "Role Started",
      cellRenderer: roleDetailsRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "leftAt",
      headerName: "Worked At",
      cellRenderer: companyDetailsRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "dateOfUpdate",
      headerName: "Date of Persana Update",
      cellRenderer: dateRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "previousFirstName",
      headerName: "Previous First Name",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "previousLastName",
      headerName: "Previous Last Name",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "hubspotId",
      headerName: "Hubspot Id",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "confidence",
      headerName: "Confidence",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
  ];

  const filterTabs = [
    {
      label: "All Leads",
      value: "",
    },
    {
      label: "Recently Hired",
      value: "changed_company",
    },
    {
      label: "Changed title / Promotion",
      value: "change_title",
    },
    {
      label: "No Change",
      value: "no_change",
    },
  ];

  const handleClearState = () => {
    setSelectedCompanies([]);
    setSelectedCompany(null);
    setSearchCompanyText("");
    setSortCompanyBy("asc");
    setShouldClearRows(true);
    setLoader(false);
    setLoading(false);
    setFilteredData(leadsData);
    setTableSearchQuery("");
  };

  const syncJobForSelectedLeads = async (
    selectedCompanies: JobChangesType[],
  ) => {
    try {
      if (selectedCompanies?.length === 0) return;

      const leadPayloads = selectedCompanies.map((lead: JobChangesType) => ({
        firstName: lead.firstName || "",
        lastName: lead.lastName || "",
        linkedinURL: lead.linkedinURL,
        companyToTrack: lead.originalCompanyToTrack,
        userId: currentUser._id,
        leadEntryId: lead._id,
      }));

      for (const leadPayload of leadPayloads) {
        if (cancelledRef.current) {
          break;
        }

        const response =
          await accountMonitoringService.getJobChanges(leadPayload);

        if (response?.success) {
          setLeadsData((prevState) =>
            prevState.map((lead) => {
              if (lead._id === response?.data?._id) {
                return {
                  ...lead,
                  _id: response?.data?._id,
                  firstName: response?.data?.firstName || "",
                  lastName: response?.data?.lastName || "",
                  previousFirstName: response?.data?.previousFirstName || "",
                  previousLastName: response?.data?.previousLastName || "",
                  priviousCompanyName:
                    response?.data?.originalCompanyToTrack || "",
                  profilePicture:
                    response?.data?.metaData?.profilePicture || "",
                  linkedinURL: response?.data?.linkedinURL || "",
                  currentPosition: response?.data?.current?.position || "",
                  companyName:
                    response?.data?.current?.companyName ||
                    response?.data?.originalCompanyToTrack ||
                    "",
                  logo: response?.data?.current?.logo || "",
                  action: response?.data?.action || "",
                  actionText: response?.data?.action
                    ? actionLabelMap[response?.data?.action] || ""
                    : "",
                  joinedDate: moment(
                    response?.data?.current?.joinedAt,
                    "D/M/YYYY",
                  ),
                  formattedDate: moment(
                    response?.data?.current?.joinedAt,
                    "D/M/YYYY",
                  ).format("MMM YYYY"),
                  previousCompany: response?.data?.previous?.companyName || "",
                  leftAt:
                    !response?.data?.previous?.leftAt?.includes("null") ||
                    !response?.data?.previous?.leftAt?.includes("undefined")
                      ? moment(
                          response?.data?.previous?.leftAt,
                          "D/M/YYYY",
                        ).format("MMM YYYY")
                      : "",
                  dateOfUpdate: dayjs(response?.data?.lastScrapedAt).format(
                    "DD MMM YY hh:mm A",
                  ),
                  origin: response?.data?.origin || "",
                  confidence: isNameChanged({
                    firstName: response.data?.firstName || "",
                    lastName: response.data?.lastName || "",
                    previousFirstName: response.data?.previousFirstName || "",
                    previousLastName: response.data?.previousLastName || "",
                  }),
                };
              }
              return lead;
            }),
          );
        }
        setSelectedLeadCountNum((prevState) => prevState + 1);
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      return false;
    } catch (error) {
      Swal.fire({
        title: "Error!",
        //@ts-ignore
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "cancel",
      });
    } finally {
      setLoader(false);
      cancelledRef.current = false;
    }
  };

  const handleCompanyClick = (item: JobChangesType) => {
    if (selectedCompany?.companyName === item.companyName) {
      setSelectedCompany(null);
      setFilteredData(leadsData);
      return;
    }
    const filtered = leadsData?.filter(
      (lead) => item?.companyName === lead?.companyName,
    );
    setFilteredData(filtered);
    setSelectedCompany(item);
  };

  const toggleSortCompanyList = () => {
    setSortCompanyBy((prevState) => (prevState === "asc" ? "desc" : "asc"));
  };

  function handleleadsPagination(payload: any) {
    return payload.sort((a: any, b: any) => {
      return moment(b?.current?.joinedAt, "D/M/YYYY").diff(
        moment(a?.current?.joinedAt, "D/M/YYYY"),
      );
    });
  }

  const formatResult = (payload: any) => {
    return payload?.map((item: any) => {
      const modifiedPayload = {
        _id: item?._id,
        firstName: item?.firstName || "",
        lastName: item?.lastName || "",
        previousFirstName: item?.previousFirstName || "",
        previousLastName: item?.previousLastName || "",
        profilePicture: item?.metaData?.profilePicture || "",
        linkedinURL: item?.linkedinURL || "",
        currentPosition: item?.current?.position || "",
        companyName:
          item?.current?.companyName || item?.originalCompanyToTrack || "",
        logo: item?.current?.logo || "",
        action: item?.action || "",
        actionText: item?.action ? actionLabelMap[item.action] || "" : "",
        joinedDate: moment(item?.current?.joinedAt, "D/M/YYYY"),
        formattedDate: moment(item?.current?.joinedAt, "D/M/YYYY").format(
          "MMM YYYY",
        ),
        previousCompany: item?.previous?.companyName || "",
        originalCompanyToTrack: item?.originalCompanyToTrack || "",
        leftAt:
          !item?.previous?.leftAt?.includes("null") ||
          !item?.previous?.leftAt?.includes("undefined")
            ? moment(item?.previous?.leftAt, "D/M/YYYY").format("MMM YYYY")
            : "",
        dateOfUpdate: dayjs(item?.lastScrapedAt).format("DD MMM YY hh:mm A"),
        origin: item?.origin || "",
        hubspotId: item?.hubspotId || "",
        hubspotCompanyName: item?.originalCompanyToTrack || "",
        hubspotFirstName: item?.updatedFirstName || "",
        hubspotLastName: item?.updatedLastName || "",
        confidence: isNameChanged({
          firstName: item?.firstName || "",
          lastName: item?.lastName || "",
          previousFirstName: item?.previousFirstName || "",
          previousLastName: item?.previousLastName || "",
        }),
      };

      return modifiedPayload;
    });
  };

  const fetchPaginationData = useCallback(async () => {
    setQuickAddModal(false);
    setLoading(true);
    const res = await accountMonitoringService.getAllLeads();
    if (res?.success) {
      let result = handleleadsPagination(res?.data);
      const formattedResult = formatResult(result);
      setLeadsData(formattedResult);
      setFilteredCompanyList(formattedResult);
    }
    setLoading(false);
  }, []);

  const handleAdd = async () => {
    setLoader(true);
    const res = await accountMonitoringService.quickAdd(
      quickAddInput,
      "job-tracking",
    );
    if (res.success) {
      toast({
        title: "Success",
        description: "Company added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      await fetchPaginationData();
    } else {
      toast({
        title: "Error",
        //@ts-ignore
        description: res.message || "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoader(false);
  };

  const deleteLeads = async (payload: { leads: string[] }) => {
    const res = await accountMonitoringService.deleteLeads(payload);
    if (res?.success) {
      toast({
        title: "Success",
        description: "Leads deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await fetchPaginationData();
      return;
    } else {
      toast({
        title: "Error",
        //@ts-ignore
        description: data.message || "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "red",
      cancelButtonColor: "green",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const payload = {
          leads: selectedCompanies.map((lead: JobChangesType) => lead._id),
        };
        await deleteLeads(payload);
        handleClearState();
      }
    });
  };

  const handleHubspotImport = () => {
    updateState({
      isOpenModal: true,
      modalData: {
        modalType: "job-tracking",
        metaData: { callback: fetchPaginationData },
      },
    });
  };

  const handleJobTracking = async () => {
    if (selectedCompanies.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one lead",
        icon: "error",
        confirmButtonText: "cancel",
      });
      return;
    }
    setShouldClearRows(!shouldClearRows);
    setLoaderCount(selectedCompanies.length || 1);
    setLoader(true);
    setSelectedLeadCountNum(0);
    await syncJobForSelectedLeads(selectedCompanies);
    setLoader(false);
  };

  const handleRefreshLeads = async () => {
    if (selectedCompanies?.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one lead",
        icon: "error",
        confirmButtonText: "cancel",
      });
      return;
    }
    if (!selectedCompanies?.length || isRefreshing) return;
    cancelledRef.current = false;
    setIsRefreshing(true);
    await syncJobForSelectedLeads(selectedCompanies);
    setIsRefreshing(false);
  };

  const handleFilterChange = (action: string) => {
    const filtered = leadsData
      ?.filter((item) => {
        return action === "" || item.action === action;
      })
      ?.filter((item) => {
        if (!selectedCompany) return true;
        return item?.companyName === selectedCompany.companyName;
      })
      ?.sort((a, b) => {
        if (sortRoleStartBy === "asc") {
          return moment(a?.joinedDate, "D/M/YYYY").diff(
            moment(b?.joinedDate, "D/M/YYYY"),
          );
        } else {
          return moment(b?.joinedDate, "D/M/YYYY").diff(
            moment(a?.joinedDate, "D/M/YYYY"),
          );
        }
      });
    setFilteredData(filtered);
    setFilter((prevState) => (prevState === action ? "" : action));
  };

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedCompanies(selectedRows);
  }, []);

  const convertToCSV = (data: JobChangesType[]) => {
    const { _id, ...rest } = data[0];
    const headers = Object.keys(rest).join(",");
    const rows = data
      .map((obj: any) => {
        const { _id, ...rest } = obj;
        const modifiedValues = Object.values(rest).map((value) =>
          typeof value === "string" ? value.replace(/,/g, "") : value,
        );
        return modifiedValues.join(",");
      })
      .join("\n");
    return headers + "\n" + rows;
  };

  const handleExport = () => {
    const payload =
      selectedCompanies?.length > 0 ? selectedCompanies : leadsData;
    const csv = convertToCSV(payload);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job-changes.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTableSearch = (query: string) => {
    setTableSearchQuery(query);
    const filtered = leadsData?.filter((item: JobChangesType) => {
      return filter === "" || item.action === filter;
    });
    if (query) {
      const queryText = query.toLocaleLowerCase();
      const filteredResult = filtered?.filter((item) => {
        return (
          item?.firstName?.toLocaleLowerCase()?.includes(queryText) ||
          item?.lastName?.toLocaleLowerCase()?.includes(queryText) ||
          item?.currentPosition?.toLocaleLowerCase()?.includes(queryText) ||
          item?.companyName?.toLocaleLowerCase()?.includes(queryText) ||
          item?.previousCompany?.toLocaleLowerCase()?.includes(queryText)
        );
      });
      setFilteredData(filteredResult);
    } else {
      setFilteredData(filtered);
    }
  };

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () =>
      accountMonitoringService.createTable({
        totalRows: filteredData?.length,
        monitoringType: "jobChange",
        selectedRowsIds: selectedCompanies?.map((item) => item._id) || [],
      }),
    onSuccess: (response) => {
      if (response.success) {
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.data?.tableData._id,
          },
        });
      } else {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (leadsData.length) {
      const companies = leadsData
        ?.filter((lead) =>
          ["changed_company", "change_title"].includes(lead.action),
        )
        .map((lead) => lead);
      const uniqueCompanies = companies.reduce(
        (acc: JobChangesType[], current) => {
          const x = acc.find(
            (item: JobChangesType) =>
              item?.companyName === current?.companyName,
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        },
        [],
      );

      const filtered = leadsData
        ?.filter((item: JobChangesType) => {
          return filter === "" || item.action === filter;
        })
        ?.filter((item: JobChangesType) => {
          if (!selectedCompany) return true;
          return item.companyName === selectedCompany.companyName;
        })
        ?.sort((a: JobChangesType, b: JobChangesType) => {
          if (sortRoleStartBy === "asc") {
            return moment(a?.joinedDate, "D/M/YYYY").diff(
              moment(b?.joinedDate, "D/M/YYYY"),
            );
          } else {
            return moment(b?.joinedDate, "D/M/YYYY").diff(
              moment(a?.joinedDate, "D/M/YYYY"),
            );
          }
        });
      setFilteredData(filtered);
      setCompanies(uniqueCompanies);
    } else {
      setFilteredData([]);
      setCompanies([]);
    }
  }, [leadsData]);

  useEffect(() => {
    const filtered = companiesList
      ?.filter((company: JobChangesType) => {
        if (!searchCompanyText) return true;
        return company?.companyName
          ?.toLowerCase()
          ?.includes(searchCompanyText.toLowerCase());
      })
      ?.sort((a: JobChangesType, b: JobChangesType) => {
        if (sortCompanyBy === "asc") {
          return a?.companyName.localeCompare(b.companyName);
        } else {
          return b?.companyName.localeCompare(a.companyName);
        }
      })
      ?.filter(Boolean);
    setFilteredCompanyList(filtered);
  }, [companiesList, searchCompanyText, sortCompanyBy]);

  useEffect(() => {
    setSelectedCompanies([]);
  }, [shouldClearRows]);

  useEffect(() => {
    fetchPaginationData();
  }, [fetchPaginationData]);

  return (
    <div className="flex w-[100%] gap-3">
      <AccountMonitoringSidebar
        loading={loading}
        searchCompanyText={searchCompanyText}
        selectedCompany={selectedCompany}
        filteredCompanyList={filteredCompanyList}
        setSearchCompanyText={setSearchCompanyText}
        toggleSortCompanyList={toggleSortCompanyList}
        handleClearState={handleClearState}
        handleCompanyClick={handleCompanyClick}
      />
      <div className="my-3 flex-1 rounded-2xl bg-white">
        <AccountMonitoringHeader
          title="Lead List"
          trackBttonText="Track Job Change"
          deleteText="leads"
          monitoringType="leads"
          loader={loader}
          loading={loading}
          loaderCount={loaderCount}
          selectedLeadCountNum={selectedLeadCountNum}
          totalItemsCount={filteredData?.length}
          selectedItemsCount={selectedCompanies?.length}
          handleDelete={handleDelete}
          handleTrack={handleJobTracking}
          setQuickAddModal={setQuickAddModal}
        />
        <div className="flex items-center justify-between rounded-2xl bg-white pt-0">
          {!!leadsData?.length && (
            <div className="flex items-center gap-3">
              <div
                className="cursor-pointer rounded-md bg-primary-gradient p-3 px-4 py-2 text-base font-normal text-white hover:shadow-md"
                onClick={handleRefreshLeads}
              >
                {isRefreshing ? "Refreshing..." : "Refresh"}
              </div>
              {!cancelledRef.current && isRefreshing && (
                <div
                  className="cursor-pointer rounded-md bg-red-500 p-3 px-4 py-2 text-base font-normal text-white hover:shadow-md"
                  onClick={() => {
                    cancelledRef.current = true;
                    setIsRefreshing(false);
                  }}
                >
                  Cancel
                </div>
              )}
            </div>
          )}
          <AccountMonitoringActions
            tableSearchQuery={tableSearchQuery}
            filteredData={filteredData}
            showFilters={true}
            filterTabs={filterTabs}
            isPending={isPending}
            filter={filter}
            handleFilterChange={handleFilterChange}
            handleExport={handleExport}
            handleTableSearch={handleTableSearch}
            handleCreate={mutateAsync}
          />
        </div>
        <div
          className="ag-theme-quartz mt-5"
          style={{ width: "100%", height: "70vh" }}
        >
          <AgGridReact
            rowHeight={92}
            ref={gridRef}
            columnDefs={colDefs}
            rowData={filteredData}
            defaultColDef={defaultColDef}
            headerHeight={40}
            rowClass="border-1 border-gray-200"
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressColumnMoveAnimation
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
      <AccountMonitoringImportModal
        loader={loader}
        loading={loading}
        isOpen={quickAddModal}
        quickAddInput={quickAddInput}
        csvType="Lead"
        placeholder="Enter Linkedin URL"
        handleHubspotImport={handleHubspotImport}
        handleAdd={handleAdd}
        fetchPaginationData={fetchPaginationData}
        setIsOpen={setQuickAddModal}
        setQuickAddInput={setQuickAddInput}
      />
    </div>
  );
};

const WrappedJobChanges = () => (
  <CustomErrorBoundary>
    <JobChanges />
  </CustomErrorBoundary>
);

export default WrappedJobChanges;
