import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

type State = {
  isProviderApiKeyModalOpen: boolean;
  providerApiKeyModalData: {
    title: string;
    providerName:
      | "openAiApiKey"
      | "github"
      | "smartLeadsApiKey"
      | "apifyApiKey"
      | null;
  };
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export const useProviderStore = create<State & Actions>((set) => ({
  isProviderApiKeyModalOpen: false,
  providerApiKeyModalData: {
    // Fix: Changed property name from 'providerKeyModalData' to 'providerApiKeyModalData'
    title: "",
    providerName: null,
  },

  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Providers", useProviderStore);
}
