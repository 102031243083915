import MakeAPICall from "@/lib/apiCalls";

class AccountMonitoringService {
  constructor(private apiCaller = MakeAPICall) {}

  async quickAdd(url: string, useCase: "job-tracking" | "job-opening") {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/quickAdd`,
      payload: {
        url,
        useCase,
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async getAllCompanies() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/companyEntries`,
    });
    const response = await apiCall.get();
    const formattedRes = Object.values(response)?.map((item) => item);
    return { success: true, data: formattedRes };
  }

  async deleteCompanies(payload: { companies: string[] }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/companies`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async uploadCompanies(formData: FormData) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/hiring/importCsv`,
      payload: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async getJobOpenings(payload: any) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/jobOpenings`,
      payload,
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async getAllLeads() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/leadEntries`,
    });
    const response = await apiCall.post();
    return response;
  }

  async deleteLeads(payload: { leads: string[] }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/leads`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async uploadLeads(payload: FormData) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/importCsv`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async getJobChanges(payload: any) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/jobChanges`,
      payload,
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async importDataFromHubspot(apiPath: string, numberOfLeads: number) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/${apiPath}`,
      payload: {
        numberOfLeads,
      },
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async getAllActiveJobs() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/activeJobs`,
    });
    const response = await apiCall.get();
    return response;
  }

  async deleteActiveJobs(payload: { jobs: string[] }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/deleteActiveJobs`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async trackActiveJobs(payload: { query: string; location: string }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/trackActiveJobs`,
      payload,
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async createTable(payload: {
    totalRows: number;
    monitoringType: string;
    tableName?: string;
    selectedRowsIds?: string[];
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/add-into-table",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }
}

const accountMonitoringService = new AccountMonitoringService();
export default accountMonitoringService;
