import MakeAPICall from "@/lib/apiCalls";

import type {
  CompanyJobOpeningPayload,
  EnrichmentApiCallPayload,
} from "@/types/enrichment.types";

class Enrichment {
  constructor(private apiCaller = MakeAPICall) {}

  async companyJobOpening(payload: CompanyJobOpeningPayload) {
    const apiCall = new this.apiCaller({
      apiPath: "enrichment/company-job-openings",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async callEnrichmentApi(payload: Partial<EnrichmentApiCallPayload>) {
    const apiCall = new this.apiCaller({
      apiPath: `enrichment/${payload.enrichmentName}`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async googleSrp(query: string, numberOfRecords: number) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/google-srp`,
      payload: { query, resultNum: numberOfRecords },
    });
    const response = await apiCall.post();
    return response;
  }

  async repoSearch(repoUrl: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/github/repo-search`,
      payload: { repoUrl },
    });
    const response = await apiCall.post();
    return response;
  }

  async githubStargazers(repoUrl: string, totalNumOfStarGazers: number) {
    const apiCall = new this.apiCaller({
      apiPath: "leads/github-star-gazer",
      payload: { repoUrl, totalNumOfStarGazers },
    });
    const response = await apiCall.post();
    return response;
  }

  async callCronJobAPI() {
    const apiCall = new this.apiCaller({
      apiPath: "crons/pending-queues",
    });
    const response = await apiCall.get();
    return response;
  }
}

const enrichment = new Enrichment();

export default enrichment;
