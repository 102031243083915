import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

import type { PresetEnrichmentType } from "@/types/presets.types";

type State = {
  isOpenPresetsModal: boolean;
  presetsModalData: {
    modalType?: PresetEnrichmentType;
    metaData?: any;
  };
  selectedPresetValues?: any;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export const usePresetsStore = create<State & Actions>((set) => ({
  isOpenPresetsModal: false,
  presetsModalData: {},
  selectedPresetValues: null,
  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Presets", usePresetsStore);
}
