import { Link } from "@chakra-ui/react";
import Icons from "../Icons";

export const UserDetailsRenderer = (props: any) => {
  return (
    <div className="flex gap-4">
      <div className="flex items-center justify-center">
        <div className="w-[35px]">
          {props?.data?.profilePicture ? (
            <img
              src={props?.data?.profilePicture}
              className="w-[35px] h-[35px] rounded-full"
            />
          ) : null}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          {props?.data?.firstName ? (
            <span>{`${props?.data?.firstName || ""} ${props?.data?.lastName || ""}`}</span>
          ) : (
            <span>{`${props?.data?.linkedinURL}`} </span>
          )}
          {props?.data?.linkedinURL && (
            <Link href={props?.data?.linkedinURL} target="_blank">
              <Icons.LinkedInIcon className="text-lg text-[#0077B5]" />
            </Link>
          )}
        </div>
        {props?.data?.currentPosition && (
          <span className="text-[14px] text-darkTextGray !leading-6">
            {props?.data?.currentPosition}
          </span>
        )}
        {props?.data?.companyName && (
          <span className="text-[14px] text-darkGrey !leading-6">
            @{props?.data?.companyName}
          </span>
        )}
      </div>
    </div>
  );
};

export const companyDetailsRenderer = (props: any) => {
  return (
    <>
      {props?.data?.origin === "hubspot" &&
      props?.data?.action === "no_change" ? null : (
        <div className="flex flex-col mt-4">
          {props?.data?.previousCompany && (
            <span className="text-[14px] text-darkGrey !leading-6">
              {props?.data?.previousCompany || ""}
            </span>
          )}
          {props?.data?.leftAt && props?.data?.leftAt !== "Invalid date" && (
            <span className="text-[14px] text-darkTextGray !leading-6">
              {`Until ${props?.data?.leftAt}`}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export const roleDetailsRenderer = (props: any) => {
  return (
    <div className="flex flex-col mt-4">
      {props?.data?.formattedDate &&
        props?.data?.formattedDate !== "Invalid date" && (
          <span className="text-[14px] text-darkGrey !leading-6">
            {props?.data?.formattedDate}
          </span>
        )}
      <p
        className="overflow-hidden text-ellipsis bg-clip-text text-xs text-lightGray"
        style={
          props?.data?.actionText.includes("New")
            ? {
                background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
              }
            : {}
        }
      >
        {props?.data?.actionText}
      </p>
    </div>
  );
};

export const dateRenderer = (props: any) => {
  return (
    <div className="flex flex-col mt-4">
      {props?.data?.dateOfUpdate && props?.data?.action && (
        <span className="text-[14px] text-darkGrey !leading-6">
          {props?.data?.dateOfUpdate}
        </span>
      )}
    </div>
  );
};
