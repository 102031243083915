import React, { useState } from "react";
import NormalResponseButton from "./NormalResponseButton";
import ObjectResponseButton from "./ObjectResponseButton";

interface AnyObject {
  [key: string]: any;
}

export interface ResponseDropdownPropsTypes {
  path?: string;
  itemKey?: string;
  value?: any;
  isNested?: boolean;
  responseObj?: AnyObject;
  isExpanded?: { [key: string]: boolean };
  toggleExpand?: (
    event: React.MouseEvent<HTMLButtonElement>,
    key: string,
  ) => void;
  handleCopyToClipboard: (value: string) => void;
  handleAddNewColumn: (value: {}) => void;
}

function ResponseDropdown({
  responseObj,
  isNested = false,
  handleCopyToClipboard,
  path = "",
  handleAddNewColumn,
}: ResponseDropdownPropsTypes) {
  const [isExpanded, setIsExpanded] = useState<{ [key: string]: boolean }>({});

  const toggleExpand = (
    event: React.MouseEvent<HTMLButtonElement>,
    key: string,
  ) => {
    event.stopPropagation();
    setIsExpanded((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  return (
    <ul className="list-none" style={isNested ? { paddingLeft: "20px" } : {}}>
      {Object.entries(responseObj || {}).map(([key, value], i) => {
        // const newPath = Array.isArray(object) ? `${path}[${key}]` : `${path}[${key}]`;
        const newPath = Array.isArray(responseObj)
          ? `${path}[${key}]`
          : `${path}['${key}']`;
        return (
          <li
            key={i}
            style={{ margin: "5px 0" }}
            className="font-title text-sm"
          >
            {typeof value === "object" && value !== null ? (
              <ObjectResponseButton
                toggleExpand={toggleExpand}
                isExpanded={isExpanded}
                itemKey={key}
                value={value}
              />
            ) : (
              <NormalResponseButton
                itemKey={key}
                value={value}
                handleCopyToClipboard={handleCopyToClipboard}
                path={newPath}
                handleAddNewColumn={handleAddNewColumn}
              />
            )}
            {typeof value === "object" && value !== null ? (
              isExpanded[key] ? (
                <ResponseDropdown
                  responseObj={value}
                  isNested={true}
                  handleCopyToClipboard={handleCopyToClipboard}
                  path={newPath}
                  handleAddNewColumn={handleAddNewColumn}
                />
              ) : null
            ) : null}
          </li>
        );
      })}
    </ul>
  );
}

export default ResponseDropdown;
