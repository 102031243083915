import Swal from "sweetalert2";
import { Button } from "@chakra-ui/react";

import OutreachLogo from "@/assets/images/outreach-icon.png";
import { userStore } from "@/stores/user.store";

const OutreachIntegration = () => {
  const currentUser = userStore((state) => state.currentUser);
  const isOutreactConnected = !!currentUser?.providers?.outreact?.data;

  const handleIntegration = () => {
    Swal.fire({
      title: "Warning!",
      icon: "warning",
      html:
        "Please contact " +
        '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
        "to upgrade your plan",
      focusConfirm: true,
    });
  };

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={OutreachLogo} alt={"Outreact"} className="w-[2rem]" />
        <h2 className="font-semibold">Outreach</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Outreach Account`}</p>
        <Button
          colorScheme={isOutreactConnected ? "red" : "green"}
          variant="outline"
          size="sm"
          //   isLoading={isPending}
          onClick={handleIntegration}
        >
          {isOutreactConnected ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default OutreachIntegration;
