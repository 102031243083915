class questConfig {
  static API_KEY = import.meta.env.VITE_QUEST_API_KEY;
  static API_SECRET = import.meta.env.VITE_QUEST_API_SECRET;
  static ENTITY_ID = import.meta.env.VITE_QUEST_ENTITY_ID;
  static ONBOARDING_QUIZ_ID = import.meta.env.VITE_QUEST_ONBOARDING_QUIZ_ID;
  static ADMIN_ID = import.meta.env.VITE_QUEST_ADMIN_ID;
  static ADMIN_TOKEN = import.meta.env.VITE_QUEST_ADMIN_TOKEN;
  static ONBOARDING_QUIZ_HEADERS = [
    { name: "Tell us more about you and your company", desc: "" },
    {
      name: "A little more details will help us to guide you the best",
      desc: "",
    },
  ];
  static ONBOARDING_QUIZ_FIELD_GROUPING = [
    [1, 2, 3],
    [4, 5, 6],
  ];
  static ONBOARDING_QUIZ_PROGRESSBAR_HEADERS = ["About You", "Details"];
  static QUEST_BACKEND_URL = import.meta.env.VITE_QUEST_BACKEND_URL;

  static ONBOARDING_QUIZ_FIELD_TYPE = {
    "ec-ec40722b-9e49-4bae-be49-3db462fc9b9d": "textArea",
  };
  static ONBOARDING_QUIZ_FIELDS = {
    FIRST_NAME_FIELD: {
      quest_id: "ec-06745e54-54ba-4ef1-bdf0-302e4d87bce2",
      persana_backend_id: "firstName",
    },
    JOB_TITLE_FIELD: {
      quest_id: "ec-42e88d70-d9e8-40f3-82e8-ca18d4e6d3b3",
      persana_backend_id: "jobTitle",
    },
    COMPANY_URL_FIELD: {
      quest_id: "ec-9bb41a05-8d3b-49bd-ba39-583379b59379",
      persana_backend_id: "companyURL",
    },
    COMPANY_DESCRIPTION_FIELD: {
      quest_id: "ec-cbb7a467-5d5f-49dd-b410-92b1087ea976",
      persana_backend_id: "companyDescription",
    },
    ASSIST_YOU_FIELD: {
      quest_id: "ec-17995fa3-2270-474e-9bc0-62c6c6bd1b43",
      persana_backend_id: "assistYouWith",
    },
    COMPANY_TARGET_FIELD: {
      quest_id: "ec-29f66c77-9760-4c91-a919-fe54dcf4947e",
      persana_backend_id: "companyTarget",
    },
  };

  static FEEDBACK_WORKFLOW_FORM_IDS = [
    "q-general-feedback",
    "q-report-a-bug",
    "q-request-a-feature",
    "q-contact-us",
  ];
  static CONTACT_US_REDIRECT_LINK = "https://calendly.com/sriya-persana/30min";
  static FLAG_CONSTRAINTS = {
    ONBOARDING: "quest.sdk.onboardings",
    FEEDBACK_WORKFLOW: "quest.sdk.feedback_workflow",
  };
  static GET_STARTED_SCREEN_ID = "q-7fbac653-8ef6-4082-a885-2835b6971936";
  static REFERRAL_SCREN_ID = "q-ae7405a4-4243-4499-ae08-47c227da34cc";
}

export default questConfig;
