import {
  ChakraProvider,
  extendTheme,
  theme as baseTheme,
} from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: baseTheme.colors.purple,
  },
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {},
      // 2. We can add a new button size or extend existing
      sizes: {},
      // 3. We can add a new visual variant
      variants: {},
      // 6. We can overwrite defaultProps
      defaultProps: {},
    },
  },
});

const StyleProvider = ({ children }: { children: React.ReactNode }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default StyleProvider;
