export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "LinkedIn Name",
    id: "name",
  },
  {
    isChecked: false,
    label: "LinkedIn Headline",
    id: "headline",
  },
  {
    isChecked: false,
    label: "LinkedIn Profile Photo",
    id: "profilePhoto",
  },
  {
    isChecked: false,
    label: "LinkedIn Location",
    id: "location",
  },
  {
    isChecked: false,
    label: "LinkedIn Summary",
    id: "summary",
  },
  {
    isChecked: false,
    label: "LinkedIn Organization",
    id: "currentCompany",
  },
  {
    isChecked: false,
    label: "LinkedIn Current Company URL",
    id: "currentCompanyUrl",
  },
  {
    isChecked: false,
    label: "LinkedIn Current Title",
    id: "currentTitle",
  },
  {
    isChecked: false,
    label: "LinkedIn School",
    id: "school",
  },
];
