import { useQuery } from "@tanstack/react-query";

import providerService from "@/services/providers.service";

export const useApifyActors = () => {
  return useQuery({
    queryKey: ["apify-actors", { isRefetch: true }],
    queryFn: () => providerService.getApifyActors(),
    refetchOnMount: true,
    gcTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};
