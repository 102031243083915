import { IconButton, Stack } from "@chakra-ui/react";
import React from "react";

import Icons from "@/components/Icons";
import CustomErrorBoundary from "@/components/Common/ErrorComponent/CustomErrorBoundary";
import NewApiKeyModal from "../Common/NewApiKeyModal";

import { useEnrichStore } from "@/stores/enrich.store";
import { filterComponentMaps } from "./mappings";
import { usePresetsStore } from "@/stores/presets.store";
import EnrichmentResponse from "./EnrichmentResponse";
import { useProviderStore } from "@/stores/provider.store";

const FilterModals = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const updatePresetState = usePresetsStore((state) => state.updateState);
  const isProviderApiKeyModalOpen = useProviderStore(
    (state) => state.isProviderApiKeyModalOpen,
  );

  const enrichmentName =
    selectedEnrichments?.enrichmentName as keyof typeof filterComponentMaps;

  if (!enrichmentName) return null;

  const FilterComponent = filterComponentMaps[enrichmentName];

  if (!FilterComponent) return null;

  return (
    <>
      <Stack
        bg="white"
        rounded={"md"}
        m={2}
        width={"32rem"}
        position={"absolute"}
        top={"8rem"}
        right={"2rem"}
        minH={"20rem"}
        maxH={"calc(100vh - 10rem)"}
        className="custom-shadow"
        height={"fit-content"}
        zIndex={10}
      >
        <div className="flex h-full min-h-[20rem] flex-col">
          <div className="flex justify-between border-b border-[#e7e8ec] p-4">
            <div className="flex items-center space-x-4">
              <FilterComponent.Icon />
              <p className="font-semibold">{FilterComponent.title}</p>
            </div>
            <IconButton
              aria-label="Close modal"
              variant={"link"}
              icon={<Icons.CloseIcon size={20} className="text-primary" />}
              onClick={() => {
                useEnrichStore.setState({
                  selectedEnrichments: null,
                });
                updatePresetState({
                  presetsModalData: {},
                  selectedPresetValues: null,
                  isOpenPresetsModal: false,
                });
              }}
            />
          </div>

          {/* Actual Component */}
          {selectedEnrichments?.showResponse ? (
            <EnrichmentResponse />
          ) : (
            <FilterComponent.Component />
          )}
        </div>
      </Stack>

      {isProviderApiKeyModalOpen && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <NewApiKeyModal />
        </React.Suspense>
      )}
    </>
  );
};

const WrappedFilterModals = () => (
  <CustomErrorBoundary>
    <FilterModals />
  </CustomErrorBoundary>
);

export default WrappedFilterModals;
