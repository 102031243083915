import { Box } from "@chakra-ui/react";

import PersanaIcon from "@/assets/images/logo.png";
import SlackIcon from "@/assets/svgs/slack.svg";

const SupportCard = () => {
  return (
    <Box className="flex flex-col items-center justify-center px-[20px] py-[30px] bg-gray rounded-[20px] w-[240px] h-[200px]">
      <div className="flex items-center gap-[10px]">
        <div className="h-[48px] w-[48px] rounded-[16px]  bg-white p-3">
          <img src={PersanaIcon} alt="PersanaIcon" width={24} height={24} />
        </div>
        <div className="h-[48px] w-[48px] rounded-[16px] bg-white p-3">
          <img src={SlackIcon} alt="SlackIcon" width={24} height={24} />
        </div>
      </div>
      <div className="mt-[16px] flex flex-col items-center justify-center gap-[8px]">
        <p className="text-center text-[18px] font-semibold text-black">
          Persana <span className="text-textPurple">Community</span>
        </p>
      </div>

      <div className="mt-[20px]">
        <a
          href="https://join.slack.com/t/persanaaicommunity/shared_invite/zt-2d5ym25sm-NBYfXe_QAbbUAs0tPIxc2w"
          rel="noreferrer"
          target="_blank"
          className="flex h-[40px] w-[140px] cursor-pointer items-center justify-center rounded-[8px] bg-white py-[8px] hover:shadow"
        >
          <span className="text-[16px] font-semibold text-textPurple">
            Join
          </span>
        </a>
      </div>
    </Box>
  );
};

export default SupportCard;
