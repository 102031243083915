import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { OnBoarding, QuestProvider } from "@questlabs/react-sdk";
import { useQueryClient } from "@tanstack/react-query";

import PersanaLogo from "@/assets/images/logo.png";
import questConfig from "@/constants/questConfig";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";
import { useUserData } from "@/hooks/useUserData";

type Props = {
  callFrom: string;
  token: string;
  redirectUrl: string;
  onSubmit: (redirectUrl: string) => void;
};

const OnboardingModal = ({ callFrom, token, redirectUrl, onSubmit }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [uniqueID, setUniqueID] = useState("");
  const userData = useUserData();
  const [answer, setAnswer] = useState({
    [questConfig.ONBOARDING_QUIZ_FIELDS.COMPANY_URL_FIELD.quest_id]: "https://",
  });
  const currentUser = userStore((state) => state.currentUser);
  const cookies = new Cookies();

  // TODO : Uncomment this code when feature flag is enabled
  // const featureFlag = cookies.get("featureFlags");

  // const featureFlagStatus = () => {
  //   return (
  //     !!featureFlag &&
  //     !!featureFlag[questConfig.FLAG_CONSTRAINTS.ONBOARDING] &&
  //     featureFlag[questConfig.FLAG_CONSTRAINTS.ONBOARDING]?.isEnabled === true
  //   );
  // };

  async function updateUserData() {
    const questUserId = cookies.get("questUserId");
    const questUserToken = cookies.get("questUserToken");
    userService.updateQuestUserData(
      questUserId,
      questUserToken,
      answer[questConfig.ONBOARDING_QUIZ_FIELDS.FIRST_NAME_FIELD.quest_id],
    );
  }

  const handleAnswer = async (outputAnswers: any) => {
    const answerData = {} as any;
    for (const ans in outputAnswers) {
      let persanaId;
      const allFields = questConfig.ONBOARDING_QUIZ_FIELDS as any;
      for (const id in allFields) {
        if (allFields[id].quest_id === ans) {
          persanaId = allFields[id].persana_backend_id;
          break;
        }
      }
      answerData[persanaId] = outputAnswers[ans] || "";
    }

    try {
      const response = await userService.updateUserProfileData(
        `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/${
          callFrom === "screen"
            ? `updateProfileInfoByToken/${token}`
            : "updateProfileInfo"
        }`,
        currentUser?.Email,
        answerData,
      );

      if (response?.success) {
        await queryClient.invalidateQueries({
          queryKey: ["user-details"],
        });

        callFrom !== "screen" && navigate({ to: "/" });
        redirectUrl && onSubmit(redirectUrl);
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    }
    updateUserData();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const handleUpdate = async () => {
        if (
          answer[
            questConfig.ONBOARDING_QUIZ_FIELDS.COMPANY_DESCRIPTION_FIELD
              .quest_id
          ] !== ""
        ) {
          return;
        }
        const response = await userService.generateUserCompanyDescription(
          answer[questConfig.ONBOARDING_QUIZ_FIELDS.COMPANY_URL_FIELD.quest_id],
        );
        if (response?.success && response?.data?.updated.description) {
          setAnswer({
            ...answer,
            [questConfig.ONBOARDING_QUIZ_FIELDS.COMPANY_DESCRIPTION_FIELD
              .quest_id]: response?.data?.updated.description,
          });
        }
      };
      if (
        answer[
          questConfig.ONBOARDING_QUIZ_FIELDS.COMPANY_URL_FIELD.quest_id
        ] !== "https://" &&
        answer[
          questConfig.ONBOARDING_QUIZ_FIELDS.COMPANY_URL_FIELD.quest_id
        ] !== ""
      ) {
        handleUpdate();
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [answer]);

  return (
    <>
      <Modal
        isOpen={true}
        onClose={() => {}}
        isCentered
        size={"6xl"}
        scrollBehavior="inside"
      >
        {callFrom !== "screen" && <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />}
        <ModalContent
          className="h-[90vh] p-0"
          display={loading ? "none" : "block"}
        >
          <ModalBody className="flex h-full p-0" p={0}>
            <div className="flex h-full w-1/2 flex-col overflow-x-auto px-10">
              <p className="mx-auto mt-10 font-extrabold text-[#5F6B77]">
                Welcome to Persana
              </p>
              <QuestProvider
                apiKey={questConfig.API_KEY}
                apiSecret={questConfig.API_SECRET}
                entityId={questConfig.ENTITY_ID}
                themeConfig={{
                  fontFamily: "Inter, 'sans-serif'",
                  buttonColor:
                    "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                }}
              >
                <OnBoarding
                  questId={questConfig.ONBOARDING_QUIZ_ID}
                  userId={questConfig.ADMIN_ID}
                  token={questConfig.ADMIN_TOKEN}
                  answer={answer}
                  setAnswer={setAnswer}
                  getAnswers={(ans: any) => handleAnswer(ans)}
                  // btnColor="#0284fe"
                  // inputBorder="1px solid #e5e7eb"
                  // btnSize="200px"
                  // headingSize="24px"
                  headingScreen={questConfig.ONBOARDING_QUIZ_HEADERS}
                  design={questConfig.ONBOARDING_QUIZ_FIELD_GROUPING}
                  progress={questConfig.ONBOARDING_QUIZ_PROGRESSBAR_HEADERS}
                  loadingTracker={true}
                  setLoading={(e: any) => setLoading(e)}
                  nextBtnText={"Get Started"}
                  singleChoose={"modal3"}
                  // questionFontSize={'14px'}
                  // answerFontSize={'14px'}
                  controlBtnType={"Buttons"}
                  uniqueUserId={userData?.data?.Email}
                  uniqueEmailId={userData?.data?.Email}
                  // @ts-ignore
                  showFooter={false}
                  styleConfig={{
                    Heading: {
                      textAlign: "center",
                    },
                  }}
                  template={"multi-question"}
                />
              </QuestProvider>
            </div>
            <div className="flex w-1/2 items-center justify-center bg-[#0C336A] text-white">
              <div className="flex flex-col items-center font-poppins space-y-4">
                <img src={PersanaLogo} className="w-[8rem]" />
                <div className="text-xl sm:text-lg flex flex-col items-center">
                  <p>Get AI superpowers</p>
                  <p>
                    Rated <span className="font-semibold italic">#1</span> AI
                    Prospecting Platform of 2024
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OnboardingModal;
