import Swal from "sweetalert2";
import { Button } from "@chakra-ui/react";

import SalesforceLogo from "@/assets/images/salesforce.png";
import { userStore } from "@/stores/user.store";

const SalesforceIntegration = () => {
  const currentUser = userStore((state) => state.currentUser);
  const isSalesforceConnected = !!currentUser?.providers?.salesforce?.data;

  const handleIntegration = async () => {
    Swal.fire({
      title: "Warning!",
      icon: "warning",
      html:
        "Please contact " +
        '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
        "to upgrade your plan",
      focusConfirm: true,
    });
  };

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={SalesforceLogo} alt={"Salesforce"} className="w-[2rem]" />
        <h2 className="font-semibold">Salesforce</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Salesforce Account`}</p>
        <Button
          colorScheme={isSalesforceConnected ? "red" : "green"}
          variant="outline"
          size="sm"
          // isLoading={isPending}
          onClick={handleIntegration}
        >
          {isSalesforceConnected ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default SalesforceIntegration;
