import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";
import { useProviderStore } from "@/stores/provider.store";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

const NewApiKeyModal = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [apiKey, setApiKey] = useState("");
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: {
      apiKey: string;
      providerId?: string;
      providerName: string;
    }) => providerService.saveKey(payload),
  });

  const { isProviderApiKeyModalOpen, providerApiKeyModalData, updateState } =
    useProviderStore();
  const { data: providerLists } = useProviderList();

  const currentProvider = providerLists?.data?.find(
    (provider) => provider.name === providerApiKeyModalData?.providerName,
  );

  const onSave = async () => {
    await mutateAsync(
      {
        apiKey,
        providerId: currentProvider?._id || "",
        providerName: providerApiKeyModalData?.providerName || "",
      },
      {
        onSuccess: (response) => {
          if (!response.success) {
            toast({
              position: "top-right",
              title: "Error",
              description: response.error.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
          queryClient.refetchQueries({
            queryKey: ["providers"],
            type: "all",
          });

          queryClient.refetchQueries({
            queryKey: ["smartLeadsCampaigns"],
          });

          queryClient.refetchQueries({
            predicate: (query) => {
              console.log("query.queryKey", query.queryKey);

              return (query.queryKey[query.queryKey.length - 1] as any)
                ?.isRefetch;
            },
          });
          onClose();

          toast({
            position: "top-right",
            title: "Success",
            description: response.data?.message || "API key saved successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const onClose = () => {
    updateState({ isProviderApiKeyModalOpen: false });
  };

  return (
    <Modal
      isCentered
      isOpen={isProviderApiKeyModalOpen}
      size={"xl"}
      onClose={onClose}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>{`Add/Change your ${providerApiKeyModalData?.title} API key`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="m-auto flex h-full w-full flex-col justify-center">
            <div className="flex flex-col  justify-center">
              <p className="text-left font-medium">Enter your key</p>
              <Input
                className="border-black openAIKey mt-1 border-2 px-4 py-1"
                placeholder={
                  currentProvider?.metaData?.accessToken || "********"
                }
                onChange={(e) => setApiKey(e.target.value)}
                value={apiKey}
              />
              <Button
                className="integrationBtn mb-4 mt-4 !hover:text-primary"
                isDisabled={!apiKey}
                onClick={onSave}
                isLoading={isPending}
              >
                {currentProvider ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewApiKeyModal;
