import { useState } from "react";

import { ResponseDropdownPropsTypes } from "./ResponseDropdown";
import Icons from "@/components/Icons";

function ObjectResponseButton({
  toggleExpand,
  isExpanded,
  itemKey,
  value,
}: Partial<ResponseDropdownPropsTypes>) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      className="w-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        className={`flex items-center gap-x-1.5 rounded-md border px-4 py-1.5 ${
          isHovered
            ? "border-primary bg-[#8ebff73f]"
            : "border-gray-300 bg-white"
        }`}
        onClick={(event) => {
          if (toggleExpand) {
            toggleExpand(event, itemKey as string);
          }
        }}
      >
        {isExpanded?.[itemKey as string] ? (
          <Icons.ChevronDownIcon />
        ) : (
          <Icons.ChevronRightIcon />
        )}
        <span className="capitalize">
          <span className="font-semibold">{itemKey}</span> (
          {Object.keys(value).length})
        </span>
      </button>
    </div>
  );
}

export default ObjectResponseButton;
