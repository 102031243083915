import { useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import Conversations from "./Conversations";
import PersanaLogo from "@/assets/images/logo.png";
import Icons from "../Icons";

import leadService from "@/services/leads.service";
import { useModalStore } from "@/stores/modal.store";
import {
  PredefinedSuggestionType,
  predefinedSuggestions,
  salesGPTDetails,
} from "./constants";
import { useStarterList } from "@/hooks/useStarterList";

const SalesGPT = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");
  const updateState = useModalStore((state) => state.updateState);

  const {
    mutateAsync: searchPeopleUsingGPTMutateAsync,
    isPending,
    data: answerData,
  } = useMutation({
    mutationFn: (input: string) =>
      leadService.searchPeopleUsingGPT({ prompt: input }),
    onSuccess: (response) => {
      setInputValue("");
      console.log("myLog response", response);

      if (response.type === "AGENT") {
        onOpen();
        return;
      }

      if (!response?.data?.tableId) return;
      navigate({
        to: `/table/$tableId`,
        params: {
          tableId: response?.data?.tableId,
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { data = [] } = useStarterList();

  const premiumStarterListPointer = data.filter((item) => item.isPremium);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    }
  };

  const handleSuggestionClick = (suggestion: PredefinedSuggestionType) => {
    if (suggestion.key === "starterTables") {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "get-started",
          metaData: { scrollToBottom: true },
          starterTableId: suggestion.id,
        },
      });
    } else if (suggestion.modalType) {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: suggestion.modalType,
          metaData: { openPreviousModal: false },
        },
      });
    } else {
      const updatedInputValue = inputValue
        ? `${inputValue} ${suggestion.description}`
        : suggestion.description;
      setInputValue(updatedInputValue!);
      inputRef?.current?.focus();
    }
  };

  return (
    <>
      <Box className="shadow-lg py-3 px-5 rounded-md border border-textPurple relative">
        <div className="absolute h-full w-full top-0 right-0 left-0 bottom-0 backdrop-blur-[2px]" />
        <div className="flex gap-2 items-center">
          <img src={PersanaLogo} className="w-[20px]" />
          <h1 className="text-lg font-bold">
            Ask our new Sales AI to find and research your best leads
          </h1>
        </div>
        <Divider className="my-4" />
        <div className="grid grid-cols-2 gap-2">
          {salesGPTDetails.map((item) => {
            const pointersData =
              item.id === "starter-tables"
                ? premiumStarterListPointer
                : item.pointers;

            return (
              <div className="py-2" key={item.title}>
                <p className="text-[14px] text-textPurple font-bold px-2 py-1 bg-[#F8F0FF] rounded-md">
                  {item.title}
                </p>
                <div className="flex flex-col gap-1 mt-3 text-[15px]">
                  {pointersData.map((point) => (
                    <div
                      className="flex items-center gap-2 cursor-pointer hover:bg-faintGray py-1 px-2 rounded"
                      key={point.name}
                      onClick={() =>
                        handleSuggestionClick({
                          title: item.title,
                          description: point.name,
                          id: point._id,
                          key:
                            item.id === "starter-tables" ? "starterTables" : "",
                        } as PredefinedSuggestionType)
                      }
                    >
                      <Icons.StarsIcon className="text-md text-[#808080]" />
                      <p className="text-cellTextGray font-[500]">
                        {point.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex gap-4 items-center mt-4 -z-0">
          {predefinedSuggestions.map((suggestion) => (
            <Button
              leftIcon={<suggestion.icon />}
              size={"sm"}
              key={suggestion.title}
              className="!bg-white border border-textPurple !text-textPurple z-0"
              isDisabled
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.title}
            </Button>
          ))}
        </div>
        <p className="font-title text-cellTextGray text-[15px] pt-3">
          <span className="font-semibold">Note:</span>{" "}
          <span className="font-medium">
            The AI-powered chat displays a limited number of people or
            companies. For more comprehensive results, utilize our 'Find People'
            or 'Find Companies' feature available on the platform. Thank you for
            your understanding.
          </span>
        </p>
        <Divider className="my-4" />
        <InputGroup className="mt-2">
          <div className="w-full">
            <Input
              style={{ borderColor: "#808080" }}
              onKeyDown={handleKeyDown}
              type="text"
              value={inputValue}
              resize={"none"}
              onChange={handleChange}
              placeholder="Ask, write or search for anything..."
              ref={inputRef}
              isDisabled
            />
            <InputRightElement
              onClick={() =>
                inputValue && searchPeopleUsingGPTMutateAsync(inputValue)
              }
              children={
                <IconButton
                  isDisabled
                  isLoading={isPending}
                  aria-label="Search"
                  size={"sm"}
                  icon={
                    <Icons.SearchBarButton className="text-xl text-textPurple" />
                  }
                  variant={"unstyled"}
                ></IconButton>
              }
            />
          </div>
        </InputGroup>
      </Box>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.1)"
          backdropFilter="revert"
          backdropInvert="80%"
          backdropBlur="2px"
        />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Conversations answerData={answerData?.data} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SalesGPT;
