import { useEffect, useRef, useState } from "react";
import { Portal, Textarea, Kbd } from "@chakra-ui/react";

import SelectColumnDropdown from "../SelectColumnDropdown";

import type { TSelectedColumnOption } from "@/types/common.types";

interface Props {
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  rows?: number;
  callFrom?: string;
  placeholder?: string;
}

const SelectableTextEditor = ({
  text,
  setText,
  title,
  rows = 3,
  callFrom,
  placeholder = "",
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText);

    const { scrollHeight, clientHeight } = e.target;
    setText(e.target.value);

    // Auto-expand textarea
    if (scrollHeight > clientHeight) {
      setTextareaHeight(`${scrollHeight}px`);
    } else {
      setTextareaHeight("auto");
    }

    const lastSlashIndex = newText.lastIndexOf("/");
    if (lastSlashIndex >= 0) {
      const editorPosition = inputRef?.current?.getBoundingClientRect();
      setCursorPosition({ x: editorPosition?.x!, y: editorPosition?.y! });
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    if (selectedOption?.key) {
      const newText = text?.replace(/\/\w*\s?/, `{{${selectedOption.key}}}`);
      setText(newText);
      setSelectedOption(null);
      setShowOptions(false);
    }
  }, [selectedOption]);

  return (
    <div>
      <p className="mt-4 pb-1 text-sm font-semibold ">{title}</p>
      <div className="relative h-full">
        <Textarea
          rows={rows}
          placeholder={placeholder || "Enter Prompt"}
          ref={inputRef}
          resize={"none"}
          value={text}
          style={{
            height: textareaHeight,
            border: "1px solid #cbd5e0",
            maxHeight: "15rem",
          }}
          className="!rounded-b-none !rounded-t !focus:outline-none !focus:ring-0 !focus:border-gray-300"
          onChange={handleTextareaChange}
        />
        <div className="border p-1 px-2 text-sm rounded-none border-t-0">
          Type <Kbd>/</Kbd> Insert Column
        </div>

        {showOptions && (
          <Portal>
            <div
              className="absolute z-[9999]"
              style={{ left: cursorPosition.x - 280, top: cursorPosition.y }}
            >
              <SelectColumnDropdown
                type="noInput"
                selectedOption={selectedOption}
                setSelectedOption={(option) => {
                  setSelectedOption(option);
                  inputRef?.current?.focus();
                }}
                notInportal={true}
                callFrom={callFrom}
              />
            </div>
          </Portal>
        )}
      </div>
    </div>
  );
};

export default SelectableTextEditor;
