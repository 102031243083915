import React, { useRef } from "react";
import Slider from "react-slick";
import Icons from "../Icons";

interface Data {
  id: number;
  avatar: string;
  quote: string;
  author: string;
  position: string;
}

interface SliderProps {
  CommonCarouselData: Data[];
}

const CommonCarousel: React.FC<SliderProps> = ({ CommonCarouselData }) => {
  const sliderRef = useRef<Slider>(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "linear",
  };

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {CommonCarouselData.map((testimonial) => (
          <div
            key={testimonial.id}
            className="text-white !flex !flex-col !items-center gap-3 "
          >
            <img
              className="h-14 w-14 rounded-full"
              src={testimonial.avatar}
              alt=""
            />
            <h2 className=" !font-medium text-sm sm:text-base sm:font-semibold h-24  ">
              {testimonial.quote}
            </h2>
            <h3 className="!font-light text-sm sm:text-base sm:font-normal h-6">
              {testimonial.author}
            </h3>
            <p className="text-xs sm:text-sm !font-light sm:font-normal">
              {testimonial.position}
            </p>
          </div>
        ))}
      </Slider>
      <div
        className="flex justify-around"
        style={{ textAlign: "center", zIndex: 1000, marginTop: 20 }}
      >
        <button className="button z-50" onClick={previous}>
          <Icons.CarouselLeftIcon size={40} color="white" />
        </button>
        <button className="button z-50" onClick={next}>
          <Icons.CarouselRightIcon size={40} color="white" />
        </button>
      </div>
    </>
  );
};

export default CommonCarousel;
