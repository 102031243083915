import {
  Button,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { useMutation } from "@tanstack/react-query";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import tableService from "@/services/table.service";

type UpdateColumnsDataParams = Parameters<
  typeof tableService.updateColumnsData
>;

const ColumnList = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (funcPayload: UpdateColumnsDataParams[0]) =>
      tableService.updateColumnsData(funcPayload),
  });

  const columns = useTableStore((state) => state.tableData.columns);
  const updateState = useTableStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);

  const activeColumnsLength = columns.filter(
    (column) => !column.metaData?.isHidden,
  ).length;

  const handleColumnAction = async (columnId: string, isChecked: boolean) => {
    const updatedColumns = columns.map((column) => {
      if (column._id === columnId) {
        column.metaData.isHidden = !isChecked;
      }
      return column;
    });
    updateState({
      tableData: {
        ...tableData,
        columns: updatedColumns,
      },
    });

    await mutateAsync({
      tableId: tableData._id,
      payload: [
        {
          columnId,
          column: {
            metaData: {
              isHidden: !isChecked,
            },
          },
        },
      ],
    });
  };

  return (
    <Popover
      placement="auto-end"
      closeOnBlur
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Button variant="outline" className="!h-[35px] gap-2">
          <Icons.ColumnIcon size={10} className="cursor-pointer" />
          <span className="!text-xs">
            {activeColumnsLength}/{columns.length} Columns
          </span>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent className="!w-[300px] !py-3">
          <PopoverArrow />
          <PopoverBody>
            <div className="flex justify-end">
              {isPending && <Spinner size={"sm"} />}
            </div>
            <div className="hidden gap-2 items-center cursor-pointer hover:bg-slate-100 px-2 py-[2px] rounded-[3px] mb-2">
              <Icons.OpenEyeIcon />
              <p>View all columns</p>
            </div>
            <div className="hidden gap-2 items-center cursor-pointer hover:bg-slate-100 px-2 py-[2px] rounded-[3px]">
              <Icons.CloseEyeIcon />
              <p>Hide all columns</p>
            </div>
            <Divider className="my-3 hidden" />
            <div className="columns max-h-[300px] overflow-y-auto">
              {columns.map((column) => {
                const HeaderIcon =
                  tableActionIcons[column?.metaData?.iconType || "text"];

                return (
                  <div
                    key={column._id}
                    className="flex justify-between items-center cursor-pointer hover:bg-slate-100 px-2 py-[2px] rounded-[3px] mb-2"
                  >
                    <div className="flex gap-1 items-center">
                      {column?.metaData?.iconImg ? (
                        <img
                          src={column?.metaData?.iconImg}
                          alt={column?.metaData?.iconType}
                          className="h-[15px]"
                        />
                      ) : (
                        <HeaderIcon className="h-[15px]" />
                      )}
                      <p className="text-ellipsis max-w-[200px]">
                        {column.name}
                      </p>
                    </div>
                    <Switch
                      size="md"
                      isChecked={!column.metaData?.isHidden}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleColumnAction(column._id, e.currentTarget.checked);
                      }}
                      disabled={isPending}
                    />
                  </div>
                );
              })}
            </div>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ColumnList;
