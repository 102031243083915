import { useEffect, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectTemplate from "./SelectTemplate";
import Examples from "./Examples";
import NewCustomPreset from "./NewCustomPreset";
import SelectableTextEditor from "../../Common/SelectableTextEditor";

import { googleSearchTutorialLink } from "../../constants";
import { useEnrichStore } from "@/stores/enrich.store";
import { EnrichmentMetaData } from "@/types/table.types";
import { usePresetsStore } from "@/stores/presets.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

interface EnrichmentDataType extends EnrichmentMetaData {
  prompts?: string;
}

const GoogleSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const _examples = useEnrichStore((state) => state.examples);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const { updateState: updatePresetState, selectedPresetValues } =
    usePresetsStore();

  const examples = _examples
    .map((exm) => {
      return "\n" + exm.input + "\n" + exm.output;
    })
    .join("\n");

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        setSearchQuery(enrichmentData.prompts || "");
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [selectedColumnToEdit]);

  useEffect(() => {
    if (selectedPresetValues) {
      setSearchQuery(selectedPresetValues);
    }
  }, [selectedPresetValues]);

  return (
    <>
      <div className="p-4 overflow-y-auto">
        <WatchTutorial
          subText="Search Google for a keyword and get the top results. You can also use the preset templates to get the top results for a specific category. For example, you can use the 'competitors' preset to get the top results for the keyword 'competitors of [company name]'."
          link={googleSearchTutorialLink}
        />
        <SelectTemplate />

        <div>
          <p className="text-base font-semibold pb-1">Google search query</p>
          <SelectableTextEditor
            title="Write your prompt"
            text={searchQuery}
            setText={(newInputText) => {
              setSearchQuery(newInputText);
              updatePresetState({
                selectedPresetValues: newInputText,
              });
            }}
            rows={8}
          />
        </div>
        <Examples />
        <NewCustomPreset />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!searchQuery}
        apiPayload={{
          prompts: `${searchQuery} ${examples}`,
        }}
      />
    </>
  );
};

export default GoogleSearch;
