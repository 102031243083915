import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectableTextEditor from "../../Common/SelectableTextEditor";

import { usePresetsStore } from "@/stores/presets.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { useEffect } from "react";
import { useEnrichStore } from "@/stores/enrich.store";

const PersanaAgent2 = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = usePresetsStore((state) => state.updateState);
  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);

  const payload = {
    prompts: selectedPresetValues,
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;

      if (enrichmentData) {
        updateState({
          selectedPresetValues: enrichmentData.prompts || "",
        });
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial
          subText={
            "Need information? I'm your go-to! Whether it's scouring the web, visiting sites, or gathering data, I've got you covered!"
          }
          link=""
        />
        <SelectableTextEditor
          title="Write your query here"
          text={selectedPresetValues || ""}
          setText={(newInputText) => {
            updateState({
              selectedPresetValues: newInputText,
            });
          }}
          rows={8}
          callFrom="persana-agent-2"
          placeholder="Visit the website of the company at /Company Website, then explore their features section. Identify the most advanced feature they offer and how it differs from the basic features available."
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedPresetValues}
        apiPayload={payload}
      />
    </>
  );
};

export default PersanaAgent2;
