import React from "react";
import { Box } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";
import { TopEnrichmentTypes, topEnrichments } from "./constants";

const TopEnrichments = () => {
  const updateState = useEnrichStore((state) => state.updateState);

  const handleEnrichmentClick = (item: TopEnrichmentTypes) => {
    updateState({
      selectedEnrichments: item,
      viewMode: "create",
      isOpenEnrichModal: false,
    });
  };

  return (
    <div className="mb-[10px] flex w-[96%] flex-row justify-center gap-[10px] px-2 pr-0">
      {topEnrichments.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Box
              className={
                "enrich-" +
                item.name.toLowerCase().replace(/ /g, "-") +
                " flex flex-col items-center justify-between gap-[1px] py-3 bg-gray"
              }
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="md"
              width={"320px"}
              height={"130px"}
              cursor={"pointer"}
              // onClick={item?.onClick}
              onClick={() => {
                handleEnrichmentClick(item);
              }}
            >
              <Box
                boxShadow="xl"
                width={"50px"}
                height={"50px"}
                borderRadius={"50%"}
                background={"#fff"}
                className="flex items-center justify-center"
              >
                {typeof item.icon === "string" ? (
                  <img src={item.icon} alt="icon" />
                ) : (
                  <item.icon
                    size={30}
                    color={`${item.name === "Use AI" ? "#0284FE" : "gray"}`}
                  />
                )}
              </Box>

              <p className="text-center text-[14px] font-medium flex-1 mt-4">
                {item.name}
              </p>
            </Box>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TopEnrichments;
