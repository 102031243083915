import { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { DotLottiePlayer } from "@dotlottie/react-player";

import { useModalStore } from "@/stores/modal.store";
import teamService from "@/services/team.service";
import { Team } from "@/types/team.types";
import sorryEmoji from "@/assets/animated/sorryEmoji.lottie";
import { UserData } from "@/types/user.types";

const Header = () => {
  const [ownedWorkspaces, setOwnedWorkspaces] = useState<Team[]>([]);
  const [sharedWorkspaces, setSharedWorkspaces] = useState<Team[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateState = useModalStore((state) => state.updateState);
  const { mutateAsync } = useMutation({
    mutationFn: () => teamService.getWorkspaces(),
    onSuccess: (response) => {
      setIsLoading(false);
      setOwnedWorkspaces(response?.data?.ownedWorkspaces || []);
      setSharedWorkspaces(response?.data?.sharedWorkspaces || []);
    },
    onError: () => {
      setIsLoading(false);
    },
  });


  useEffect(() => {
    mutateAsync();
  }, []);

  console.log("ownedWorkspaces", ownedWorkspaces, sharedWorkspaces);

  return (
    <div className="mr-[20px]">
      <div className="max-w-[52rem] w-full mx-auto h-full shadow p-8">
        <div className="flex justify-between items-center">
          <Text className=" text-[22px] font-[600]">Team Overview</Text>
          <button
            className="btn-primary-outline flex h-[48px] min-w-fit cursor-pointer items-center justify-center rounded-[5px] px-[28px] py-[8px] text-[16px] font-semibold text-[#302323]"
            onClick={() => {
              updateState({
                addTeamMembersModal: {
                  isOpen: true,
                  isForShare: false,
                },
              });
            }}
          >
            Manage Your Workspace
          </button>
        </div>

        {
          isLoading ? ("") : ownedWorkspaces?.length === 0 && sharedWorkspaces?.length === 0 ? (
            <div className="flex flex-col items-center py-16">
              <div className="w-[150px]">
                <DotLottiePlayer
                  src={sorryEmoji}
                  autoplay
                  loop
                  style={{ height: "auto", width: "auto" }}
                />
              </div>
              <Text className="text-[16px] font-semibold text-[#302323]">You have not created or shared any workspaces yet.</Text>
            </div>
          ) : (
            <div>
              {
                sharedWorkspaces.map((team, index) => {
                  const teamAdmin = team.members.find((member) => member.role === "admin");

                  return (
                    <Box key={`shared-workspace-${index}`} className="shadow-lg py-3 px-5 rounded-md border border-textPurple mt-10 !font-title space-y-2">
                      <h3 className="text-xl font-semibold capitalize">{team.name}</h3>
                      <p className="font-medium">
                        Admin Name : {(teamAdmin?.userId as UserData).profileInfo?.firstName || ""} {(teamAdmin?.userId as UserData).profileInfo?.lastName || ""}
                      </p>
                      <p>Admin Email : {(teamAdmin?.userId as UserData).Email || ""}</p>
                      <p className="font-medium">Members : {team.members.length}</p>
                    </Box>
                  )
                })
              }
            </div>
          )
        }

      </div>
    </div>
  );
};

export default Header;
