import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

import {
  TopEnrichmentTypes,
  categories,
} from "@/components/Enrichment/constants";
import { Column } from "@/types/table.types";

export type CategoryType = (typeof categories)[number]["name"];

type State = {
  activeCategory: CategoryType | "";
  searchInput: string;
  selectedEnrichments: TopEnrichmentTypes | null;
  selectedColumnToEdit: Column | null;
  isOpenEnrichModal: boolean;
  generateFormulaModal: {
    isOpen: boolean;
    type: "formula" | "condition" | null;
  };
  examples: {
    input?: string;
    output?: string;
  }[];
  keySelect: "persanaApiKey" | "ownApiKey";
  gptVersion: "gpt3.5" | "gpt4.0";
  viewMode?: "edit" | "viewOnly" | "create";
  runConditionFormula?: any;
  isInvalidConditionFormula: boolean;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export const useEnrichStore = create<State & Actions>((set) => ({
  activeCategory: "All Enrichments",
  searchInput: "",
  selectedEnrichments: null,
  selectedColumnToEdit: null,
  isOpenEnrichModal: false,
  generateFormulaModal: {
    isOpen : false,
    type: "formula",
  },
  isInvalidConditionFormula: false,
  runConditionFormula: null,
  examples: [
    {
      input: "",
      output: "",
    },
  ],
  viewMode: "create",
  keySelect: "persanaApiKey", // Api Key Select
  gptVersion: "gpt3.5",
  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Enrich", useEnrichStore);
}
