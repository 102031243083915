import { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import SmartleadLogo from "@/assets/images/smartlead.png";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";

const SmartleadIntegration = () => {
  const toast = useToast();
  const [smartleadAPIKeyValue, setSmartleadAPIKeyValue] = useState("");
  const { data: providerList } = useProviderList();
  const smartLeadsApiKey = providerList?.data?.find(
    (provider) => provider.name === "smartLeadsApiKey",
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: {
      apiKey: string;
      providerId?: string;
      providerName: string;
    }) => providerService.saveKey(payload),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: response?.data?.message || "Key saved successfully.",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={SmartleadLogo} alt={"Smartlead"} className="w-[2rem]" />
        <h2 className="font-semibold">Smartlead</h2>
      </div>
      <div>
        <p className="mb-4">{`Add your Smartlead key`}</p>
        <div className="flex gap-1 items-center">
          <Input
            size={"sm"}
            placeholder={smartLeadsApiKey?.metaData?.accessToken || "********"}
            className="mr-5 border-2 border-black"
            onChange={(e) => setSmartleadAPIKeyValue(e.target.value)}
            value={smartleadAPIKeyValue}
          />

          <Button
            colorScheme={"green"}
            variant="outline"
            size="sm"
            isLoading={isPending}
            onClick={() =>
              mutateAsync({
                apiKey: smartleadAPIKeyValue,
                providerId: smartLeadsApiKey?._id || "",
                providerName: "smartLeadsApiKey",
              })
            }
            isDisabled={!smartleadAPIKeyValue}
          >
            {smartLeadsApiKey ? "Update" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SmartleadIntegration;
