import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

import type {
  Column,
  ResponseData,
  TableData,
  FilterType,
  RowData,
} from "@/types/table.types";
import {
  TopEnrichmentTypes,
  allEnrichments,
} from "@/components/Enrichment/constants";
import { useEnrichStore } from "./enrich.store";
import { Team } from "@/types/team.types";

type State = ResponseData & {
  isShowLoader: boolean;
  selectedRowsId: string[];
  isSelectedAll: boolean;
  selectedWorkSpace: Team | null;
  tableFilters: {
    open: boolean;
    filters?: FilterType[];
  };
  contextMenuState: {
    x: number;
    y: number;
    metaData: {
      rowId: string | null;
    };
  };
  expendedCellValue: {
    isOpen: boolean;
    columnName: string;
    columnId: string;
    rowId: string;
    cellValue: string;
    rowIndex?: number;
  };
  queryParams: {
    limit: number;
    pageNumber: number;
    sortingKey?: string;
    sortOrder: "ASC" | "DESC";
    checkForFilter?: boolean;
    clearFilters?: boolean;
  };
  sortingProcessingColumnKey?: string;
  isCreatedNewColumn?: boolean;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
  updateRowsData: (payload: RowData[]) => void;
  updateRows: (payload: ResponseData["rowsData"], isReplace?: boolean) => void;
  updateTableMetaDta: (payload: TableData["metaData"]) => void;
  deleteRow: (rowId: string) => void;
  deleteColumn: (columnId: string) => void;
  handleCellClick: (colValue: Column, cellValue: any) => void;
  updateColumns: (payload: Partial<Column>) => void;
};

export const useTableStore = create<State & Actions>()((set) => ({
  isShowLoader: false,
  isCreatedNewColumn: false,
  totalPages: 0,
  totalRows: 0,
  currentPage: 1,
  rowsData: [],
  tableData: {} as TableData,
  selectedRowsId: [],
  isSelectedAll: false,
  sortingProcessingColumnKey: "",
  selectedWorkSpace: null,
  tableFilters: {
    open: false,
  },
  contextMenuState: {
    x: 0,
    y: 0,
    metaData: {
      rowId: null,
    },
  },
  queryParams: {
    limit: 500,
    pageNumber: 1,
    sortingKey: "",
    sortOrder: "ASC",
    clearFilters: false,
  },
  expendedCellValue: {
    isOpen: false,
    columnName: "",
    columnId: "",
    rowId: "",
    cellValue: "",
  },

  handleCellClick: (colValue: Column, cellvalue: any) => {
    const selectedEnrichment = allEnrichments.find((enrichment) => {
      return (
        enrichment.enrichmentName ===
        colValue?.metaData?.enrichmentMetaData?.enrichmentName
      );
    });

    useEnrichStore.getState().updateState({
      selectedEnrichments: {
        ...selectedEnrichment,
        showResponse: true,
        columnData: colValue,
        rowData: cellvalue,
      } as TopEnrichmentTypes,
    });
  },

  updateTableMetaDta: (payload: Partial<TableData["metaData"]>) => {
    set((state) => {
      const {
        tableData,
      }: {
        tableData: ResponseData["tableData"];
      } = state;
      return {
        tableData: {
          ...tableData,
          metaData: {
            ...tableData.metaData,
            ...payload,
          },
        },
      };
    });
  },

  updateState: (payload) => set(() => ({ ...payload })),

  updateColumns: (payload: Partial<Column>) => {
    set((state) => {
      const {
        tableData,
      }: {
        tableData: ResponseData["tableData"];
      } = state;
      const newColumns = tableData.columns.map((column) => {
        if (column._id === payload._id) {
          if (column.metaData) {
            column.metaData = {
              ...column.metaData,
              ...payload.metaData,
            };
            return {
              ...column,
            };
          }
          return {
            ...column,
            ...payload,
          };
        }
        return column;
      });
      return {
        tableData: {
          ...tableData,
          columns: newColumns,
        },
      };
    });
  },

  updateRows: (payload, isReplace = false) =>
    set((state) => {
      const {
        rowsData,
      }: {
        rowsData: ResponseData["rowsData"];
      } = state;
      return {
        rowsData:
          isReplace && payload?.length
            ? [...payload]
            : [...rowsData, ...payload],
      };
    }),

  updateRowsData: (payload: RowData[]) => {
    set((state) => {
      // Loop through the rowsData and update the cellData
      const { rowsData } = state;
      const newRowsData = rowsData.map((row) => {
        const newRow = payload.find((data) => data._id === row._id);
        if (newRow) {
          return newRow;
        }
        return row;
      });
      return { rowsData: newRowsData };
    });
  },

  deleteRow: (rowId: string) => {
    set((state) => {
      const {
        rowsData,
      }: {
        rowsData: ResponseData["rowsData"];
      } = state;
      const newRowsData = rowsData.filter((row) => row._id !== rowId);
      return { rowsData: newRowsData };
    });
  },

  deleteColumn(columnId: string) {
    set((state) => {
      const {
        tableData,
      }: {
        tableData: ResponseData["tableData"];
      } = state;
      const newColumns = tableData.columns.filter(
        (column) => column._id !== columnId,
      );
      return {
        tableData: {
          ...tableData,
          columns: newColumns,
        },
      };
    });
  },
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Table", useTableStore);
}
