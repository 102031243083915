import { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { useToast } from "@chakra-ui/react";

import AnimatedLoader from "@/components/Icons/SvgIcons/AnimatedLoaders";

import oAuthService from "@/services/oauth.service";
import providerService from "@/services/providers.service";

const Oauth = ({ provider }: { provider: string }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    code && setCode(code);
  }, [location.search]);

  useEffect(() => {
    let timeout: any;

    if (provider === "github" && code) {
      oAuthService.generateAccessToken(code).then((response) => {
        if (!response.success) {
          toast({
            title: "Error",
            description: response?.error || "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "Success",
            description: "Github connected successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
        timeout = setTimeout(() => {
          navigate({ to: "/" });
        }, 3000);
      });
    } else if (provider === "gmail" && code) {
      providerService.gmailOauth({ code }).then((response) => {
        console.log(response);
        if (!response.success) {
          toast({
            title: "Error",
            description: response?.error?.message || "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "Success",
            description: "Gmail connected successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
        timeout = setTimeout(() => {
          navigate({ to: "/" });
        }, 3000);
      });
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [provider, code]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="rounded-md bg-white px-20 py-10 shadow-md">
        <p className="font-title text-2xl font-bold">Verifying</p>
        <AnimatedLoader className="h-12 w-12" />
      </div>
    </div>
  );
};

export default Oauth;
