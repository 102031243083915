import {
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Divider,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";

import BookACallCard from "@/components/Cards/BookACallCard";
import PersanaProCard from "@/components/Cards/PersanaProCard";
import SupportCard from "@/components/Cards/SupportCard";
import Referral from "@/components/Leads/Referrals";
import SalesGPT from "@/components/SalesGPT";
import { userStore } from "@/stores/user.store";
import QuickStartGuide from "../QuickStartGuide";
import TableList from "../TableList";

import { useTableList } from "@/hooks/useTableList";
import { useModalStore } from "@/stores/modal.store";
import MobileAlertDialogComponent from "@/components/alerts/MobileVIewAlert";
import teamService from "@/services/team.service";
import Icons from "@/components/Icons";
import { Team } from "@/types/team.types";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { setCookie } from "@/utils";
import { queryClient } from "@/lib/queryClient";

const Dashboard = () => {
  const [referralPopup, setReferralPopup] = useState(false);
  const [workspaces, setWorkspaces] = useState<Team[]>([]);
  const [isLoadingWorkspaces, setIsLoadingWorkspaces] = useState(false);

  const { data, isLoading, refetch } = useTableList();
  const { updateState } = useModalStore((state) => state);
  const currentUser = userStore((state) => state.currentUser);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setfilteredData] = useState(data || []);

  const selectedWorkSpace = useTableStore((state) => state.selectedWorkSpace);
  const updateTableState = useTableStore((state) => state.updateState);
  const cookies = new Cookies();

  const { mutateAsync } = useMutation({
    mutationFn: () => teamService.getWorkspaces(),
    onSuccess: (response) => {
      const workspaceId = cookies.get("workspaceId");

      if (workspaceId && Array.isArray(response.data?.sharedWorkspaces)) {
        const selectedWorkspace = response.data.sharedWorkspaces.find(
          (workspace: Team) => workspace._id === workspaceId,
        );
        updateTableState({
          selectedWorkSpace: selectedWorkspace,
        });
      }

      setWorkspaces(response?.data?.sharedWorkspaces || []);
    },
  });

  const { mutateAsync: getWorkspaceTableLists } = useMutation({
    // @ts-ignore
    mutationFn: (workspaceId: string) => tableService.getTableList(),
  });

  const handleFilterChange = (query: string) => {
    setSearchValue(query);
    const newData = query
      ? data?.filter((row) =>
          row.name?.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
        )
      : data;
    setfilteredData(newData || []);
  };

  const handleWorkspaceChange = async (workspace: Team | null) => {
    setCookie("workspaceId", workspace?._id || "");
    updateTableState({
      selectedWorkSpace: workspace,
    });
    setIsLoadingWorkspaces(true);
    await getWorkspaceTableLists(workspace?._id || "", {
      onSuccess: (response) => {
        setIsLoadingWorkspaces(false);
        setfilteredData(response || []);
      },
      onError: () => {
        setIsLoadingWorkspaces(false);
      },
    });

    queryClient.invalidateQueries({
      queryKey: ["user-credit-details"],
    });
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setfilteredData(data || []);
  }, [data]);

  useEffect(() => {
    mutateAsync();
  }, []);

  return (
    <div>
      {/* Header Section */}
      <div className="fixed top-0 z-[11] w-[93%] bg-white px-[40px] py-[10px]">
        <div className="flex items-center justify-between gap-[60px] mt-6 md:w-[75%]">
          <p className="text-[24px] font-[700] text-black">{`👋 Welcome, ${
            currentUser?.profileInfo?.firstName || "User"
          }`}</p>
          <div className="flex gap-5">
            <button
              className="btn-primary-outline flex h-[48px] min-w-fit cursor-pointer items-center justify-center rounded-[5px] px-[28px] py-[8px] text-[16px] font-semibold text-[#fff]"
              onClick={() => setReferralPopup(true)}
            >
              Refer & Earn Free Credits
            </button>
            <button
              className="h-[48px] rounded-[5px] px-[28px] py-[8px] text-[16px] font-semibold text-white !bg-primary-gradient cursor-not-allowed"
              onClick={() =>
                updateState({
                  isOpenModal: true,
                  modalData: { modalType: "get-started" },
                })
              }
              disabled
            >
              Get Started
            </button>
          </div>
        </div>
      </div>

      {referralPopup && (
        <Referral isOpen={referralPopup} setIsOpen={setReferralPopup} />
      )}
      <MobileAlertDialogComponent />
      <div className="mt-[85px] md:flex items-start justify-between gap-[15px] p-[40px]">
        {/* Left Section */}
        <div className="md:w-[75%]">
          <SalesGPT />
          <Divider className="my-6" />
          {workspaces.length > 0 || filteredData.length > 0 ? (
            <div className="overflow-hidden ">
              <div className="mb-4 flex items-center justify-between">
                {/* <h1 className="text-lg font-bold">Your Workflows</h1> */}
                <Menu>
                  <MenuButton as={Button} rightIcon={<Icons.ChevronDownIcon />}>
                    {selectedWorkSpace
                      ? selectedWorkSpace.name
                      : "Personal workspace"}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        handleWorkspaceChange(null);
                      }}
                    >
                      Personal workspace
                    </MenuItem>
                    {workspaces?.length > 0
                      ? workspaces?.map((workspace, index) => (
                          <MenuItem
                            key={`workspaces-${index}`}
                            onClick={() => {
                              handleWorkspaceChange(workspace);
                            }}
                          >
                            <span className="capitalize">{workspace.name}</span>
                          </MenuItem>
                        ))
                      : null}
                  </MenuList>
                </Menu>
                <Input
                  placeholder="Search by table name"
                  size="md"
                  value={searchValue}
                  onChange={(event) => {
                    handleFilterChange(event?.target.value);
                  }}
                  style={{
                    width: "300px",
                    height: "40px",
                  }}
                />
              </div>

              {isLoading || isLoadingWorkspaces ? (
                <div>
                  <Skeleton height="20px" className="mb-2" />
                  <Skeleton height="20px" className="mb-2" />
                  <Skeleton height="20px" className="mb-2" />
                </div>
              ) : filteredData?.length ? (
                <TableList data={filteredData} />
              ) : (
                <QuickStartGuide />
              )}
            </div>
          ) : null}
        </div>

        {/* Right Section */}
        <div className="flex justify-between md:w-[25%]  md:flex-col overflow-x-auto items-end gap-[15px] bg-white custom-scrollbar mb-4">
          <SupportCard />
          <PersanaProCard />
          <BookACallCard />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
