import { Button, useToast } from "@chakra-ui/react";

import GmailLogo from "@/assets/images/gmail-icon.png";
import { useProviderList } from "@/hooks/useProviderData";
import { useMutation } from "@tanstack/react-query";
import providerService from "@/services/providers.service";

const GmailIntegration = () => {
  const toast = useToast();
  const { data: providerList, refetch } = useProviderList();
  const gmailProvider = providerList?.data?.find(
    (provider) => provider.name === "gmail",
  );

  const clientId = `${import.meta.env.VITE_GMAIL_CLIENT_ID}`;
  const redirectUri = `${window.location.origin}/oauth/gmail/`;
  const scopes = [
    "https://mail.google.com/",
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ];

  const authUrl = `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(
    "%20",
  )}&prompt=consent`;

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      providerService.deleteProvider({ providerId: gmailProvider?._id || "" }),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        refetch();
        toast({
          title: response?.data?.message || "Disconnected successfully",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  const redirect = () => {
    window.location.assign(authUrl);
  };

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow dark:!bg-navy-700">
      <div className="mb-4 flex items-center gap-4">
        <img src={GmailLogo} alt={"Gmail"} className="w-[2rem]" />
        <h2 className="font-semibold">Gmail</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Gmail Account`}</p>

        <Button
          colorScheme={gmailProvider ? "red" : "green"}
          variant="outline"
          size="sm"
          isLoading={isPending}
          onClick={() => {
            if (gmailProvider) {
              mutateAsync();
            } else {
              redirect();
            }
          }}
        >
          {gmailProvider ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default GmailIntegration;
