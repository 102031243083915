import { Button } from "@chakra-ui/react";

type Props = {
  title: string;
  monitoringType: string;
  deleteText: string;
  trackBttonText: string;
  totalItemsCount: number;
  selectedItemsCount: number;
  loading: boolean;
  loader: boolean;
  selectedLeadCountNum?: number;
  loaderCount?: number;
  showImportButton?: boolean;
  handleDelete: () => void;
  handleTrack: any;
  setQuickAddModal?: (val: boolean) => void;
};

const AccountMonitoringHeader = ({
  title,
  monitoringType,
  deleteText,
  trackBttonText,
  totalItemsCount,
  selectedItemsCount,
  loading,
  loader,
  selectedLeadCountNum = 0,
  loaderCount = 0,
  showImportButton = true,
  handleDelete,
  setQuickAddModal,
  handleTrack,
}: Props) => {
  return (
    <div className="flex items-center justify-between px-3 mb-2">
      <div className="text-lg font-bold text-[#5F6B77]">
        {title}
        {totalItemsCount > 0 && (
          <p className="text-[13px] font-[400] text-[#5F6B77]">
            ({totalItemsCount} {monitoringType})
          </p>
        )}
      </div>
      <div className="flex items-center gap-3">
        {selectedItemsCount > 0 && (
          <Button
            isLoading={loading}
            colorScheme="red"
            onClick={() => handleDelete()}
            className="!h-[35px] p-3"
          >
            Delete
          </Button>
        )}
        {selectedItemsCount > 0 && (
          <div className="font-semibold text-[#5F6B77] text-[14px]">
            ({selectedItemsCount} {deleteText} selected)
          </div>
        )}

        <div className="flex gap-5 ">
          {!loader ? (
            <div
              className={`flex items-center h-[40px] gap-3 rounded-md bg-primary-gradient p-3 text-white cursor-pointer`}
              onClick={() =>
                title === "Actively Hiring Companies"
                  ? handleTrack(true)
                  : handleTrack()
              }
            >
              <div>{trackBttonText}</div>
            </div>
          ) : (
            title === "Lead List" && (
              <Button
                isLoading={true}
                disabled={true}
                variant="outline"
                colorScheme="teal"
                loadingText={`${selectedLeadCountNum} of ${loaderCount} Generating...`}
                w="300px"
              />
            )
          )}
        </div>
        {showImportButton && (
          <Button
            isLoading={loading}
            onClick={() => setQuickAddModal && setQuickAddModal(true)}
            style={{
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
              color: "#fff",
            }}
          >
            <div>Import Data</div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default AccountMonitoringHeader;
