import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import HiringIntent from "@/components/HiringIntent";
import HiringJobs from "@/components/HiringJobs";
import JobChanges from "@/components/JobChanges";
import PrimaryLoader from "@/components/Common/PrimaryLoader";

import userService from "@/services/user.service";
import sorryEmoji from "@/assets/animated/sorryEmoji.lottie";
import { DotLottiePlayer } from "@dotlottie/react-player";
import Icons from "@/components/Icons";

const AccountMonitoring = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState(0);
  const [isWhiteListed, setIsWhiteListed] = useState(false);

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () => userService.checkIsWhitelisted(),
    onSuccess: (response) => {
      setIsWhiteListed(response?.success);
    },
  });

  const checkIsWhiteListed = useCallback(async () => {
    await mutateAsync();
  }, []);

  const handleClick = (title: string) => {
    switch (title) {
      case "Job Change Tracker":
        setActiveTab(0);
        break;
      case "Hiring Trend Analyzer":
        setActiveTab(1);
        break;
      case "Find Companies Hiring":
        setActiveTab(2);
        break;
      default:
        break;
    }
    onClose();
  };

  useEffect(() => {
    onOpen();
    checkIsWhiteListed();
  }, []);

  const tabs = [
    {
      name: "Job Change Tracker",
      component: JobChanges,
    },
    {
      name: "Hiring Trend Analyzer",
      component: HiringIntent,
    },
    {
      name: "Find Companies Hiring",
      component: HiringJobs,
    },
  ];

  const monitoringDetails = [
    {
      title: "Job Change Tracker",
      icon: () => <Icons.SwitchIcon className="text-white" size={22} />,
      content:
        "Monitor career changes among your clients. Those undergoing job transitions are 5 times more inclined to utilize your solutions, typically accompanied by new budgets and strategic endeavors.",
    },
    {
      title: "Hiring Trend Analyzer",
      icon: () => <Icons.TrendIcon className="text-white" size={22} />,
      content:
        "Track hiring trends in your customer base and target market. Businesses expanding their teams are 4 times more inclined to invest in new solutions for supporting their growth.",
    },
    {
      title: "Find Companies Hiring",
      icon: () => <Icons.SearchCompanyIcon className="text-white" size={22} />,
      content:
        "Spot organizations actively hiring for roles relevant to your product or service. Such companies are growing and more likely to invest in solutions aiding their expansion, boosting conversion rates.",
    },
    {
      title: "Technographic Signals (Coming Soon)",
      icon: () => <Icons.SignalIcon className="text-white" size={22} />,
      content:
        "Get insights into your prospects' tech landscape. Knowing their current stack helps pinpoint companies utilizing or requiring complementary solutions.",
    },
  ];

  return (
    <>
      <section>
        {isPending ? (
          <div className="w-full h-screen flex justify-center items-center">
            <PrimaryLoader className="w-[180px] h-auto" />
          </div>
        ) : isWhiteListed ? (
          <Tabs index={activeTab} onChange={(index) => setActiveTab(index)}>
            <div>
              <TabList>
                {tabs.map((tab) => (
                  <Tab fontSize={"l"} fontWeight={"bold"} key={tab.name}>
                    {tab.name}
                  </Tab>
                ))}
              </TabList>
            </div>
            <TabPanels>
              {tabs.map((tab) => (
                <TabPanel key={tab.name}>{<tab.component />}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          <div className="flex items-center justify-center mt-20 bg-[rgba(255,_255,_255,_0.4)] rounded-[2rem] backdrop-blur-[7px]">
            <Box
              w={500}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              className="flex flex-col gap-4 px-10 py-5 items-center justify-center"
            >
              <div className="w-[150px]">
                <DotLottiePlayer
                  src={sorryEmoji}
                  autoplay
                  loop
                  style={{ height: "auto", width: "auto" }}
                />
              </div>
              <p className="font-[400]">
                Currently, this feature is in beta available for paid users
                only. If you'd like early access please book a quick{" "}
                <Link
                  style={{ textDecoration: "none" }}
                  href="https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding"
                  target="_blank"
                >
                  <span className="text-[#0284fe]">onboarding call</span>
                </Link>{" "}
                or email{" "}
                <a className="text-[#0284fe]" href="mailto: sriya@persana.ai">
                  sriya@persana.ai
                </a>{" "}
                for access.
              </p>
            </Box>
          </div>
        )}
      </section>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen && isWhiteListed}
        motionPreset="scale"
        size="4xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

        <ModalContent className="rounded-[20px]">
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody className="flex gap-4">
            <div className="flex flex-col gap-5">
              {monitoringDetails.slice(0, 2).map((item) => (
                <div
                  key={item.title}
                  className="flex items-start gap-3 cursor-pointer shadow-md hover:shadow-lg p-3 border border-textPurple rounded min-h-[154px]"
                  onClick={() => handleClick(item.title)}
                >
                  <div className="flex gap-2 items-center">
                    <div className="h-[35px] w-[35px] flex items-center justify-center rounded-full bg-textPurple">
                      <item.icon />
                    </div>
                  </div>
                  <div className="text-sm text-darkTextGray flex flex-col gap-2">
                    <Text className="text-[16px] text-textPurple font-[600]">
                      {item.title}
                    </Text>
                    <div className="">{item.content}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-5 mb-5">
              {monitoringDetails.slice(2).map((item, index) => (
                <div
                  key={item.title}
                  className={`flex items-start gap-3 shadow-md hover:shadow-lg p-3 border border-textPurple ${index === 0 && "cursor-pointer"} rounded min-h-[154px]`}
                  onClick={() => handleClick(item.title)}
                >
                  <div className="flex gap-2 items-center">
                    <div className="h-[35px] w-[35px] flex items-center justify-center rounded-full bg-textPurple">
                      <item.icon />
                    </div>
                  </div>
                  <div className="text-sm text-darkTextGray flex flex-col gap-2">
                    <Text className="text-[16px] text-textPurple font-[600]">
                      {item.title}
                    </Text>
                    <div className="">{item.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AccountMonitoring;
