export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "Name",
    id: "name",
  },
  {
    isChecked: false,
    label: "Title",
    id: "title",
  },
  {
    isChecked: false,
    label: "Headline",
    id: "headline",
  },
  {
    isChecked: false,
    label: "LinkedIn URL",
    id: "linkedinUrl",
  },
  {
    isChecked: false,
    label: "Company Name",
    id: "companyName",
  },
  {
    isChecked: false,
    label: "Company Website",
    id: "companyWebsite",
  },
  {
    isChecked: false,
    label: "Industry",
    id: "industry",
  },
  {
    isChecked: false,
    label: "Country",
    id: "country",
  },
];
