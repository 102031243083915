import { useProviderStore } from "@/stores/provider.store";

const ApiKey = () => {
  const updateProviderState = useProviderStore((state) => state.updateState);

  return (
    <button
      className="cursor-pointer text-xs text-white bg-[linear-gradient(127deg,_#693DC7_0%,_#BF72E1_100%)] px-2.5 py-1 rounded font-medium"
      onClick={() => {
        updateProviderState({
          isProviderApiKeyModalOpen: true,
          providerApiKeyModalData: {
            title: "Apify",
            providerName: "apifyApiKey",
          },
        });
      }}
    >
      Add/Change API key
    </button>
  );
};

export default ApiKey;
