import CheckIcon from "./SvgIcons/CheckIcon";
import EmailIcon from "./SvgIcons/EmailIcon";
import ImageIcon from "./SvgIcons/ImageIcon";
import NumberIcon from "./SvgIcons/NumberIcon";
import TextIcon from "./SvgIcons/TextIcon";
import UrlIcon from "./SvgIcons/UrlIcon";
import Icons from ".";

export type IconType = ({
  className,
}: {
  className?: string | undefined;
}) => JSX.Element;

export type TTableActionIconKeys =
  | "text"
  | "checkbox"
  | "email"
  | "image"
  | "number"
  | "url"
  | "github"
  | "link"
  | "linkedin"
  | "webhook";

export const tableActionIcons: {
  [key in TTableActionIconKeys]: IconType;
} = {
  text: TextIcon,
  checkbox: CheckIcon,
  email: EmailIcon,
  image: ImageIcon,
  number: NumberIcon,
  url: UrlIcon,
  github: Icons.GithubIcon,
  link: Icons.LinkIcon,
  linkedin: Icons.LinkedInIcon,
  webhook: Icons.WebhookIcon,
};
