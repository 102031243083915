import { FormEvent, SetStateAction, useState } from "react";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CheckEmailModal from "@/components/CheckEmailModal";
import CommonCarousel from "@/components/Common/Carousel";

import Icons from "@/components/Icons";
import PersanaLogo from "@/assets/svgs/persana-logo.svg";

import userService from "@/services/user.service";
import { CarouselDataData } from "./constants";

const Login = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();
  const [checkEmailModalOpen, setCheckEmailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setErrorMessage("");
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (!emailRegex.test(userInput)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    setIsLoading(true);
    e.preventDefault();
    const data = await userService.getOtp(userInput);
    if (data?.data?.blocked) {
      //@ts-ignore
      navigate({ to: "/closed-beta" });
    }
    if (data.success) {
      setCheckEmailModalOpen(true);
    } else {
      setErrorMessage(data?.data?.message);
      localStorage.clear();
    }
    setIsLoading(false);
  };

  return (
    <section className="!h-[100vh] w-full flex flex-col lg:flex-row ">
      <div className=" order-2 lg:order-1 py-1 w-full lg:w-[40%] flex flex-col justify-center bg-[url('/src/assets/images/login-bg.png')] bg-cover bg-no-repeat gap-6">
        <div className="slider-container !max-w-64  sm:!max-w-96 !text-center !flex !flex-col !justify-center self-center	">
          <CommonCarousel CommonCarouselData={CarouselDataData} />
        </div>
      </div>
      <div className="order-1 lg:order-2 w-full lg:w-[60%] text-primary-text py-14 flex  flex-col h-full items-center  justify-center bg-[#F8F0FF] px-4  shadow-2xl sm:px-12  lg:rounded-tr-none">
        <div className="custom1230:w-[60%] font-primary w-full lg:w-[80%]">
          <div className="flex flex-col text-center items-center justify-center">
            <img src={PersanaLogo} className="mb-[40px]" alt="logo" />
            <div className="flex flex-col items-center text-center justify-center rounded-[40px] bg-white px-[30px] md:px-[40px] py-9 md:py-12 md:w-[75%]">
              <p className="mb-[30px] md:mb-[20px]  text-[24px] font-[700] text-[#222]">
                Welcome to <span className="text-textPurple">Persana</span>
              </p>
              <p className="mb-[25px]  md:mb-[50px] text-sm md:text-base text-center text-[14px] font-[500] text-[#222]">
                Rated <span className="text-textPurple">#1</span> AI Prospecting
                Platform of 2024
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex w-full flex-col gap-[30px]"
              >
                <div>
                  <div className=" w-full !h-14">
                    <InputGroup className="w-full !h-full">
                      <InputLeftElement
                        pointerEvents="none"
                        className="!h-full"
                      >
                        <Icons.MailIcon className="!h-full w-5 text-gray-400" />
                      </InputLeftElement>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Enter your email here"
                        className={`!text-sm md:!text-base w-full !h-full rounded-lg border p-3 font-medium text-[#272727d8] placeholder:text-[#B4B4B4] focus:outline-none ${errorMessage ? "!border-[#EB5757]" : "!border-[#B4B4B4]"}`}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </div>
                  {errorMessage && (
                    <p className="mt-[5px] w-full text-left text-[14px] font-[400] text-[#EB5757]">
                      {errorMessage}
                    </p>
                  )}
                </div>
                <div className="!justify-center !items-center hidden">
                  <p className="text-sm md:text-base cursor-pointer ">
                    Having trouble signing in?
                  </p>
                </div>

                <Button
                  height={"auto"}
                  type="submit"
                  className="h-[60px] !rounded-[8px] !px-[20px] !py-[15px] !text-[18px] font-[600] !text-white"
                  isDisabled={!userInput}
                  isLoading={isLoading}
                  style={{
                    background:
                      "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                  }}
                >
                  Get Started
                </Button>
                <p className="text-center text-[14px] font-[400] text-[#222]">
                  By logging in, I agree to the Terms of Service Privacy Policy,
                  and AI Content Policy. Please view the policies
                  <a
                    target="_blank"
                    href="https://viralcopy.notion.site/Persana-Help-Center-656c99a5b9b048e9955fc9a1676e0a00"
                    className="text-[#693DC7]"
                    rel="noreferrer"
                  >
                    {` here`}
                  </a>
                  .
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <CheckEmailModal
        isOpen={checkEmailModalOpen}
        onClose={() => setCheckEmailModalOpen(false)}
        email={userInput}
      />
    </section>
  );
};

export default Login;
