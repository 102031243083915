import MakeAPICall from "@/lib/apiCalls";
import { Team } from "@/types/team.types";

class TeamService {
  constructor(private apiCaller = MakeAPICall) {}

  async addMembers(payload: { emails: string[] }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/team/add-members`,
      payload: payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getDetails() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/team/details`,
    });
    const response = await apiCall.get<Team>();
    return response;
  }

  async getWorkspaces() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/team/workspaces`,
    });
    const response = await apiCall.get<{
      ownedWorkspaces : Team[];
      sharedWorkspaces : Team[];
    }>();
    return response;
  }

  async removeSharedUser(memberId : string) {
    const apiCall = new MakeAPICall({
      apiPath: `team/remove-member/${memberId}`,
    });

    const response = await apiCall.delete();
    return response;
  }
}

const teamService = new TeamService();

export default teamService;
