export const methods = [
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PUT', label: 'PUT' },
    { value: 'DELETE', label: 'DELETE' },
];

export const initialSlateValue = [
    {
        type: "paragraph",
        children: [{ text: "" }],
    },
];