import Select from "react-select";
import React from "react";
import { AgGridReact } from "ag-grid-react";

import { useTableStore } from "@/stores/table.store";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

const Pagination = ({ gridRef }: Props) => {
  const totalPages = useTableStore((state) => state.totalPages);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateState = useTableStore((state) => state.updateState);

  if (totalPages < 2) return null;

  const options = Array.from({ length: totalPages }, (_, i) => ({
    value: i + 1,
    label: String(i + 1),
  }));

  return (
    <div className="w-[60px]">
      <Select
        defaultValue={
          options.find((option) => option.value === queryParams.pageNumber) ||
          options[0]
        }
        options={options}
        value={
          options.find((option) => option.value === queryParams.pageNumber) ||
          options[0]
        }
        isSearchable
        isClearable={false}
        menuPosition="fixed"
        components={{
          DropdownIndicator: () => null,
        }}
        onChange={(option) => {
          if (option?.value) {
            updateState({
              queryParams: {
                ...queryParams,
                pageNumber: option?.value,
              },
            });
            gridRef?.current?.api.redrawRows();
            gridRef?.current?.api.ensureIndexVisible(0, "top");
          }
        }}
      />
    </div>
  );
};

export default React.memo(Pagination);
