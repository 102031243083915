import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import PrimaryLoader from "./PrimaryLoader";

const FallbackLoader = () => {
  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen onClose={() => ""}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <PrimaryLoader />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FallbackLoader;
