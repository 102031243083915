type Props = {
  name: string;
  checked: boolean;
  setChecked: (event: any) => void;
  label: string;
};

const RadioButton = ({ name, label, checked, setChecked }: Props) => {
  return (
    <div className="flex items-center">
      <input
        id={name}
        type="radio"
        name={name}
        checked={checked}
        onClick={setChecked}
        className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
        aria-labelledby={`${label}`}
        aria-describedby={`${label}`}
      />
      <label
        htmlFor={name}
        className="ml-2 block font-title text-sm font-semibold lowercase text-[#141313b2]"
      >
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
