/* eslint-disable tailwindcss/no-custom-classname */
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";

import Icons from "@/components/Icons";
import ColumnList from "./ColumnList";
import Conditions from "./Conditions";
import Actions from "./Actions";
import ColCondition from "./ColCondition";
import AddFilter from "./AddFilter";
import ClearFilter from "./ClearFilter";

import { useTableStore } from "@/stores/table.store";
import { generateFilterPayload, generateUniqueId } from "@/lib/utils";
import { colConditions, conditions } from "./constants";
import { FilterType } from "@/types/table.types";
import tableService from "@/services/table.service";

const Filters = () => {
  const toast = useToast({
    position: "top-right",
  });

  const { mutateAsync: fetchFilteredTableData, isPending } = useMutation({
    mutationFn: (payload: any) =>
      tableService.fetchTableData({
        tableId: payload.tableId,
      }),
  });

  const tableFilters = useTableStore((state) => state.tableFilters);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateState = useTableStore((state) => state.updateState);
  const updateRows = useTableStore((state) => state.updateRows);
  const columns = useTableStore((state) => state.tableData.columns);
  const tableId = useTableStore((state) => state.tableData._id);

  const handleOpen = () => {
    if (!tableFilters?.filters?.length) {
      updateState({
        tableFilters: {
          ...tableFilters,
          open: true,
          filters: [
            {
              id: generateUniqueId(),
              column: columns[0],
              type: "input",
              colCondition: colConditions[0],
              condition: conditions[0],
              value: "",
            },
          ],
        },
      });
    } else {
      updateState({
        tableFilters: {
          ...tableFilters,
          open: true,
        },
      });
    }
  };

  const filterTable = useCallback(
    async (filter: FilterType[]) => {
      const { isAnyEmpty } = generateFilterPayload(filter);

      if (isAnyEmpty) return;

      const payload = {
        tableId,
      };
      fetchFilteredTableData(payload, {
        onSuccess: (response) => {
          if (!response?.rowsData?.length) {
            toast({
              title: "No data",
              description: "No data found for the applied filter",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
            return;
          }
          updateRows(response?.rowsData || [], true);
          updateState({
            totalRows: response?.totalRows || 0,
            totalPages: response?.totalPages || 0,
            currentPage: response?.currentPage || 1,
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      });
    },
    [tableId, queryParams],
  );

  const debouncedFilter = useMemo(() => {
    return debounce(filterTable, 1000);
  }, [filterTable]);

  useEffect(() => {
    if (tableFilters.filters?.length) {
      debouncedFilter(tableFilters.filters);
    }
  }, [tableFilters.filters, debouncedFilter]);

  return (
    <Popover
      isOpen={tableFilters.open}
      onOpen={handleOpen}
      onClose={() => {
        updateState({
          tableFilters: {
            ...tableFilters,
            open: false,
          },
        });
      }}
      placement="bottom-start"
      closeOnBlur
    >
      <PopoverTrigger>
        <Button
          className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !text-sm hover:!shadow-md"
          leftIcon={<Icons.FilterIcon />}
          isDisabled
        >
          Filters{" "}
          {tableFilters.filters?.length
            ? `(${tableFilters.filters?.length})`
            : ""}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="!w-fit !border-none !shadow-popover-content">
        <PopoverArrow />
        <PopoverBody px={4} py={3} className="relative">
          <div className="mb-4 space-y-2">
            {tableFilters.filters?.map((filter, index) => (
              <div
                key={`filter_key_${index}`}
                className="flex w-[35rem] max-w-[35rem] items-center gap-x-3"
              >
                <div className="col-condition w-[50px] min-w-[50px] max-w-[50px]">
                  {index === 0 ? (
                    <p>Where</p>
                  ) : (
                    <ColCondition filter={filter} />
                  )}
                </div>
                <div className="columns w-[158px] min-w-[158px]">
                  <ColumnList columns={columns} filter={filter} />
                </div>
                <div className="conditions  w-[92px] min-w-[92px]">
                  <Conditions filter={filter} />
                </div>

                <div className="input-field w-[168px] min-w-[168px]">
                  {filter.condition.hasInput && (
                    <input
                      placeholder="Enter value"
                      className="border-gray-500 flex-1 rounded border py-[3px] pl-1.5 text-sm font-medium text-[#000000]/90 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary"
                      value={filter.value}
                      onChange={(e) => {
                        let value = e.target.value.toString() as
                          | string
                          | number;

                        // @ts-ignore
                        if (/^\d+$/.test(value)) {
                          value = Number(value);
                        }

                        updateState({
                          tableFilters: {
                            ...tableFilters,
                            filters: tableFilters.filters?.map((item) =>
                              item.id === filter.id ? { ...item, value } : item,
                            ),
                          },
                        });
                      }}
                    />
                  )}
                </div>
                <div className="actions  w-[80px]">
                  {index === 0 && isPending && (
                    <Spinner color="#3C22BB" size="md" />
                  )}
                  {index !== 0 && <Actions filter={filter} />}
                </div>
              </div>
            ))}
          </div>
          <AddFilter />
          <ClearFilter />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Filters;
