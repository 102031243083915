import { FileRoute } from "@tanstack/react-router";

import Settings from "@/pages/Settings";

export const Route = new FileRoute("/settings/").createRoute({
  component: SettingsPage,
});

function SettingsPage() {
  return <Settings />;
}

export default SettingsPage;
