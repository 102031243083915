import Icons from "@/components/Icons";
import News from "@/assets/svgs/enrichment-news.svg";
import Verify from "@/assets/svgs/enrichment-verify.svg";
import GPT from "@/assets/svgs/enrichment-gpt.svg";
import Campaign from "@/assets/svgs/campaign.svg";
import ApiFyLogo from "@/assets/images/apify.png";

import JobOpenings from "./JobOpenings";
import AskAQuestionAiAgent from "./AskAQuestionAiAgent";
import FindPeopleAtCompany from "./FindPeopleAtCompany";
import LinkedInProfileDetails from "./LinkedInProfileDetails";
import FindLinkedInUrl from "./FindLinkedInUrl";
import CompanyTechnologies from "./CompanyTechnologies";
import CompanyNews from "./CompanyNews";
import CompanyDetails from "./CompanyDetails";
import VerifyEmail from "./VerifyEmail";
import FindWorkEmail from "./FindWorkEmail";
import GoogleSearch from "./GoogleSearch";
import ScrapeWebsite from "./ScrapeWebsite";
import UseAi from "./UseAi";
import GenerateSalesEmails from "./GenerateSalesEmails";
import LeadToSmartLeads from "./LeadToSmartLeads";
import ImportData from "../../Leads/ImportData";
import PersanaAgent2 from "./PersanaAgent2";
import HttpApi from "./HttpApi";
import ApiFy from "./Apify";

export const filterComponentMaps = {
  apifyActor: {
    title: "Apify Actor",
    Icon: () => <img src={ApiFyLogo} alt="Apify" className="w-[26px]" />,
    Component: ApiFy,
  },
  webhook: {
    title: "Webhook",
    Icon: () => <Icons.WebhookIcon size={26} color="#1972f5" />,
    Component: () => <div>Webhook</div>,
  },
  persanaAgent2: {
    title: "Persana Agent 2.0",
    Component: PersanaAgent2,
    Icon: () => <Icons.AgentIcon size={26} color="#0284fe" />,
  },
  jobOpenings: {
    title: "Job Openings",
    Component: JobOpenings,
    Icon: () => <Icons.UserListIcon size={26} color="#27AE60" />,
  },
  addLeadToSmartleadsCampaign: {
    title: "Add Lead to Smartleads Campaign",
    Component: LeadToSmartLeads,
    Icon: () => <img src={Campaign} alt="Campaign" />,
  },
  useAi: {
    title: "Use AI (ChatGPT + Claude)",
    Component: UseAi,
    Icon: () => <img src={GPT} alt="GPT" />,
  },
  askAQuestion: {
    title: "Persana Company AI Agent",
    Icon: () => <Icons.AgentIcon size={26} color="#ffef00" />,
    Component: AskAQuestionAiAgent,
  },
  findPeopleAtCompany: {
    title: "Find People At Company",
    Icon: () => <Icons.PeopleIcon size={26} color="#C04000" />,
    Component: FindPeopleAtCompany,
  },
  linkedInDetails: {
    title: "Person Profile Details",
    Icon: () => <Icons.LinkedInIcon size={26} color="#2F80ED" />,
    Component: LinkedInProfileDetails,
  },
  findLinkedInUrl: {
    title: "Find Linkedin Url",
    Icon: () => <Icons.LinkedInIcon size={26} color="#2F80ED" />,
    Component: FindLinkedInUrl,
  },
  companyTechnologies: {
    title: "Company Technologies",
    Icon: () => <Icons.QRCodeIcon size={26} />,
    Component: CompanyTechnologies,
  },
  companyNews: {
    title: "Company News",
    Icon: () => <img src={News} alt="News" />,
    Component: CompanyNews,
  },
  companyDetails: {
    title: "Enrich Company Details",
    Icon: () => <Icons.CompanyDetailsIcon size={26} color="#E2B93B" />,
    Component: CompanyDetails,
  },
  verifyEmail: {
    title: "Email Verification and Bounce Rates",
    Icon: () => <img src={Verify} alt="Verify" />,
    Component: VerifyEmail,
  },
  findWorkEmail: {
    title: "Find Work Email",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  googleSearch: {
    title: "Google Search",
    Icon: () => <Icons.GoogleIcon size={26} />,
    Component: GoogleSearch,
  },
  scrapeWebsite: {
    title: "Scrape Website",
    Icon: () => <Icons.WebsiteIcon size={26} color="#27AE60" />,
    Component: ScrapeWebsite,
  },
  generateSalesEmails: {
    title: "Generate Sales Emails",
    Icon: () => <Icons.CampaignIcon size={26} color="#1972f5" />,
    Component: GenerateSalesEmails,
  },
  httpApi: {
    title: "HTTP API",
    Icon: () => <Icons.HttpIcon size={26} color="#1972f5" />,
    Component: () => <HttpApi />,
  },
  importData: {
    title: "Import Data",
    Icon: () => <Icons.ImportIcon size={26} color="#1972f5" />,
    Component: ImportData,
  },
};
