import MakeAPICall from "@/lib/apiCalls";

class OAuthService {
  constructor(private apiCaller = MakeAPICall) {}

  async generateAccessToken(code: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/oauth/github`,
      params: {
        code,
      },
    });
    const response = await apiCall.get();
    return response;
  }
}

const oAuthService = new OAuthService();
export default oAuthService;
