import {
  CiImport,
  CiExport,
  CiSearch,
  CiCalendar,
  CiImageOn,
  CiLinkedin,
  CiViewTable,
  CiSquarePlus,
  CiCircleChevLeft,
  CiCircleChevRight,
} from "react-icons/ci";
import {
  MdOutlineNaturePeople,
  MdAlternateEmail,
  MdDelete,
  MdOutlineMailOutline,
  MdOutlineWebhook,
  MdNumbers,
  MdOutlineLayers,
  MdOutlineSelectAll,
  MdAssuredWorkload,
  MdWebhook,
  MdOutlineAdd,
  MdOutlineSearch,
  MdSubtitles,
  MdLocationOn,
  MdFileUpload,
  MdOutlineAccountTree,
  MdOutlineAdminPanelSettings,
  MdDone,
  MdOutlinePeopleAlt,
  MdAutoFixNormal,
  MdSavedSearch,
  MdAdd,
} from "react-icons/md";
import { SiAiohttp } from "react-icons/si";
import {
  BsThreeDots,
  BsPinAngle,
  BsCheck2,
  BsStars,
  BsPersonFillAdd,
  BsBriefcaseFill,
  BsListColumnsReverse,
  BsFillSendFill,
} from "react-icons/bs";
import {
  FaDownload,
  FaRegCirclePlay,
  FaRegCirclePause,
  FaLocationDot,
  FaIndustry,
  FaArrowLeftLong,
  FaCirclePlay,
  FaCheck,
  FaRobot,
  FaLightbulb,
  FaUserSecret,
} from "react-icons/fa6";
import {
  IoCopyOutline,
  IoArrowBackSharp,
  IoSettingsOutline,
  IoAddOutline,
  IoChevronDownCircleOutline,
  IoShareSocial,
  IoExtensionPuzzleOutline,
  IoWarningSharp,
} from "react-icons/io5";
import {
  FaQuestion,
  FaLinkedin,
  FaMailBulk,
  FaGithubSquare,
  FaHubspot,
  FaCoins,
  FaPeopleArrows,
  FaGoogle,
  FaFileCsv,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { CgWebsite, CgUserList } from "react-icons/cg";
import {
  IoIosPeople,
  IoIosLink,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosAdd,
  IoIosArrowUp,
  IoIosCloseCircleOutline,
  IoIosArrowDropleftCircle,
  IoIosMailOpen,
  IoMdSave,
  IoIosRefresh,
  IoIosCloseCircle,
  IoMdInformationCircleOutline,
  IoIosArrowDropdownCircle,
} from "react-icons/io";
import { BiBook, BiSolidDetail, BiSortAlt2 } from "react-icons/bi";
import { LuQrCode, LuEye, LuEyeOff } from "react-icons/lu";
import { FcGoogle } from "react-icons/fc";
import { SiGoogleads } from "react-icons/si";
import { RxCross2, RxDashboard } from "react-icons/rx";
import {
  FiCheckCircle,
  FiArrowLeft,
  FiHelpCircle,
  FiChevronDown,
  FiChevronRight,
  FiChevronLeft,
  FiRefreshCcw,
  FiEdit,
} from "react-icons/fi";
import { TfiText } from "react-icons/tfi";
import {
  PiFunctionBold,
  PiArrowBendUpLeftBold,
  PiArrowBendUpRightBold,
  PiCrown,
  PiTrafficSignalBold,
} from "react-icons/pi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import {
  HiDotsHorizontal,
  HiOfficeBuilding,
  HiOutlineFilter,
  HiOutlinePhone,
  HiTrendingUp,
  HiX,
} from "react-icons/hi";
import { HiOutlineQueueList } from "react-icons/hi2";
import {
  RiFullscreenFill,
  RiDeleteBin6Line,
  RiLogoutBoxRLine,
} from "react-icons/ri";
import { TiArrowDownThick, TiFlowSwitch } from "react-icons/ti";
import { LiaFileImportSolid } from "react-icons/lia";
import { AiOutlineFileSearch } from "react-icons/ai";
import { HiArrowsUpDown } from "react-icons/hi2";
import { GiProcessor } from "react-icons/gi";

const Icons = {
  ImportIcon: CiImport,
  ExportIcon: CiExport,
  FindPeopleIcon: MdOutlineNaturePeople,
  FindWorkEmailIcon: MdAlternateEmail,
  MoreIcon: BsThreeDots,
  DownloadIcon: FaDownload,
  CopyIcon: IoCopyOutline,
  DeleteIcon: MdDelete,
  BackIcon: IoArrowBackSharp,
  SearchIcon: CiSearch,
  MailIcon: MdOutlineMailOutline,
  QuestionIcon: FaQuestion,
  LinkedInIcon: FaLinkedin,
  CampaignIcon: FaMailBulk,
  WebsiteIcon: CgWebsite,
  UserListIcon: CgUserList,
  PeopleIcon: IoIosPeople,
  CompanyDetailsIcon: BiSolidDetail,
  QRCodeIcon: LuQrCode,
  GoogleIcon: FcGoogle,
  GoogleAdsIcon: SiGoogleads,
  CloseIcon: RxCross2,
  MdFileUpload,
  PlayIcon: FaRegCirclePlay,
  GithubIcon: FaGithubSquare,
  LinkIcon: IoIosLink,
  WebhookIcon: MdOutlineWebhook,
  ArrowDownIcon: IoIosArrowDown,
  ArrowForwardIcon: IoIosArrowForward,
  CheckCircleIcon: FiCheckCircle,
  AddIcon: IoIosAdd,
  AddIcon2: MdAdd,
  CalendarIcon: CiCalendar,
  ImageIcon: CiImageOn,
  NumbersIcon: MdNumbers,
  TextIcon: TfiText,
  LayersIcon: MdOutlineLayers,
  FunctionIcon: PiFunctionBold,
  LeftArrowIcon: BsArrowLeft,
  LongLeftArrow: FaArrowLeftLong,
  RightArrowIcon: BsArrowRight,
  SortAscIcon: PiArrowBendUpLeftBold,
  SortDescIcon: PiArrowBendUpRightBold,
  FilterIcon: HiOutlineFilter,
  PinIcon: BsPinAngle,
  FullscreenIcon: RiFullscreenFill,
  ArrowUpIcon: IoIosArrowUp,
  AgentIcon: FaUserSecret,
  ThickArrowDown: TiArrowDownThick,
  SelectAllIcon: MdOutlineSelectAll,
  ArrowLeftIcon: FiArrowLeft,
  DeleteBinIcon: RiDeleteBin6Line,
  PauseIcon: FaRegCirclePause,
  CloseCircleIcon: IoIosCloseCircleOutline,
  HiXIcon: HiX,
  HubspotIcon: FaHubspot,
  DashboardIcon: RxDashboard,
  SettingsIcon: IoSettingsOutline,
  LinkedinIcon: CiLinkedin,
  QuickStartGuideIcon: BiBook,
  CoinsIcon: FaCoins,
  CheckIcon: BsCheck2,
  FillCheckIcon: FaCheck,
  MenuIcon: HiDotsHorizontal,
  CrownIcon: PiCrown,
  PhoneIcon: HiOutlinePhone,
  HelpIcon: FiHelpCircle,
  LogoutIcon: RiLogoutBoxRLine,
  PeopleArrowIcon: FaPeopleArrows,
  WorkloadIcon: MdAssuredWorkload,
  FileImportIcon: LiaFileImportSolid,
  WebhookIconMd: MdWebhook,
  StarsIcon: BsStars,
  StarIcon: FaStar,
  Processor: GiProcessor,
  OutlineAddIcon: MdOutlineAdd,
  GoogleIconFa: FaGoogle,
  MailOpenIcon: IoIosMailOpen,
  ChevronDownIcon: FiChevronDown,
  ChevronRightIcon: FiChevronRight,
  ChevronLeftIcon: FiChevronLeft,
  FillCloseCircleIcon: IoIosCloseCircle,
  PersonAddIcon: BsPersonFillAdd,
  LocationIcon: FaLocationDot,
  OutlineSearchIcon: MdOutlineSearch,
  SubtitleIcon: MdSubtitles,
  OfficeBuildingIcon: HiOfficeBuilding,
  SaveIcon: IoMdSave,
  FileSearchIcon: AiOutlineFileSearch,
  FaceIcon: BsBriefcaseFill,
  LocationOnIcon: MdLocationOn,
  IndustryIcon: FaIndustry,
  FileUploadIcon: MdFileUpload,
  ArrowUpDownIcon: HiArrowsUpDown,
  AddOutlineIcon: IoAddOutline,
  ViewTableIcon: CiViewTable,
  SquarePlusIcon: CiSquarePlus,
  CircleArrowIcon: IoIosArrowDropdownCircle,
  LeftCircleArrow: IoIosArrowDropleftCircle,
  CSVIcon: FaFileCsv,
  DownCircleIcon: IoChevronDownCircleOutline,
  QueueListIcon: HiOutlineQueueList,
  CirclePlayIcon: FaCirclePlay,
  RefreshIcon: IoIosRefresh,
  RefreshFailedIcon: FiRefreshCcw,
  ShareIcon: IoShareSocial,
  AccountMonitoringIcon: MdOutlineAccountTree,
  CarouselLeftIcon: CiCircleChevLeft,
  CarouselRightIcon: CiCircleChevRight,
  ExtensionIcon: IoExtensionPuzzleOutline,
  AdminPanelSettingsIcon: MdOutlineAdminPanelSettings,
  TrashIcon: FaTrash,
  DoneIcon: MdDone,
  EditIcon: FiEdit,
  WarningIcon: IoWarningSharp,
  RobotIcon: FaRobot,
  InformationIcon: IoMdInformationCircleOutline,
  ColumnIcon: BsListColumnsReverse,
  OpenEyeIcon: LuEye,
  CloseEyeIcon: LuEyeOff,
  HttpIcon: SiAiohttp,
  TeamIcon: MdOutlinePeopleAlt,
  FormalizeIcon: MdAutoFixNormal,
  SwitchIcon: TiFlowSwitch,
  TrendIcon: HiTrendingUp,
  SignalIcon: PiTrafficSignalBold,
  SearchCompanyIcon: MdSavedSearch,
  SearchBarButton: BsFillSendFill,
  BulbIcon: FaLightbulb,
  SortIcon: BiSortAlt2,
};

export default Icons;
