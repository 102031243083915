import LottiePlayer from "./LottiePlayer";
import SearchPeopleLottie from "@/assets/animated/searchPeople.lottie";

const ResultScreenLoader = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center">
        <LottiePlayer src={SearchPeopleLottie} style={{ height: "auto", width: "300px" }} />
        <p className="font-title text-lg">
          Firing up 🔥 Persana's brain, Please Wait...
        </p>
      </div>
    </div>
  );
};

export default ResultScreenLoader;
