import { useState } from "react";
import { Button, Input, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import GptLogo from "@/assets/svgs/chatGpt.svg";
import providerService from "@/services/providers.service";
import { useProviderList } from "@/hooks/useProviderData";

const OpenAIIntegration = () => {
  const toast = useToast();
  const [openAIKeyValue, setOpenAIKeyValue] = useState("");
  const { data: providerList } = useProviderList();
  const openAiApiKey = providerList?.data?.find(
    (provider) => provider.name === "openAiApiKey",
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: { apiKey: string; providerId?: string , providerName : string }) =>
      providerService.saveKey(payload),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: response?.data?.message || "Key saved successfully.",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={GptLogo} alt={"OpenAI"} className="w-[2rem]" />
        <h2 className="font-semibold">Open AI</h2>
      </div>
      <div>
        <p className="mb-4">{`Add your Open AI key`}</p>
        <div className="flex gap-1 items-center">
          <Input
            size={"sm"}
            placeholder={openAiApiKey?.metaData?.accessToken || "********"}
            className="mr-5 border-2 border-black"
            onChange={(e) => setOpenAIKeyValue(e.target.value)}
            value={openAIKeyValue}
          />

          <Button
            colorScheme={"green"}
            variant="outline"
            size="sm"
            isLoading={isPending}
            onClick={() =>
              mutateAsync({
                apiKey: openAIKeyValue,
                providerId: openAiApiKey?._id || "",
                providerName: "openAiApiKey",
              })
            }
            isDisabled={!openAIKeyValue}
          >
            {openAiApiKey ? "Update" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OpenAIIntegration;
