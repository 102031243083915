import { useState } from "react";

import Icons from "../Icons";

interface Props {
  title: string;
  children: React.ReactNode;
  titleClassName?: string;
}

const CustomAccordion = ({ title, children, titleClassName = "" }: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="mt-4">
      <button
        className={`flex items-center space-x-1 w-full text-left rounded-md font-semibold ${titleClassName} text-[0.9rem]`}
        onClick={(e) => {
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}
        aria-expanded={accordionOpen}
        aria-controls={`accordion-text-01`}
      >
        <Icons.CircleArrowIcon className={`fill-primaryPink shrink-0 ttransform origin-center transition duration-200 ease-out text-base ${!accordionOpen ? "transform -rotate-90" : ""
          }`} />
        <span>{title}</span>
      </button>
      <div
        id={`accordion-text-01`}
        role="region"
        aria-labelledby={`accordion-title-01`}
        className={`${accordionOpen ? "block" : "hidden"
          } mt-2 text-sm text-gray-500`}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CustomAccordion;
