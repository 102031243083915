import Icons from "@/components/Icons";
import { RoutesType } from "@/types/routes.types";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    path: "/",
    icon: Icons.DashboardIcon,
    isHidden: false,
  },
  {
    name: "Quick Start Guide",
    path: "/quick-start-guide",
    icon: Icons.QuickStartGuideIcon,
    isHidden: false,
  },
  {
    name: "AI Signals",
    path: "/account-monitoring",
    icon: Icons.AccountMonitoringIcon,
    isHidden: false,
  },
  {
    name: "Chrome Extension",
    path: "/linkedin-profiles",
    icon: Icons.ExtensionIcon,
    isHidden: false,
  },
  {
    name: "Settings",
    path: "/settings",
    icon: Icons.SettingsIcon,
    isHidden: false,
  },
  {
    name: "Team Overview",
    path: "/team-overview",
    icon: Icons.TeamIcon,
    isHidden: false,
  },
  {
    name: "Admin",
    path: "/padmin",
    icon: Icons.AdminPanelSettingsIcon,
    isHidden: false,
    isForAdmin: true,
  },
];

export default routes;
