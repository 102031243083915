import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";
import {
  ActiveJobsType,
  HiringIntentDetailsType,
  JobChangesType,
} from "@/types/accountMonitoring.types";

type Props = {
  showFilters: boolean;
  filter?: any;
  tableSearchQuery: string;
  filterTabs?: { label: string; value: string }[];
  filteredData: HiringIntentDetailsType[] | JobChangesType[] | ActiveJobsType[];
  isPending: boolean;
  handleTableSearch: (val: string) => void;
  handleFilterChange?: (val: string) => void;
  handleExport: () => void;
  handleCreate: () => void;
};

const AccountMonitoringActions = ({
  showFilters,
  tableSearchQuery,
  filterTabs,
  filteredData,
  isPending,
  filter,
  handleExport,
  handleTableSearch,
  handleFilterChange,
  handleCreate,
}: Props) => {
  return (
    <div className="flex items-center justify-end">
      <div className="flex gap-2 items-center">
        <div className="searching w-[250px]">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icons.SearchIcon color="black.300" />
            </InputLeftElement>
            <Input
              size={"md"}
              placeholder="Search"
              onChange={(e) => handleTableSearch(e.target.value)}
              value={tableSearchQuery}
            />
          </InputGroup>
        </div>
        {showFilters && (
          <Select
            // @ts-ignore
            value={filter.value}
            width={"fit-content"}
            onChange={(e) => {
              handleFilterChange && handleFilterChange(e.target.value);
            }}
          >
            {filterTabs?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        )}

        <Button
          className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !bg-gray !text-sm hover:!shadow-md"
          leftIcon={<Icons.ExportIcon />}
          onClick={() => handleExport()}
          isDisabled={filteredData?.length === 0}
        >
          Export Data
        </Button>
        <Button
          className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !bg-gray !text-sm hover:!shadow-md"
          onClick={() => handleCreate()}
          isDisabled={filteredData?.length === 0}
          isLoading={isPending}
        >
          Create New Table
        </Button>
      </div>
    </div>
  );
};

export default AccountMonitoringActions;
