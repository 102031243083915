import Icons from "@/components/Icons";
import { Button } from "@chakra-ui/react";

import { PricingType } from "@/types/pricing.types";
interface Props {
  pricing: PricingType;
  isLoading?: boolean;
  onClick: (priceId: string) => void;
};

export const PriceBox = ({
  pricing,
  isLoading = false,
  onClick,
}: Props) => {


  return (
    <div className={`rounded-[2rem] p-8 font-title h-full flex flex-col justify-between`} style={{
      backgroundColor: "rgba(255,255,255,0.5)",
      border: pricing.mostPopular ? "2px solid #693DC7" : "none"
    }}>
      <div>
        <div className="h-[7.3rem]">
          <div className="pb-4">
            <h2 className="font-bold text-[1.35rem] flex items-center gap-x-2">{pricing.title} {
              pricing.mostPopular && <Icons.StarIcon className="w-5 h-5 text-[#693DC7]" />
            }</h2>
            {/* <p className="text-sm">{pricing.description}</p> */}
          </div>
          <div className="font-medium space-y-1">
            {
              typeof pricing.price === "number" ? <p className="font-bold">${pricing.price.toLocaleString("en-US")}/{pricing.frequency}</p> : <p className="font-bold">{pricing.price}</p>
            }
            {/* <span className="discounted">${pricing.priceBeforeDiscount}</span> now  */}
            <p className="text-[#7a7a7a] text-sm">{
              typeof pricing.price === "number" ? `${pricing.credits} credits with unlimited users` : pricing.description
            }</p>

          </div>
        </div>

        <div className="w-full h-[1.5px] bg-[#7a7a7a] rounded-full opacity-50 my-5"></div>

        <div className="space-y-3">
          {
            pricing?.features?.map((feature, index) => (
              <p key={`pricing-feature-${index}`} className="font-medium flex">
                <span className="w-5 h-5 mr-2 rounded-full flex justify-center items-center flex-shrink-0" style={{
                  background:
                    "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                }}>
                  <Icons.FillCheckIcon className="w-3 h-3 flex-shrink-0 text-white" />
                </span>
                <span className="text-[14.5px]">{feature}</span>
              </p>
            ))
          }
        </div>
      </div>

      <div className="flex justify-start pt-10">
        <Button
          className="!w-fit !rounded-full !px-7 !py-1.5 !font-semibold !text-[#fff] !font-title"
          variant={"outline"}
          style={{
            background:
              "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
          }}
          isLoading={isLoading}
          onClick={
            () => {
              onClick?.(pricing?.priceId || "");
            }
          }
        >
          {
            pricing.action === "upgrade" ? "Upgrade now" : "Contact us"
          }
        </Button>
      </div>
    </div>
  );
};
