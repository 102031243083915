export const iScraperCompanyTypes = [
  {
    name: "privately held",
    doc_count: 6894212,
  },
  {
    name: "sole proprietorship",
    doc_count: 2007720,
  },
  {
    name: "self-employed",
    doc_count: 1571950,
  },
  {
    name: "partnership",
    doc_count: 1447785,
  },
  {
    name: "public company",
    doc_count: 1354855,
  },
  {
    name: "nonprofit",
    doc_count: 1045022,
  },
  {
    name: "educational institution",
    doc_count: 481679,
  },
  {
    name: "government agency",
    doc_count: 101952,
  },
];

export const staticCountryWithCodes = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo (the Democratic Republic of the)", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands [Malvinas]", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran (Islamic Republic of)", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea (the Democratic People's Republic of)", code: "KP" },
  { name: "Korea (the Republic of)", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia (Federated States of)", code: "FM" },
  { name: "Moldova (the Republic of)", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "North Macedonia", code: "MK" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Réunion", code: "RE" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan (Province of China)", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom of Great Britain and Northern Ireland", code: "GB" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "United States of America", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands (British)", code: "VG" },
  { name: "Virgin Islands (U.S.)", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const iScraperIndustriesList = [
  {
    name: "real estate",
    doc_count: 2998658,
  },
  {
    name: "medical practice",
    doc_count: 2756171,
  },
  {
    name: "construction",
    doc_count: 2550355,
  },
  {
    name: "retail",
    doc_count: 2406583,
  },
  {
    name: "management consulting",
    doc_count: 1676103,
  },
  {
    name: "individual & family services",
    doc_count: 1667149,
  },
  {
    name: "information technology & services",
    doc_count: 1606821,
  },
  {
    name: "computer software",
    doc_count: 1565861,
  },
  {
    name: "restaurants",
    doc_count: 1546577,
  },
  {
    name: "marketing & advertising",
    doc_count: 1123864,
  },
  {
    name: "hospital & health care",
    doc_count: 1042664,
  },
  {
    name: "wholesale",
    doc_count: 881433,
  },
  {
    name: "civic & social organization",
    doc_count: 833694,
  },
  {
    name: "health, wellness & fitness",
    doc_count: 767028,
  },
  {
    name: "financial services",
    doc_count: 756613,
  },
  {
    name: "hospitality",
    doc_count: 699597,
  },
  {
    name: "facilities services",
    doc_count: 573044,
  },
  {
    name: "electrical & electronic manufacturing",
    doc_count: 567327,
  },
  {
    name: "consumer goods",
    doc_count: 549639,
  },
  {
    name: "transportation/trucking/railroad",
    doc_count: 542888,
  },
  {
    name: "consumer services",
    doc_count: 540610,
  },
  {
    name: "machinery",
    doc_count: 530582,
  },
  {
    name: "food & beverages",
    doc_count: 511514,
  },
  {
    name: "internet",
    doc_count: 501033,
  },
  {
    name: "automotive",
    doc_count: 478519,
  },
  {
    name: "non-profit organization management",
    doc_count: 471672,
  },
  {
    name: "sports",
    doc_count: 471167,
  },
  {
    name: "mining & metals",
    doc_count: 445542,
  },
  {
    name: "farming",
    doc_count: 435589,
  },
  {
    name: "accounting",
    doc_count: 430669,
  },
  {
    name: "food production",
    doc_count: 417252,
  },
  {
    name: "architecture & planning",
    doc_count: 414418,
  },
  {
    name: "education management",
    doc_count: 394806,
  },
  {
    name: "insurance",
    doc_count: 381272,
  },
  {
    name: "mechanical or industrial engineering",
    doc_count: 375404,
  },
  {
    name: "apparel & fashion",
    doc_count: 370153,
  },
  {
    name: "design",
    doc_count: 368351,
  },
  {
    name: "legal services",
    doc_count: 345054,
  },
  {
    name: "performing arts",
    doc_count: 340286,
  },
  {
    name: "business supplies & equipment",
    doc_count: 332316,
  },
  {
    name: "higher education",
    doc_count: 327439,
  },
  {
    name: "professional training & coaching",
    doc_count: 326525,
  },
  {
    name: "leisure, travel & tourism",
    doc_count: 317022,
  },
  {
    name: "research",
    doc_count: 292179,
  },
  {
    name: "entertainment",
    doc_count: 290120,
  },
  {
    name: "e-learning",
    doc_count: 283815,
  },
  {
    name: "religious institutions",
    doc_count: 272423,
  },
  {
    name: "law practice",
    doc_count: 268260,
  },
  {
    name: "publishing",
    doc_count: 267958,
  },
  {
    name: "environmental services",
    doc_count: 245464,
  },
  {
    name: "investment management",
    doc_count: 240999,
  },
  {
    name: "civil engineering",
    doc_count: 239767,
  },
  {
    name: "government administration",
    doc_count: 237699,
  },
  {
    name: "building materials",
    doc_count: 233833,
  },
  {
    name: "human resources",
    doc_count: 231938,
  },
  {
    name: "media production",
    doc_count: 231410,
  },
  {
    name: "events services",
    doc_count: 229823,
  },
  {
    name: "recreational facilities & services",
    doc_count: 226096,
  },
  {
    name: "telecommunications",
    doc_count: 224759,
  },
  {
    name: "furniture",
    doc_count: 218115,
  },
  {
    name: "package/freight delivery",
    doc_count: 212124,
  },
  {
    name: "staffing & recruiting",
    doc_count: 210638,
  },
  {
    name: "utilities",
    doc_count: 206409,
  },
  {
    name: "photography",
    doc_count: 204446,
  },
  {
    name: "supermarkets",
    doc_count: 194294,
  },
  {
    name: "textiles",
    doc_count: 194191,
  },
  {
    name: "primary/secondary education",
    doc_count: 192409,
  },
  {
    name: "medical device",
    doc_count: 183308,
  },
  {
    name: "oil & energy",
    doc_count: 178104,
  },
  {
    name: "paper & forest products",
    doc_count: 175760,
  },
  {
    name: "chemicals",
    doc_count: 174162,
  },
  {
    name: "plastics",
    doc_count: 167610,
  },
  {
    name: "cosmetics",
    doc_count: 167233,
  },
  {
    name: "motion pictures & film",
    doc_count: 162270,
  },
  {
    name: "veterinary",
    doc_count: 161491,
  },
  {
    name: "music",
    doc_count: 158219,
  },
  {
    name: "broadcast media",
    doc_count: 142284,
  },
  {
    name: "graphic design",
    doc_count: 140992,
  },
  {
    name: "ranching",
    doc_count: 136217,
  },
  {
    name: "banking",
    doc_count: 135711,
  },
  {
    name: "renewables & environment",
    doc_count: 129999,
  },
  {
    name: "printing",
    doc_count: 129748,
  },
  {
    name: "security & investigations",
    doc_count: 128930,
  },
  {
    name: "online media",
    doc_count: 123751,
  },
  {
    name: "logistics & supply chain",
    doc_count: 121615,
  },
  {
    name: "public relations & communications",
    doc_count: 120358,
  },
  {
    name: "information services",
    doc_count: 120281,
  },
  {
    name: "consumer electronics",
    doc_count: 117424,
  },
  {
    name: "pharmaceuticals",
    doc_count: 104434,
  },
  {
    name: "luxury goods & jewelry",
    doc_count: 100678,
  },
  {
    name: "international trade & development",
    doc_count: 98788,
  },
  {
    name: "mental health care",
    doc_count: 97047,
  },
  {
    name: "arts & crafts",
    doc_count: 91493,
  },
  {
    name: "investment banking",
    doc_count: 86647,
  },
  {
    name: "museums & institutions",
    doc_count: 83661,
  },
  {
    name: "biotechnology",
    doc_count: 79343,
  },
  {
    name: "fine art",
    doc_count: 79054,
  },
  {
    name: "writing & editing",
    doc_count: 77603,
  },
  {
    name: "industrial automation",
    doc_count: 75700,
  },
  {
    name: "airlines/aviation",
    doc_count: 74738,
  },
  {
    name: "executive office",
    doc_count: 73990,
  },
  {
    name: "wine & spirits",
    doc_count: 70903,
  },
  {
    name: "commercial real estate",
    doc_count: 65156,
  },
  {
    name: "glass, ceramics & concrete",
    doc_count: 64235,
  },
  {
    name: "import & export",
    doc_count: 62507,
  },
  {
    name: "venture capital & private equity",
    doc_count: 55842,
  },
  {
    name: "computer & network security",
    doc_count: 54646,
  },
  {
    name: "computer games",
    doc_count: 54232,
  },
  {
    name: "outsourcing/offshoring",
    doc_count: 54172,
  },
  {
    name: "packaging & containers",
    doc_count: 50395,
  },
  {
    name: "semiconductors",
    doc_count: 50319,
  },
  {
    name: "defense & space",
    doc_count: 50296,
  },
  {
    name: "maritime",
    doc_count: 46718,
  },
  {
    name: "dairy",
    doc_count: 42666,
  },
  {
    name: "aviation & aerospace",
    doc_count: 41251,
  },
  {
    name: "computer hardware",
    doc_count: 40155,
  },
  {
    name: "sporting goods",
    doc_count: 39606,
  },
  {
    name: "warehousing",
    doc_count: 38838,
  },
  {
    name: "capital markets",
    doc_count: 38136,
  },
  {
    name: "market research",
    doc_count: 36315,
  },
  {
    name: "newspapers",
    doc_count: 33744,
  },
  {
    name: "translation & localization",
    doc_count: 33524,
  },
  {
    name: "fishery",
    doc_count: 32477,
  },
  {
    name: "alternative medicine",
    doc_count: 32424,
  },
  {
    name: "public safety",
    doc_count: 30038,
  },
  {
    name: "political organization",
    doc_count: 27219,
  },
  {
    name: "philanthropy",
    doc_count: 26942,
  },
  {
    name: "program development",
    doc_count: 26450,
  },
  {
    name: "computer networking",
    doc_count: 25845,
  },
  {
    name: "think tanks",
    doc_count: 25724,
  },
  {
    name: "shipbuilding",
    doc_count: 25244,
  },
  {
    name: "law enforcement",
    doc_count: 24831,
  },
  {
    name: "animation",
    doc_count: 22419,
  },
  {
    name: "fundraising",
    doc_count: 22229,
  },
  {
    name: "government relations",
    doc_count: 20575,
  },
  {
    name: "tobacco",
    doc_count: 20249,
  },
  {
    name: "international affairs",
    doc_count: 17532,
  },
  {
    name: "gambling & casinos",
    doc_count: 17268,
  },
  {
    name: "libraries",
    doc_count: 14591,
  },
  {
    name: "judiciary",
    doc_count: 13538,
  },
  {
    name: "railroad manufacture",
    doc_count: 12855,
  },
  {
    name: "software development",
    doc_count: 12236,
  },
  {
    name: "public policy",
    doc_count: 12219,
  },
  {
    name: "wireless",
    doc_count: 12063,
  },
  {
    name: "military",
    doc_count: 9983,
  },
  {
    name: "it services and it consulting",
    doc_count: 7840,
  },
  {
    name: "alternative dispute resolution",
    doc_count: 7619,
  },
  {
    name: "business consulting and services",
    doc_count: 5961,
  },
  {
    name: "nanotechnology",
    doc_count: 5673,
  },
  {
    name: "advertising services",
    doc_count: 5618,
  },
  {
    name: "legislative office",
    doc_count: 3986,
  },
  {
    name: "non-profit organizations",
    doc_count: 2419,
  },
  {
    name: "staffing and recruiting",
    doc_count: 2156,
  },
  {
    name: "hospitals and health care",
    doc_count: 1916,
  },
  {
    name: "mobile games",
    doc_count: 1484,
  },
  {
    name: "professional training and coaching",
    doc_count: 1442,
  },
  {
    name: "truck transportation",
    doc_count: 1160,
  },
  {
    name: "retail apparel and fashion",
    doc_count: 1110,
  },
  {
    name: "wellness and fitness services",
    doc_count: 1029,
  },
  {
    name: "technology, information and internet",
    doc_count: 1000,
  },
  {
    name: "venture capital and private equity principals",
    doc_count: 943,
  },
  {
    name: "human resources services",
    doc_count: 858,
  },
  {
    name: "manufacturing",
    doc_count: 841,
  },
  {
    name: "design services",
    doc_count: 811,
  },
  {
    name: "renewable energy semiconductor manufacturing",
    doc_count: 731,
  },
  {
    name: "spectator sports",
    doc_count: 703,
  },
  {
    name: "architecture and planning",
    doc_count: 689,
  },
  {
    name: "food and beverage services",
    doc_count: 679,
  },
  {
    name: "biotechnology research",
    doc_count: 674,
  },
  {
    name: "industrial machinery manufacturing",
    doc_count: 657,
  },
  {
    name: "food and beverage manufacturing",
    doc_count: 653,
  },
  {
    name: "motor vehicle manufacturing",
    doc_count: 650,
  },
  {
    name: "entertainment providers",
    doc_count: 534,
  },
  {
    name: "oil and gas",
    doc_count: 533,
  },
  {
    name: "travel arrangements",
    doc_count: 511,
  },
  {
    name: "research services",
    doc_count: 508,
  },
  {
    name: "international trade and development",
    doc_count: 465,
  },
  {
    name: "public relations and communications services",
    doc_count: 442,
  },
  {
    name: "medical equipment manufacturing",
    doc_count: 418,
  },
  {
    name: "online audio and video media",
    doc_count: 415,
  },
  {
    name: "book and periodical publishing",
    doc_count: 409,
  },
  {
    name: "appliances, electrical, and electronics manufacturing",
    doc_count: 405,
  },
  {
    name: "computer and network security",
    doc_count: 392,
  },
  {
    name: "education administration programs",
    doc_count: 364,
  },
  {
    name: "wholesale building materials",
    doc_count: 354,
  },
  {
    name: "musicians",
    doc_count: 348,
  },
  {
    name: "machinery manufacturing",
    doc_count: 347,
  },
  {
    name: "civic and social organizations",
    doc_count: 322,
  },
  {
    name: "e-learning providers",
    doc_count: 319,
  },
  {
    name: "transportation, logistics, supply chain and storage",
    doc_count: 305,
  },
  {
    name: "retail luxury goods and jewelry",
    doc_count: 294,
  },
  {
    name: "furniture and home furnishings manufacturing",
    doc_count: 293,
  },
  {
    name: "broadcast media production and distribution",
    doc_count: 277,
  },
  {
    name: "pharmaceutical manufacturing",
    doc_count: 271,
  },
  {
    name: "chemical manufacturing",
    doc_count: 246,
  },
  {
    name: "primary and secondary education",
    doc_count: 239,
  },
  {
    name: "security and investigations",
    doc_count: 229,
  },
  {
    name: "artists and writers",
    doc_count: 213,
  },
  {
    name: "beverage manufacturing",
    doc_count: 200,
  },
  {
    name: "mining",
    doc_count: 194,
  },
  {
    name: "outsourcing and offshoring consulting",
    doc_count: 187,
  },
  {
    name: "personal care product manufacturing",
    doc_count: 178,
  },
  {
    name: "automation machinery manufacturing",
    doc_count: 174,
  },
  {
    name: "individual and family services",
    doc_count: 173,
  },
  {
    name: "packaging and containers manufacturing",
    doc_count: 170,
  },
  {
    name: "textile manufacturing",
    doc_count: 170,
  },
  {
    name: "medical practices",
    doc_count: 169,
  },
  {
    name: "airlines and aviation",
    doc_count: 164,
  },
  {
    name: "defense and space manufacturing",
    doc_count: 156,
  },
  {
    name: "printing services",
    doc_count: 149,
  },
  {
    name: "aviation and aerospace component manufacturing",
    doc_count: 140,
  },
  {
    name: "writing and editing",
    doc_count: 128,
  },
  {
    name: "plastics manufacturing",
    doc_count: 123,
  },
  {
    name: "computers and electronics manufacturing",
    doc_count: 103,
  },
  {
    name: "translation and localization",
    doc_count: 99,
  },
  {
    name: "paper and forest product manufacturing",
    doc_count: 96,
  },
  {
    name: "animation and post-production",
    doc_count: 94,
  },
  {
    name: "retail office equipment",
    doc_count: 84,
  },
  {
    name: "government relations services",
    doc_count: 73,
  },
  {
    name: "political organizations",
    doc_count: 66,
  },
  {
    name: "freight and package transportation",
    doc_count: 65,
  },
  {
    name: "museums, historical sites, and zoos",
    doc_count: 61,
  },
  {
    name: "newspaper publishing",
    doc_count: 61,
  },
  {
    name: "recreational facilities",
    doc_count: 56,
  },
  {
    name: "semiconductor manufacturing",
    doc_count: 49,
  },
  {
    name: "gambling facilities and casinos",
    doc_count: 47,
  },
  {
    name: "glass, ceramics and concrete manufacturing",
    doc_count: 39,
  },
  {
    name: "computer hardware manufacturing",
    doc_count: 36,
  },
  {
    name: "armed forces",
    doc_count: 35,
  },
  {
    name: "executive offices",
    doc_count: 29,
  },
  {
    name: "nanotechnology research",
    doc_count: 26,
  },
  {
    name: "administration of justice",
    doc_count: 20,
  },
  {
    name: "railroad equipment manufacturing",
    doc_count: 12,
  },
  {
    name: "fisheries",
    doc_count: 9,
  },
  {
    name: "maritime transportation",
    doc_count: 9,
  },
  {
    name: "tobacco manufacturing",
    doc_count: 8,
  },
  {
    name: "leasing non-residential real estate",
    doc_count: 6,
  },
  {
    name: "movies, videos, and sound",
    doc_count: 5,
  },
  {
    name: "philanthropic fundraising services",
    doc_count: 5,
  },
  {
    name: "sporting goods manufacturing",
    doc_count: 5,
  },
  {
    name: "public policy offices",
    doc_count: 4,
  },
  {
    name: "retail groceries",
    doc_count: 4,
  },
  {
    name: "wholesale import and export",
    doc_count: 4,
  },
  {
    name: "retail art supplies",
    doc_count: 3,
  },
  {
    name: "warehousing and storage",
    doc_count: 3,
  },
  {
    name: "computer networking products",
    doc_count: 2,
  },
  {
    name: "dairy product manufacturing",
    doc_count: 2,
  },
  {
    name: "legislative offices",
    doc_count: 2,
  },
  {
    name: "strategic management services",
    doc_count: 2,
  },
  {
    name: "wireless services",
    doc_count: 2,
  },
  {
    name: "mobile gaming apps",
    doc_count: 1,
  },
  {
    name: "veterinary services",
    doc_count: 1,
  },
];
