import { ColCondition, Condition } from "@/types/table.types";

export const conditions: Condition[] = [
  {
    name: "equal to",
    value: "equal_to",
    hasInput: true,
  },
  {
    name: "not equal to",
    value: "not_equal_to",
    hasInput: true,
  },
  {
    name: "contains",
    value: "contains",
    hasInput: true,
  },
  {
    name: "does not contain",
    value: "does_not_contain",
    hasInput: true,
  },
  {
    name: "is empty",
    value: "is_empty",
    hasInput: false,
  },
  {
    name: "is not empty",
    value: "is_not_empty",
    hasInput: false,
  },
];

export const enrichmentFilterOptions = [
  {
    name: "has results",
    value: "has_results",
  },
  {
    name: "has no results",
    value: "has_no_results",
  },
  // {
  //   name: "has an error",
  //   value: "has_an_error",
  // },
  // {
  //   name: "does not have an error",
  //   value: "does_not_have_an_error",
  // },
];

export const colConditions: ColCondition[] = [
  {
    name: "Or",
    value: "or",
    syntex: "||",
  },
  {
    name: "And",
    value: "and",
    syntex: "&&",
  },
];
