import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { REGEX, companyNewsTutorialLink } from "../../constants";

import type { TSelectedColumnOption } from "@/types/common.types";

const CompanyNews = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial
          subText="Get the latest news about any company"
          link={companyNewsTutorialLink}
        />
        <p className="pb-1 text-base font-semibold">Website URL</p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
        }}
      />
    </>
  );
};

export default CompanyNews;
