import { Box } from "@chakra-ui/react";
import { useModalStore } from "@/stores/modal.store";
import PersanaIcon from "@/assets/images/logo.png";

const PersanaProCard = () => {
  const updateState = useModalStore((state) => state.updateState);
  return (
    <Box className="flex flex-col items-center justify-center px-[30px] py-[50px] rounded-[20px] w-[240px] h-[200px] bg-primaryLightPink">
      <div className="mb-[16px] h-[48px] w-[48px]  rounded-[16px] bg-white p-3">
        <img src={PersanaIcon} alt="PersanaIcon" width={24} height={24} />
      </div>
      <div className="mb-[20px] flex flex-col items-center justify-center gap-[8px]">
        <p className="text-[18px] font-semibold text-black">
          Persana <span className="text-primaryPink">PRO</span>
        </p>
      </div>

      <div>
        <div
          onClick={() =>
            updateState({
              isOpenModal: true,
              modalData: { modalType: "pricing" },
            })
          }
          className="flex h-[40px] w-[140px] cursor-pointer items-center justify-center rounded-md bg-white hover:shadow border-textPurple border-[1px]"
        >
          <span className="text-[16px] font-semibold text-textPurple">
            Get PRO
          </span>
        </div>
      </div>
    </Box>
  );
};

export default PersanaProCard;
