import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";

const VersionSelect = () => {
  const gptVersion = useEnrichStore((state) => state.gptVersion);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <>
      <div className="mt-4 px-4">
        <RadioGroup
          onChange={(newValue: "gpt3.5" | "gpt4.0") => {
            updateState({
              gptVersion: newValue,
            });
          }}
          value={gptVersion}
        >
          <Stack direction="row">
            <Radio size="md" name="gpt3.5" value="gpt3.5" colorScheme="primary">
              Use GPT 3.5
            </Radio>
            <Radio size="md" name="gpt4.0" value="gpt4.0" colorScheme="primary">
              Use GPT 4.0
            </Radio>
          </Stack>
        </RadioGroup>
      </div>
    </>
  );
};

export default VersionSelect;
