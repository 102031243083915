import MakeAPICall from "@/lib/apiCalls";

import type {
  PresetEnrichmentType,
  PresetsResponseData,
} from "@/types/presets.types";

class PresetsService {
  private apiCall: MakeAPICall;

  constructor() {
    this.apiCall = new MakeAPICall({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/chatapipresets/all`,
    });
  }

  async fetchPresets(enrichmentType: PresetEnrichmentType) {
    try {
      const response = await this.apiCall.get<PresetsResponseData[]>({
        params: {
          enrichmentType: enrichmentType,
        },
      });
      if (response.success) {
        return {
          success: true,
          data: response.data,
        };
      } else {
        return {
          success: false,
          error: response.error,
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
      };
    }
  }

  async savePreset(payload: any) {
    try {
      const saveApiCall = new MakeAPICall({
        url: `${
          import.meta.env.VITE_OLD_BE_BASE_URL
        }/api/chatapipresets/saveCustomPreset`,
        payload: payload,
      });

      await saveApiCall.post({});

      return {
        success: true,
      };
    } catch (error: any) {
      return {
        success: false,
        error: error.message,
      };
    }
  }
}

const presetsService = new PresetsService();

export default presetsService;
