import { GetStarted, QuestProvider } from "@questlabs/react-sdk";

import { useModalStore } from "@/stores/modal.store";
import questConfig from "@/constants/questConfig";
import PersanaLogo from "@/assets/images/logo.png";
import ProspectIcon from "@/assets/svgs/sidebar-prospect-enrichment.svg";
import EmailIcon from "@/assets/svgs/sidebar-email-info.svg";
import AIIcon from "@/assets/svgs/sidebar-ai-enrichment.svg";
import GPTIcon from "@/assets/svgs/enrichment-gpt.svg";

import "@questlabs/react-sdk/dist/style.css";
import { useUserData } from "@/hooks/useUserData";

const QuickStartGuide = () => {
  const { updateState } = useModalStore((state) => state);
  const userData = useUserData();

  const onLinkTrigger = (e: string) => {
    let allTriggersSerials = [
      "extension",
      "setofleads",
      "workemail",
      "generatemessage",
      "aicompanyagent",
    ];
    if (e === allTriggersSerials[0]) {
      window.open(
        "https://www.google.com/url?q=https://chrome.google.com/webstore/detail/persana-ai-chatgpt-for-li/jdbdgcibmddkccanncenaahimbfcgglj&sa=D&source=docs&ust=1702141765964951&usg=AOvVaw14JP3i-1wfyBkNMATHv2Nq",
        "_blank",
      );
    } else if (e === allTriggersSerials[1]) {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "apollo-people-search",
        },
      });
    } else if (e === allTriggersSerials[2]) {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "tutorial",
          metaData: {
            videoLink:
              "https://www.youtube.com/embed/L39i0MyO2Io?si=tnoZo9mAmyNtzv_Q",
            name: "Find Work Emails",
          },
        },
      });
    } else if (e === allTriggersSerials[3]) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started" },
      });
    } else if (e === allTriggersSerials[4]) {
      updateState({
        isOpenModal: true,
        modalData: {
          modalType: "tutorial",
          metaData: {
            videoLink:
              "https://www.youtube.com/embed/KEBestxLkJQ?si=N4mnee4Vk--e3CqR",
            name: "Ask questions about a company",
          },
        },
      });
    }
  };

  return (
    <div className="quick-start-container mb-4">
      <QuestProvider
        apiKey={questConfig.API_KEY}
        apiSecret={questConfig.API_SECRET}
        entityId={questConfig.ENTITY_ID}
      >
        <GetStarted
          questId={questConfig.GET_STARTED_SCREEN_ID}
          userId={questConfig.ADMIN_ID}
          token={questConfig.ADMIN_TOKEN}
          // @ts-ignore
          iconUrls={[PersanaLogo, ProspectIcon, EmailIcon, AIIcon, GPTIcon]}
          cardBorderColor={"rgb(248, 240, 255)"}
          onLinkTrigger={onLinkTrigger}
          uniqueUserId={userData?.data?.Email}
          uniqueEmailId={userData?.data?.Email}
          headingText="Persana AI Quick Start"
          descriptionText="Follow our quick start guide below to use Persana"
          showFooter={false}
          showProgressBar
          allowMultiClick
          styleConfig={{
            Form: {
              background: "#fafafb",
              borderRadius: "20px",
            },
            Card: {
              background: "white",
            },
          }}
        />
      </QuestProvider>
    </div>
  );
};

export default QuickStartGuide;
