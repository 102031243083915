import Header from "@/components/TeamOverview/Header";

const TeamOverview = () => {
  return (
    <div className="mt-5 ml-2">
      <Header />
    </div>
  );
};

export default TeamOverview;
