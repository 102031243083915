import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

import type { TSelectedColumnOption } from "@/types/common.types";

type FilterType = "fullName" | "first&lastName";

const UserDetailsTab = ({
  selectedFullName,
  selectedCompanyName,
  selectedFirstName,
  selectedLastName,
  selectedNameType,
  setSelectedFullName,
  setSelectedCompanyName,
  setSelectedFirstName,
  setSelectedLastName,
  setSelectedNameType,
}: {
  selectedFullName: TSelectedColumnOption | null;
  selectedCompanyName: TSelectedColumnOption | null;
  selectedFirstName: TSelectedColumnOption | null;
  selectedLastName: TSelectedColumnOption | null;
  selectedNameType: FilterType;
  setSelectedFullName: (value: TSelectedColumnOption | null) => void;
  setSelectedCompanyName: (value: TSelectedColumnOption | null) => void;
  setSelectedFirstName: (value: TSelectedColumnOption | null) => void;
  setSelectedLastName: (value: TSelectedColumnOption | null) => void;
  setSelectedNameType: (value: FilterType) => void;
}) => {
  return (
    <div className="space-y-3">
      <RadioGroup onChange={setSelectedNameType} value={selectedNameType}>
        <Stack direction="row">
          <Radio value="fullName">Full Name</Radio>
          <Radio value="first&lastName">First & Last Name</Radio>
        </Stack>
      </RadioGroup>

      {selectedNameType === "fullName" && (
        <div>
          <p className="pb-1 text-base font-semibold">Full Name</p>
          <SelectColumnDropdown
            selectedOption={selectedFullName}
            setSelectedOption={(value) => {
              const selectedFullName = value as TSelectedColumnOption;
              setSelectedFullName(selectedFullName);
            }}
          />
        </div>
      )}

      {selectedNameType === "first&lastName" && (
        <div className="mb-1">
          <div className="mb-3">
            <p className="pb-1 text-base font-semibold">First Name</p>
            <SelectColumnDropdown
              selectedOption={selectedFirstName}
              setSelectedOption={(value) => {
                const selectedFirstName = value as TSelectedColumnOption;
                setSelectedFirstName(selectedFirstName);
              }}
            />
          </div>
          <div>
            <p className="pb-1 text-base font-semibold">Last Name</p>
            <SelectColumnDropdown
              selectedOption={selectedLastName}
              setSelectedOption={(value) => {
                const selectedLastName = value as TSelectedColumnOption;
                setSelectedLastName(selectedLastName);
              }}
            />
          </div>
        </div>
      )}

      <div>
        <p className="pb-1 text-base font-semibold">Domain/Company Name</p>
        <SelectColumnDropdown
          selectedOption={selectedCompanyName}
          setSelectedOption={(value) => {
            const selectedCompanyName = value as TSelectedColumnOption;
            setSelectedCompanyName(selectedCompanyName);
          }}
        />
      </div>
    </div>
  );
};

export default UserDetailsTab;
