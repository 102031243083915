import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import ConfettiAnimation from "@/components/Common/ConfettiAnimation";

const WelcomeModal = () => {
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const steps = [
    {
      title: "Install our Chrome extesion for LinkedIn prospecting",
      buttonText: "Install",
      link: "https://chromewebstore.google.com/detail/persana-ai-on-linkedin-b2/jdbdgcibmddkccanncenaahimbfcgglj",
    },
    {
      title: "Join our Slack Community",
      buttonText: "Join",
      link: "https://persanaaicommunity.slack.com/join/shared_invite/zt-2d5ym25sm-NBYfXe_QAbbUAs0tPIxc2w#/shared-invite/email",
    },
    {
      title: "Visit the Persana Learning Center",
      buttonText: "Visit",
      link: "https://persana.ai/learn-persana",
    },
    {
      title: "Book a free onboarding call with us",
      buttonText: "Book",
      link: "https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding",
    },
  ];

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  return (
    <>
      <ConfettiAnimation />
      <Modal
        isCentered
        onClose={handleModalClose}
        isOpen={isOpenModal && modalData.modalType === "welcome-modal"}
        motionPreset="scale"
        size="2xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

        <ModalContent className="rounded-[20px]">
          <ModalHeader className="text-center">
            Welcome to Persana 🎊️
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="font-[500]">
              Follow the steps to make the most of your prospecting 👇️
            </Text>
            <div className="flex flex-col gap-4 mt-6 mb-5">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex items-center justify-between"
                >
                  <div className="flex gap-3 items-center">
                    <div className="relative w-[26px] h-[26px] bg-blue-500 rounded-full flex justify-center items-center text-white">
                      <span className="text-[14px] font-bold">{index + 1}</span>
                    </div>
                    <Text className="text-[16px] font-[400]">{step.title}</Text>
                  </div>
                  <Link href={step.link} target="_blank">
                    <Button
                      w={"80px"}
                      size={"sm"}
                      colorScheme="primary"
                      variant="outline"
                      className="!bg-light !text-textPurple !border-none h-[40px] rounded-md"
                    >
                      {step.buttonText}
                    </Button>
                  </Link>
                </div>
              ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WelcomeModal;
