import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";

const Search = () => {
  const searchInput = useEnrichStore((state) => state.searchInput);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <div className="mb-[30px] flex items-center justify-between">
      <InputGroup width="50%">
        <InputLeftElement>
          <Icons.SearchIcon size={20} color="gray" />
        </InputLeftElement>
        <Input
          placeholder="Search Persana Enrichment"
          variant="outline"
          onChange={(e) => {
            updateState({
              searchInput: e.target.value,
              activeCategory: "",
            });
          }}
          value={searchInput}
        />
      </InputGroup>
      <Button
        colorScheme="primary"
        variant="outline"
        leftIcon={<Icons.BackIcon />}
        className="!bg-light !text-textPurple !border-none h-[40px] rounded-md"
        onClick={() => {
          updateState({
            isOpenEnrichModal: false,
          });
        }}
      >
        Go Back
      </Button>
    </div>
  );
};

export default Search;
