import Swal from "sweetalert2";
import { Button } from "@chakra-ui/react";

import SalesoftLogo from "@/assets/images/salesloft-icon.png";
import { userStore } from "@/stores/user.store";

const SalesoftIntegration = () => {
  const currentUser = userStore((state) => state.currentUser);
  const isSalesoftConnected = !!currentUser?.providers?.salesoft?.data;

  const handleIntegration = () => {
    Swal.fire({
      title: "Warning!",
      icon: "warning",
      html:
        "Please contact " +
        '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
        "to upgrade your plan",
      focusConfirm: true,
    });
  };

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={SalesoftLogo} alt={"Salesoft"} className="w-[2rem]" />
        <h2 className="font-semibold">Salesoft</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Salesoft Account`}</p>
        <Button
          colorScheme={isSalesoftConnected ? "red" : "green"}
          variant="outline"
          size="sm"
          //   isLoading={isPending}
          onClick={handleIntegration}
        >
          {isSalesoftConnected ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default SalesoftIntegration;
