import { useEffect, useState } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import { useTableStore } from "@/stores/table.store";

import type { TSelectedColumnOption } from "@/types/common.types";

import { functions, options } from "./constant";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

const CompanyDetails = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [selectedSearchType, setSelectedSearchType] = useState(
    () => options[0],
  );

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  function findObjectByKey(array: any, key: string, value: string) {
    return array.find((item: any) => item[key] === value);
  }

  useEffect(() => {
    if (viewMode !== "edit") {
      functions.map((func) => {
        if (func.value === selectedSearchType.value) {
          const initialColumn = func.getColumn();
          if (initialColumn) {
            setSelectedOption({
              key: initialColumn.name,
              keyId: initialColumn._id,
              iconType: initialColumn.metaData?.iconType || "url",
            });
          }
        }
      });
    } else {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      if (enrichmentData) {
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [columns, selectedSearchType]);

  // Handle Edit Mode
  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

      if (enrichmentData) {
        // FUTURE: This function needs to be changed if we have more than one state to be updated
        const relyOnColumn = columns.find(
          (column) => column._id === enrichmentData.columnId,
        );
        if (relyOnColumn) {
          setSelectedOption({
            key: relyOnColumn.name,
            keyId: relyOnColumn._id,
            iconType: relyOnColumn.metaData?.iconType || "url",
          });
        }

        setSelectedSearchType((prevState) => ({
          ...prevState,
          value: enrichmentData.searchType,
        }));
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4">
        <div className="mb-4">
          <RadioGroup
            onChange={(value) => {
              const foundObject = findObjectByKey(options, "value", value);
              setSelectedSearchType(foundObject);
              setSelectedOption(null);
            }}
            value={selectedSearchType.value}
          >
            <Stack direction="row">
              {options.map((option) => (
                <Radio value={option.value} key={option.value}>
                  {option.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </div>

        <p className="pb-1 text-base font-semibold">
          {selectedSearchType?.label}
        </p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
          searchType: selectedSearchType?.value,
        }}
      />
    </>
  );
};

export default CompanyDetails;
