import Swal from "sweetalert2";
import { Button } from "@chakra-ui/react";

import HubspotLogo from "@/assets/images/hubsport.png";
import { userStore } from "@/stores/user.store";
import userService from "@/services/user.service";

const HubspotIntegration = () => {
  const currentUser = userStore((state) => state.currentUser);
  const isHubspotConnected = !!currentUser?.providers?.hubspot?.data;

  const handleIntegration = async () => {
    Swal.fire({
      title: "Warning!",
      icon: "warning",
      html:
        "Please contact " +
        '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
        "to upgrade your plan",
      focusConfirm: true,
    });
  };

  const removeHubspot = async () => {
    try {
      const data = await userService.disconnectHubspot(currentUser?._id);
      if (data?.success) {
        Swal.fire({
          title: "Success!",
          text: "HubSpot Account Removed Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={HubspotLogo} alt={"Hubspot"} className="w-[2rem]" />
        <h2 className="font-semibold">Hubspot</h2>
      </div>
      <div>
        <p className="mb-4">{`Connect your Hubspot Account`}</p>
        <Button
          colorScheme={isHubspotConnected ? "red" : "green"}
          variant="outline"
          size="sm"
          //   isLoading={isPending}
          onClick={isHubspotConnected ? removeHubspot : handleIntegration}
        >
          {isHubspotConnected ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default HubspotIntegration;
