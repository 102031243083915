import { useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import { Option } from "../Common/MultiSelectDropdown/types";
import { validateEmail } from "@/utils";
import teamService from "@/services/team.service";
import { userStore } from "@/stores/user.store";

interface Props {
  changeTab: (index: number) => void;
}

const AddTeammates = ({ changeTab }: Props) => {
  const [emails, setEmails] = useState<Option[]>([]);
  const [creatingTeam, setCreatingTeam] = useState<boolean>(false);

  const toast = useToast();
  const queryClient = useQueryClient();
  const currentUser = userStore((state) => state.currentUser);
  const { mutateAsync } = useMutation({
    mutationFn: ({ emails }: { emails: string[] }) =>
      teamService.addMembers({
        emails: emails,
      }),
  });

  const handleAdd = async () => {
    if (emails.length === 0) return;

    const emailList = emails.map((email) => email.value);

    if (emailList.some((email) => !validateEmail(email))) {
      toast({
        position: "top-right",
        title:
          "One or more emails are invalid. Please enter a valid email address.",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }

    setCreatingTeam(true);

    await mutateAsync(
      {
        emails: emailList,
      },
      {
        onSuccess: () => {
          toast({
            position: "top-right",
            title: "Team created successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          queryClient.invalidateQueries({
            queryKey: ["team-details"],
          });

          setEmails([]);
          changeTab(1);
          setCreatingTeam(false);
        },
        onError: (error) => {
          setCreatingTeam(false);
          console.log("Error creating team", error);
        },
      },
    );
  };

  return (
    <div className="flex flex-col items-center justify-between pt-5 pb-2 w-[100%] min-h-[200px] font-title">
      <div>
        <h3 className="text-start font-semibold text-lg ">
          Add to{" "}
          <span className="capitalize">
            {currentUser?.profileInfo?.firstName}'s
          </span>{" "}
          Workspace
        </h3>
        <p>Add your teammates down below and start enriching together.</p>
        <div className="w-full flex flex-col mt-4">
          <MultiSelectDropdown
            isInlined
            options={[]}
            onChange={(selected) => {
              setEmails(selected as Option[]);
            }}
            isMulti={true}
            placeholder="Add emails..."
            value={emails}
            closeMenuOnSelect
          />
          <p className="text-sm text-[#4a5568] mt-2 ml-1">
            Tip: Add separate emails by hitting enter or clicking outside of the
            box!
          </p>
        </div>
      </div>
      <Button
        isDisabled={emails.length === 0}
        className="flex !h-[40px] items-center rounded-md border !bg-primary-gradient !text-white !text-sm hover:!shadow-md w-full mt-5"
        onClick={handleAdd}
        isLoading={creatingTeam}
      >
        Add
      </Button>
    </div>
  );
};

export default AddTeammates;
