import { useToast } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Icons from "@/components/Icons";

import tableService from "@/services/table.service";

interface Props {
  tableId: string;
}

const CancelProcess = ({ tableId }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (tableId: string) => tableService.deleteRunningProcess(tableId),
  });

  const handleCancelJob = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, cancel it!",
      showLoaderOnConfirm: true,
      cancelButtonText: "No, keep it",
      preConfirm: async () => {
        return await mutateAsync(tableId, {
          onSuccess: (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top-right",
              });
              return;
            }

            queryClient.refetchQueries({
              queryKey: ["table", tableId],
            });

            toast({
              title: "Process cancelled successfully",
              status: "success",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
        });
      },
    });
  };
  return (
    <Icons.CloseCircleIcon
      className="text-[24px] font-extrabold text-red-600 cursor-pointer"
      onClick={handleCancelJob}
    />
  );
};

export default CancelProcess;
