import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";
import tableService from "@/services/table.service";
import { createANewColumnName } from "../utils";
import type { ColumnCreatePayload } from "@/types/table.types";

type ActionKey =
  | "text"
  | "number"
  | "date"
  | "email"
  | "url"
  | "time"
  | "file"
  | "formula"
  | "enrichment";

const inputMenuList = [
  {
    label: "Text",
    key: "text",
    icon: <Icons.TextIcon />,
    actionKey: "text",
  },
  {
    label: "Number",
    key: "number",
    icon: <Icons.NumbersIcon />,
    isDisabled: true,
    actionKey: "number",
  },
  {
    label: "Date",
    key: "date",
    icon: <Icons.CalendarIcon />,
    isDisabled: true,
    actionKey: "date",
  },
  {
    label: "Email",
    key: "email",
    icon: <Icons.MailIcon />,
    isDisabled: true,
    actionKey: "email",
  },
  {
    label: "URL",
    key: "url",
    icon: <Icons.LinkIcon />,
    isDisabled: true,
    actionKey: "url",
  },
  {
    label: "Time",
    key: "time",
    icon: <Icons.CalendarIcon />,
    isDisabled: true,
    actionKey: "time",
  },
  {
    label: "File",
    key: "file",
    icon: <Icons.ImageIcon />,
    isDisabled: true,
    actionKey: "file",
  },
];

const topMenuList = [
  {
    label: "Formula",
    key: "formula",
    icon: <Icons.FunctionIcon />,
    actionKey: "formula",
  },
  {
    label: "Enrichment",
    key: "enrichment",
    icon: <Icons.LayersIcon />,
    actionKey: "enrichment",
  },
];

interface Props {
  onClose?: () => void;
}

const AddColumn = ({ onClose }: Props) => {
  const toast = useToast();

  const columns = useTableStore((state) => state.tableData.columns);
  const tableId = useTableStore((state) => state.tableData._id);
  const updateState = useTableStore((state) => state.updateState);

  const { mutateAsync: createColumnAsync } = useMutation({
    mutationFn: (payload: ColumnCreatePayload) =>
      tableService.insertColumn(tableId, payload),
  });

  const handleAddColumn = async (actionKey: ActionKey) => {
    toast({
      title: "Creating new column",
      status: "loading",
      duration: 2000,
      isClosable: false,
      position: "top-right",
    });
    onClose && onClose();

    if (actionKey === "text") {
      await createColumnAsync(
        {
          name: createANewColumnName("New Column", columns),
        },
        {
          onSuccess: (response) => {
            if (!response.data?.success) {
              return toast({
                position: "top-right",
                title: response.data?.message || "Failed to create new column",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
            if (response.data?.data) {
              updateState({
                tableData: response.data.data,
              });
            }
            toast({
              position: "top-right",
              title: "New column created",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
          onError: () => {
            toast({
              position: "top-right",
              title: "Failed to create new column",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }
  };

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        size="sm"
        variant="outline"
        leftIcon={<Icons.AddIcon size={20} />}
        className="m-auto w-[94%]"
      >
        Add Column
      </MenuButton>
      <MenuList
        className="p-2"
        sx={{
          shadow:
            "rgba(76, 76, 76, 0.32) 0px 0px 1px, rgba(76, 76, 76, 0.06) 0px 4px 8px, rgb(238, 238, 238) 0px 8px 48px",
          width: "max-content",
        }}
      >
        {topMenuList.map((item) => (
          <MenuItem className="p-2" key={item.key}>
            <div className="flex items-center gap-1">
              {item.icon}
              {item.label}
            </div>
          </MenuItem>
        ))}
        <MenuDivider />
        {inputMenuList.map((item) => (
          <MenuItem
            className="p-2"
            key={item.key}
            isDisabled={item.isDisabled}
            onClick={() => {
              handleAddColumn(item.actionKey as ActionKey);
            }}
          >
            <div className="flex items-center gap-1">
              {item.icon}
              {item.label}
            </div>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default AddColumn;
