import { useMemo } from "react";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";
import { userStore } from "@/stores/user.store";
import { allEnrichments } from "./constants";
import Icons from "../Icons";

const AllEnrichments = () => {
  const activeCategory = useEnrichStore((state) => state.activeCategory);
  const searchInput = useEnrichStore((state) => state.searchInput);
  const currentUser = userStore((state) => state.currentUser);
  const updateState = useEnrichStore((state) => state.updateState);

  const filteredEnrichments = useMemo(() => {
    if (searchInput.trim()) {
      return allEnrichments.filter(
        (item) =>
          item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.subtitle?.toLowerCase().includes(searchInput.toLowerCase()),
      );
    }

    if (!activeCategory.trim()) return allEnrichments;
    if (activeCategory === "All Enrichments") {
      return allEnrichments;
    } else {
      return allEnrichments.filter((item) =>
        !item.categories ? true : item.categories?.includes(activeCategory),
      );
    }
  }, [activeCategory, searchInput]);

  return (
    <div className="custom-scrollbar mx-2 max-h-[360px] overflow-y-auto">
      <Stack direction={"column"} gap={"6px"} overflow={"auto"} py={2}>
        {filteredEnrichments
          .filter((item) => {
            return (
              !item.isHidden ||
              (item.name === "Persana AI Agent 2.0" &&
                currentUser?.role === "admin")
            );
          })
          .map((item) => {
            return (
              <Stack
                ml={1}
                direction={"row"}
                p={"12px"}
                alignItems={"center"}
                cursor={"pointer"}
                gap={"25px"}
                key={item.name}
                className="relative rounded-lg"
                onClick={() => {
                  updateState({
                    selectedEnrichments: item,
                    isOpenEnrichModal: false,
                    viewMode: "create",
                  });
                }}
                background={"#fff"}
                borderRadius={"10px"}
                w={"97%"}
                boxShadow="md"
              >
                <Tooltip
                  hasArrow
                  label="Persana Credits"
                  bg="#2bbf71"
                  fontWeight={500}
                  borderRadius={"lg"}
                  fontSize={"12px"}
                  color="#fff"
                  placement="top"
                >
                  <div className="absolute right-[15px] top-[35%] flex items-center justify-center gap-[4px] rounded-[4px] bg-[#e6fce9] px-[4px] py-[2px] text-[#2bbf71]">
                    <span className="text-[12px] font-[500]">
                      {item.credits}
                    </span>
                    <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
                  </div>
                </Tooltip>
                <div>
                  <Box
                    boxShadow="xl"
                    width={"42px"}
                    height={"42px"}
                    borderRadius={"50%"}
                    padding={"12px"}
                    background={item.iconBg}
                    className="flex items-center justify-center"
                  >
                    {typeof item.icon === "string" ? (
                      <img src={item.icon} alt="enrichment-icon" />
                    ) : (
                      <item.icon
                        color={`${item.name === "Use AI" ? "#0284FE" : "gray"}`}
                      />
                    )}
                  </Box>
                </div>

                <Stack direction={"column"} gap={"8px"}>
                  <Tooltip
                    label={item?.label}
                    bg="gray.300"
                    color="black.500"
                    borderRadius={10}
                  >
                    <Text fontWeight={400} fontSize={"16px"}>
                      {item.name}
                    </Text>
                  </Tooltip>
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </div>
  );
};

export default AllEnrichments;
