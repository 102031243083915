import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

import Icons from "@/components/Icons";
import CSVImport from "./CSVImport";

const _meta = [
  {
    name: "Import From CSV",
    icon: Icons.CSVIcon,
    dsecription: "Upload Your Data By CSV based file",
    modalType: "importCSV",
  },
];

const ImportData = () => {
  const [modalType, setModalType] = useState<"importCSV" | "">("");

  const modalTitle = _meta.find((item) => item.modalType === modalType)?.name;

  return (
    <>
      <div className="px-5">
        {_meta.map((item) => (
          <Card className="mt-2" key={item.name}>
            <CardBody
              className="!p-2 cursor-pointer flex flex-row gap-2 hover:shadow-md items-center"
              onClick={() => {
                setModalType(item.modalType as typeof modalType);
              }}
            >
              <item.icon size={"30"} color="gray" />
              <div>
                <Text fontSize="md" as="b">
                  {item.name}
                </Text>
                <Text fontSize="xs" className="text-darkGray">
                  {item.dsecription}
                </Text>
              </div>
            </CardBody>
          </Card>
        ))}
      </div>

      <Modal
        isCentered
        onClose={() => {
          setModalType("");
        }}
        isOpen={!!modalType}
        motionPreset="scale"
        size="xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent className="rounded-[20px] font-poppins">
          <ModalHeader>
            <p className="text-2xl">{modalTitle}</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalType === "importCSV" && (
              <CSVImport
                onClose={() => {
                  setModalType("");
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportData;
