/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo } from "react";
import {
  CellEditingStoppedEvent,
  ColDef,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import CustomHeader from "./CustomHeader";
import CustomCell from "./CustomCell";
import CellAction from "./PageActions/CellAction";

import { useTableStore } from "@/stores/table.store";
import { convertObject } from "@/utils";
import tableService from "@/services/table.service";
import { RowUpdatePayload } from "@/types/table.types";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

const DataTable = ({ gridRef }: Props) => {
  const toast = useToast();
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");

  const tableData = useTableStore((state) => state.tableData);
  const columnsData = useTableStore((state) => state.tableData.columns);
  const rowsData = useTableStore((state) => state.rowsData);
  const updateState = useTableStore((state) => state.updateState);
  const isCreatedNewColumn = useTableStore((state) => state.isCreatedNewColumn);

  const tableId = tableData._id;

  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      payload,
      rowId,
    }: {
      tableId: string;
      payload: RowUpdatePayload;
      rowId: string;
    }) =>
      tableService.updateRowData({
        tableId,
        payload,
        rowId,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: "Error",
          description: response.error?.message || "Something went wrong",
          status: "error",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
        return;
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error?.message || "Something went wrong",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    },
  });

  const rowData = useMemo(() => {
    const rows = rowsData.map(({ cells, ...rowData }) => {
      return {
        ...convertObject(cells),
        rowData,
      };
    });
    return rows;
  }, [rowsData]);

  const colDefs = useMemo<ColDef[]>(() => {
    const columns = columnsData
      .filter((column) => !column.metaData?.isHidden)
      .map((column) => {
        const col: ColDef = {
          field: column._id,
          headerName: column.name,
          headerComponentParams: {
            columnData: column,
          },
          pinned: column.metaData?.pinned as any,
          cellStyle: { borderRight: "1px solid #e2e8f0" },
        };
        return col;
      });
    return [
      {
        headerName: "",
        valueGetter: (params) => (params?.node?.rowIndex ?? 0) + 1,
        checkboxSelection: true,
        width: 100,
        pinned: "left",
        headerCheckboxSelection: true,
        // cellStyle: { borderRight: "1px solid #e2e8f0" },
      },
      ...columns,
      {
        headerName: "Add Column",
        cellStyle: { borderRight: "1px solid #e2e8f0" },
      },
    ];
  }, [columnsData]);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
      customCell: CustomCell,
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: isTablePreviewPage ? false : true,
      cellDataType: false,
      cellRenderer: "customCell",
      cellEditorPopup: false,
    };
  }, []);

  const onCellEditingStopped = useCallback(
    async (event: CellEditingStoppedEvent) => {
      const newValue = event.value;

      if (!event.valueChanged) {
        return;
      }

      const rowId = event.data?.rowData?._id;
      // @ts-ignore
      const columnId = event.column?.colId;
      const bodyPayload = {
        tableId,
        rowId,
        payload: {
          columnId,
          cellValue: {
            value: newValue,
          },
        },
      };

      const updatedRowsData = rowsData.map((row) => {
        if (row._id === rowId && row.cells[columnId]) {
          row.cells[columnId].value = newValue;
        }
        return row;
      });
      updateState({
        rowsData: updatedRowsData,
      });

      await mutateAsync(bodyPayload);
    },
    [tableId, mutateAsync, rowsData],
  );

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    updateState({
      selectedRowsId: selectedRows.map((row) => row.rowData._id),
    });
  }, []);

  useEffect(() => {
    if (isCreatedNewColumn) {
      if (gridRef.current) {
        const lastColumn = colDefs[colDefs.length - 2];
        if (lastColumn?.field) {
          gridRef.current.api.ensureColumnVisible(lastColumn.field!);
        }
      }
    }
  }, [isCreatedNewColumn, colDefs]);

  return (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: `calc(100vh - 180px)` }}
    >
      <AgGridReact
        rowData={rowData}
        ref={gridRef}
        headerHeight={40}
        rowHeight={38}
        columnDefs={colDefs}
        components={components}
        defaultColDef={defaultColDef}
        getRowId={(params) => params.data.rowData._id}
        onCellEditingStopped={onCellEditingStopped}
        rowClass="border-1 border-gray-200"
        rowSelection="multiple"
        rowMultiSelectWithClick
        suppressColumnMoveAnimation
        suppressRowClickSelection
        suppressPaginationPanel
        onSelectionChanged={onSelectionChanged}
        suppressColumnVirtualisation
        stopEditingWhenCellsLoseFocus
        enterNavigatesVerticallyAfterEdit
      />
      <CellAction />
    </div>
  );
};

export default DataTable;
