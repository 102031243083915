import MakeAPICall from "@/lib/apiCalls";

import type { ProviderListResponse } from "@/types/providers.types";
import type { Actors } from "@/types/apify.types";

class ProviderService {
  constructor(private apiCaller = MakeAPICall) {}

  async getProviderList() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/list",
    });
    const response = await apiCall.get<ProviderListResponse["data"]>();
    return response;
  }

  async deleteProvider(payload: { providerId: string }) {
    const apiCall = new this.apiCaller({
      apiPath: `providers/delete-provider/${payload.providerId}`,
      payload,
    });
    const response = await apiCall.delete();
    return response;
  }

  async saveKey(payload: {
    apiKey: string;
    providerId?: string;
    providerName: string;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/save-api-key",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getSmarLeadsCampaigns() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/smartleads-campaigns",
    });
    const response = await apiCall.get();
    return response;
  }

  async gmailOauth(payload: { code: string }) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/gmail-oauth",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }
  async getApifyActors() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/apify/actors",
    });
    const response = await apiCall.get<Actors[]>();
    return response;
  }
}

const providerService = new ProviderService();

export default providerService;
