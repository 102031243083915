import { useCallback } from "react";
import type { ICellRendererParams } from "ag-grid-community";

import Icons from "../Icons";
import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";

import { isUrl } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { queryClient } from "@/lib/queryClient";

const CustomCell = (props: ICellRendererParams) => {
  const isTablePreviewPage = true;
  const isUserLoggedIn = queryClient.getQueryData(["user-details"]);
  const updateState = useTableStore((state) => state.updateState);
  const handleCellClick = useTableStore((state) => state.handleCellClick);
  const tableRows = useTableStore((state) => state.rowsData);

  const columnDef = props.column?.getColDef();
  let cellValue = props.value || "";
  cellValue =
    typeof cellValue === "object" ? JSON.stringify(cellValue) : cellValue;

  const isLink = isUrl(cellValue);
  const showImageDetails =
    (columnDef?.headerComponentParams?.columnData?.metaData?.enrichmentMetaData
      ?.enrichmentName === "linkedInDetails" ||
      columnDef?.headerComponentParams?.columnData?.metaData?.enrichmentMetaData
        ?.enrichmentName === "companyDetails") &&
    columnDef?.headerComponentParams?.columnData?.metaData?.event === "modal";
  const rowData = props?.data?.rowData;

  const getProfilePhoto = (rowId: string, enrichment: string) => {
    const row = tableRows.filter((item) => item._id === rowId);
    let imageUrl = "";
    const input = row?.[0]?.cells;
    for (const key in input) {
      if (input[key].value === cellValue) {
        imageUrl =
          enrichment === "linkedInDetails"
            ? input[key]?.metaData?.imgDetails?.profile_picture || ""
            : input[key]?.metaData?.imgDetails?.logo || "";
      }
    }
    return (
      imageUrl ||
      columnDef?.headerComponentParams?.columnData?.metaData?.iconImg
    );
  };

  const handleFullView = useCallback(() => {
    updateState({
      expendedCellValue: {
        isOpen: true,
        columnName: columnDef?.headerName || "",
        columnId: props.column?.getColId() || "",
        rowId: props?.data?.rowData?._id || "",
        cellValue: props.value || "",
        rowIndex: props.rowIndex,
      },
    });
  }, [props, isTablePreviewPage]);

  return (
    <>
      <div
        className="group flex h-full w-full cursor-pointer items-center justify-between"
        onContextMenu={(event) => {
          if (!isTablePreviewPage) {
            event.preventDefault();
            const { clientX, clientY } = event;
            updateState({
              contextMenuState: {
                x: clientX,
                y: clientY,
                metaData: {
                  rowId: props?.data?.rowData?._id,
                },
              },
            });
          }
        }}
        onClick={() => {
          if (
            columnDef?.headerComponentParams?.columnData?.metaData?.event ===
              "modal" &&
            !isTablePreviewPage
          ) {
            handleCellClick(
              columnDef?.headerComponentParams?.columnData,
              rowData,
            );
          }
        }}
      >
        {isLink && (
          <Icons.LinkIcon
            className="mr-2  text-primary flex-shrink-0"
            onClick={() => {
              window.open(cellValue, "_blank");
            }}
            size={20}
          />
        )}
        {showImageDetails && (
          <img
            src={getProfilePhoto(
              rowData?._id,
              columnDef?.headerComponentParams?.columnData?.metaData
                ?.enrichmentMetaData?.enrichmentName,
            )}
            className="h-[20px] w-[20px] rounded-full mr-2"
          />
        )}
        <span
          className={`w-[90%] truncate ${
            isLink ? "text-primary" : "text-darkTextGray"
          } ${isTablePreviewPage && props.rowIndex > 9 && !isUserLoggedIn && "blur-sm"}`}
        >
          {cellValue}
        </span>
        {columnDef?.headerComponentParams?.columnData?.metaData?.event !==
          "modal" &&
          !isTablePreviewPage && (
            <Icons.FullscreenIcon
              className="hidden group-hover:inline-block flex-shrink-0"
              onClick={handleFullView}
              size={20}
            />
          )}
        {columnDef?.headerComponentParams?.columnData?.metaData?.event ===
          "modal" &&
          !isTablePreviewPage && (
            <Icons.ArrowDownIcon
              className="flex-shrink-0 text-darkTextGray"
              size={16}
            />
          )}
      </div>
    </>
  );
};

const WrappedCustomCell = (props: ICellRendererParams) => (
  <CustomErrorBoundary>
    <CustomCell {...props} />
  </CustomErrorBoundary>
);

export default WrappedCustomCell;
