import { v4 as uuidv4 } from "uuid";

import { useSearchLeadsStore } from "@/stores/searchleads.store";
import SearchLeadsResultTable from "../Common/SearchLeadsResultTable";

type Props = {
  isSearching: boolean;
  currentSearchData: any;
  onCloseModal: (closeAll?: boolean) => void;
};

const SearchLinkedInComapnyResultIscraper = ({
  isSearching,
  currentSearchData,
  onCloseModal,
}: Props) => {
  const { searchLeadsResult: searchPeopleResult, updateState } =
    useSearchLeadsStore();

  const inputType = {
    eventType: "input",
    iconType: "text",
    event: "doubleClick",
    selectAble: true,
  };

  const getFormattedColumnRows = (columns: any, rowsToFormat: any) => {
    const formattedData = columns?.map((item: any) => {
      switch (item.value) {
        case "Name":
          const rows = rowsToFormat?.map((item: any) => {
            return {
              value: item.name,
            };
          });
          return {
            colId: item.id,
            rows,
          };

        case "Linkedin URL":
          const rows1 = rowsToFormat?.map((item: any) => {
            return {
              value: item.linkedin_url || "",
            };
          });
          return {
            colId: item.id,
            rows: rows1,
          };

        case "Description":
          const rows5 = rowsToFormat?.map((item: any) => {
            return {
              value: item?.description || "",
            };
          });
          return {
            colId: item.id,
            rows: rows5,
          };

        case "Total Stuff":
          const rows2 = rowsToFormat?.map((item: any) => {
            return {
              value: item?.staff_count || "",
            };
          });
          return {
            colId: item.id,
            rows: rows2,
          };

        case "Followers":
          const rows3 = rowsToFormat?.map((item: any) => {
            return {
              value: item?.followers || "",
            };
          });
          return {
            colId: item.id,
            rows: rows3,
          };

        case "Tagline":
          const rows4 = rowsToFormat?.map((item: any) => {
            return {
              value: item?.tagline || "",
            };
          });
          return {
            colId: item.id,
            rows: rows4,
          };

        case "Location":
          const rows6 = rowsToFormat?.map((item: any) => {
            return {
              value: item?.location_country || "",
            };
          });
          return {
            colId: item.id,
            rows: rows6,
          };

        case "Industries":
          const rows7 = rowsToFormat?.map((item: any) => {
            return {
              value: item?.industries?.join(", ") || "",
            };
          });
          return {
            colId: item.id,
            rows: rows7,
          };

        default:
          break;
      }
    });

    return formattedData;
  };

  const generateInitialResultColumns = () => {
    const columNames = [
      "Name",
      "Linkedin URL",
      "Description",
      "Total Stuff",
      "Followers",
      "Tagline",
      "Location",
      "Industries",
    ];

    const idAddedColumNames = columNames.map((item) => {
      return {
        id: uuidv4(),
        value: item,
        ...inputType,
      };
    });

    updateState({
      searchLeadsColumnNames: idAddedColumNames,
    });

    if (searchPeopleResult && searchPeopleResult?.profiles?.length > 0) {
      const columnRows = getFormattedColumnRows(
        idAddedColumNames,
        searchPeopleResult?.profiles,
      );

      updateState({ searchLeadsResultRows: columnRows });
    }
  };

  return (
    <SearchLeadsResultTable
      isSearching={isSearching}
      currentSearchData={currentSearchData}
      getFormattedColumnRows={getFormattedColumnRows}
      generateInitialResultTable={generateInitialResultColumns}
      onCloseModal={onCloseModal}
    />
  );
};

export default SearchLinkedInComapnyResultIscraper;
