import { useState } from "react";
import { PriceBox } from "./PriceBox";
import { useMutation } from "@tanstack/react-query";

import logo from "@/assets/images/logoWithText.png";
import { useModalStore } from "@/stores/modal.store";
import TailwindModalLayout from "@/components/Common/TailwindModalLayout";
import { PricingType } from "@/types/pricing.types";
import Icons from "@/components/Icons";
import userService from "@/services/user.service";

const PricingModal = () => {

  const [isLoading, setIsLoading] = useState<boolean>(false);


  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const { mutateAsync } = useMutation({
    mutationFn: ({
      priceId
    }: {
      priceId: string;
    }) =>
      userService.getSubscriptionLink(priceId),
  });

  const pricings: PricingType[] = [
    {
      title: "Starter",
      description: "Best for individuals and recruiters",
      credits: 2000,
      price: 85,
      priceBeforeDiscount: 170,
      frequency: "month",
      features: [
        "Create unlimited tables",
        "50+ Bulk enrichment",
        "Share workspace",
        "Webhooks",
        "Prompt library",
        "AI research agent",
        "Export to CSV",
        "Chrome extension (LinkedIn)",
        "Email responder (Gmail)",
      ],
      priceId: "price_1OsCoTDoimYFMUv0ZvaeGNe3",
      mostPopular: false,
      action: "upgrade",
    },
    {
      title: "Growth",
      credits: 5000,
      frequency: "month",
      description: "Best for early stage startups and growth agencies.",
      price: 189,
      priceBeforeDiscount: 378,
      features: ["Everything from Starter", "Automate Workflow", "Hiring Triggers", "Job change alerts", "Company news tracking"],
      priceId: "price_1OsCpwDoimYFMUv0cmPW9nrE",
      mostPopular: true,
      action: "upgrade",
    },
    {
      title: "Pro",
      credits: 10000,
      description: "Best for sales teams and growing companies.",
      frequency: "month",
      price: 350,
      priceBeforeDiscount: 700,
      features: [
        "Everything from Accelerate",
        "Custom Intent Scoring",
        "CRM Integration",
      ],
      priceId: "price_1OsCr7DoimYFMUv0wyLVqRgD",
      mostPopular: false,
      action: "upgrade",
    },
    {
      title: "Enterprise",
      price: "Contact us",
      description: "Best for custom plans and higher credits",
      features: [
        "Everything from Pro",
        "Priority support",
        "Higher volume of credits",
      ],
      action: "contact"
    }
  ];

  const handleClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "" },
    });
  };


  const handleActionBtnClick = async (priceId: string) => {
    if (!priceId) {
      window.open("https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding", "_blank");
    } else {
      setIsLoading(true);

      await mutateAsync({ priceId }, {
        onError: (error) => {
          console.log("error", error);
          setIsLoading(false);
        },
        onSuccess: (data) => {
          setIsLoading(false);
          const newWindow = window.open();
          if (newWindow) {
            // @ts-ignore
            newWindow.location.href = data?.link;
          }
        },
      });

    }
  };

  return (
    <TailwindModalLayout modalOpen={isOpenModal && modalData.modalType === "pricing"} setModalOpen={handleClose} closeOnOuterClick overlayClassName="bg-[rgba(0,_0,_0,_0.18)]">
      <div className="font-title relative inline-block h-full transform overflow-hidden text-left align-bottom transition-all sm:w-full sm:max-w-[87rem] sm:align-middle p-4">
        <div className="w-full h-full overflow-hidden p-12 bg-[rgba(255,_255,_255,_0.4)] rounded-[2rem] backdrop-blur-[7px]">
          <Icons.FillCloseCircleIcon
            className="absolute right-4 top-4 cursor-pointer text-4xl text-[#693DC7]"
            onClick={handleClose}
          />

          <div className="pb-8 flex items-center gap-x-3">
            <img src={logo} alt="logo" className="w-36" />
            {/* <p className="font-title gradient-text text-xl">
             ( Launch special 50% off for a limited time )
            </p> */}
          </div>
          <div className="grid grid-cols-4 gap-6 content-center">
            {
              pricings.map((pricing, index) => (
                <PriceBox key={`pricing-${index}`} pricing={pricing} onClick={handleActionBtnClick} isLoading={isLoading} />
              ))
            }
          </div>
        </div>
      </div>
    </TailwindModalLayout>
  );
};

export default PricingModal;
