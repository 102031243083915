import { FileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

import LottiePlayer from "@/components/Common/LottiePlayer";
import TablePage from "@/pages/table";

import { useTableData } from "@/hooks/useTableData";
import { useTableStore } from "@/stores/table.store";

import MainLoader from "@/assets/lottieFiles/main_loader.lottie";
import { useEnrichStore } from "@/stores/enrich.store";
import { TableData } from "@/types/table.types";

export const Route = new FileRoute("/table/$tableId").createRoute({
  parseParams: (params) => ({
    tableId: z.string().parse(String(params.tableId)),
  }),
  component: TableComponent,
});

function TableComponent() {
  const params = Route.useParams();
  const queryClient = useQueryClient();

  const updateState = useTableStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);

  const {
    data,
    isLoading,
    isSuccess,
    isFetched,
    error,
    isRefetching,
    refetch,
  } = useTableData(params.tableId);

  useEffect(() => {
    if (isFetched && data) {
      updateState({
        ...data,
        tableFilters: {
          filters: data?.tableData.metaData?.filters || [],
          open: false,
        },
        sortingProcessingColumnKey: "",
      });
    }
  }, [isFetched, data, updateState]);

  useEffect(() => {
    if (queryParams.pageNumber) {
      refetch();
    }
  }, [queryParams]);

  useEffect(() => {
    return () => {
      updateEnrichmentState({
        selectedEnrichments: null,
        isOpenEnrichModal: false,
      });
      // Clear the table cache data
      queryClient.removeQueries({
        queryKey: ["table", params.tableId],
      });
      // Clear table state
      updateState({
        totalPages: 0,
        totalRows: 0,
        currentPage: 1,
        rowsData: [],
        isSelectedAll: false,
        selectedRowsId: [],
        tableData: {} as TableData,
        tableFilters: {
          filters: [],
          open: false,
        },
        queryParams: {
          limit: 500,
          pageNumber: 1,
          sortingKey: "",
          sortOrder: "ASC",
        },
      });
    };
  }, [params]);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-[90%] w-full">
        <LottiePlayer src={MainLoader} />
        <p className="text-primaryPurple font-medium text-2xl gradient-text">
          Firing up Persana's Brain
        </p>
      </div>
    );
  }
  if (!isSuccess || !data || !tableData?._id || error) {
    return (
      <div className="flex flex-col items-center">
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          alt=""
          className="h-[200px] w-[200px]"
        />
        <span className="text-red-600 inline-block text-lg">
          {error?.message}
        </span>
        <span>
          No Data found for table <b>{params.tableId}</b>
        </span>
      </div>
    );
  }

  return <TablePage isRefetching={isRefetching} />;
}

export default TableComponent;
