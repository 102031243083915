import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectableTextEditor from "../../Common/SelectableTextEditor";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import ApiKey from "./ApiKey";

import { useApifyActors } from "@/hooks/useApify";
import { usePresetsStore } from "@/stores/presets.store";
import type { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useEnrichStore } from "@/stores/enrich.store";

const ApiFy = () => {
  const [selectedActor, setSelectedActor] = useState<Option | null>(null);

  const actorInput = usePresetsStore((state) => state.selectedPresetValues);
  const updateState = usePresetsStore((state) => state.updateState);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const { updateState: updateEnrichState } = useEnrichStore();

  const { isLoading, data } = useApifyActors();

  const actorsList = data?.data || [];

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      if (enrichmentData) {
        updateEnrichState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial subText="Run Your Apify actors" link="" />
        <div>
          <div className="flex justify-between items-end mb-2 relative">
            <div className="flex items-center gap-1">
              <p className="text-sm font-semibold">Apify Actor</p>
              {isLoading && <Spinner size="sm" />}
            </div>
            <ApiKey />
          </div>
          <MultiSelectDropdown
            options={actorsList.map((actor) => ({
              value: actor.id,
              label: actor.title,
            }))}
            onChange={(selected) => {
              setSelectedActor(selected as Option);
            }}
            isMulti={false}
            isCreateAble={false}
            placeholder="Select actor"
            value={selectedActor}
            closeMenuOnSelect
          />
        </div>
        <SelectableTextEditor
          title="Actor's input"
          text={actorInput || ""}
          setText={(newInputText) => {
            updateState({
              selectedPresetValues: newInputText,
            });
          }}
          placeholder="Paste your actor's input here"
          rows={8}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedActor || !actorInput}
        apiPayload={{
          actorId: selectedActor?.value,
          input: actorInput,
          apiKeyName: "apifyApiKey",
          apiKeySelect: "ownApiKey",
        }}
      />
    </>
  );
};

export default ApiFy;
