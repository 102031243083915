import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IHeaderParams } from "ag-grid-community";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";

import { tableActionIcons } from "../Icons/tableActionIcons";
import tableService from "@/services/table.service";
import enrichment from "@/services/enrichment.service";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";

import { addColumnOptions, headOptions } from "./constants";
import { colConditions, conditions } from "./PageActions/Filters/constants";
import { allEnrichments } from "../Enrichment/constants";
import type { Column } from "@/types/table.types";
import { generateUniqueId } from "@/lib/utils";
import { EnrichmentApiCallPayload } from "@/types/enrichment.types";
import Icons from "../Icons";

export interface ICustomHeaderParams extends IHeaderParams {
  columnData: Column;
}

const CustomHeader = (props: ICustomHeaderParams) => {
  const isTablePreviewPage = true;
  const toast = useToast({
    position: "top-right",
    id: "header-changes",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPopoverOpen,
    onToggle,
    onClose: popoverOnClose,
  } = useDisclosure();

  const tableId = useTableStore((state) => state.tableData._id);
  const totalRows = useTableStore((state) => state.totalRows);
  const tableRows = useTableStore((state) => state.rowsData);
  const deleteColumn = useTableStore((state) => state.deleteColumn);
  const updateState = useTableStore((state) => state.updateState);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const sortingProcessingColumnKey = useTableStore(
    (state) => state.sortingProcessingColumnKey,
  );
  const tableFilters = useTableStore((state) => state.tableFilters);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateColumns = useTableStore((state) => state.updateColumns);
  const [columnName, setColumnName] = useState(() => props.displayName);
  const [loadingType, setLoadingType] = useState<"SORTING" | "">("");

  const columnId = props.columnData?._id;

  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      columnId,
    }: {
      tableId: string;
      columnId: string;
    }) => tableService.deleteColumn(tableId, columnId),
  });

  const { mutateAsync: enrichMutationAsync } = useMutation({
    mutationFn: (payload: Partial<EnrichmentApiCallPayload>) =>
      enrichment.callEnrichmentApi(payload),
  });

  const { mutateAsync: updateColumnNameAsync, isPending } = useMutation({
    mutationFn: ({
      tableId,
      name,
      columnId,
    }: {
      tableId: string;
      name: string;
      columnId: string;
    }) =>
      tableService.updateColumnData(tableId, columnId, {
        name,
      }),
  });

  const { mutateAsync: updateColumnPinnedState } = useMutation({
    mutationFn: ({
      tableId,
      pinned,
      columnId,
    }: {
      tableId: string;
      pinned: string;
      columnId: string;
    }) =>
      tableService.updateColumnData(tableId, columnId, {
        // @ts-ignore
        metaData: {
          pinned,
        },
      }),
  });

  const handleDeleteColumn = () => {
    toast.closeAll();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(
          {
            tableId,
            columnId: props.columnData?._id,
          },
          {
            onSuccess: (response) => {
              if (response.success == false) {
                toast({
                  title: "Something went wrong",
                  description: response.error.message,
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                return;
              }

              // Update state
              deleteColumn(props.columnData?._id);

              toast({
                title: `"${props.columnData.name}" Column deleted successfully.`,
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            },
            onError: (error) => {
              toast({
                title: "Something went wrong",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          },
        );
      },
    });
  };

  const handleFilter = () => {
    updateState({
      tableFilters: {
        ...tableFilters,
        open: true,
        filters: [
          ...(tableFilters.filters || []),
          {
            id: generateUniqueId(),
            column: props.columnData,
            type: "input",
            colCondition: colConditions[0],
            condition: conditions[0],
            value: "",
          },
        ],
      },
    });
  };

  const updateColumnName = useCallback(async (columnName: string) => {
    // Call API using debounce
    await updateColumnNameAsync(
      { tableId, name: columnName, columnId: props.columnData?._id },
      {
        onSuccess: (response) => {
          if (response.success == false) {
            toast({
              title: "Something went wrong",
              description: response.error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }

          const updatedColumns = response.data?.columns;

          if (!updatedColumns || !updatedColumns.length) return;

          updateState({
            tableData: {
              ...tableData,
              columns: updatedColumns,
            },
          });

          toast({
            title: `"${props.displayName}" Column name updated successfully`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        onError: (error) => {
          toast({
            title: "Something went wrong",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      },
    );
  }, []);

  const handleSort = useCallback(
    async (type: "ASC" | "DESC") => {
      try {
        setLoadingType("SORTING");

        if (
          queryParams?.sortingKey === props.columnData?._id &&
          queryParams?.sortOrder === type
        ) {
          updateState({
            queryParams: {
              ...queryParams,
              sortingKey: "",
              sortOrder: "ASC",
              pageNumber: 1,
            },
            sortingProcessingColumnKey: columnId,
          });
          return;
        }

        updateState({
          queryParams: {
            ...queryParams,
            sortingKey: props.columnData?._id,
            sortOrder: type === "ASC" ? "ASC" : "DESC",
            pageNumber: 1,
          },
          sortingProcessingColumnKey: columnId,
        });
      } catch (error: any) {
        toast({
          title: "Something went wrong",
          description: error?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoadingType("");
        onClose();
      }
    },
    [queryParams, props.columnData?._id],
  );

  const handleEdit = useCallback(() => {
    const selectedEnrichment = allEnrichments.find((enrichment) => {
      return (
        enrichment.enrichmentName ===
        props.columnData.metaData?.enrichmentMetaData?.enrichmentName
      );
    });
    updateEnrichmentState({
      selectedEnrichments: selectedEnrichment,
      isOpenEnrichModal: false,
      viewMode: "edit",
      selectedColumnToEdit: props.columnData,
    });

    onClose();
  }, []);

  const handlePinColumn = useCallback(() => {
    const pinned = props.columnData?.metaData?.pinned === "left" ? "" : "left";
    const columnId = props.columnData._id;

    updateColumns({
      _id: columnId,
      // @ts-ignore
      metaData: {
        pinned,
      },
    });

    updateColumnPinnedState({
      tableId,
      pinned,
      columnId,
    });

    onClose();
  }, []);

  const clearAllState = () => {
    usePresetsStore.setState({
      selectedPresetValues: "",
      presetsModalData: {},
    });
    useEnrichStore.setState({
      examples: [],
      selectedEnrichments: null,
    });

    useTableStore.setState({
      isSelectedAll: false,
      selectedRowsId: [],
    });
  };

  const isEnrichedColumn = !!props.columnData?.metaData?.isShowReRun;

  const handleRunColumn = async (action: "all" | "failed" | "selected") => {
    onClose();
    popoverOnClose();

    if (!props.columnData?.metaData?.enrichmentMetaData) return;
    const payLoad: Partial<EnrichmentApiCallPayload> = {
      ...props?.columnData?.metaData?.enrichmentMetaData,
      reRunColumnId: props?.columnData?._id,
      selectedRowsId: action === "selected" ? selectedRowsId : [],
      isSelectedAll:
        action === "failed" || action === "selected" ? false : true,
      totalDataToProcess: totalRows,
      action,
    };
    updateTableMetaDta({
      isProcessing: true,
      totalDataToProcess: payLoad.isSelectedAll
        ? payLoad.totalDataToProcess
        : payLoad.selectedRowsId?.length,
      processedData: 0,
    });
    await enrichMutationAsync(payLoad, {
      onSuccess: (response) => {
        if (!response.success || response.data.success === false) {
          toast({
            title:
              response?.error?.message ||
              response?.data?.message ||
              "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          updateTableMetaDta({
            isProcessing: false,
            totalDataToProcess: 0,
            processedData: 0,
          });
          return;
        }
        toast({
          title: response.data?.message || "Enrichment started",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        clearAllState();

        const newAddedColumn = response?.data?.newColumns || [];

        const updatedRows = tableRows.map((row) => {
          const newRow = { ...row };

          // TODO: queued status for failed/empty rows
          if (action === "selected") {
            if (selectedRowsId.includes(row._id)) {
              newRow.cells = {
                ...newRow.cells,
                [newAddedColumn?.[0]._id]: {
                  value: "queued...",
                },
              };
            }
          } else {
            newRow.cells = {
              ...newRow.cells,
              [newAddedColumn?.[0]._id]: {
                value: "queued...",
              },
            };
          }

          return newRow;
        });

        updateState({
          tableData: {
            ...tableData,
            metaData: {
              isProcessing: true,
              processedData: 0,
              totalDataToProcess: payLoad.isSelectedAll
                ? payLoad.totalDataToProcess
                : payLoad.selectedRowsId?.length,
            },
          },
          rowsData: updatedRows,
        });
      },
      onError: (err) => {
        toast({
          title: err.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        updateTableMetaDta({
          isProcessing: false,
          totalDataToProcess: 0,
          processedData: 0,
        });
      },
    });
  };

  const debouncedUpdateColumnName = useMemo(() => {
    return debounce(updateColumnName, 1000);
  }, [updateColumnName]);

  if (props.displayName === "#") {
    return (
      <span className="ml-3">
        <span>{props.displayName}</span>
      </span>
    );
  }

  const HeaderIcon =
    tableActionIcons[props.columnData?.metaData?.iconType || "text"];

  const isAnyColumnProcessing = sortingProcessingColumnKey === columnId;

  return (
    <Menu
      closeOnSelect={false}
      isOpen={!isTablePreviewPage && isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      {props.columnData || props.displayName === "Add Column" ? (
        <MenuButton
          className="hover:bg-light flex items-center h-full w-full cursor-pointer text-left px-2"
          transition="all 0.2s"
          _expanded={{ bg: "#AA6BFA", color: "#FFFFFF" }}
        >
          <div className="flex items-center justify-between w-full">
            <span className="flex gap-2 items-center">
              {props.displayName === "Add Column" ? (
                <Icons.AddIcon2 className="text-[1.1rem]" />
              ) : props.columnData?.metaData?.iconImg ? (
                <img
                  src={props.columnData?.metaData?.iconImg}
                  alt={props.columnData?.metaData?.iconType}
                  className="h-[15px]"
                />
              ) : (
                <HeaderIcon className="h-[15px]" />
              )}
              <span>{props.displayName}</span>
            </span>
            <span className="flex items-center gap-x-3">
              {props.displayName === "Add Column" ? (
                <Icons.ArrowDownIcon className="text-[1.1rem]" />
              ) : null}
              {isAnyColumnProcessing && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="purple.500"
                  size="sm"
                />
              )}
            </span>
          </div>
        </MenuButton>
      ) : null}

      {!isTablePreviewPage && isEnrichedColumn && (
        <Popover isOpen={isPopoverOpen} onClose={popoverOnClose}>
          <PopoverTrigger>
            <div className="px-2 cursor-pointer" onClick={() => onToggle()}>
              <Icons.PlayIcon className="text-[#693DC7]" />
            </div>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody className="flex flex-col justify-center gap-2 p-0 m-0">
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => handleRunColumn("all")}
                  isLoading={isPending}
                  className="m-auto w-[94%]"
                >
                  <span className="flex-1">
                    Rerun <span className="font-bold">all</span>
                  </span>
                </Button>
                {selectedRowsId?.length > 0 && (
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => handleRunColumn("selected")}
                    isLoading={isPending}
                    className="m-auto w-[94%]"
                  >
                    <span className="flex-1">
                      Rerun <span className="font-bold">selected</span> rows
                    </span>
                  </Button>
                )}

                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => handleRunColumn("failed")}
                  isLoading={isPending}
                  className="m-auto w-[94%]"
                >
                  <span className="flex-1">
                    Rerun{" "}
                    <span className="font-bold text-red-400">Failed/Empty</span>{" "}
                    records
                  </span>
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}

      <Portal>
        {props.displayName === "Add Column" ? (
          <MenuList
            width={"max-content"}
            sx={{
              shadow:
                "rgba(76, 76, 76, 0.32) 0px 0px 1px, rgba(76, 76, 76, 0.06) 0px 4px 8px, rgb(238, 238, 238) 0px 8px 48px",
              py: "0",
              p: "0.25rem",
              fontSize: "13.2px",
              fontFamily: "Inter",
              fontWeight: "500",
            }}
          >
            {addColumnOptions.map((option, index) => (
              <MenuItem
                key={`add_column_${index}`}
                sx={{
                  borderRadius: "0rem",
                  px: "0.3rem",
                  _hover: {
                    borderRadius: "5px",
                  },
                }}
                onClick={() => {
                  option.onClick();
                  onClose();
                }}
              >
                <div className="flex items-center text-[0.95rem] font-medium">
                  {/* <addTabelTextIcon.icon className="h-auto w-4 text-[#000000]" />{" "} */}
                  <span className="w-[30px]">{option.icon}</span>
                  <span>{option.name}</span>
                </div>
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <MenuList
            sx={{
              shadow:
                "rgba(76, 76, 76, 0.32) 0px 0px 1px, rgba(76, 76, 76, 0.06) 0px 4px 8px, rgb(238, 238, 238) 0px 8px 48px",
              width: "max-content",
            }}
          >
            <Box px="3" role="menuitem" py="3">
              <InputGroup>
                <Input
                  autoFocus
                  placeholder={"Enter value"}
                  size="sm"
                  value={columnName}
                  onChange={(e) => {
                    const value = e.target.value;

                    setColumnName(value);
                    if (value === props.displayName) return;

                    debouncedUpdateColumnName(value);
                  }}
                  disabled={isPending}
                  className="focus:ring-offset-gray-100 h-full w-full rounded-sm border-primary px-1.5 py-1.5 text-[#1D2026] ring-1 ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                />
                {isPending && (
                  <InputRightElement className="!top-[-4px]">
                    <Spinner color="purple" size="sm" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>

            {headOptions.map((option, index) => {
              const isSelected =
                queryParams?.sortingKey === props?.columnData?._id &&
                option.params?.includes(queryParams?.sortOrder);

              if (option.isHidden) return null;

              if (
                option.params?.includes("selectedRows") &&
                !selectedRowsId.length
              )
                return null;

              if (option.type === "divider") {
                return (
                  <MenuDivider
                    key={`column_action_${index}`}
                    className="!m-0"
                  />
                );
              }
              return (
                <MenuItem
                  key={`column_action_${index}`}
                  className={`p-2 gap-2 ${
                    option.isDanger ? "!text-red-500" : ""
                  } ${isSelected ? "!bg-slate-300" : ""}`}
                  onClick={() => {
                    if (option.menuType === loadingType) return;

                    option?.onClick?.((type) => {
                      if (type === "DELETE") {
                        handleDeleteColumn();
                      } else if (type === "ASC" || type === "DESC") {
                        handleSort(type);
                      } else if (type === "EDIT") {
                        handleEdit();
                      } else if (type === "FILTER") {
                        handleFilter();
                      } else if (type === "PIN") {
                        handlePinColumn();
                      }
                    });
                  }}
                >
                  {option.menuType === loadingType ? (
                    <Spinner size="sm" color="purple" />
                  ) : (
                    <>{option.icon}</>
                  )}
                  {props.columnData?.metaData?.pinned === "left" &&
                  option.name === "Pin" ? (
                    <span>Unpin</span>
                  ) : (
                    <span>{option.name}</span>
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        )}
      </Portal>
    </Menu>
  );
};

const WrappedCustomHeader = (props: ICustomHeaderParams) => (
  <CustomErrorBoundary>
    <CustomHeader {...props} />
  </CustomErrorBoundary>
);

export default WrappedCustomHeader;
