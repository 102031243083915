import Icons from "@/components/Icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Stack,
} from "@chakra-ui/react";

type Props = {
  loading: boolean;
  searchCompanyText: string;
  selectedCompany: any;
  filteredCompanyList: any;
  toggleSortCompanyList: any;
  setSearchCompanyText: (val: any) => void;
  handleClearState: () => void;
  handleCompanyClick: (val: any) => void;
};

const AccountMonitoringSidebar = ({
  loading,
  searchCompanyText,
  selectedCompany,
  filteredCompanyList,
  toggleSortCompanyList,
  setSearchCompanyText,
  handleClearState,
  handleCompanyClick,
}: Props) => {
  return (
    <div className="mt-3 w-[250px] border-r-[1px] border-r-[#B4B4B4]">
      <h2 className="mb-[10px] text-lg font-bold text-[#5F6B77]">Companies</h2>
      <div className="searching mb-[10px] pr-[40px]">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icons.SearchIcon color="black.300" />
          </InputLeftElement>
          <Input
            size={"md"}
            placeholder="Search"
            onChange={(e) => setSearchCompanyText(e.target.value)}
            value={searchCompanyText}
          />
        </InputGroup>
      </div>
      <div className="companies-list pr-3 mt-4">
        <div className="flex items-center gap-5 px-[1px] pb-3">
          <h2 className="flex-1 text-base font-bold text-[#5F6B77]">
            Warmest Companies
          </h2>
          <Icons.SortIcon
            size={20}
            className="cursor-pointer text-xl"
            onClick={() => toggleSortCompanyList()}
          />
          {!!selectedCompany && (
            <Icons.RefreshIcon
              size={20}
              className="cursor-pointer"
              onClick={() => handleClearState()}
            />
          )}
        </div>
        <div className="list max-h-[70vh] overflow-y-auto border-t-[1px] border-t-[#B4B4B4] pt-4">
          {loading && (
            <Stack className="p-[18px] px-[16px]">
              {Array.from({ length: 15 }).map((_, index) => (
                <Skeleton key={index} height="45px" />
              ))}
            </Stack>
          )}

          {!loading &&
            filteredCompanyList?.map((company: any, index: number) => (
              <div
                key={`company-list-${index}`}
                className={`company flex cursor-pointer items-center gap-[10px] rounded-lg p-[8px] hover:bg-faintGray ${
                  company?.companyName === selectedCompany?.companyName
                    ? "bg-primaryLightPink"
                    : ""
                }`}
                onClick={() => {
                  handleCompanyClick(company);
                }}
              >
                <img
                  src={
                    company?.logo ||
                    "https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png"
                  }
                  alt=""
                  className="h-[20px] w-[20px] rounded-full"
                />
                <p className="text-[14px] font-normal text-dark">
                  {company?.companyName || ""}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AccountMonitoringSidebar;
