import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import GenerateLeadsLayout from "./GenerateLeadsLayout";
import { useModalStore } from "@/stores/modal.store";

const GenerateLeadsModal = () => {
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const handleModalClose = (closeAll?: boolean) => {
    if (modalData.metaData?.openPreviousModal && !closeAll) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started", metaData: "" },
      });
    } else {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "", metaData: "" },
      });
    }
  };
  return (
    <Transition.Root
      show={
        isOpenModal &&
        (modalData.modalType === "apollo-people-search" ||
          modalData.modalType === "linkedin-company-search")
      }
      as={Fragment}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-[200] overflow-y-auto"
        onClose={handleModalClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="font-primary relative inline-block h-[87vh] transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[90rem] sm:align-middle">
              <div
                className="h-[18px]"
                style={{
                  background:
                    "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
                  boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
                  borderRadius: "8px 8px 0 0",
                }}
              />
              <GenerateLeadsLayout onCloseModal={handleModalClose} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GenerateLeadsModal;
