import { useRef, useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import Icons from "@/components/Icons";

import leadService from "@/services/leads.service";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { TableData } from "@/types/table.types";

interface Props {
  onClose?: () => void;
}

const CSVImport = ({ onClose }: Props) => {
  const navigate = useNavigate();

  const toast = useToast({
    position: "top-right",
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: FormData;
    }) => leadService.uploadCSV(tableId, payload),
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [dragging, setDragging] = useState(false);

  const tableId = useTableStore((state) => state.tableData._id);
  const updateTableState = useTableStore((state) => state.updateState);
  const updateState = useEnrichStore((state) => state.updateState);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (file?.type !== "text/csv") {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("csvFile", selectedFile!);
    await mutateAsync(
      {
        tableId: tableId || "new",
        payload: formData,
      },
      {
        onSuccess(response) {
          if (response?.success) {
            toast({
              title: "Success",
              description: response.message,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            if (tableId) {
              updateTableState({
                tableData: response.data as unknown as TableData,
              });
            }
            if (!tableId && response.data?.tableId) {
              navigate({
                to: "/table/$tableId",
                params: {
                  tableId: response.data.tableId,
                },
              });
            }
            handleClose();
            updateState({
              isOpenEnrichModal: false,
              selectedEnrichments: null,
            });
          } else {
            toast({
              title: "Error",
              description: response?.message || "Something went wrong",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        },
        onError(error) {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const files = [...e.dataTransfer.files];
    if (files.length > 1) {
      toast({
        title: "Error",
        description: "Please upload only one file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const file = e.dataTransfer.files?.[0] || null;

    if (file?.type !== "text/csv") {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setSelectedFile(file);
  };

  const isDisabled = !selectedFile;

  return (
    <div>
      <p className="mb-2">Upload a CSV for your leads.</p>
      <div className="mb-4">
        <input
          type="file"
          accept=".csv"
          ref={inputRef}
          className="hidden"
          onChange={onFileChange}
        />
        <div
          onClick={() => {
            inputRef?.current?.click();
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDropFile}
          className={`${dragging ? "border-primaryPurple" : "border-gray-200"} dark:!border-navy-700 
          flex h-full w-full cursor-pointer 
          flex-col items-center justify-center 
          rounded-xl border-[2px] border-dashed py-3 lg:pb-0`}
        >
          {selectedFile?.name ? (
            <p className="pb-3 text-textPurple">{selectedFile.name}</p>
          ) : (
            <>
              <Icons.FileUploadIcon className="text-[65px] text-textPurple" />
              <h4 className="pb-2 font-bold text-textPurple">
                Upload or Drag & Drop CSV
              </h4>
            </>
          )}
        </div>
      </div>
      <div className="flex justify-end items-center gap-4">
        <Button
          className="w-[100px]"
          onClick={handleClose}
          isLoading={isPending}
        >
          Cancel
        </Button>
        <Button
          className={`!bg-primary-gradient !text-white w-[100px] ${isDisabled ? "cursor-not-allowed" : ""}`}
          disabled={isDisabled}
          onClick={handleUpload}
          isLoading={isPending}
          isDisabled={isDisabled}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default CSVImport;
