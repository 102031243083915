import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import LeadCSVUpload from "@/components/JobChanges/LeadCSVUpload";
import CompanyCSVUpload from "@/components/HiringIntent/CompanyCSVUpload";

type Props = {
  isOpen: boolean;
  loader: boolean;
  loading: boolean;
  quickAddInput: string;
  placeholder: string;
  csvType: "Lead" | "Company";
  setIsOpen: (val: boolean) => void;
  setQuickAddInput: (val: string) => void;
  handleAdd: () => void;
  fetchPaginationData: () => void;
  handleHubspotImport: () => void;
};

const AccountMonitoringImportModal = ({
  isOpen,
  loader,
  quickAddInput,
  loading,
  placeholder,
  csvType,
  setIsOpen,
  setQuickAddInput,
  fetchPaginationData,
  handleAdd,
  handleHubspotImport,
}: Props) => {
  return (
    <Modal onClose={() => setIsOpen(false)} isOpen={isOpen} isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <ModalHeader>
          <p className="w-full text-center text-2xl"> Quick Add</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="m-auto w-[90%]">
          <div className="mb-4 flex items-center justify-center gap-2">
            <Input
              onChange={(e) => setQuickAddInput(e.target.value)}
              placeholder={placeholder}
              size="md"
              rounded="md"
            />
            <Button
              isLoading={loader}
              onClick={() => handleAdd()}
              className="hover:opacity-80"
              style={{
                background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                color: "#fff",
              }}
              isDisabled={!quickAddInput}
            >
              <div>Add</div>
            </Button>
          </div>

          <div className="flex items-center justify-center gap-5 pb-8 pt-2">
            {csvType === "Lead" ? (
              <LeadCSVUpload fetchPaginationData={fetchPaginationData} />
            ) : (
              <CompanyCSVUpload fetchPaginationData={fetchPaginationData} />
            )}

            <Button
              isLoading={loading}
              onClick={() => handleHubspotImport()}
              className="hover:opacity-80"
              style={{
                background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                color: "#fff",
              }}
            >
              <div>Import from Hubspot</div>
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AccountMonitoringImportModal;
