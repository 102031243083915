import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import Swal from "sweetalert2";

import InputField from "@/components/Common/InputField";
import TextField from "@/components/Common/TextField";

import { userStore } from "@/stores/user.store";
import userService from "@/services/user.service";

const ProfileDetails = () => {
  const { currentUser, refreshCurrentUser } = userStore();
  const [updating, setUpdating] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [inputtedData, setInputtedData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    companyURL: "",
    companyDescription: "",
    companyTarget: "",
    callToAction: "Book a Call",
    assistYouWith: "",
  });

  const handleInputField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputtedData({ ...inputtedData, [name]: value });
  };

  const handleTextField = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInputtedData({ ...inputtedData, [name]: value });
  };

  const generateDescription = async () => {
    setGenerating(true);
    const response = await userService.generateUserCompanyDescription(
      inputtedData?.companyURL,
    );
    if (response?.success) {
      setInputtedData((prevState) => ({
        ...prevState,
        companyDescription: response?.data?.updated.description,
      }));
    }
    setGenerating(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setUpdating(true);

    if (
      !inputtedData?.firstName ||
      !inputtedData?.companyName ||
      !inputtedData?.companyDescription
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please fill in all the Required fields",
        icon: "error",
      });
      setUpdating(false);
      return;
    }

    const response = await userService.updateProfileInfo({
      email: currentUser?.Email,
      info: inputtedData,
    });

    if (response?.success) {
      setUpdating(false);
      Swal.fire({
        title: "Success!",
        text: "Profile updated successfully",
        icon: "success",
      });

      refreshCurrentUser();
    } else {
      setUpdating(false);
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (currentUser?.profileInfo) {
      const profile = currentUser?.profileInfo;
      setInputtedData({
        firstName: profile?.firstName,
        lastName: profile?.lastName || "",
        jobTitle: profile?.jobTitle || "",
        companyName: profile?.companyName,
        companyURL: profile?.companyURL ? profile?.companyURL : "https://",
        companyDescription: profile?.companyDescription,
        companyTarget: profile?.companyTarget,
        callToAction: profile?.callToAction,
        assistYouWith: profile?.assistYouWith || "",
      });
    }
  }, [currentUser]);

  return (
    <section>
      <div className="flex justify-center">
        <Box boxShadow="lg" className="p-5 w-[44rem]">
          <h2 className="text-[20px] font-bold text-center">
            Profile Information
          </h2>
          <form onSubmit={handleSubmit} className="mr-auto mt-5 space-y-5 ">
            <InputField
              label="First name *"
              id="firstName"
              type="text"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.firstName}
            />
            <InputField
              label="Last name"
              id="lastName"
              type="text"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.lastName}
            />
            <InputField
              label="Job title"
              id="jobTitle"
              type="text"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.jobTitle}
            />
            <InputField
              label="Company name *"
              id="companyName"
              type="text"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.companyName}
            />
            <InputField
              label="Company url"
              id="companyURL"
              type="text"
              placeholder="https://"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.companyURL || "https://"}
            />

            <Button
              type="button"
              className="btn-primary-outline min-w-fit rounded text-[16px] font-[500] hover:!text-white !bg-white !text-textPurple hover:!bg-primary-gradient"
              onClick={generateDescription}
              isLoading={generating}
              loadingText={"Generating..."}
            >
              Generate Description
            </Button>
            {inputtedData && (
              <TextField
                label="What does your company do? *"
                id="companyDescription"
                labelClass="font-medium text-lg"
                inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
                minHeight={"10rem"}
                placeholder={
                  "Eg. Persana as an AI platform to help you convert more leads through hyperpersonalization."
                }
                event={handleTextField}
                value={inputtedData?.companyDescription}
              />
            )}
            <InputField
              label="What is your call to action? *"
              id="callToAction"
              type="text"
              placeholder="Eg. Book a call"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.callToAction}
            />
            <InputField
              label="What can we assist you with? *"
              id="assistYouWith"
              type="text"
              placeholder="What can we assist you with?"
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.assistYouWith}
            />
            <InputField
              label="Who do you sell to?"
              id="companyTarget"
              type="text"
              placeholder="Eg. Marketing agencies."
              labelClass="font-medium text-lg"
              inputClass={"!bg-white dark:!bg-navy-700 dark:shadow-none"}
              event={handleInputField}
              value={inputtedData?.companyTarget}
            />
            <Button
              type="submit"
              className="btn-primary-outline min-w-fit rounded text-[16px] font-[500] hover:!text-white !bg-white !text-textPurple hover:!bg-primary-gradient"
              isLoading={updating}
              loadingText={"Updating..."}
            >
              Save Changes
            </Button>
          </form>
        </Box>
      </div>
    </section>
  );
};

export default ProfileDetails;
