export const categoriesList = [
  {
    value: "administration",
    label: "Administration",
  },
  {
    value: "consulting",
    label: "Consulting",
  },
  {
    value: "data_analysis",
    label: "Data analysis",
  },
  {
    value: "design",
    label: "Design",
  },
  {
    value: "directors",
    label: "Directors",
  },
  {
    value: "education",
    label: "Education",
  },
  {
    value: "engineering",
    label: "Engineering",
  },
  {
    value: "finance",
    label: "Finance",
  },
  {
    value: "food",
    label: "Food",
  },
  {
    value: "healthcare_services",
    label: "Healthcare services",
  },
  {
    value: "human_resources",
    label: "Human resources",
  },
  {
    value: "information_technology",
    label: "Information technology",
  },
  {
    value: "internship",
    label: "Internship",
  },
  {
    value: "legal",
    label: "Legal",
  },
  {
    value: "management",
    label: "Management",
  },
  {
    value: "manual_work",
    label: "Manual work",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
  {
    value: "military_and_protective_services",
    label: "Military and protective services",
  },
  {
    value: "operations",
    label: "Operations",
  },
  {
    value: "purchasing",
    label: "Purchasing",
  },
  {
    value: "product_management",
    label: "Product management",
  },
  {
    value: "quality_assurance",
    label: "Quality assurance",
  },
  {
    value: "real_estate",
    label: "Real estate",
  },
  {
    value: "research",
    label: "Research",
  },
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "software_development",
    label: "Software development",
  },
  {
    value: "support",
    label: "Support",
  },
];

export const apolloCompanySize = [
  {
    name: "1-10",
  },
  {
    name: "11-20",
  },
  {
    name: "21-50",
  },
  {
    name: "51-100",
  },
  {
    name: "101-200",
  },
  {
    name: "201-500",
  },
  {
    name: "501-1000",
  },
  {
    name: "1001-2000",
  },
  {
    name: "2001-5000",
  },
  {
    name: "5001-10000",
  },
  {
    name: "10001+",
  },
];

export const jobTitles = [
  {
    name: "Chief Executive Officer (CEO)",
  },
  {
    name: "Chief Operating Officer (COO)",
  },
  {
    name: "General Manager",
  },
  {
    name: "Administrative Assistant",
  },
  {
    name: "Office Manager",
  },
  {
    name: "Operations Manager",
  },
  {
    name: "Executive Assistant",
  },
  {
    name: "Receptionist",
  },
  {
    name: "Project Manager",
  },
  {
    name: "Product Manager",
  },
  {
    name: "Program Manager",
  },
  {
    name: "Chief Financial Officer (CFO)",
  },
  {
    name: "Accountant",
  },
  {
    name: "Financial Analyst",
  },
  {
    name: "Bookkeeper",
  },
  {
    name: "Controller",
  },
  {
    name: "Auditor",
  },
  {
    name: "Tax Specialist",
  },
  {
    name: "Payroll Clerk",
  },
  {
    name: "Credit Analyst",
  },
  {
    name: "Treasurer",
  },
  {
    name: "Marketing Manager",
  },
  {
    name: "Sales Manager",
  },
  {
    name: "Business Development Manager",
  },
  {
    name: "Account Executive",
  },
  {
    name: "Marketing Coordinator",
  },
  {
    name: "Social Media Manager",
  },
  {
    name: "Brand Manager",
  },
  {
    name: "Public Relations Specialist",
  },
  {
    name: "SEO Specialist",
  },
  {
    name: "Digital Marketing Specialist",
  },
  {
    name: "Chief Information Officer (CIO)",
  },
  {
    name: "Software Developer",
  },
  {
    name: "Systems Administrator",
  },
  {
    name: "IT Manager",
  },
  {
    name: "Network Engineer",
  },
  {
    name: "Data Analyst",
  },
  {
    name: "Cybersecurity Specialist",
  },
  {
    name: "Web Developer",
  },
  {
    name: "Database Administrator",
  },
  {
    name: "AI Engineer",
  },
  {
    name: "Human Resources Manager",
  },
  {
    name: "Recruiter",
  },
  {
    name: "HR Coordinator",
  },
  {
    name: "Training and Development Manager",
  },
  {
    name: "Payroll Specialist",
  },
  {
    name: "Benefits Coordinator",
  },
  {
    name: "HR Analyst",
  },
  {
    name: "Employee Relations Manager",
  },
  {
    name: "Talent Acquisition Specialist",
  },
  {
    name: "Doctor",
  },
  {
    name: "Nurse",
  },
  {
    name: "Pharmacist",
  },
  {
    name: "Medical Assistant",
  },
  {
    name: "Healthcare Administrator",
  },
  {
    name: "Physical Therapist",
  },
  {
    name: "Occupational Therapist",
  },
  {
    name: "Dentist",
  },
  {
    name: "Surgeon",
  },
  {
    name: "Psychiatrist",
  },
  {
    name: "Teacher",
  },
  {
    name: "Professor",
  },
  {
    name: "Instructional Designer",
  },
  {
    name: "School Principal",
  },
  {
    name: "Academic Advisor",
  },
  {
    name: "Educational Consultant",
  },
  {
    name: "Librarian",
  },
  {
    name: "Trainer",
  },
  {
    name: "Curriculum Developer",
  },
  {
    name: "Tutor",
  },
  {
    name: "Mechanical Engineer",
  },
  {
    name: "Electrical Engineer",
  },
  {
    name: "Civil Engineer",
  },
  {
    name: "Chemical Engineer",
  },
  {
    name: "Biomedical Engineer",
  },
  {
    name: "Quality Engineer",
  },
  {
    name: "Research Scientist",
  },
  {
    name: "Laboratory Technician",
  },
  {
    name: "Architect",
  },
  {
    name: "Environmental Engineer",
  },
  {
    name: "Graphic Designer",
  },
  {
    name: "Interior Designer",
  },
  {
    name: "Fashion Designer",
  },
  {
    name: "UX/UI Designer",
  },
  {
    name: "Art Director",
  },
  {
    name: "Photographer",
  },
  {
    name: "Videographer",
  },
  {
    name: "Copywriter",
  },
  {
    name: "Illustrator",
  },
  {
    name: "Animator",
  },
  {
    name: "Lawyer",
  },
  {
    name: "Legal Assistant",
  },
  {
    name: "Paralegal",
  },
  {
    name: "Compliance Officer",
  },
  {
    name: "Legal Secretary",
  },
  {
    name: "Contract Manager",
  },
  {
    name: "Patent Attorney",
  },
  {
    name: "Litigation Associate",
  },
  {
    name: "Intellectual Property Lawyer",
  },
  {
    name: "Legal Analyst",
  },
  {
    name: "Chef",
  },
  {
    name: "Event Planner",
  },
  {
    name: "Pilot",
  },
  {
    name: "Construction Worker",
  },
  {
    name: "Electrician",
  },
  {
    name: "Plumber",
  },
  {
    name: "Carpenter",
  },
  {
    name: "Real Estate Agent",
  },
  {
    name: "Flight Attendant",
  },
  {
    name: "Truck Driver",
  },
  {
    name: "Sustainability Coordinator",
  },
  {
    name: "Data Scientist",
  },
  {
    name: "Blockchain Developer",
  },
  {
    name: "Drone Operator",
  },
  {
    name: "AI Research Scientist",
  },
  {
    name: "Virtual Reality Developer",
  },
  {
    name: "eSports Coach",
  },
  {
    name: "Social Media Influencer",
  },
  {
    name: "Podcast Producer",
  },
  {
    name: "User Researcher",
  },
  {
    name: "Intern",
  },
  {
    name: "Junior Manager",
  },
  {
    name: "Trainee",
  },
  {
    name: "Assistant",
  },
  {
    name: "Coordinator",
  },
  {
    name: "Associate",
  },
  {
    name: "Apprentice",
  },
  {
    name: "Junior Accountant",
  },
  {
    name: "Junior Developer",
  },
  {
    name: "Research Assistant",
  },
  {
    name: "Teaching Assistant",
  },
  {
    name: "Graduate Assistant",
  },
  {
    name: "Junior Analyst",
  },
  {
    name: "Intern Engineer",
  },
  {
    name: "Sales Associate",
  },
  {
    name: "Customer Service Representative",
  },
  {
    name: "Junior Designer",
  },
  {
    name: "Junior Consultant",
  },
  {
    name: "Technical Assistant",
  },
  {
    name: "Administrative Intern",
  },
  {
    name: "Marketing Intern",
  },
  {
    name: "HR Intern",
  },
  {
    name: "Finance Intern",
  },
  {
    name: "Legal Intern",
  },
];
