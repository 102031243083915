import Icons from "@/components/Icons";

import { ActionType } from "./types";

export const allActions: {
  name: string;
  maxCards: number;
  actions: {
    title: string;
    description: string;
    icon: JSX.Element;
    highlight?: boolean;
    actionType?: ActionType;
  }[];
}[] = [
  {
    name: "",
    maxCards: 2,
    actions: [
      {
        title: "Find People",
        description:
          "Search by skills, experience, location & more. Reveal contact information, send outreach!",
        icon: <Icons.PeopleArrowIcon className="text-2xl text-textPurple" />,
        actionType: "people",
      },
      {
        title: "Find Companies",
        description:
          "Our company database provides in-depth profiles on millions of public and private companies globally",
        icon: <Icons.WorkloadIcon className="text-2xl text-textPurple" />,
        highlight: true,
        actionType: "companies",
      },
      {
        title: "Find Stargazers on GitHub",
        description: "Find people who have starred a repository.",
        icon: <Icons.GithubIcon className="text-2xl text-textPurple" />,
        actionType: "github",
      },
      {
        title: "Import CSV",
        description:
          "Effortlessly turbocharge your data! Import CSV files and amplify your insights with a range of enrichments.",
        icon: <Icons.FileImportIcon className="text-2xl text-textPurple" />,
        actionType: "csv",
      },
    ],
  },
  {
    name: "Automate Your Workflows",
    maxCards: 3,
    actions: [
      {
        title: "Import Data From Webhook",
        description:
          "Automatically import data from your favorite apps and services.",
        icon: <Icons.WebhookIconMd className="text-2xl text-textPurple" />,
        actionType: "webhook",
      },
    ],
  },
];
