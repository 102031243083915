import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import Swal from "sweetalert2";
import { ColDef, SelectionChangedEvent } from "ag-grid-community";
import { Link, Text } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import TrackActiveJobsModal from "./TrackActiveJobsModal";
import AccountMonitoringSidebar from "../Common/AccountMonitoring/AccountMonitoringSidebar";
import AccountMonitoringActions from "../Common/AccountMonitoring/AccountMonitoringActions";
import AccountMonitoringHeader from "../Common/AccountMonitoring/AccountMonitoringHeader";
import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";

import accountMonitoringService from "@/services/accountMonitoring.service";
import { ActiveJobsType } from "@/types/accountMonitoring.types";
import { Option } from "../Common/MultiSelectDropdown/types";

const HiringJobs = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const gridRef = useRef<AgGridReact>(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trackingModal, setTrackingModal] = useState(false);
  const [searchCompanyText, setSearchCompanyText] = useState<string>("");
  const [tableSearchQuery, setTableSearchQuery] = useState("");
  const [sortCompanyBy, setSortCompanyBy] = useState<"asc" | "desc">("asc");
  const [selectedFilters, setSelectedFilters] = useState<{
    jobTitle: string;
    location: string;
    jobTitleFilter: string;
    numberOfJobs: number;
    experience: Option[];
    jobType: Option[];
    postedAgo: Option;
  }>({
    jobTitle: "",
    location: "",
    jobTitleFilter: "",
    numberOfJobs: 1,
    experience: [],
    jobType: [],
    postedAgo: { label: "Any time", value: "" },
  });
  const [selectedSidebarCompany, setSelectedSidebarCompany] =
    useState<ActiveJobsType | null>(null);
  const [sidebarCompanyList, setSidebarCompanyList] = useState<
    ActiveJobsType[]
  >([]);
  const [companiesData, setCompaniesData] = useState<ActiveJobsType[]>([]);
  const [filteredData, setFilteredData] = useState<ActiveJobsType[]>([]);
  const [companiesList, setCompaniesList] = useState<ActiveJobsType[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<ActiveJobsType[]>(
    [],
  );

  const jobRenderer = (props: any) => {
    return (
      <Link
        style={{ color: "#693DC7" }}
        target="_blank"
        href={props.data.jobPostedUrl}
      >
        {props?.data?.title || ""}
      </Link>
    );
  };

  const jobPostedRenderer = (props: any) => {
    return <Text>{props?.data?.jobPosted?.split("T")?.[0] || ""}</Text>;
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: false,
      cellDataType: false,
    };
  }, []);

  const colDefs: ColDef[] = [
    {
      headerName: "",
      checkboxSelection: true,
      width: 80,
      pinned: "left",
      headerCheckboxSelection: true,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "companyName",
      headerName: "Company",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "title",
      headerName: "Job Title",
      cellRenderer: jobRenderer,
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },

    {
      field: "location",
      headerName: "Location",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "jobType",
      headerName: "Job Type",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "benefits",
      headerName: "Benefits",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
    },
    {
      field: "jobPosted",
      headerName: "Job Posted",
      cellStyle: { borderRight: "1px solid #e2e8f0" },
      cellRenderer: jobPostedRenderer,
    },
  ];

  const toggleSortCompanyList = () => {
    setSortCompanyBy((prevState) => (prevState === "asc" ? "desc" : "asc"));
  };

  function handleSorting(payload: any) {
    return payload.sort((a: any, b: any) => {
      if (a?.jobPosted && b?.jobPosted) {
        return moment(b?.jobPosted).diff(moment(a?.jobPosted));
      } else {
        return a;
      }
    });
  }

  const fetchPaginationData = useCallback(async () => {
    setLoading(true);
    const res = await accountMonitoringService.getAllActiveJobs();
    if (res) {
      const result = handleSorting(res?.data);
      setCompaniesData(result);
      setFilteredData(result);
      setSidebarCompanyList(result);
    }
    setLoading(false);
  }, []);

  const handleClearState = () => {
    setSelectedCompanies([]);
    setSelectedSidebarCompany(null);
    setSearchCompanyText("");
    setSortCompanyBy("asc");
    setFilteredData(companiesData);
    setTableSearchQuery("");
    setSelectedFilters({
      jobTitle: "",
      location: "",
      jobTitleFilter: "",
      numberOfJobs: 1,
      experience: [],
      jobType: [],
      postedAgo: { label: "", value: "" },
    });
  };

  const handleCompanyClick = (item: ActiveJobsType) => {
    if (selectedSidebarCompany?.companyName === item.companyName) {
      setSelectedSidebarCompany(null);
      setFilteredData(companiesData);
      return;
    }
    setSelectedSidebarCompany(item);
    const filtered = companiesData?.filter((companyDetails: ActiveJobsType) => {
      return item.companyName === companyDetails.companyName;
    });
    setFilteredData(filtered);
  };

  const updateSelectedFilters = useCallback(
    (key: string, value: Option[] | Option | string | number) => {
      setSelectedFilters((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const deleteCompanies = async (payload: { jobs: string[] }) => {
    const res = await accountMonitoringService.deleteActiveJobs(payload);
    if (res?.success) {
      toast({
        title: "Success",
        description: "Jobs deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchPaginationData();
    } else {
      toast({
        title: "Error",
        //@ts-ignore
        description: data.message || "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "red",
      cancelButtonColor: "green",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          jobs: selectedCompanies?.map((item) => item._id),
        };
        await deleteCompanies(payload);
        handleClearState();
      }
    });
  };

  const trackAllActiveJobs = async () => {
    const payload = {
      query: selectedFilters.jobTitle,
      location: selectedFilters.location,
    };
    const response = await accountMonitoringService.trackActiveJobs(payload);
    toast({
      title: "Success",
      position: "top-right",
      description: response.data?.status || "Success",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    return response;
  };

  const handleTrack = async () => {
    setLoader(true);
    await trackAllActiveJobs();
    handleClearState();
    await fetchPaginationData();
    setLoader(false);
    setTrackingModal(false);
  };

  const handleTrackModal = (val: boolean) => {
    setTrackingModal(val);
    setSelectedFilters({
      jobTitle: "",
      location: "",
      jobTitleFilter: "",
      numberOfJobs: 1,
      experience: [],
      jobType: [],
      postedAgo: { label: "", value: "" },
    });
  };

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedCompanies(selectedRows);
  }, []);

  const convertToCSV = (data: any) => {
    const { _id, user, createdAt, updatedAt, __v, ...rest } = data[0];
    const headers = Object.keys(rest).join(",");
    const rows = data
      .map((obj: any) => {
        const { _id, user, createdAt, updatedAt, __v, ...rest } = obj;
        const modifiedValues = Object.values(rest).map((value) =>
          typeof value === "string" ? value.replace(/,/g, "") : value,
        );
        return modifiedValues.join(",");
      })
      .join("\n");
    return headers + "\n" + rows;
  };

  const handleExport = () => {
    const payload =
      selectedCompanies?.length > 0 ? selectedCompanies : companiesData;
    const csv = convertToCSV(payload);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "active-jobs.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTableSearch = (query: string) => {
    setTableSearchQuery(query);
    if (query) {
      const queryText = query.toLocaleLowerCase();
      const filtered = companiesData?.filter((item) => {
        return (
          item?.companyName?.toLocaleLowerCase()?.includes(queryText) ||
          item?.title?.toLocaleLowerCase()?.includes(queryText) ||
          item?.location?.toLocaleLowerCase()?.includes(queryText) ||
          item?.companyName?.toLocaleLowerCase()?.includes(queryText) ||
          item?.jobType?.toLocaleLowerCase()?.includes(queryText) ||
          item?.experienceLevel?.toLocaleLowerCase()?.includes(queryText)
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(companiesData);
    }
  };

  const { isPending, mutateAsync } = useMutation({
    mutationFn: () =>
      accountMonitoringService.createTable({
        totalRows: filteredData?.length,
        monitoringType: "activeJobs",
        selectedRowsIds: selectedCompanies?.map((item) => item._id) || [],
      }),
    onSuccess: (response) => {
      if (response.success) {
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data.data?.tableData._id,
          },
        });
      } else {
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    const filteredCompanyList = companiesList
      ?.filter((company: ActiveJobsType) => {
        if (!searchCompanyText) return true;
        return company?.companyName
          ?.toLowerCase()
          ?.includes(searchCompanyText.toLowerCase());
      })
      ?.sort((a: ActiveJobsType, b: ActiveJobsType) => {
        if (sortCompanyBy === "asc") {
          return a?.companyName.localeCompare(b.companyName);
        } else {
          return b?.companyName.localeCompare(a.companyName);
        }
      })
      ?.filter(Boolean);
    setSidebarCompanyList(filteredCompanyList);
  }, [companiesList, searchCompanyText, sortCompanyBy]);

  useEffect(() => {
    if (companiesData.length) {
      const uniqueCompanies = companiesData.reduce(
        (acc: ActiveJobsType[], current: ActiveJobsType) => {
          const x = acc.find(
            (item: ActiveJobsType) =>
              item?.companyName === current?.companyName,
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        },
        [],
      );
      setCompaniesList(uniqueCompanies);
    }
  }, [companiesData]);

  useEffect(() => {
    fetchPaginationData();
  }, [fetchPaginationData]);

  return (
    <div className="flex w-[100%] gap-3">
      <AccountMonitoringSidebar
        loading={loading}
        searchCompanyText={searchCompanyText}
        selectedCompany={selectedSidebarCompany}
        filteredCompanyList={sidebarCompanyList}
        setSearchCompanyText={setSearchCompanyText}
        toggleSortCompanyList={toggleSortCompanyList}
        handleClearState={handleClearState}
        handleCompanyClick={handleCompanyClick}
      />
      <div className="my-3 flex-1 rounded-2xl bg-white">
        <AccountMonitoringHeader
          title="Actively Hiring Companies"
          deleteText="companies"
          monitoringType="active jobs"
          trackBttonText="Track Active Jobs"
          loader={loader}
          loading={loading}
          totalItemsCount={filteredData?.length}
          selectedItemsCount={selectedCompanies?.length}
          showImportButton={false}
          handleDelete={handleDelete}
          handleTrack={setTrackingModal}
        />
        <AccountMonitoringActions
          tableSearchQuery={tableSearchQuery}
          filteredData={filteredData}
          showFilters={false}
          isPending={isPending}
          handleExport={handleExport}
          handleTableSearch={handleTableSearch}
          handleCreate={mutateAsync}
        />
        <div
          className="ag-theme-quartz mt-5"
          style={{ width: "100%", height: "70vh" }}
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={filteredData}
            defaultColDef={defaultColDef}
            headerHeight={40}
            rowHeight={38}
            rowClass="border-1 border-gray-200"
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressColumnMoveAnimation
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
      <TrackActiveJobsModal
        isOpen={trackingModal}
        isLoading={loader}
        selectedFilters={selectedFilters}
        updateSelectedFilters={updateSelectedFilters}
        setIsOpen={handleTrackModal}
        handleTrack={handleTrack}
      />
    </div>
  );
};

const WrappedHiringJobs = () => (
  <CustomErrorBoundary errorMessage="Something went wrong...">
    <HiringJobs />
  </CustomErrorBoundary>
);

export default WrappedHiringJobs;
