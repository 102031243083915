import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

type State = {
  searchLeadsResult: {
    profiles: any[];
    apiPath?: string;
    pagination: {
      total_entries: number;
      total_pages: number;
    };
  } | null;
  searchLeadsColumnNames: any[];
  searchLeadsResultRows: any[];
};

export const useSearchLeadsStore = create<State & Actions>((set) => ({
  searchLeadsResult: null,
  searchLeadsColumnNames: [],
  searchLeadsResultRows: [],
  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("SearchLeads", useSearchLeadsStore);
}
