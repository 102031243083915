import { useState } from "react";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { FaRegCopy } from "react-icons/fa6";
import { FiChevronDown } from "react-icons/fi";

import { ResponseDropdownPropsTypes } from "./ResponseDropdown";
import { createANewColumnName } from "@/components/Table/utils";

function NormalResponseButton({
  itemKey,
  value,
  handleCopyToClipboard,
  path,
  handleAddNewColumn,
}: Partial<ResponseDropdownPropsTypes>) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [newColumnName, setNewColumnName] = useState(
    createANewColumnName(itemKey as string),
  );

  if (!value) return null;

  return (
    <div
      className="relative w-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered || isOpen ? (
        <div className="absolute right-0 top-[3px] flex h-fit items-center gap-x-2 text-sm">
          <button
            className="rounded bg-[#E7E8EC] px-2 py-1.5"
            onClick={() => {
              if (handleCopyToClipboard) {
                handleCopyToClipboard(value);
              }
            }}
          >
            <FaRegCopy />
          </button>

          <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              <button
                onClick={() => {
                  onToggle();
                  // handleAddAsAnewColumn();
                }}
                className="flex items-center gap-x-1 rounded bg-[#E7E8EC] px-2 py-1 text-[12px] font-semibold"
              >
                <span>Add as column</span> <FiChevronDown />
              </button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent top={"-8px"} shadow={"xl"}>
                <PopoverBody className="!font-title">
                  <p className="text-xs font-medium">Add as a new column</p>
                  <div className="grid grid-cols-[repeat(1,_62%_1fr)] gap-x-1.5 pt-1.5">
                    <div className="">
                      <input
                        value={newColumnName}
                        onChange={(e) => setNewColumnName(e.target.value)}
                        type="text"
                        className="border-gray-400 w-full rounded border px-2 py-1 text-sm font-medium focus:border-primary focus:outline-none"
                      />
                    </div>
                    <button
                      onClick={() => {
                        if (handleAddNewColumn) {
                          handleAddNewColumn({ path, newColumnName, itemKey });
                        }
                        onClose();
                      }}
                      // isLoading={isSearching}
                      className="w-full rounded-md bg-[#1e86ff] py-1 font-title text-sm !font-semibold tracking-wide text-white"
                    >
                      Add Column
                    </button>
                  </div>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </div>
      ) : null}
      <button
        className={`flex items-start gap-x-1.5 rounded-md border px-4 py-1.5 ${
          isHovered
            ? "border-primary bg-[#8ebff73f]"
            : "border-gray-300 bg-white"
        }`}
      >
        <span className="block w-fit font-semibold capitalize">{itemKey}</span>:{" "}
        <span className="text-left">{value}</span>
      </button>
    </div>
  );
}

export default NormalResponseButton;
