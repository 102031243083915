import ProcessingBar from "@/components/Common/ProcessingBar";
import Icons from "@/components/Icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import React, { Fragment } from "react";
import Swal from "sweetalert2";

import AddColumn from "./AddColumn";
import AddRow from "./AddRow";
import CancelProcess from "./CancelProcess";
import DownloadsList from "./DownloadsList";
import Filters from "./Filters";
import PendingQueues from "./PendingQueues";
import RemoveRows from "./RemoveRows";
import TableName from "./TableName";
import ShareTable from "./ShareTable";
import TableInfo from "./TableInfo";

import enrichmentSVG from "@/assets/svgs/enrichment.svg";

import tableService from "@/services/table.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
import { allEnrichments } from "@/components/Enrichment/constants";
import ImportData from "./ImportData";
import ExportData from "./ExportData";

interface MenuItemProps {
  onClose?: () => void;
}

const PageActions = () => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (tableId: string) => tableService.deleteTable(tableId),
  });

  const { mutateAsync: copyTableMutation, isPending } = useMutation({
    mutationFn: (tableId: string) => tableService.copyTable(tableId),
  });

  const tableData = useTableStore((state) => state.tableData);
  const isShowLoader = useTableStore((state) => state.isShowLoader);
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const { currentUser } = userStore();
  const updateState = useEnrichStore((state) => state.updateState);

  const handleDeleteTable = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(tableData._id, {
          onSuccess: (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top-right",
              });
              return;
            }
            queryClient.refetchQueries({
              queryKey: ["tables"],
            });
            toast({
              title: "Table deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            navigate({
              to: "/",
            });
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
        });
      },
    });
  };

  const handleCopyTable = () => {
    copyTableMutation(tableData._id, {
      onSuccess: (response) => {
        if (response.success) {
          navigate({
            to: `/table/$tableId`,
            params: {
              tableId: response.data.tableId,
            },
          });
        } else {
          toast({
            title: "Something went wrong",
            description: response.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Something went wrong",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  const processedPercentage =
    ((tableData?.metaData?.processedData || 0) /
      (tableData?.metaData?.totalDataToProcess || 0)) *
      100 || 0;

  const isAnyProcessing = tableData.metaData?.processingType;
  const processingMessage =
    tableData.metaData?.processingMessage || "We're processing your data...";

  const AddColumnMenuItem = ({ onClose }: MenuItemProps) => {
    return (
      <MenuItem className="!p-0" closeOnSelect={false}>
        <AddColumn onClose={onClose} />
      </MenuItem>
    );
  };

  const items = [
    {
      key: "add-row",
      label: (
        <MenuItem className="mb-2 !p-0">
          <AddRow tableId={tableData._id} />
        </MenuItem>
      ),
    },
    {
      key: "add-column",
      label: <AddColumnMenuItem />,
    },
    {
      key: "formula",
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.AddIcon size={20} />}
            className="m-auto w-[94%]"
            onClick={(e) => {
              e.preventDefault();
              updateState({
                generateFormulaModal: {
                  isOpen: true,
                  type: "formula",
                },
              });
            }}
          >
            <span className="flex-1">Formula</span>
          </Button>
        </MenuItem>
      ),
    },
    {
      key: "divider-0",
      label: <MenuDivider />,
    },
    {
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.CopyIcon size={20} />}
            className="m-auto w-[94%]"
            onClick={handleCopyTable}
          >
            <span className="flex-1">Duplicate Data</span>
          </Button>
        </MenuItem>
      ),
      key: "1",
    },
    {
      key: "divider-1",
      label: <MenuDivider />,
    },
    {
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.DeleteIcon size={20} className="text-red-700" />}
            className="m-auto w-[94%]"
            onClick={handleDeleteTable}
          >
            <span className="flex-1">Delete Table</span>
          </Button>
        </MenuItem>
      ),
      key: "3",
    },
  ];

  const encrichmentItems = [
    {
      key: "linkedInDetails",
      name: "Enrich Profile",
      icon: <Icons.LinkedInIcon />,
    },
    {
      key: "findWorkEmail",
      name: "Find Work Email",
      icon: <Icons.FindWorkEmailIcon />,
    },
    {
      key: "generateSalesEmails",
      name: "Generate Sales Emails",
      icon: <Icons.CampaignIcon />,
    },
    {
      key: "askAQuestion",
      name: "Use Agent",
      icon: <Icons.RobotIcon />,
    },
  ];

  if (selectedRowsId?.length) {
    items.push({
      key: "divider-2",
      label: <MenuDivider />,
    });
    items.push({
      label: <RemoveRows />,
      key: "4",
    });
  }

  const handleImportTable = async () => {
    if (currentUser?._id) {
      handleCopyTable();
    } else {
      const URL = `${window.location.pathname}${window.location.search}`;
      navigate({
        to: `/login?redirect=${encodeURIComponent(URL)}` as `/login`,
      });
    }
  };

  const handleEnrichmentButtonClick = (enrichmentKey: string) => {
    const workEmailEnrichment = allEnrichments.find(
      (item) => item.enrichmentName === enrichmentKey,
    );
    updateState({
      selectedEnrichments: workEmailEnrichment,
      isOpenEnrichModal: false,
      viewMode: "create",
    });
  };

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <TableName />
        <div className="flex items-center gap-3">
          {isShowLoader && (
            <div className="mr-3 flex items-center gap-2">
              <Spinner size="lg" colorScheme="purple" />
              <p>One moment please...</p>
            </div>
          )}
          <DownloadsList />
          {isTablePreviewPage ? (
            <Button
              isLoading={isPending}
              colorScheme="primary"
              variant="outline"
              leftIcon={<Icons.DownloadIcon />}
              className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
              onClick={handleImportTable}
            >
              Import Table
            </Button>
          ) : (
            <div className="flex items-center gap-3">
              <ImportData />
              <ExportData />
              <ShareTable />
            </div>
          )}
        </div>
      </div>
      {tableData.metaData?.isProcessing && (
        <div className="mb-5 flex items-center gap-3">
          {tableData?.metaData?.totalDataToProcess ? (
            <div className="w-[200px] max-w-[400px]">
              <ProcessingBar
                processedPercentage={Math.round(processedPercentage)}
                processedRows={tableData?.metaData?.processedData || 0}
                totalRows={tableData?.metaData?.totalDataToProcess || 0}
              />
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <Spinner />
              <p>{processingMessage}</p>
            </div>
          )}
          <CancelProcess tableId={tableData._id} />
        </div>
      )}
      {isAnyProcessing && (
        <div className="mb-5 flex items-center gap-3">
          <Spinner />
          <p>{processingMessage}</p>
        </div>
      )}
      {!isTablePreviewPage && (
        <div className="mb-3 flex items-center justify-between">
          <div className="flex items-center gap-4">
            {encrichmentItems.map((item) => (
              <Button
                className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !bg-gray !text-sm hover:!shadow-md cursor-not-allowed"
                leftIcon={item.icon}
                onClick={() => handleEnrichmentButtonClick(item.key)}
                isDisabled
              >
                {item.name}
              </Button>
            ))}
            <Button
              className="flex items-center rounded-md !bg-primary-gradient !text-white hover:!shadow-md h-[48px] px-[28px] py-[8px] text-[16px] font-semibold cursor-not-allowed"
              leftIcon={<img src={enrichmentSVG} alt="" />}
              onClick={() => {
                updateState({
                  isOpenEnrichModal: true,
                  selectedEnrichments: null,
                });
              }}
              disabled
              isDisabled
            >
              Enrich Data
            </Button>
            <Menu>
              {({ onClose }) => (
                <>
                  <MenuButton
                    as={Button}
                    isLoading={isPending}
                    className="!h-[35px] hover:!shadow-md"
                    isDisabled
                  >
                    <Icons.MoreIcon />
                  </MenuButton>
                  <Portal>
                    <MenuList className="!z-[99999] !bg-white">
                      {items.map((item) => (
                        <Fragment key={item.key}>
                          {React.cloneElement(item.label, { onClose })}
                        </Fragment>
                      ))}
                    </MenuList>
                  </Portal>
                </>
              )}
            </Menu>
            <TableInfo />
          </div>
          <div className="flex items-center gap-4">
            <Filters />
            <PendingQueues />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageActions;
