import { useNavigate } from "@tanstack/react-router";

import OnboardingModal from "@/components/Leads/OnboardingModal";
import { useModalStore } from "@/stores/modal.store";

const CompleteOnboarding = ({ token }: { token: string }) => {
  const navigate = useNavigate();
  const updateState = useModalStore((state) => state.updateState);

  if (!token) {
    navigate({ to: "/login" });
    return;
  }

  const onOnboardingSubmit = (redirectUrl: string) => {
    //@ts-ignore
    navigate({ to: redirectUrl });
    updateState({
      isOpenModal: true,
      modalData: {
        modalType: "welcome-modal",
      },
    });
    //@ts-ignore
    if (typeof window.Intercom === "function") {
      // @ts-ignore
      window.Intercom("startChecklist", 39141957);
    }
  };

  return (
    <section className="h-screen w-full">
      <OnboardingModal
        callFrom="screen"
        token={token}
        redirectUrl={`/`}
        onSubmit={onOnboardingSubmit}
      />
    </section>
  );
};

export default CompleteOnboarding;
