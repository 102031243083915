import { InputFieldProps } from "@/types/common.types";

const InputField = (props: InputFieldProps) => {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    state,
    disabled = false,
    labelClass,
    event,
    inputClass,
    defaultValue,
    required,
    value,
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-[14px] text-secondary ${labelClass}`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        name={id}
        value={value}
        onChange={event}
        defaultValue={defaultValue ? defaultValue : ""}
        placeholder={placeholder}
        required={required}
        className={`mt-2 flex w-full items-center justify-center rounded-md border px-3 py-2 text-sm outline-none focus:outline-primary ${inputClass} ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200"
        }`}
      />
    </div>
  );
};

export default InputField;
