import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectTemplate from "./SelectTemplate";
import Examples from "./Examples";
import SelectableTextEditor from "../../Common/SelectableTextEditor";
import NewCustomPreset from "./NewCustomPreset";
import KeySelect from "./KeySelect";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import { usePresetsStore } from "@/stores/presets.store";
import { useChatGPTTutorialLink } from "../../constants";
import { useEnrichStore } from "@/stores/enrich.store";
import { useEffect } from "react";
import { EnrichmentMetaData } from "@/types/table.types";
import VersionSelect from "./VersionSelect";
import ConditionalFormula from "../../Common/ConditionalFormula";

interface EnrichmentDataType extends EnrichmentMetaData {
  prompts: string;
  isUseOwnKey: boolean;
}

const UseAi = () => {
  const updateState = usePresetsStore((state) => state.updateState);
  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );

  const gptVersion = useEnrichStore((state) => state.gptVersion);
  const _examples = useEnrichStore((state) => state.examples);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const examples = _examples
    .map((exm) => {
      return "\n" + exm.input + "\n" + exm.output;
    })
    .join("\n");

  const payload = {
    prompts: `${selectedPresetValues} ${examples}`,
    gptVersion,
    apiKeyName: "openAiApiKey",
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        updateState({
          selectedPresetValues: enrichmentData.prompts || "",
        });
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: enrichmentData.isUseOwnKey ? "ownApiKey" : "persanaApiKey",
        });
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="flex-grow p-4 overflow-y-auto">
        <WatchTutorial
          subText={"Use AI to answer any question"}
          link={useChatGPTTutorialLink}
        />
        <SelectTemplate />
        <SelectableTextEditor
          title="Write your prompt"
          text={selectedPresetValues || ""}
          setText={(newInputText) => {
            updateState({
              selectedPresetValues: newInputText,
            });
          }}
          rows={8}
          callFrom="use-ai"
        />
        <Examples />
        <NewCustomPreset />
        <KeySelect />
        <VersionSelect />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedPresetValues}
        apiPayload={payload}
      />
    </>
  );
};

export default UseAi;
