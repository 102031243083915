import React from "react";

import DataTable from "@/components/Table";
import PageActions from "@/components/Table/PageActions";

interface Props {
  isRefetching: boolean;
}

const TablePage = ({ isRefetching }: Props) => {
  return (
    <div className="mx-10 mt-4  h-full flex flex-col">
      <PageActions />
      <DataTable isRefetching={isRefetching} />
    </div>
  );
};

export default React.memo(TablePage);
