import questConfig from "@/constants/questConfig";
import { useUserData } from "@/hooks/useUserData";
import { FeedbackWorkflow, QuestProvider } from "@questlabs/react-sdk";
import { useState } from "react";
import Cookies from "universal-cookie";
import feedback from "@/assets/images/feedback.png";

export default function Feedback({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const userData = useUserData();

  let cookies = new Cookies();
  let featureFlag = cookies.get("featureFlags");
  const featureFlagStatus = () => {
    return (
      !!featureFlag &&
      !!featureFlag[questConfig.FLAG_CONSTRAINTS.FEEDBACK_WORKFLOW] &&
      featureFlag[questConfig.FLAG_CONSTRAINTS.FEEDBACK_WORKFLOW]?.isEnabled ===
        true
    );
  };

  return (
    <div className="relative z-9 h-[95vh]">
      {featureFlagStatus() ? (
        <div
          className="fixed -right-[50px] top-1/2 z-40 flex -rotate-90 cursor-pointer items-center gap-2 rounded-t-md bg-[#1972f5] px-5 py-2 pb-3 text-white transition-all hover:scale-[1.1] hover:shadow-2xl"
          onClick={() => setIsOpen(true)}
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
          }}
        >
          <img src={feedback} className="h-5 w-5" />
          <p>Feedback</p>
        </div>
      ) : null}
      <QuestProvider
        apiKey={questConfig.API_KEY}
        apiSecret={questConfig.API_SECRET}
        entityId={questConfig.ENTITY_ID}
      >
        <FeedbackWorkflow
          userId={questConfig.ADMIN_ID}
          token={questConfig.ADMIN_TOKEN}
          questIds={questConfig.FEEDBACK_WORKFLOW_FORM_IDS}
          contactUrl={questConfig.CONTACT_US_REDIRECT_LINK}
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          // zIndex={1000}
          starBorderColor={"#1972f5"}
          starColor={"#1972f5"}
          tickBg={"#1972f5"}
          uniqueUserId={userData?.data?.Email}
          uniqueEmailId={userData?.data?.Email}
          // @ts-ignore
          styleConfig={{
            Modal: {
              zIndex: 1000,
            },
            PrimaryButton: {
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
            },
          }}
        />
      </QuestProvider>
      {children}
    </div>
  );
}
