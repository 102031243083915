import {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

import FieldDetails from "./FieldDetails";
import TextareaField from "./TextareaField";
import OptionsPortal from "./OptionsPortal";

import Icons from "@/components/Icons";

import { generateId } from "@/utils";
import { PersanaInputProps } from "@/types/common.types";
import InputForm from "./InputForm";

function PersanaInput({
  label = "",
  description = "",
  name = "",
  placeholder = "",
  onChange = () => {},
  onClick = () => {},
  value = "",
  type = "",
  rootClassName = "",
  inputClassName = "",
  labelClassName = "",
  showDropdown = false,
  allowSingle = false,
  arrayOfValues = [],
  disabledCustomSelect = false,
  isTextArea = false,
  required = false,
  isDynamic = false,
  optionComponent,
}: PersanaInputProps) {
  const [focused, setFocused] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [inputtedString, setInputtedString] = useState<string>();
  const [dropdownBoundary, setDropdownBoundary] = useState<DOMRect>();
  const [arrayOfItems, setArrayOfItems] = useState<any>(arrayOfValues);
  const [supportiveArrayOfItems, setSupportiveArrayOfItems] =
    useState<any>(arrayOfValues);
  const [dropdownClicked, setDropdownClicked] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownInputRef = useRef<HTMLInputElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !dropdownContentRef?.current?.contains(event.target)
    ) {
      setInputtedString("");
      setShowOptions(false);
      setDropdownClicked(false);
    }
  };

  const handleDropdownOptionClick = (item: any) => {
    onClick({
      name,
      value: value.filter((i: any) => i.name !== item.name),
    });
    setSupportiveArrayOfItems([...supportiveArrayOfItems, item]);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputtedString(e.target.value);
    onChange(e.target);
    if (e.target.value !== "") {
      const filteredItems = supportiveArrayOfItems.filter((item: any) =>
        item?.name?.toLowerCase().includes(e.target.value.toLowerCase()),
      );

      setArrayOfItems(filteredItems);
    } else {
      setArrayOfItems(supportiveArrayOfItems);
    }
  };

  const handleOptionButtonClick = (
    e: MouseEvent<HTMLButtonElement>,
    item: any,
  ) => {
    e.stopPropagation();
    onClick({
      name,
      value: [...value, item],
    });
    setSupportiveArrayOfItems(
      supportiveArrayOfItems.filter((i: any) => i?.name !== item?.name),
    );
    if (dropdownInputRef.current) {
      dropdownInputRef.current?.focus();
    }
    setInputtedString("");
    setShowOptions(false);
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick({
      name,
      value: [
        ...value,
        {
          name: inputtedString,
          id: inputtedString,
        },
      ],
    });
    if (dropdownInputRef.current) {
      dropdownInputRef.current?.focus();
    }
    setInputtedString("");
    setShowOptions(false);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (inputtedString === "" || disabledCustomSelect || isDynamic) {
      return;
    }

    onClick({
      name,
      value: [
        ...value,
        {
          name: inputtedString,
          id: inputtedString,
        },
      ],
    });
    if (dropdownInputRef.current) {
      dropdownInputRef.current?.focus();
    }
    setInputtedString("");
    setShowOptions(false);
  };

  useEffect(() => {
    if (showDropdown) {
      if (dropdownRef.current) {
        const boundary = dropdownRef.current.getBoundingClientRect();
        setDropdownBoundary(boundary);
      }
    }
  }, [dropdownRef, dropdownClicked, value?.length, showDropdown]);

  useEffect(() => {
    if (arrayOfValues?.length > 0 && showDropdown) {
      setArrayOfItems(arrayOfValues);
      setSupportiveArrayOfItems(arrayOfValues);
    }
  }, [arrayOfValues, showDropdown]);

  useEffect(() => {
    // Add when the component mounts
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);

      // Return function to be called when it unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDropdown]);

  return (
    <div className={rootClassName}>
      <FieldDetails
        name={name}
        description={description}
        label={label}
        labelClassName={labelClassName}
        optionComponent={optionComponent}
      />

      {isTextArea ? (
        <TextareaField
          name={name}
          required={required}
          inputClassName={inputClassName}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : showDropdown ? (
        <div
          className={`relative flex h-fit w-full flex-wrap items-center gap-x-1.5 gap-y-2 overflow-x-visible rounded border bg-white px-2 py-1.5 text-sm ${
            focused ? "border-primary" : "border-[#c6c6ca]"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setDropdownClicked(true);
          }}
          ref={dropdownRef}
          id={generateId("dropdownInputContainer")}
        >
          {value?.length > 0 &&
            value?.map((item: any) => {
              return (
                <button
                  type="button"
                  className="flex cursor-default items-center gap-x-0.5 rounded-md bg-[#97bde93f] px-2 py-0.5 font-title text-xs font-semibold"
                >
                  <span>{item?.name}</span>{" "}
                  <Icons.FillCloseCircleIcon
                    className="cursor-pointer text-sm text-primary"
                    onClick={() => {
                      handleDropdownOptionClick(item);
                    }}
                  />
                </button>
              );
            })}
          {!allowSingle || value?.length !== 1 ? (
            <InputForm
              name={name}
              placeholder={placeholder}
              type={type}
              value={value}
              inputtedString={inputtedString || ""}
              inputClassName={inputClassName}
              dropdownInputRef={dropdownInputRef}
              handleInputChange={handleInputChange}
              onSubmit={onSubmit}
              setFocused={setFocused}
              setShowOptions={setShowOptions}
            />
          ) : null}
          {inputtedString || showOptions ? (
            <OptionsPortal
              arrayOfItems={arrayOfItems}
              disabledCustomSelect={disabledCustomSelect}
              dropdownBoundary={dropdownBoundary}
              dropdownContentRef={dropdownContentRef}
              inputtedString={inputtedString || ""}
              isDynamic={isDynamic}
              showOptions={showOptions}
              handleButtonClick={handleButtonClick}
              handleOptionButtonClick={handleOptionButtonClick}
            />
          ) : null}
        </div>
      ) : (
        <input
          type={type || "text"}
          id={name}
          value={value}
          name={name}
          onChange={onChange}
          className={`${twMerge(
            "w-full rounded border border-[#c6c6ca] px-2 py-1.5 text-sm focus:border-primary focus:outline-none focus:ring-0",
            inputClassName,
          )}`}
          placeholder={placeholder}
          required={required || false}
        />
      )}
    </div>
  );
}

export default PersanaInput;
