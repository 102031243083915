import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import WhiteList from "./WhiteList";
import Credits from "./Credits";
import UserLogin from "./UserLogin";

const PAdmin = () => {
  return (
    <Tabs isFitted variant="enclosed" isLazy>
      <TabList>
        <Tab className="font-bold">Whitelisting</Tab>
        <Tab className="font-bold">Credits</Tab>
        <Tab className="font-bold">User Login</Tab>
      </TabList>
      <TabPanels className="border">
        <TabPanel>
          <WhiteList />
        </TabPanel>
        <TabPanel>
          <Credits />
        </TabPanel>
        <TabPanel>
          <UserLogin />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PAdmin;
